import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchStoreDetails } from "../../../services/projects";
import * as actions from "../storeActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";

export default function* fetchStoreDetailsWatcher() {
  yield takeLatest(actions.FETCH, fetchStoreDetailsWorker);
}

export function* fetchStoreDetailsWorker(action) {
  yield put(actions.loading(true));
  try {
    if (action.storeNumber){
      const response = yield call(fetchStoreDetails, {
        storeNumber: parseInt(action.storeNumber),
      });
      yield call(success, response);
    } else {
      yield call(failure, err);
    }  
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));
  yield put(actions.error(null));
  yield put (actions.dataFetched(true));
  let data = [];

  const success = _.get(response, "data.fetchStoreDetailsFromJDE.Success", {});
  if (success === "true") {
    data = _.get(response, "data.fetchStoreDetailsFromJDE.Stores", {});
    yield put(actions.setData(data));
  } else {
    yield call(failure, err);
  }
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put (actions.dataFetched(false));
  yield put(actions.error(error));
  yield put(actions.setData([]));
  yield put(
    errorOpenModal({
      title: "Sorry, could not find store",
      message: "Store details were not fetched using the store number provided",
    }),
  );
}
