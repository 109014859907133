
const environments = [
  {
  PUBLIC_URL:'/cloudeclosing',
  REACT_APP_API_URL:'http://localhost:3000/graphql',
  BASE_URL:'https://localhost:3000/',
  URL_LINK:'https://teams.wal-mart.com/sites/SecDir-Public/DSFAQ/Password%20Construction%20FAQ.aspx',
  REACT_APP_CLIENT_APP_BASE_URL:'http://localhost:8080',
  },
  {
  PUBLIC_URL:'/',
  REACT_APP_API_URL:'https://eclosing-api-dev.walmart.com/graphql',
  BASE_URL:'https://eclosing-api-dev.walmart.com/',
  URL_LINK:'https://teams.wal-mart.com/sites/SecDir-Public/DSFAQ/Password%20Construction%20FAQ.aspx',
  REACT_APP_CLIENT_APP_BASE_URL:'https://eclosing-dev.walmart.com',
},
{
PUBLIC_URL:'/',
REACT_APP_API_URL:'https://eclosing-api.walmart.com/graphql',
BASE_URL:'https://eclosing-api.walmart.com/',
URL_LINK:'https://teams.wal-mart.com/sites/SecDir-Public/DSFAQ/Password%20Construction%20FAQ.aspx',
REACT_APP_CLIENT_APP_BASE_URL:'https://eclosing.walmart.com',
},
{
  PUBLIC_URL:'/',
  REACT_APP_API_URL:'https://eclosing-api-stage.walmart.com/graphql',
  BASE_URL:'https://eclosing-api-stage.walmart.com/',
  URL_LINK:'https://teams.wal-mart.com/sites/SecDir-Public/DSFAQ/Password%20Construction%20FAQ.aspx',
  REACT_APP_CLIENT_APP_BASE_URL:'https://eclosing-stage.walmart.com',
}
];


const config = environments.find(e => e.REACT_APP_CLIENT_APP_BASE_URL.includes(window.location.host) || e.REACT_APP_CLIENT_APP_BASE_URL === window.location.origin);

export default config;
