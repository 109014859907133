export const validate = (values) => {
  const errors = {};
  const specialChars = new RegExp('[`~_!@#\$%\^&\*\(\)\+=,<>\.\?/;:\'\"\{\}\[\\]\|\\\\-]');
  if (!values.password) {
    errors.password = 'Please enter old password';
  }
  if (!values.newPassword) {
    errors.newPassword = 'Please enter password';
  } else if (values.newPassword.length < 8) {
    errors.newPassword = 'Password should contain at least 8 characters';
  } else if (values.newPassword.match('(?=.*[A-Z])') === null) {
    errors.newPassword = 'Password should contain at least one uppercase letter';
  } else if (values.newPassword.match('(?=.*[a-z])') === null) {
    errors.newPassword = 'Password should contain at least one lowercase letter';
  } else if (values.newPassword.match('(?=.*[0-9])') === null) {
    errors.newPassword = 'Password should contain at least one number';
  } else if (values.newPassword.match(specialChars) === null) {
    errors.newPassword = 'Password should contain at least one special character';
  } else if (!values.confirmNewPassword) {
    errors.confirmNewPassword = 'Please confirm your password';
  } else if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = 'Password does not match';
  }
  return errors;
};
