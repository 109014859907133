import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import * as _ from "lodash";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Field, reduxForm } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InputField from "../../_shared/input-field/InputField";
import { uploadDocVersion } from "../../document/documentActions";
import { validateUploadDocVersionFields } from "./ValidateDocInfo";

// eslint-disable-next-line react/prop-types
const customFileInput = ({
  meta: { visited, touched, error },
  input,
  ...custom
}) => {
  delete input.value; // <-- just delete the value property
  return (
    <React.Fragment>
      <InputField {...input} {...custom} error />
      {(touched || visited) && error && (
        <span
          style={{
            color: "#f44336",
            fontFamily: "Arial",
            fontSize: "0.75rem",
            marginLeft: "10px",
          }}>
          {error}
        </span>
      )}
    </React.Fragment>
  );
};
const useStyles = makeStyles(theme => ({
  dialogPaper: {
    margin: theme.spacing(10),
    minWidth: "60vw",
    maxWidth: "60vw",
    padding: theme.spacing(2),
  },
  dialogContent: {
    marginBottom: theme.spacing(1),
    overflowY: "unset",
  },
  loadingSpinner: {
    marginLeft: "40%",
  },
}));
const UploadNewDocVersion = props => {
  const { open, handleClose, reset, isLoading } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      classes={{ paper: classes.dialogPaper }}
      scroll='body'>
      <form onSubmit={values => props.handleSubmit(values)}>
        <Container maxWidth='sm'>
          {isLoading && <CircularProgress className={classes.loadingSpinner} />}
          <DialogTitle id='form-dialog-title'>
            Upload New Document Version
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid container spacing={2} alignItems='center' justify='center'>
              <Grid item md={12}>
                <Field
                  name='document_file'
                  component={customFileInput}
                  inputLabel='Document File'
                  inputType='file'
                  fullWidth
                  inputProps={{ accept: "application/pdf" }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Container>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              handleClose();
            }}
            color='primary'
            disabled={isLoading}>
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            variant='contained'
            disabled={isLoading}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
UploadNewDocVersion.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const uploadDocVersionForm = reduxForm({
  form: "uploadNewDocVersion",
  enableReinitialize: true,
  validate: validateUploadDocVersionFields,
  shouldError: () => true,
  onSubmit: (values, dispatch, props) => {
    dispatch(
      uploadDocVersion({
        documentId: props.documentId,
        docStatus: props.docStatus,
        resetForm: props.reset,
      }),
    );
  },
})(UploadNewDocVersion);
const mapStateToProps = state => ({
  isLoading: _.get(state, "document.request.loading", false),
});
const reduxConnected = connect(mapStateToProps)(uploadDocVersionForm);

export default withRouter(reduxConnected);
