export const ERROR = 'COMMENT/ERROR';
export const FETCH = 'COMMENT/FETCH';
export const LOADING = 'COMMENT/LOADING';
export const SET_DATA = 'COMMENT/SET_DATA';
export const DELETE_ITEM = 'COMMENT/DELETE_ITEM';
export const PROJECT_CREATE = 'PROJECT_COMMENT/CREATE';
export const CATEGORY_CREATE = 'CATEGORY_COMMENT/CREATE';
export const FETCH_CATEGORY_COMMENTS = 'COMMENTS/FETCH_CATEGORY_COMMENTS';
export const RESET_DATA = 'COMMENTS/RESET_DATA';
export const INSERT_ITEM = 'COMMENTS/INSERT_NEW_COMMENT';
export const DELETE_CATEGORY_COMMENT = 'COMMENTS/DELETE_CATEGORY_COMMENT';
export const EDIT_PROJECT_COMMENT = 'COMMENTS/EDIT_PROJECT_COMMENT';
export const EDIT_CATEGORY_COMMENT = 'COMMENTS/EDIT_CATEGORY_COMMENT';
export const UPDATE_ITEM = 'COMMENT/UPDATE_COMMENT';
export const DELETE_PROJECT_COMMENT = 'COMMENTS/DELETE_PROJECT_COMMENT';
export const TOGGLE_COMMENT_RESOLVE = 'COMMENTS/TOGGLE_COMMENT_RESOLVE';
export const CREATE_CATEGORY_COMMENT_REPLY = 'COMMENTS/CREATE_CATEGORY_COMMENT_REPLY';
export const FETCH_ALL_CATEGORY_COMMENTS = 'COMMENT/FETCH_ALL_CATEGORY_COMMENTS';
export const APPEND_DATA = 'COMMENT/APPEND_DATA';
export const SET_DROPDOWN_SELECTION = 'COMMENT/SET_DROPDOWN_SELECTION';
export const DELETE_CATEGORY_COMMENT_RESPONSE = 'COMMENTS/DELETE_CATEGORY_COMMENT_RESPONSE';
export const EDIT_CATEGORY_COMMENT_RESPONSE = 'COMMENTS/EDIT_CATEGORY_COMMENT_RESPONSE';

export const error = err => ({ type: ERROR, error: err });
export const fetch = closingId => ({ type: FETCH, closingId });
export const loading = isLoading => ({ type: LOADING, loading: isLoading });
export const setData = data => ({ type: SET_DATA, data });
export const deleteItem = id => ({ type: DELETE_ITEM, id });
export const updateComment = (id, data) => ({ type: UPDATE_ITEM, id, data });
export const SetNewComment = data => ({ type: INSERT_ITEM, data });
export const createProjectComment = () => ({ type: PROJECT_CREATE });
export const createCategoryComment = categoryId => ({ type: CATEGORY_CREATE, categoryId });
export const fetchCategoryComments = categoryId => ({ type: FETCH_CATEGORY_COMMENTS, categoryId });
export const deleteCategoryComment = (categoryId, sequenceNumber) => ({
  type: DELETE_CATEGORY_COMMENT, categoryId, sequenceNumber,
});
export const deleteCategoryResponse = (categoryId, sequenceNumber, commentDate) => ({
  type: DELETE_CATEGORY_COMMENT_RESPONSE, categoryId, sequenceNumber, commentDate,
});
export const editCategoryComment = categoryId => ({ type: EDIT_CATEGORY_COMMENT, categoryId });
export const editProjectComment = projectId => ({ type: EDIT_PROJECT_COMMENT, projectId });
export const deleteProjectComment = (projectId, sequenceNumber) => ({
  type: DELETE_PROJECT_COMMENT, projectId, sequenceNumber,
});
export const toggleCommentResolve = (categoryId, sequenceNumber, resolve) => ({
  type: TOGGLE_COMMENT_RESOLVE, categoryId, sequenceNumber, resolve,
});
export const createCategoryCommentReply = (categoryId, commentSeqNbr) => ({
  type: CREATE_CATEGORY_COMMENT_REPLY, categoryId, commentSeqNbr,
});
export const fetchAllCategoryCommentsForProject = closingId => ({
  type: FETCH_ALL_CATEGORY_COMMENTS, closingId,
});
export const appendData = data => ({ type: APPEND_DATA, data });
export const setDropdownSelection = (label, value) => ({
  type: SET_DROPDOWN_SELECTION, label, value,
});
export const editCategoryResponse = (categoryId, sequenceNumber, commentDate) => ({
  type: EDIT_CATEGORY_COMMENT_RESPONSE, categoryId, sequenceNumber, commentDate,
});
