import _ from 'lodash';
import { storeBusinnesUnitTypes } from '../../configs/storeBusinessUnitTypes';

export const selectProject = state => _.get(state, 'projectDetails.data', {});

export const selectProjectusers = state => _.values(_.get(state, 'users.data', []));

export const selectProjectDocumentCategory = (state, categoryID) => (
  _.get(state, `projectCategories.data[${categoryID - 1}]`, {})
);

export const selectProjectDetailsList = state => _.values(_.get(state, 'projectDetails.data', {}));

export const selectProjectDocumentCategoriesList = state => _.values(_.get(state, 'projectCategories.data', []));

export const selectProjectDocuments = state => (
  _.values(_.get(state, 'document.data', []))
);
export const selectProjectStatuses = state => _.get(state, 'models.closingStatuses', []);

export const metaDataViewOpen = state => _.get(state, 'projectDetails.projectMetaDataView', {});

export const mapProjectDataToUI = (projectDetails) => {
  const projectData = { ...projectDetails.projectDetails, ...projectDetails.storeDetails };
  const address = {
    label: projectData && projectData.addr_line1 ? projectData.addr_line1 : '',
    value: projectData && projectData.jde_company_nbr ? projectData.jde_company_nbr : '',
  };
  const status = {
    label: projectData && projectData.status_code_desc ? projectData.status_code_desc : '',
    value: projectData && projectData.status_code ? projectData.status_code : '',
  };
  const buTypeCode = projectData ? storeBusinnesUnitTypes.get(projectData.bu_type_code) || projectData.bu_type_code : '';

  return {
    ...projectData,
    addr_line1: address,
    status_code_desc: status,
    bu_type_code: buTypeCode,
  };
};
