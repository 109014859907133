export const ARCHIVE = 'PROJECT/ARCHIVE';
export const EDIT = 'PROJECT/EDIT';
export const CREATE = 'PROJECTS/CREATE';
export const ERROR = 'PROJECT/ERROR';
export const FETCH = 'PROJECT/FETCH';
export const LOADING = 'PROJECT/LOADING';
export const LOCK = 'PROJECT/LOCK';
export const SET_DATA = 'PROJECT/SET_DATA';
export const SET_METADATA_VIEW = 'PROJECT/SET_METADATA_VIEW';
export const UNLOCK = 'PROJECT/UNLOCK';
export const CLOSE = 'PROJECT/CLOSE';
export const DROP = 'PROJECT/DROP';
// TODO remove history argument once react connected router supports
// redux 7+
export const archive = (id, history) => ({ type: ARCHIVE, id, history });
export const edit = history => ({ type: EDIT, history });
export const create = history => ({ type: CREATE, history });
export const error = err => ({ type: ERROR, error: err });
export const fetch = closing_id => ({ type: FETCH, closing_id });
export const loading = isLoading => ({ type: LOADING, loading: isLoading });
export const setData = data => ({ type: SET_DATA, data });
export const setMetaDataView = (metaDataView, document) => ({
  type: SET_METADATA_VIEW, metaDataView, document,
});
export const unlock = (closing_id, history, projectOpen) => ({ type: UNLOCK, closing_id, history, projectOpen });
export const lock = (closing_id, history, projectOpen) => ({ type: LOCK, closing_id, history, projectOpen });
export const close = (closing_id, history, projectOpen) => ({ type: CLOSE, closing_id, history, projectOpen });
export const drop = (closing_id, history, projectOpen) => ({ type: DROP, closing_id, history, projectOpen });