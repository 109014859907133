import { all } from "redux-saga/effects";
import fetchUsers from "../components/users/sagas/fetchUsers";
import searchUsers from "../components/users/sagas/searchUsers";
import fetchProjectUsers from "../components/users/sagas/fetchProjectUsers";
import fetchProjects from "../components/projects/sagas/fetchProjects";
import fetchProjectDetails from "../components/project/sagas/fetchProjectDetails";
import fetchProjectCategories from "../components/project/sagas/fetchProjectCategories";
//import fetchProjectStatus from '../components/project/sagas/fetchProjectStatus';
import fetchDocument from "../components/document/sagas/fetchDocument";
import projectDocSorting from "../components/document/sagas/projectDocSorting";
import fetchDocByProjectCategory from "../components/document/sagas/fetchDocByProjectCategory";
import uploadDocument from "../components/document/sagas/uploadDocument";
import uploadDocumentVersion from "../components/document/sagas/uploadDocumentVersion";
import fetchDocByProject from "../components/document/sagas/fetchDocByProject";
import filterProjects from "../components/projects/sagas/filterProjects";
import archiveProject from "../components/project/sagas/archiveProject";
import editProject from "../components/project/sagas/editProject";
import createProject from "../components/project/sagas/createProject";
import createProjectComment from "../components/comment/sagas/createComment";
import createCategoryComment from "../components/comment/sagas/createCategoryComment";
import fetchComment from "../components/comment/sagas/fetchComments";
import fetchCategoryComment from "../components/comment/sagas/fetchCategoryComment";
import deleteCategoryComment from "../components/comment/sagas/deleteCategoryComment";
import editCategoryComment from "../components/comment/sagas/editCategoryComment";
import editProjectComment from "../components/comment/sagas/editProjectComment";
import deleteProjectComment from "../components/comment/sagas/deleteProjectComment";
import toggleCommentResolve from "../components/comment/sagas/toggleCommentResolve";
import createCategoryCommentReply from "../components/comment/sagas/createCategoryResponse";
import fetchUsersWithRoles from "../components/users/sagas/fetchUsersWithRoles";
import fetchAllCategoryComments from "../components/comment/sagas/fetchAllCategoryComments";
import deleteCategoryResponse from "../components/comment/sagas/deleteCategoryResponse";
import editCategoryResponse from "../components/comment/sagas/editCategoryResponse";
import fetchStoreDetails from "../components/project/sagas/fetchStoreDetails";
import fetchStoreDetailsByCompany from "../components/project/sagas/fetchStoreDetailsByCompany";
import fetchDocumentSubTypes from "../components/document/sagas/fetchDocumentSubTypes";
import fetchDocumentTypes from "../components/document/sagas/fetchDocumentTypes";
import fetchDocumentMetaData from "../components/document/sagas/fetchDocumentMetaData";
import deleteDocument from "../components/document/sagas/deleteDocument";
import editDocumentMetadata from "../components/document/sagas/editDocumentMetadata";
import fetchDocumentVerions from "../components/document/sagas/fetchDocumentVersions";
import unlockProject from "../components/project/sagas/unlockProject";
import lockProject from "../components/project/sagas/lockProject";
import closeProject from "../components/project/sagas/closeProject";
import dropProject from "../components/project/sagas/dropProject";
import editProjectUsers from '../components/users/sagas/editProjectUsers';
import updatePasswprd from '../components/users/sagas/changePassword';
import createTempUserPassword from '../components/users/sagas/createTempUserPassword';
import deleteExternalUser from '../components/users/sagas/deleteExternalUser';
import fetchCompanies from '../components/users/sagas/fetchCompanies';
import createUser from '../components/users/sagas/createUser';

export function* rootSaga() {
  yield all([
    fetchUsers(),
    searchUsers(),
    fetchProjectUsers(),
    fetchProjects(),
    projectDocSorting(),
    fetchProjectDetails(),
    fetchProjectCategories(),
    fetchDocument(),
    fetchDocByProjectCategory(),
    fetchDocByProject(),
    archiveProject(),
    filterProjects(),
    editProject(),
    createProject(),
    createProjectComment(),
    createCategoryComment(),
    fetchComment(),
    fetchCategoryComment(),
    deleteCategoryComment(),
    editCategoryComment(),
    editProjectComment(),
    deleteProjectComment(),
    toggleCommentResolve(),
    createCategoryCommentReply(),
    fetchUsersWithRoles(),
    fetchAllCategoryComments(),
    deleteCategoryResponse(),
    editCategoryResponse(),
    fetchStoreDetails(),
    fetchStoreDetailsByCompany(),
    fetchDocumentTypes(),
    uploadDocument(),
    uploadDocumentVersion(),
    fetchDocumentSubTypes(),
    fetchDocumentMetaData(),
    fetchDocumentVerions(),
    editDocumentMetadata(),
    deleteDocument(),
    unlockProject(),
    lockProject(),
    closeProject(),
    dropProject(),
    editProjectUsers(),
    updatePasswprd(),
    createTempUserPassword(),
    deleteExternalUser(),
    fetchCompanies(),
    createUser(),
  ]);
}
