
import React from 'react';
import { NoLayout } from './components/layouts/NoLayout';
import { EmptyComponent } from './components/EmptyComponent';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';


const LayoutRoute = ({ component: ContentComponent, layout: Layout, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={
      props => (
        authenticated ? (
          <Layout>
            <ContentComponent {...props} />
          </Layout>

        ) : (
            <Redirect to={{
              pathname: '/preLogin',
              state: { from: props.location }
            }} />

          )

      )
    }
  />
);


LayoutRoute.propTypes = {
  component: PropTypes.func,
  layout: PropTypes.func,
};

LayoutRoute.defaultProps = {
  component: EmptyComponent,
  layout: NoLayout,
};


export default LayoutRoute;