import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchStoreDetailsByCompany } from "../../../services/projects";
import * as actions from "../storeActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";

export default function* fetchStoreDetailsByCompanyWatcher() {
  yield takeLatest(actions.FETCH_BY_COMPANY, fetchStoreDetailsByCompanyWorker);
}

export function* fetchStoreDetailsByCompanyWorker(action) {
  yield put(actions.loading(true));

  try {
    if (action.companyNumber) {
      const response = yield call(fetchStoreDetailsByCompany, {
        companyNumber: parseInt(action.companyNumber),
      });
      yield call(success, response);
    } else {
      yield call(failure, error);  
    }
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));
  yield put (actions.dataFetched(true));
  yield put(actions.error(null));
  let data = [];

  const success = _.get(
    response,
    "data.fetchStoreDetailsByCompanyFromJDE.Success",
    {},
  );
  if (success === "true") {
    data = _.get(response, "data.fetchStoreDetailsByCompanyFromJDE.Stores", {});
    yield put(actions.setData(data));
  } else {
    yield call(failure, err);
  }
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  yield put (actions.dataFetched(false));
  yield put(actions.setData([]));
  yield put(
    errorOpenModal({
      title: "Sorry, could not find store",
      message:
        "Store details were not fetched using the company number provided",
    }),
  );
}
