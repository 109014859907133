import React from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Send from '@material-ui/icons/Send';
import GetApp from '@material-ui/icons/GetApp';
import Print from '@material-ui/icons/Print';

import './DocBar.scss';

/**
 * Document Information Bar
 *
 * @namespace Document
 * @memberof document.components
 */

const sampleData = {
  documentName: 'Racon Sheet #5944 04/06/16',
  documentNumber: 1,
  numberOfDocuments: 3,
};

// TODO: implement share model and update test when updating the return
export const handleShare = () => 'handleShare';

// TODO: implement download modal and update test when updating the return
export const handleDownload = () => 'handleDownload';

// TODO: implement print modal and update test when updating the return
export const handlePrint = () => 'handlePrint';

const DocBar = () => (
  <div className="document-bar">
    <div className="back-button">
      {/* TODO: update to prop to go back to document link in the correct category */}
      <Link to="/documents">
        <KeyboardArrowLeft className="icon icon-button" />
      </Link>
    </div>
    <div className="document-info">
      {sampleData.documentName}
    </div>
    <div className="document-number">
      {`${sampleData.documentNumber}/${sampleData.numberOfDocuments}`}
    </div>
    <div className="icon-button" onClick={handleShare}>
      <Send className="icon" />
    </div>
    <div className="icon-button" onClick={handleDownload}>
      <GetApp className="icon" />
    </div>
    <div className="icon-button" onClick={handlePrint}>
      <Print className="icon" />
    </div>
  </div>
);

export default DocBar;
