import SSO from "../../sso";
import _config from "../../config"
export const docStatuses = [
  "In Progress",
  "Auditor Ready",
  "Reviewed for closing",
  "Partially Executed",
  "Fully Executed",
  "Recorded",
  "Public Viewable",
];

export function getDocumentType(value, documentTypes) {
  let returnValue = "";

  if (null != value && undefined != value && "" != value) {
    const index = _.findIndex(documentTypes, { type_name: value });
    if (index >= 0) {
      returnValue = documentTypes[index].label_text;
    } else {
      returnValue = value;
    }
  }
  return returnValue;
}
export function getDateToDisplay(value) {
  let returnValue = "";
  if (null != value && undefined != value && "" != value) {
    let res = value.split("T");
    returnValue = res[0];
  }
  return returnValue;
}
export function getDocumentStatus(value) {
  let returnValue = "";
  if (!_.isUndefined(value) && !_.isNull(value)) {
    const docStatus = value.filter(r => docStatuses.includes(r));
    if (docStatus.length > 0) {
      returnValue = docStatus[0];
    } else {
    }
  }
  return returnValue;
}

export const downloadDocument = (
  documentId,
  isLatestVersion,
  openErrorModel,
  setLoading,
) => {
  const downloadDocUrl = `${_config.BASE_URL}document?docID=${documentId}&isLatestVersion=${isLatestVersion}`;
  setLoading(true, documentId);
  fetch(downloadDocUrl, { credentials: `include` })
    .then(response => {
      if (response.status === 200) {
        return response.blob();
      } else if (response.status === 401) {
        SSO.redirectToSignInUrl(response.signInUrl);
      }
      throw new Error("Something went wrong");
    })
    .then(blob => {
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
      setLoading(false, null);
    })
    .catch(err => {
      setLoading(false, null);

      openErrorModel({
        title: "Something went wrong",
        message: "An error occurred while attempting to locate this document.",
      });
    });
};

export function setDocumentStatus(versionLabel, docStatus) {
  let versionLabelReturn = [];

  if (!_.isUndefined(docStatus) && !_.isNull(docStatus)) {
    versionLabelReturn[0] = versionLabel.find(r => isNaN(r) == false);
    if (versionLabel.find(r => r === "CURRENT")) {
      versionLabelReturn.push("CURRENT");
    }
    versionLabelReturn.push(docStatus);
    console.log(versionLabelReturn);
    return versionLabelReturn;
  } else {
    return versionLabel;
  }
}

export function setDate(date) {
  let returnValue = "";
  if (
    !_.isUndefined(date) &&
    !_.isNull(date) &&
    !_.isNil(date) &&
    date != "null"
  ) {
    returnValue = date;
  }
  return returnValue;
}
