import _ from 'lodash';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { fetchCategoryComments } from '../../../services/comments';
import * as actions from '../commentActions';

export default function* fetchCategoryCommentWatcher() {
  yield takeLatest(actions.FETCH_CATEGORY_COMMENTS, fetchCategoryCommentWorker);
}

export function* fetchCategoryCommentWorker(action) {
  yield put(actions.loading(true));
  try {
    const response = yield call(fetchCategoryComments, { categoryId: action.categoryId });
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.fetchCommentsByCategoryId.Comments', []);
  yield put(actions.setData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
