import {
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';
import _ from 'lodash';
import React from 'react';
import { reset } from 'redux-form';
import CryptoJS from 'crypto-js';
import { sessionService } from "redux-react-session";
import { updatePassword } from '../../../services/users';
import { openSnackbar } from '../../_shared/snack-bar/snackbarActions';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';
import * as actions from '../usersActions';
import SSO from '../../../sso';
import _config from "../../../config"

const formValueSelector = state => _.get(state, 'form.changePassword.values');
const userSelector = state => _.get(state, 'session.user.data.loginId', '');

export default function* updatePasswordWatcher() {
  yield takeLatest(actions.UPDATE_PASSWORD, updatePasswordWorker);
}

export function* updatePasswordWorker() {
  yield put(actions.loading(true));
  const formData = yield select(formValueSelector);
  const formValues = {};
  formValues.userName = yield select(userSelector);
  formValues.password = formData.password;
  formValues.newPassword = formData.newPassword;

  // formValues.password = CryptoJS.AES.encrypt(formData.password, process.env.ENCRYPTION_KEY).toString();
  // formValues.newPassword = CryptoJS.AES.encrypt(formData.newPassword, process.env.ENCRYPTION_KEY).toString();
  try {
   
    const response = yield call(updatePassword, formValues);
    const data = _.get(response, 'data.resetAdUserPassword', { Success: 'false', Message: 'Something went wrong', });
    if (data.Success === 'true') {
      yield call(success, data);
    } else {
      yield call(failure, new Error(data.Message));
    }
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  try {
    const user = yield sessionService.loadUser();
    const { passwordExpiryDays } = user.data.extUserInfo[0].passwordExpiry;
    if (passwordExpiryDays != null && passwordExpiryDays <= 0) {
      // password has been reset now update the expiry flag
      const extUserInfo = user.data.extUserInfo[0];
      const userUpdate = {
        ...user,
        data: {
          ...user.data,
          extUserInfo: [{
            ...extUserInfo,
            passwordExpiry: {
              passwordExpiryDays: null,
              error: extUserInfo.passwordExpiry.error,
            }
          }]
        }
      }
      sessionService.saveUser(userUpdate);
    }
  } catch (error) {
    console.log('error while loading user in updatePassword', error);
  };

  yield put(actions.loading(false));
  yield put(reset('changePassword'));

  yield put(
    openSnackbar({
      message: 'Your password is updated. Please Log In again with new credentials.',
      showCloseIcon: true,
    })
  );

  SSO.signOut();
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  const pwdPolicyLink = <a href={_config.URL_LINK} rel="noopener noreferrer" target="_blank" >here</a>;
  const pwdPolicy = <span>Password is not <b>adhering</b> to Walmart policy. Please follow Walmart password guidelines {pwdPolicyLink}.</span>;
  const errMsg = error.message.toLowerCase().includes('password') ? <span>{error.message} <br />{pwdPolicy}</span> : error.message;
  yield put(
    errorOpenModal({
      title: 'Password could not be updated',
      message: errMsg || 'An error occured while updating the password',
    }),
  );
  return error;
}
