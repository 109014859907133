import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Modal from "./components/_shared/modal/Modal";
import ErrorModal from "./components/_shared/modal/ErrorModal";
import Snackbar from "./components/_shared/snack-bar/Snackbar";
import Routes from "./Routes";
import _config from "./config"



const App = () => (
  <Router basename={`${_config.PUBLIC_URL}`} >
    <Routes/>
    <Modal />
    <Snackbar />
    <ErrorModal />
  </Router>
);

export default App;
