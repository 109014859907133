export default [{
  id: 1,
  value: 'BANK',
}, {
  id: 2,
  value: 'DISTRIBUTION',
}, {
  id: 3,
  value: 'HOME OFFICE',
}, {
  id: 4,
  value: 'GAS STATION',
}, {
  id: 5,
  value: 'CARVE OUT',
}, {
  id: 6,
  value: 'HYPERMART',
}, {
  id: 7,
  value: 'MATTRESS',
}, {
  id: 8,
  value: 'MCDONALDS',
}, {
  id: 9,
  value: 'NEIGHBORHOOD MARKET',
}, {
  id: 10,
  value: 'ONCE OCCUPIED',
}, {
  id: 11,
  value: 'OUTLOT',
}, {
  id: 12,
  value: 'PORTRAITS',
}, {
  id: 13,
  value: 'SAM\'S CLUB',
}, {
  id: 14,
  value: 'SHOPPING CENTER',
}, {
  id: 15,
  value: 'SUPERCENTER',
}, {
  id: 16,
  value: 'TRAILER STORAGE',
}, {
  id: 17,
  value: 'WAL-MART',
}, {
  id: 18,
  value: 'WAREHOUSE',
}];
