import { combineReducers } from 'redux';
import { createDataReducer } from '../../store/dataReducer';
import { createRequestReducer } from '../../store/requestReducer';
import * as actions from './projectActions';

export const initialFilterState = null;
export const initialState = {}

export const isProjectMetadataView = {
  metaDataViewOpen: false,
  selectedDocument: null,
}

export function setProjectMetaDataView(state = isProjectMetadataView, action = {}) {
  switch (action.type) {
    case actions.SET_METADATA_VIEW:
        return {
          metaDataViewOpen: action.metaDataView,
          selectedDocument: action.document,
        }
    default:
      return state;
  }
}

export default combineReducers({
  data: createDataReducer({
    SET_DATA: actions.SET_DATA,
  }),
  request: createRequestReducer({
    ERROR: actions.ERROR,
    LOADING: actions.LOADING,
  }),
  projectMetaDataView: setProjectMetaDataView,
});
