import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import ProjectOverview from './ProjectOverview/ProjectOverview';
import ProjectDocuments from './ProjectDocuments/ProjectDocuments';

export const ProjectContent = ({ match }) => {
  const documentsCategoryId = _.get(match, 'params.documentsCategoryId', null);

  const Content = documentsCategoryId ? <ProjectDocuments documentCategoryID={documentsCategoryId} />
    : <ProjectOverview />;

  return Content;
};

ProjectContent.defaultProps = {
  match: {},
};

ProjectContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};

export default withRouter(ProjectContent);
