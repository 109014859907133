import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reset, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import {
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Button,
  Modal,
  Paper,
  makeStyles,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { validateProjectDetails } from '../shared/ValidateProjectDetails';

import ProjectEditDiscardModalContent from '../edit/ProjectEditDiscardModalContent';

import { create } from '../../projects/projectsActions';
import * as modalActions from '../../_shared/modal/modalActions';
import * as userActions from '../../users/usersActions';

import './ProjectCreate.scss';

import AddProjectDetails from '../shared/projectDetails/ProjectDetailsContainer';
import AddUsers from '../shared/ProjectUsers';
import Review from './Review';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    position: 'absolute',
    left: 'calc(50% - 249px)',
    top: 'calc(50% - 128px)',
    width: '498px',
    height: '257px',
  },
  paperContainer: {
    margin: '43px 49px',
  },
  buttonContainer: {
    display: 'flex',
    float: 'right',
    'margin-right': '48px',
  },
  errorButton: {
    'background-color': '#E62716',
    '&:hover': {
      'background-color': '#CA2213',
    },
  },
  button: {
    margin: '8px',
  },
  popupHeader: {
    'font-size': '24px',
  },
  formContainer: {
    padding: theme.spacing(2),
    'background-color': '#fff',
    'padding-bottom': '20px',
  },
  loadingSpinner: {
    position: 'absolute',
    left: '50%',
  },
  closeButton: {
    position: 'absolute',
    top: '6%',
    right: '20%',
  },
  divClass: {
    width: '100%',
  },
}));

const mapStateToProps = (state) => {
  const { projectCreate } = state.form;
  const usersWithRoles = _.get(state, 'users.usersWithRoles.data');
  const isLoading = _.get(state, 'projects.createProject.request.loading', false);
  const isStoreDetailsLoading = _.get(state, 'store.request.loading', false);
  let isStoreDetailsOnError = _.get(state, 'store.request.error', false);
  isStoreDetailsOnError = _.isNull(isStoreDetailsOnError) ? false : true;
  const isDataFetched = _.get(state, 'store.dataFetched.dataFetched', false);
  console.log("isDataFetched",isDataFetched)
  return { projectCreate, usersWithRoles, isLoading, isStoreDetailsOnError, isStoreDetailsLoading, isDataFetched };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  triggerSubmit: () => dispatch(create(ownProps.history)),
  openConfirmModal: body => dispatch(modalActions.openModal({ body })),
  getUsersWithRoles: () => dispatch(userActions.fetchUsersWithRoles()),
  resetForm: () => dispatch(reset('projectCreate')),
});
const formName = 'projectCreate';
const formSteps = [
  {
    label: 'Add project details',
    content: (<AddProjectDetails form={formName} />),
  },
  {
    label: 'Add users',
    content: (<AddUsers />),
  },
  {
    label: 'Review',
    content: (
      <Review />
    ),
  },
];

const getStepContent = step => formSteps[step].content;

const requiredStepFields = [
  ['closing_name', 'store_nbr', 'project_seq_nbr', 'seller_count', 'parcel_count'],
  ['attorney', 'paraLegal', 'realEstateManager', 'designManager', 'auditor', 'regionalVicePresident', 'accountant', 'outsideCouncil', 'cecFirms'],
];

const hasValue = value => !!(typeof value === 'undefined' || value === null || value === '');

const disableButton = (currentStep, currentState) => _.chain(requiredStepFields[currentStep])
  .map((fieldName) => {
    const fieldValue = _.get(currentState.values, fieldName);
    return !hasValue(fieldValue);
  })
  .some(val => !val)
  .value();


const ProjectFormContent = ({ activeStep }) => getStepContent(activeStep);

export const ProjectCreate = ({
  history,
  projectCreate,
  triggerSubmit,
  openConfirmModal,
  getUsersWithRoles,
  resetForm,
  isLoading,
  isStoreDetailsLoading,
  isStoreDetailsOnError,
  isDataFetched,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  useEffect(() => {
    getUsersWithRoles();
  }, {});
  const handleNext = currentStep => (
    currentStep + 1 < formSteps.length ? setActiveStep(currentStep + 1) : triggerSubmit()
  );

  const checkIfProgressHasStarted = (currentState) => {
    const progressHasStarted = _.chain(requiredStepFields)
      .map(subArray => _.map(subArray, fieldName => !!_.get(currentState.values, fieldName)))
      .flatten()
      .some(val => val)
      .value();

    if (progressHasStarted) {
      showPopup();
    } else {
      onCancel();
    }
  };

  const showPopup = () => {
    openConfirmModal(
      <ProjectEditDiscardModalContent
        onDiscard={onCancel}
      />
    );
  }

  const onCancel = () => {
    resetForm(),
      setActiveStep(0);
    history.push('/projects');
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={classes.divClass}>
      <Modal
        open={false}
      >
        <Paper className={classes.modalContainer}>
          <div className={classes.paperContainer}>
            <Typography className={classes.popupHeader}>
              Discard project?
            </Typography>
            <Typography className={classes.popupContent}>
              If you leave now, you&apos;ll lose all of your work.
              Are you sure you want to discard this project?
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Button variant="contained" className={classes.button}>
              Stay
            </Button>
            <Button variant="contained" color="primary" className={`${classes.errorButton} ${classes.button}`}>
              Discard
            </Button>
          </div>
        </Paper>
      </Modal>

      <Grid container spacing={10} className="grid-container">
        <Grid item xs={2} />
        <Grid item xs={8} className="app-view">
          <div className="project-create-container">
            <div>
              {isLoading && <CircularProgress className={classes.loadingSpinner} />}
              <Typography className="project-create-title" gutterBottom>
                Create Project
              </Typography>
              <IconButton
                className={classes.closeButton}
                disabled={isLoading}
              >
                <Close onClick={() => checkIfProgressHasStarted(projectCreate)} />
              </IconButton>
              <div className="stepper-container">
                <Stepper activeStep={activeStep}>
                  {formSteps.map(obj => (
                    <Step key={obj.label}>
                      <StepLabel>{obj.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
              <div className="form-container">
                <form className={classes.formContainer}>
                  <ProjectFormContent activeStep={activeStep} />
                </form>
              </div>
              <div className="project-create-button-container">
                <Button disabled={isLoading} onClick={handleBack} className={activeStep === 0 ? 'project-create-back-hide' : 'project-create-back'}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNext(activeStep, projectCreate)}
                  disabled={disableButton(activeStep, projectCreate) || !isDataFetched  ||
                    isLoading || isStoreDetailsLoading || isStoreDetailsOnError}
                >
                  {activeStep === formSteps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </div>
  );
};

ProjectCreate.defaultProps = {
  history: null,
  projectCreate: {},
};

ProjectCreate.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  projectCreate: PropTypes.object,
  triggerSubmit: PropTypes.func.isRequired,
  getUsersWithRoles: PropTypes.func.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
};
export default withRouter(reduxForm({
  form: 'projectCreate',
  validate: validateProjectDetails,
  destroyOnUnmount: false,
  enableReinitialize: true,
  onSubmitFail: () => {
    window.scrollTo(0, 0);
  },
})(connect(mapStateToProps, mapDispatchToProps)(ProjectCreate)));
