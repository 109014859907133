import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Button,
} from '@material-ui/core';
import _ from 'lodash';
import ReplyCommentForm from '../forms/ReplyCommentForm';
import Comment from './Comment';
import styles from '../../../styles/utils/config.scss';
import { mapComment, mapCategoryComment, mapCategoryCommentResponse } from '../commentsSelectors';

import { connect } from 'react-redux';
import * as commentActions from '../commentActions';

import { REL_ADMIN } from "../../../roleaccess/adGroups";
import { 
  viewOnlyWeight, 
  auditorWeight, 
  adminWeight, 
  getMaxWeight 
} from "../../../roleaccess/utils";

const ResolvedCheckbox = withStyles({
  root: {
    margin: '0 0 0 -10px',
  },
  checked: {
    color: styles.primaryColor,
  },
})(props => <Checkbox color="default" {...props} />);

const CommentCard = (props) => {
  const { 
    comment, 
    isProjectLevel, 
    categoryId, 
    categoryName, 
    triggerToggleCommentResolve, 
    createCategoryCommentReply,
    userRole,
    loginId,
    statusCode,
  } = props;

  let canCreateEditReplyComments = true;
  let canResolveComments = true;

  if (userRole && !userRole.includes(REL_ADMIN) && statusCode != '2'){
    canCreateEditReplyComments = !getMaxWeight(userRole) <= viewOnlyWeight();
  } else {
    canCreateEditReplyComments = false;
  }

  if (userRole){
    canResolveComments = (statusCode != '2') && (getMaxWeight(userRole) == adminWeight() || getMaxWeight(userRole) == auditorWeight());
  } else {
    canResolveComments = false;
  }

  var initialCheckboxState = !(comment.cmt_complete_ts == null || comment.cmt_complete_ts == undefined || comment.cmt_complete_ts == '');

  const [resolved, setResolved] = useState(initialCheckboxState);

  const [isReply, setIsReply] = useState(false);
  const category = isProjectLevel ? 'Project-Level' : categoryName;
  useEffect(() => {
    var checkboxState = !(comment.cmt_complete_ts == null || comment.cmt_complete_ts == undefined || comment.cmt_complete_ts == '');
    if (checkboxState !== resolved) {
      setResolved(checkboxState);
    }
  });

  const handleCheckboxChange = e => {
    if (!isProjectLevel) {
      var resolve = (comment.cmt_complete_ts == null || comment.cmt_complete_ts == undefined || comment.cmt_complete_ts == '');
      triggerToggleCommentResolve(comment.doc_category_id, comment.comment_seq_nbr, resolve);

      if (resolve) {
        comment.cmt_complete_ts = "Present";
      }
      else {
        comment.cmt_complete_ts = null;
      }
      setResolved(e.target.checked);
    }
  };

  const handleCommentReply = () => {
    createCategoryCommentReply(categoryId, comment.comment_seq_nbr);
    setIsReply(false);
  };

  const isUserCommentOwner = (comment, isReply) => {
    let retVal = false;
    if (loginId) {
      if (!isReply) {
        retVal = comment.comment_userid.trim() == loginId.trim();
      } else {
        retVal = comment.response_userid.trim() == loginId.trim();
      }
    }
    return retVal;
  };

  return (
    <div
      key={comment.seq_nbr || comment.comment_seq_nbr}
      className="comment-card"
    >
      <Comment
        isProjectLevel={isProjectLevel}
        categoryId={categoryId}
        categoryName={category}
        comment={isProjectLevel ? mapComment(comment) : mapCategoryComment(comment)}
        resolved={resolved}
        isReply={false}
        userRole={userRole}
        isUserCommentOwner={isUserCommentOwner(comment, false)}
        statusCode={statusCode}
      />
      {!isProjectLevel && (comment.responses.map(commentResponse => (
        <Comment
          isProjectLevel={isProjectLevel}
          categoryId={categoryId}
          categoryName={category}
          comment={mapCategoryCommentResponse(commentResponse)}
          resolved={resolved}
          isReply
          userRole={userRole}
          isUserCommentOwner={isUserCommentOwner(commentResponse, true)}
          statusCode={statusCode}
        />
      )))}
      {!isProjectLevel && (
        <div>
          {(canCreateEditReplyComments && !resolved) && (
            <div>
              <Button
                testId="reply-button"
                onClick={() => setIsReply(true)}
              >
                Reply
              </Button>
              {isReply && (
                <ReplyCommentForm
                  reply={isReply}
                  onSubmit={handleCommentReply}
                  onCancel={() => setIsReply(false)}
                  id={comment.comment_seq_nbr}
                />
              )}
            </div>
          )}
          {canResolveComments && <ResolvedCheckbox
            testId="resolve-check-box"
            checked={resolved}
            defaultValue={false}
            onChange={e => handleCheckboxChange(e)}
          />}
          {canResolveComments && !resolved && (<span className="label">Mark as resolved</span>)}
          {resolved && (<span className="label">Resolved</span>)}
        </div>
      )}

    </div>
  );
};

CommentCard.propTypes = {
  comment: PropTypes.object.isRequired,
  isProjectLevel: PropTypes.bool.isRequired,
  categoryId: PropTypes.number,
  categoryName: PropTypes.string,
  triggerToggleCommentResolve: PropTypes.func.isRequired,
  createCategoryCommentReply: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  triggerToggleCommentResolve: (categoryId, sequenceNumber, resolve) => {
    dispatch(commentActions.toggleCommentResolve(categoryId, sequenceNumber, resolve));
  },
  createCategoryCommentReply: (categoryId, commentSeqNbr) => {
    dispatch(commentActions.createCategoryCommentReply(categoryId, commentSeqNbr));
  },
});

export default connect(null, mapDispatchToProps)(CommentCard);

