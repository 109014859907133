import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchDocumentVersionDetails } from "../../../services/document";
import * as actions from "../documentActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";

export default function* fetchDocumentVersions() {
  yield takeLatest(
    actions.FETCH_DOCUMENT_VERSIONS_DETAILS,
    fetchDocumentVersionsWorker,
  );
}

export function* fetchDocumentVersionsWorker(action) {
  yield put(actions.loadingVersions(true));

  try {
    const response = yield call(fetchDocumentVersionDetails, action.documentId);
    const isSuccess = _.get(
      response,
      "data.fetchDocumentVersionDetails.Success",
      false,
    );
    if (isSuccess === "true") {
      const versionData = _.get(
        response,
        "data.fetchDocumentVersionDetails.Versions",
        [],
      );
      yield call(success, versionData);
    } else {
      yield call(
        failure,
        new Error(
          "Something went wrong while trying to get the document versions",
        ),
      );
    }
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loadingVersions(false));
  yield put(actions.setDocumentVersionsData(response));
}

export function* failure(error) {
  yield put(actions.loadingVersions(false));
  yield put(actions.errorLoadingVersions(error));
  yield put(
    errorOpenModal({
      title: "Something went wrong",
      message: "Could not fetch document versions",
    }),
  );
}
