import React from 'react';
import Tabs from '../_shared/tabs/Tabs';
import TabInfo from './TabInfo';
import TabVersion from './TabVersion';
import TabComments from './TabComments';

import './DocInfo.scss';

/**
 * Document Metadata Component
 *
 * @namespace Document
 * @memberof document.components
 */

const InfoPane = () => (
  <>
    <div className="border-top" />
    <div className="border-bottom" />
    <Tabs centered>
      <div
        className="scrollable-content"
        label="INFORMATION"
      >
        <TabInfo />
      </div>
      <div
        className="scrollable-content"
        label="VERSIONS"
      >
        <TabVersion />
      </div>
      <div
        className="scrollable-content"
        label="COMMENTS"
      >
        <TabComments />
      </div>
    </Tabs>
  </>
);

export default InfoPane;
