export const ERROR = "DOCUMENT/ERROR";
export const ERROR_FETCHING_VERSIONS = "DOCUMENT/VERSIONS/ERROR";
export const ERROR_FETCHING_METADATA = "DOCUMENT/METADATA/ERROR";
export const FETCH = "DOCUMENT/FETCH";
export const LOADING = "DOCUMENT/LOADING";
export const LOADING_VERSIONS = "DOCUMENT/VERSIONS/LOADING";
export const LOADING_METADATA = "DOCUMENT/METADATALOADING";
export const SET_DATA = "DOCUMENT/SET_DATA";
export const SET_DOCUMENT_TYPES = "DOCUMENT/SET_DOCUMENT_TYPES";
export const SET_DOCUMENT_SUB_TYPES = "DOCUMENT/SET_DOCUMENT_SUB_TYPES";
export const RESET_DOCUMENT_SUB_TYPES = "DOCUMENT/RESET_DOCUMENT_SUB_TYPES";
export const FETCH_DOC_PROJ_CAT = "DOCUMENT/FETCH_DOC_PROJ_CAT";
export const FETCH_DOC_PROJ = "DOCUMENT/FETCH_DOC_PROJ";
export const FETCH_DOCUMENT_TYPES = "DOCUMENT/FETCH_DOCUMENT_TYPES";
export const FETCH_DOCUMENT_SUB_TYPES = "DOCUMENT/FETCH_DOCUMENT_SUB_TYPES";
export const RESET_DATA = "DOCUMENT/RESET_DATA";
export const LOAD_METADATA = "DOCUMENT/LOAD_METADATA";
export const SET_METADATA_FOR_SELECTED_PROJECT = "DOCUMENT/SET_METADATA";
export const EDIT_METADATA = "DOCUMENT/EDIT_METADATA";
export const DELETE = "DOCUMENT/DELETE";
export const FETCH_DOCUMENT_VERSIONS_DETAILS =
  "DOCUMENT/FETCH_DOCUMENT_VERSION_DETAIL";
export const SET_DOCUMENT_VERSIONS_DETAILS =
  "DOCUMENT/SET_DOCUMENT_VERSION_DETAIL";
export const RESET_DOCUMENT_VERSIONS_DETAILS =
  "DOCUMENT/RESET_DOCUMENT_VERSION_DETAIL";
export const OPEN_MODAL = "DOCUMENT/UPLOAD_NEW/OPEN_MODAL";
export const CLOSE_MODAL = "DOCUMENT/UPLOAD_NEW/CLOSE_MODAL";
export const OPEN_EDIT_MODAL = "DOCUMENT/EDIT_METADATA/OPEN_MODAL";
export const CLOSE_EDIT_MODAL = "DOCUMENT/EDIT_METADATA/CLOSE_MODAL";
export const OPEN_UPLOAD_VERSION_MODAL = "DOCUMENT/UPLOAD_VERSION/OPEN_MODAL";
export const CLOSE_UPLOAD_VERSION_MODAL = "DOCUMENT/UPLOAD_VERSION/CLOSE_MODAL";
export const UPLOAD = "UPLOADDOCUMENT/UPLOAD";
export const UPLOAD_DOC_VERSION = "UPLOADDOCUMENT/UPLOAD_DOC_VERSION";
export const SORT_DOC_LIST = "DOCUMENTS/SORTDOCLIST";

export const error = err => ({ type: ERROR, error: err });
export const errorLoadingVersions = err => ({ type: ERROR_FETCHING_VERSIONS, error: err });
export const errorLoadingMetadata = err => ({ type: ERROR_FETCHING_METADATA, error: err });
export const fetch = documentId => ({ type: FETCH, documentId });
export const loading = isLoading => ({ type: LOADING, loading: isLoading });
export const loadingVersions = isLoading => ({ type: LOADING_VERSIONS, loading: isLoading });
export const loadingMetadata = isLoading => ({ type: LOADING_METADATA, loading: isLoading });
export const setData = data => ({ type: SET_DATA, data });
export const sortDocList = data => ({ type: SORT_DOC_LIST, data });
export const setDocumentTypes = data => ({ type: SET_DOCUMENT_TYPES, data });

export const setDocumentSubTypes = data => ({
  type: SET_DOCUMENT_SUB_TYPES,
  data,
});

export const fetchDocProjCat = (closing_id, doc_cat_id) => ({
  type: FETCH_DOC_PROJ_CAT,
  closing_id,
  doc_cat_id,
});

export const fetchDocProj = closing_id => ({
  type: FETCH_DOC_PROJ,
  closing_id,
});

export const fetchDocTypes = () => ({ type: FETCH_DOCUMENT_TYPES });

export const fetchDocSubTypes = docType => ({
  type: FETCH_DOCUMENT_SUB_TYPES,
  docType,
});

export const loadMetadata = documentId => ({
  type: LOAD_METADATA,
  documentId,
});

export const setMetaDataForProject = documentMetaData => ({
  type: SET_METADATA_FOR_SELECTED_PROJECT,
  documentMetaData,
});

export const deleteDocument = (documentId, closingId) => ({
  type: DELETE,
  documentId,
  closingId,
});

export const editMetadata = data => ({
  type: EDIT_METADATA,
  data,
});
export const fetchDocumentVersionsData = documentId => ({
  type: FETCH_DOCUMENT_VERSIONS_DETAILS,
  documentId,
});
export const setDocumentVersionsData = documentVersionsData => ({
  type: SET_DOCUMENT_VERSIONS_DETAILS,
  data: documentVersionsData,
});
export const resetDocumentVersionsData = () => ({
  type: SET_DOCUMENT_VERSIONS_DETAILS,
});
export const resetDocumentSubTypes = () => ({
  type: RESET_DOCUMENT_SUB_TYPES,
});
export const upload = data => ({ type: UPLOAD, data });
export const uploadDocVersion = data => ({ type: UPLOAD_DOC_VERSION, data });
export const openUploadDocumentModal = () => ({ type: OPEN_MODAL });
export const closeUploadDocumentModal = () => ({ type: CLOSE_MODAL });

export const openEditDocumentMetadataModal = () => ({ type: OPEN_EDIT_MODAL });
export const closeEditDocumentMetadataModal = () => ({
  type: CLOSE_EDIT_MODAL,
});

export const openUploadVersionModal = () => ({
  type: OPEN_UPLOAD_VERSION_MODAL,
});
export const closeUploadVersionModal = () => ({
  type: CLOSE_UPLOAD_VERSION_MODAL,
});
