import _ from "lodash";
import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  fetchMetadataForDocument,
  fetchDocumentSubTypes,
} from "../../../services/document";
import * as actions from "../documentActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";
import { getDocumentType, getDocumentStatus } from "../utils";

const documentTypesSelect = state => _.get(state, "document.documentTypes", []);

export default function* fetchDocumentMetadata() {
  yield takeLatest(actions.LOAD_METADATA, fetchDocumentMetadataWorker);
}

export function* fetchDocumentMetadataWorker(action) {
  yield put(actions.loadingMetadata(true));

  try {
    const response = yield call(fetchMetadataForDocument, action.documentId);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loadingMetadata(false));
  const success = _.get(response, "data.fetchDocumentMetadata.Success", {});
  if (success === "true") {
    const data = _.get(response, "data.fetchDocumentMetadata.DocInfo", {});
    const docMetadata = data[0];
    const docSubTypeResponse = yield call(
      fetchDocumentSubTypes,
      docMetadata.doc_type,
    );
    const docSubTypeIsSuccess = _.get(
      docSubTypeResponse,
      "data.fetchSubTypes.Success",
    );
    if (docSubTypeIsSuccess) {
      const docSubTypeList = _.get(
        docSubTypeResponse,
        "data.fetchSubTypes.DocSubTypesList",
        [],
      );
      yield put(actions.setDocumentSubTypes(docSubTypeList));
    } else {
      yield call(failure, err);
    }
    const documentTypes = yield select(documentTypesSelect);

    const docTypeDesc = getDocumentType(docMetadata.doc_type, documentTypes);
    const docState = getDocumentStatus(docMetadata.r_version_label);
    const modifiedMetadata = {
      ...docMetadata,
      doc_status: docState,
      doc_type_desc: docTypeDesc,
    };
    yield put(actions.setMetaDataForProject(modifiedMetadata));
  } else {
    const err = new Error(
      "Something went wrong while trying to fetch metadeta",
    );
    yield call(failure, err);
  }
}

export function* failure(error) {
  yield put(actions.loadingMetadata(false));
  yield put(actions.errorLoadingMetadata(error));
  yield put(
    errorOpenModal({
      title: "No data",
      message: "Could not fetch document information",
    }),
  );
}
