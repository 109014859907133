import _ from 'lodash';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { fetchCompanies } from '../../../services/users';
import * as actions from '../usersActions';

export default function* fetchCompaniesWatcher() {
  yield takeLatest(actions.FETCH_COMPANIES, fetchCompaniesWorker);
}

export function* fetchCompaniesWorker() {
  yield put(actions.loading(true));
  try {
    const response = yield call(fetchCompanies);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));
  const data = _.get(response, 'data.fetchAllCompanies.Companies', []);
  yield put(actions.setCompanies(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
