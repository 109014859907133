import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import renderTextField from '../../_shared/utils/renderTextField';
import { validate } from './ValidateChangePassword';
import { updatePassword } from '../usersActions';
import { isRequestInprogress } from '../usersSelectors';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
  },
  paper: {
    width: 'inherit',
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  dot: {
    height: '12px',
    width: '12px',
    backgroundColor: '#bbb',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '15px',
  },
  ChangeButton: {
    marginTop: '20px',
    float: 'right',
  },
  loadingSpinner: {
    position: 'absolute',
    left: '50%',
    top: '14%',
  },
  pwdExpireMsg: {
    marginBottom: 25,
  }
}));
let ChangePassword = ({ invalid, trigerUpdatePassword, isLoading, passwordExpiryDays }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      {isLoading && <CircularProgress />}
      {passwordExpiryDays && passwordExpiryDays <= 0 && (
      <Grid item xs={12} className={classes.pwdExpireMsg}>
        Password will expire soon. Please change your password.
      </Grid>
      )}
      <Grid container item xs={5} spacing={3}>
        <Paper elevation={3} className={classes.paper}>
          <Grid xs={12} container spacing={3}>
            <Grid item xs={12}>
              PASSWORD
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="password"
                component={renderTextField}
                inputType="password"
                inputLabel="Old password"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="newPassword"
                component={renderTextField}
                inputType="password"
                inputLabel="New Password"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="confirmNewPassword"
                component={renderTextField}
                inputType="password"
                inputLabel="Confirm New Password"
                fullWidth
              />
            </Grid>
            <Grid item container xs={12} spacing={0}>
              <Grid item xs={6}>
                <Typography variant="body2"><span className={classes.dot} /> 8 characters minimum</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><span className={classes.dot} /> One uppercase letter</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><span className={classes.dot} /> One lowercase letter</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><span className={classes.dot} /> One number</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><span className={classes.dot} /> One special character</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2"><span className={classes.dot} /> Can only be changed once in 24 hours</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Button
            color="primary"
            variant="contained"
            className={classes.ChangeButton}
            disabled={invalid}
            onClick={trigerUpdatePassword}
          >
            CHANGE
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};
ChangePassword.propTypes = {
  invalid: PropTypes.bool.isRequired,
  trigerUpdatePassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  passwordExpiryDays: PropTypes.number.isRequired,
};
ChangePassword = reduxForm({
  form: 'changePassword',
  enableReinitialize: true,
  validate,
})(ChangePassword);

const mapStateToProps = state => ({
  formValues: getFormValues('changePassword')(state),
  isLoading: isRequestInprogress(state),
  passwordExpiryDays: _.get(state, 'session.user.data.extUserInfo[0].passwordExpiry.passwordExpiryDays'),
});
const mapDispatchToProps = dispatch => ({
  trigerUpdatePassword: () => dispatch(updatePassword()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
