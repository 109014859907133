import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import renderTextField from '../_shared/utils/renderTextField';
import renderSelectField from '../_shared/utils/renderSelectField';
import _config from "../../config"
const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
  },
  innerContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  button: {
    float: 'right',
  },
  updateButton: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
  loadingSpinner: {
    position: 'relative',
    left: '44%',
  },
  pwdLink: {
    marginLeft: 12,
  },
}));
const ManageUser = (props) => {
  const { form, companies } = props;
  const disabled = form === 'createTempUserPassword';
  const classes = useStyles();
  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={12}
      lg={12}
      spacing={4}
      alignItems="center"
      justify="center"
      className={classes.innerContainer}
    >
      <Grid item container xs={6} spacing={2}>
        <Grid item xs={12}>User Details</Grid>
        <Grid item xs={12}>
          <Field
            name="extrn_first_name"
            component={renderTextField}
            inputType="text"
            inputLabel="First Name"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="extrn_last_name"
            component={renderTextField}
            inputType="text"
            inputLabel="Last Name"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="sys_userid"
            component={renderTextField}
            inputType="text"
            inputLabel="User ID"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="password"
            component={renderTextField}
            inputType="password"
            inputLabel="Password"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="ext_email_addr_id"
            component={renderTextField}
            inputType="text"
            inputLabel="Email"
            fullWidth
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid item container xs={6} spacing={2}>
        <Grid item xs={12}>Firm</Grid>
        <Grid item xs={12}>
          <Field
            name="extrn_company_name"
            options={companies || []}
            component={renderSelectField}
            inputType="text"
            inputLabel="Firm Name"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="extrn_addr_line1"
            component={renderTextField}
            inputType="text"
            inputLabel="Address"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="extrn_city_name"
            component={renderTextField}
            inputType="text"
            inputLabel="City"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="extrn_st_prov_cd"
            component={renderTextField}
            inputType="text"
            inputLabel="State"
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="extrn_postal_cd"
            component={renderTextField}
            inputType="text"
            inputLabel="Zip"
            fullWidth
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid xs={12} item className={classes.pwdLink}>
      Please follow Walmart password guidelines <a href={_config.URL_LINK} rel="noopener noreferrer" target="_blank" >here</a>.
      </Grid>
    </Grid>
  );
};
ManageUser.propTypes = {
  form: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
};

export default ManageUser;
