import { combineReducers } from "redux";
import { createDataReducer } from "../../store/dataReducer";
import { createRequestReducer } from "../../store/requestReducer";
import * as actions from "./usersActions";

const SET_DATA = "USERS/SET_USERS_WITH_ROLES";
export const setData = data => ({ type: SET_DATA, data });

export const initialState = {
  totalUsers: 0,
  pageDetails: {
    page: 0,
    rowsPerPage: 10,
    sortColumn: "LastLogin",
    sortDirection: "desc",
    filterObject: {
      UserName: "",
    },
    currentSortedColumnName: "Last Login",
  },
};

export function pageDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_PAGE_DETAILS:
      return {
        ...state,
        pageDetails: action.pageDetails,
      };
    default:
      return state;
  }
}

export function totalUsersReducer(
  state = initialState.totalUsers,
  action = {},
) {
  switch (action.type) {
    case actions.SET_TOTAL_USERS:
      return {
        ...state,
        totalUsers: action.totalUsers,
      };
    default:
      return state;
  }
}

const usersWithRolesReducer = combineReducers({
  data: createDataReducer({
    SET_DATA,
  }),
  request: createRequestReducer({
    ERROR: actions.ERROR,
    LOADING: actions.LOADING,
  }),
});
export default combineReducers({
  data: createDataReducer({ SET_DATA: actions.SET_DATA }),
  totalUsers: totalUsersReducer,
  usersPageDetails: pageDetailsReducer,
  request: createRequestReducer({
    ERROR: actions.ERROR,
    LOADING: actions.LOADING,
  }),
  usersWithRoles: usersWithRolesReducer,
  companies: createDataReducer({ SET_DATA: actions.SET_COMPANIES })
});
