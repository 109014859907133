import React from 'react';
import moment from 'moment';
import MomentFnsUtils from '@date-io/moment';
import {
  Button,
  Checkbox,
  createMuiTheme,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import InputField, { SIZES } from '../_shared/input-field/InputField';
import SelectField from '../_shared/select-field/SelectField';

import Edit from '../../assets/images/Edit.svg';

import styles from '../../styles/utils/config.scss';
import './TabInfo.scss';

// TODO: replace these constants with data from backend.
const statusOptions = [
  { value: 'inProgress', label: 'In Progress' },
  { value: 'outProgress', label: 'Out Progress' },
];

const typeOptions = [
  { value: 'plans', label: 'Plans' },
];

const originDeptOptions = [
  { value: 'constructionOther8530', label: 'Construction Other 8530' },
];

const sampleData = {
  documentName: 'document.pdf',
  executed: true,
  executedDate: moment('05/28/2019').valueOf(),
  documentStatus: statusOptions[0],
  documentType: typeOptions[0],
  documentSubType: typeOptions[0],
  originDept: originDeptOptions[0],
};

const materialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiTextField: {
      root: {
        margin: '0',
      },
    },
    MuiInputBase: {
      formControl: {
        borderColor: styles.black38opacityColor,
        '&:hover': {
          borderColor: styles.black54opacityColor,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: styles.primaryColor,
        '&:hover': {
          backgroundColor: styles.primaryColor,
        },
      },
    },
  },
});

const MainCheckbox = withStyles({
  root: {
    margin: '-9px 0 -9px -10px',
  },
  checked: {
    color: styles.primaryColor,
  },
})(props => <Checkbox color="default" {...props} />);

/**
 * Document Information Metadata Tab Component
 *
 * @namespace Document
 * @memberof document.components
 */

class TabInfo extends React.Component {
  constructor(props) {
    super(props);
    this.undoSettings = {};
    this.state = {
      showForm: false,
      documentName: sampleData.documentName,
      executed: sampleData.executed,
      executedDate: sampleData.executedDate,
      documentStatus: sampleData.documentStatus,
      documentType: sampleData.documentType,
      documentSubType: sampleData.documentSubType,
      originDept: sampleData.originDept,
    };
  }

  handleEditMetadata = () => {
    const {
      documentName,
      executed,
      executedDate,
      documentStatus,
      documentType,
      documentSubType,
      originDept,
    } = this.state;
    this.undoSettings = {
      documentName,
      executed,
      executedDate,
      documentStatus,
      documentType,
      documentSubType,
      originDept,
    };
    this.setState({ showForm: true });
  }

  handleDateChange = (date) => {
    this.setState({ executedDate: date.unix() });
  }

  handleSelectInputChange = (field, value) => {
    this.setState({ [field]: value });
  }

  handleCancelButton = () => {
    const {
      documentName,
      executed,
      executedDate,
      documentStatus,
      documentType,
      documentSubType,
      originDept,
    } = this.undoSettings;
    this.setState({
      showForm: false,
      documentName,
      executed,
      executedDate,
      documentStatus,
      documentType,
      documentSubType,
      originDept,
    }, () => { this.undoSettings = {}; });
  }

  handleSaveButton = () => {
    // TODO: send information in this.state to backend
    this.setState({ showForm: false });
  }

  renderInformation = () => {
    const {
      documentName,
      executed,
      executedDate,
      documentStatus,
      documentType,
      documentSubType,
      originDept,
    } = this.state;
    return (
      <>
        <Button
          className="edit-metadata-button"
          onClick={this.handleEditMetadata}
        >
          <Edit className="edit-metadata-icon" />
          EDIT METADATA
        </Button>
        <div className="info-item">
          <div className="label">Document name</div>
          <div className="content">{documentName}</div>
        </div>
        <div className="info-item">
          <div className="content">
            <MainCheckbox checked={executed} disabled />
            <span className="label">Executed</span>
          </div>
        </div>
        <div className="info-item">
          <div className="label">Executed date</div>
          <div className="content">{moment(executedDate).format('MM/DD/YYYY')}</div>
        </div>
        <div className="info-item">
          <div className="label">Document status</div>
          <div className="content">{documentStatus.label}</div>
        </div>
        <div className="info-item">
          <div className="label">Document type</div>
          <div className="content">{documentType.label}</div>
        </div>
        <div className="info-item">
          <div className="label">Document subtype</div>
          <div className="content">{documentSubType.label}</div>
        </div>
        <div className="info-item">
          <div className="label">Originating Department</div>
          <div className="content">{originDept.label}</div>
        </div>
      </>
    );
  }

  renderInformationForm = () => {
    const {
      documentName,
      executed,
      executedDate,
      documentStatus,
      documentType,
      documentSubType,
      originDept,
    } = this.state;
    return (
      <>
        <div className="form-item">
          <InputField
            inputLabel="Document name"
            inputType="text"
            inputDefaultValue={documentName}
            inputStyleType={SIZES.FULL}
            showInputAdornment={false}
            onChange={(e) => { this.setState({ documentName: e.target.value }); }}
          />
        </div>
        <div className="form-item">
          <div className="content">
            <MainCheckbox
              checked={executed}
              onClick={() => { this.setState({ executed: !executed }); }}
            />
            <span className="label">Executed</span>
          </div>
        </div>
        <div className="form-item">
          <div className="label">Executed date</div>
          <ThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider utils={MomentFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                format="MM-DD-YYYY"
                inputVariant="outlined"
                variant="inline"
                value={moment(executedDate).format('MM/DD/YYYY')}
                onChange={this.handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </div>
        <SelectField
          isClearable={false}
          label="Document status"
          value={documentStatus}
          options={statusOptions}
          onChange={value => this.handleSelectInputChange('documentStatus', value)}
        />
        <SelectField
          isClearable={false}
          label="Document type"
          value={documentType}
          options={typeOptions}
          onChange={value => this.handleSelectInputChange('documentType', value)}
        />
        <SelectField
          isClearable={false}
          label="Document sub type"
          value={documentSubType}
          options={typeOptions}
          onChange={value => this.handleSelectInputChange('documentSubType', value)}
        />
        <SelectField
          isClearable={false}
          label="Originating department"
          value={originDept}
          options={originDeptOptions}
          onChange={value => this.handleSelectInputChange('originDept', value)}
        />
        <div className="button-group">
          <Button
            className="button-base cancel-button"
            onClick={this.handleCancelButton}
          >
            Cancel
          </Button>
          <Button
            className="button-base save-button"
            onClick={this.handleSaveButton}
          >
            Save
          </Button>
        </div>
      </>
    );
  }

  render() {
    const { showForm } = this.state;
    return (
      <div className="tab-info">
        { showForm ? this.renderInformationForm() : this.renderInformation() }
      </div>
    );
  }
}

export default TabInfo;
