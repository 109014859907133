import * as _ from 'lodash';
import moment from 'moment';
import { getValueWithoutLeadingSpaces } from '../../../utilities';

export const validateProjectDetails = (values, props) => {
  const errors = {};
  // only required for create
  if (props.form !== 'projectEdit') {
    if (!values.closing_name) {
      errors.closing_name = 'Project name required';
    } else if (values.closing_name.length > 30) {
      errors.closing_name = 'Project Name must be 30 chracters or less';
    } else if (getValueWithoutLeadingSpaces(values.closing_name) === '') {
      errors.closing_name = 'Project Name only contains white spaces';
    }
  }
  // only for edit
  if (props.form === 'projectEdit') {
    if (
      _.isEmpty(values.closing_start_ts)
      || _.isNull(values.closing_start_ts)
      || _.isNaN(values.closing_start_ts)
    ) {
      errors.closing_start_ts = 'Closing date required';
    }
  }
  if (values.project_seq_nbr === '' || _.isNil(values.project_seq_nbr)) {
    errors.project_seq_nbr = 'Project sequence number required';
  } else if (_.isNaN(_.toNumber(values.project_seq_nbr))) {
    errors.project_seq_nbr = 'Must be number';
  } else if (_.toNumber(values.project_seq_nbr) < 0) {
    errors.project_seq_nbr = 'Must be a positive number';
  }
  if (values.seller_count === '' || _.isNil(values.seller_count)) {
    errors.seller_count = 'sellers count required';
  } else if (_.isNaN(_.toNumber(values.seller_count))) {
    errors.seller_count = 'Must be number';
  } else if (_.toNumber(values.seller_count) < 0) {
    errors.seller_count = 'Must be a positive number';
  }
  if (_.isNil(values.parcel_count) || values.parcel_count === '') {
    errors.parcel_count = 'Parcels count required';
  } else if (_.isNaN(_.toNumber(values.parcel_count))) {
    errors.parcel_count = 'Must be number';
  } else if (_.toNumber(values.parcel_count) < 0) {
    errors.parcel_count = 'Must be a positive number';
  }
  if (values.store_nbr === '' || _.isNil(values.store_nbr)) {
    errors.store_nbr = 'Store number required';
  }
  if (values.closing_start_ts && !moment(values.closing_start_ts).isValid()) {
    errors.closing_start_ts = 'Invalid Date Format';
  }
  return errors;
};
