import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import * as actions from "../commentActions";
import { createComment } from "../../../services/comments";

const formValueSelector = state =>
  _.get(state, "form.subCategoryCommentForm.values");
const projectDetails = state => _.get(state, "projectDetails.data.projectDetails", {});
const loggedInUserSelector = state => _.get(state, "session.user.data");

export default function* createProjectCommentWatcher() {
  yield takeLatest(actions.PROJECT_CREATE, createProjectCommentWorker);
}

export function* createProjectCommentWorker(action) {
  const formValues = yield select(formValueSelector);
  const projectData = yield select(projectDetails);
  const loggedInUserData = yield select(loggedInUserSelector);

  formValues.closing_id = projectData.closing_id;
  formValues.create_ts = moment().utc();
  if (loggedInUserData) {
    formValues.comment_userid = loggedInUserData.loginId;
  } else {
    formValues.comment_userid = "eUserId";
  }

  try {
    const response = yield call(createComment, formValues);
    const data = _.get(response, "data.createProjectComment");

    if (data.Success === "true") {
      formValues.seq_nbr = Number(data.SeqNbr);
      formValues.user_full_name = loggedInUserData.name.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
      yield call(success, formValues, action.history);
    } else {
      yield call(failure, new Error("Something Went Wrong"));

      alert("Could not add comment");
    }
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response) {
  yield put(actions.SetNewComment(response));
}

export function* failure(error) {
  yield put(actions.error(error));
}
