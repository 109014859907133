import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import defaultStyles from '../../../styles/utils/config.scss';
import inputStyles from './InputField.module.scss';

export const SIZES = {
  DEFAULT: 'sizeDefault',
  FULL: 'sizeFull',
  LARGE: 'sizeLarge',
};

const styles = {
  textField: {
    '&:hover $notchedOutline': {
      borderColor: `${defaultStyles.black54opacityColor} !important`,
    },
  },
  notchedOutline: {
    borderColor: `${defaultStyles.black38opacityColor} !important`,
  },
  focused: {
    color: `${defaultStyles.black54opacityColor}`,
  },
  outlinedInput: {
    '&$focused $notchedOutline': {
      borderColor: `${defaultStyles.focusedInputColor} !important`,
    },
  },
};

/**
 * Customized Input Field Component
 *
 * @namespace Shared
 * @memberof shared.components
 */

const InputField = (props) => {
  const {
    classes,
    inputLabel,
    inputType,
    inputDefaultValue,
    showInputAdornment,
    inputStyleType,
    onChange,
    disabled,
    placeholder,
    value,
    InputProps,
    ...others
  } = props;

  const showPassword = () => {
    // TODO: implement show password logic
  };

  const renderTextField = () => (showInputAdornment
    ? (
      <TextField
        InputProps={{
          classes: {
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
          },
          endAdornment: (
            <InputAdornment position="end">
              <Typography
                className="show-password-label"
                onClick={showPassword}
                gutterBottom
                variant="caption"
              >
                Show
              </Typography>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        className={inputStyles[inputStyleType]}
        value={value}
        defaultValue={inputDefaultValue}
        placeholder={placeholder}
        type={inputType}
        variant="outlined"
        onChange={onChange || null}
        disabled={disabled}
        {...others}
      />
    ) : (
      <TextField
        InputProps={{
          classes: {
            root: classes.outlinedInput,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
          },
          ...InputProps,
        }}
        className={inputStyles[inputStyleType]}
        value={value}
        defaultValue={inputDefaultValue}
        placeholder={placeholder}
        type={inputType}
        variant="outlined"
        onChange={onChange || null}
        disabled={disabled}
        {...others}
      />
    ));

  return (
    <div className="input-field">
      <Typography
        className={inputStyles.textFieldLabel}
        variant="caption"
        gutterBottom
      >
        {inputLabel}
        <br />
      </Typography>

      {renderTextField()}
    </div>
  );
};

InputField.defaultProps = {
  inputStyleType: SIZES.DEFAULT,
  onChange: null,
  disabled: false,
  placeholder: '',
  value: null,
  inputType: null,
  inputDefaultValue: null,
  showInputAdornment: false,
};

InputField.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  inputLabel: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  inputDefaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  showInputAdornment: PropTypes.bool,
  inputStyleType: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

export default withStyles(styles)(InputField);
