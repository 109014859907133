import _ from 'lodash';

export const selectFormValues = state => _.get(state, 'form.projectCreate.values', {});

export const selectEditFormValues = state => _.get(state, 'form.projectDetails.values');

export const selectStoreDetails = state => _.get(state, 'store.data', []);

export const getSelectedStore = state => _.get(state, 'store.selectedStore', []);

export const isRefreshData = state => _.get(state, 'store.refreshData.refreshData', []);



