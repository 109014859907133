import { combineReducers } from "redux";
import { createDataReducer } from "../../store/dataReducer";
import { createRequestReducer } from "../../store/requestReducer";
import * as actions from "./documentActions";

export const selectedProjectMetadata = {
  r_object_id: "",
  object_name: "",
  i_chronicle_id: "",
  r_version_label: "",
  r_creation_date: "",
  r_modify_date: "",
  owner_name: "",
  acl_name: "",
  doc_subtype_desc: "",
  originating_dept: "",
  doc_type: "",
  ecm_attr_doc_date: "",
  doc_type_desc: "",
  doc_status: "",
  ecm_attr_env_related: "",
};

export function setSelectedProjectMetadata(
  state = selectedProjectMetadata,
  action = {}
) {
  switch (action.type) {
    case actions.SET_METADATA_FOR_SELECTED_PROJECT:
      return action.documentMetaData;
    default:
      return state;
  }
}
const uploadNewDocModalReducer = (state = { open: false }, action = {}) => {
  switch (action.type) {
    case actions.OPEN_MODAL:
      return {
        ...state,
        open: true,
      };
    case actions.CLOSE_MODAL:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
const editMetadataDocModalReducer = (state = { open: false }, action = {}) => {
  switch (action.type) {
    case actions.OPEN_EDIT_MODAL:
      return {
        ...state,
        open: true,
      };
    case actions.CLOSE_EDIT_MODAL:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
const uploadDocVersionModalReducer = (state = { open: false }, action = {}) => {
  switch (action.type) {
    case actions.OPEN_UPLOAD_VERSION_MODAL:
      return {
        ...state,
        open: true,
      };
    case actions.CLOSE_UPLOAD_VERSION_MODAL:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
export default combineReducers({
  data: createDataReducer({
    SET_DATA: actions.SET_DATA,
  }),
  documentTypes: createDataReducer({
    SET_DATA: actions.SET_DOCUMENT_TYPES,
  }),
  documentSubTypes: createDataReducer({
    SET_DATA: actions.SET_DOCUMENT_SUB_TYPES,
    RESET_DATA: actions.RESET_DOCUMENT_SUB_TYPES,
  }),
  request: createRequestReducer({
    ERROR: actions.ERROR,
    LOADING: actions.LOADING,
  }),
  documentVersions: combineReducers({
    data: createDataReducer({
      SET_DATA: actions.SET_DOCUMENT_VERSIONS_DETAILS,
      RESET_DATA: actions.RESET_DOCUMENT_VERSIONS_DETAILS,
    }),
    request: createRequestReducer({
      ERROR: actions.ERROR_FETCHING_VERSIONS,
      LOADING: actions.LOADING_VERSIONS,
    }),
  }),
  selectedDocumentMetadata: combineReducers({
    data: setSelectedProjectMetadata,
    request: createRequestReducer({
      ERROR: actions.ERROR_FETCHING_METADATA,
      LOADING: actions.LOADING_METADATA,
    }),
  }),
  uploadNewDocModal: uploadNewDocModalReducer,
  editMetadataDocModal: editMetadataDocModalReducer,
  uploadDocVersionModal: uploadDocVersionModalReducer,
});
