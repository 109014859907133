import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Field, change, getFormValues } from "redux-form";
import { Typography, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import renderTextField from "../../../_shared/utils/renderTextField";
import renderSelectField from "../../../_shared/utils/renderSelectField";
import {
  fetch,
  setSelectedStore,
  refreshData,
  fetchByCompany,
  dataFetched,
  setData,
} from "../../storeActions";
import {
  selectStoreDetails,
  getSelectedStore,
  isRefreshData,
} from "../../storeSelectors";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  searchButton: {
    marginTop: 20,
  },
  gridItem: {
    color: theme.palette.action,
  },
}));

const StoreDetails = props => {
  const classes = useStyles();
  const {
    addr_line1,
    store_type_code,
    project_type_desc,
    bu_type_code,
    store_seq_nbr,
    jde_company_nbr,
    store_nbr,
    city_name,
    state_prov_code,
  } = props.fetchedValues;

  const {
    storeDetails,
    setSelectedStore,
    fetchStoreDetailsFromJde,
    formValues,
    selectedStore,
    refreshData,
    setRefreshData,
    setDataFetched,
    fetchStoreDetailsByCompanyFromJde,
    form,
    setStoreDetails,
  } = props;
  const disableForm = form === "projectEdit";
  const addresses = storeDetails.map(store => {
    return { label: store.address_line_1, value: store.company };
  });
  const selectStore = company => {
    let selectedIndex = _.findIndex(storeDetails, { company: company });
    setSelectedStore(storeDetails[selectedIndex]);
    setRefreshData(true);
  };

  const hanldeStoreNumberChange = () => event => {
    //reset data 
    const storeDetail = []; 
    setStoreDetails(storeDetail);
    const selectedStore = {
      bu_type_code: "",
      store_nbr: "",
      dark_store_nbr: "",
      company: "",
      store_seq_nbr: "",
      store_type_code: "",
      project_type_code: "",
      address_line_1: "",
      city_name: "",
      state_prov_code: "",
      store_nbr: event.target.values,
    }
    setSelectedStore(selectedStore); //reset store data in state
    setRefreshData(true);
    props.dispatch(change(form, "store_nbr", event.target.values));
    setDataFetched(false);
  }

  const hanldeCompanyNumberChange = () => event => {
    //reset data 
    const storeDetail = [];
    setStoreDetails(storeDetail);
    let selectedStore = {
      bu_type_code: "",
      store_nbr: "",
      dark_store_nbr: "",
      company: event.target.values,
      store_seq_nbr: "",
      store_type_code: "",
      project_type_code: "",
      address_line_1: "",
      city_name: "",
      state_prov_code: "",
      store_nbr: "",
    }
    setSelectedStore(selectedStore);
    setRefreshData(true);
    props.dispatch(change(form, "jde_company_nbr", event.target.values));
    setDataFetched(false);
  }

  const onAddressSelect = value => {
    selectStore(value.value);
  };

  const fetchStoreDetails = () => {
    fetchStoreDetailsFromJde(formValues.store_nbr);
    // if (
    //   null != storeDetails &&
    //   undefined != storeDetails &&
    //   storeDetails.length > 0
    // ) {
    //   if (
    //     formValues.jde_company_nbr == null ||
    //     formValues.jde_company_nbr == undefined ||
    //     selectedStore.company != formValues.jde_company_nbr
    //   ) {
    //     props.dispatch(change(form, "jde_company_nbr", jde_company_nbr));
    //   }
    // }
  };

  const fetchCompanyDetails = () => {
    fetchStoreDetailsByCompanyFromJde(formValues.jde_company_nbr);
    // if (
    //   null != storeDetails &&
    //   undefined != storeDetails &&
    //   storeDetails.length > 0
    // ) {
    //   if (
    //     formValues.store_nbr == null ||
    //     formValues.store_nbr == undefined ||
    //     selectedStore.store_nbr != formValues.store_nbr
    //   ) {
    //     props.dispatch(change(form, "store_nbr", store_nbr));
    //   }
    // }
  };

  if (
    null != storeDetails &&
    undefined != storeDetails &&
    storeDetails.length > 0
  ) {
    if (selectedStore.store_nbr == "") {
      setSelectedStore(storeDetails[0]);
      setRefreshData(true);
    } else {
      let selectedIndex = _.findIndex(storeDetails, {
        company: selectedStore.company,
      });
      if (selectedIndex < 0) {
        setSelectedStore(storeDetails[0]);
        setRefreshData(true);
      } else {
      }
    }
  } else if (selectedStore.store_nbr != "") {
    let initialStoreState = {
      bu_type_code: "",
      store_nbr: "",
      dark_store_nbr: "",
      company: "",
      store_seq_nbr: "",
      store_type_code: "",
      project_type_code: "",
      address_line_1: "",
      city_name: "",
      state_prov_code: "",
    };
    setSelectedStore(initialStoreState);
    setRefreshData(false);
  } else {
  }

  useEffect(() => {
    if (refreshData == true) {
      props.dispatch(change(form, "store_type_code", store_type_code));
      props.dispatch(change(form, "project_type_desc", project_type_desc));
      props.dispatch(change(form, "bu_type_code", bu_type_code));
      props.dispatch(change(form, "store_seq_nbr", store_seq_nbr));
      props.dispatch(change(form, "addr_line1", addr_line1));
      props.dispatch(change(form, "city_name", city_name));
      props.dispatch(change(form, "state_prov_code", state_prov_code));
      props.dispatch(change(form, "jde_company_nbr", jde_company_nbr));
      props.dispatch(change(form, "store_nbr", store_nbr));
      setRefreshData(false);
    }
  }, [jde_company_nbr]);

  return (
    <div className={classes.root}>
      <Typography variant='h5' gutterBottom>
        Store Details
      </Typography>
      <form>
        <Grid
          container
          alignItems='center'
          item
          spacing={2}
          xl={12}
          xs={12}
          lg={12}
          md={12}>
          <Grid item xl={3} xs={3} lg={3} md={3}>
            <Field
              component={renderTextField}
              inputLabel='Store #'
              placeholder='Enter store #'
              name='store_nbr'
              inputType='text'
              fullWidth
              disabled={disableForm}
              onChange={hanldeStoreNumberChange()}
            />
          </Grid>
          <Grid item xl={3} xs={3} lg={3} md={3}>
            <Button
              variant='contained'
              color='primary'
              className={classes.searchButton}
              onClick={fetchStoreDetails}
              style={{
                fontSize: 13,
                width: 165,
                padding: 9,
                whiteSpace: "nowrap",
              }}
              disabled={disableForm}>
              Lookup by store #
            </Button>
          </Grid>
          <Grid item xl={3} xs={3} lg={3} md={3}>
            <Field
              component={renderTextField}
              inputLabel='Company #'
              placeholder='Enter company #'
              name='jde_company_nbr'
              inputType='text'
              fullWidth
              disabled={disableForm}
              onChange={hanldeCompanyNumberChange()}
            />
          </Grid>
          <Grid item xl={3} xs={3} lg={3} md={3}>
            <Button
              variant='contained'
              color='primary'
              className={classes.searchButton}
              onClick={fetchCompanyDetails}
              style={{ fontSize: 13, padding: 9, whiteSpace: "nowrap" }}
              disabled={disableForm}>
              Lookup by company #
            </Button>
          </Grid>
          <Grid item xl={3} xs={3} lg={3} md={3}>
            <Field
              inputLabel='Store Sequence #'
              inputType='text'
              showInputAdornment={false}
              disabled
              component={renderTextField}
              name='store_seq_nbr'
              fullWidth
            />
          </Grid>
          <Grid item xl={6} xs={6} lg={6} md={6}>
            <Field
              component={renderTextField}
              name='just_for_spacing'
              type='hidden'
            />
          </Grid>
          <Grid item xl={6} xs={6} lg={6} md={6}>
            <Field
              inputLabel='Address'
              name='addr_line1'
              label='Address'
              options={addresses}
              component={renderSelectField}
              onChange={value => onAddressSelect(value)}
              disabled={disableForm}
            />
          </Grid>
          <Grid item xl={3} xs={3} lg={3} md={3}>
            <Field
              inputLabel='City'
              inputType='text'
              showInputAdornment={false}
              disabled
              component={renderTextField}
              name='city_name'
              fullWidth
            />
          </Grid>
          <Grid item xl={3} xs={3} lg={3} md={3}>
            <Field
              inputLabel='State'
              inputType='text'
              showInputAdornment={false}
              disabled
              component={renderTextField}
              name='state_prov_code'
              fullWidth
            />
          </Grid>
          <Grid item xl={4} xs={4} lg={4} md={4}>
            <Field
              inputLabel='Store Type'
              inputType='text'
              showInputAdornment={false}
              disabled
              component={renderTextField}
              name='store_type_code'
              className={classes.gridItem}
              fullWidth
            />
          </Grid>
          <Grid item xl={4} xs={4} lg={4} md={4}>
            <Field
              inputLabel='Project Type'
              inputType='text'
              showInputAdornment={false}
              disabled
              component={renderTextField}
              name='project_type_desc'
              fullWidth
            />
          </Grid>
          <Grid item xl={4} xs={4} lg={4} md={4}>
            <Field
              inputLabel='Business Unit Type'
              inputType='text'
              showInputAdornment={false}
              disabled
              component={renderTextField}
              name='bu_type_code'
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchStoreDetailsFromJde: storeNumber => {
    dispatch(fetch(storeNumber));
  },
  fetchStoreDetailsByCompanyFromJde: compahyNumber => {
    dispatch(fetchByCompany(compahyNumber));
  },
  setSelectedStore: selectedStore => {
    dispatch(setSelectedStore(selectedStore));
  },
  setRefreshData: refresh => {
    dispatch(refreshData(refresh));
  },
  setDataFetched: dataFetch => {
    dispatch(dataFetched(dataFetch));
  },
  setStoreDetails: storeDetail => {
    dispatch(setData(storeDetail));
  },
  dispatch,
});

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues(ownProps.form)(state);
  const storeDetails = selectStoreDetails(state);
  const selectedStore = getSelectedStore(state);
  const refreshData = isRefreshData(state);
  const fetchedValues = {
    addr_line1: {
      label: selectedStore.address_line_1,
      value: selectedStore.company,
    },
    store_type_code: selectedStore.store_type_code,
    project_type_desc: selectedStore.project_type_code,
    bu_type_code: selectedStore.bu_type_code,
    store_seq_nbr: selectedStore.store_seq_nbr,
    jde_company_nbr: selectedStore.company,
    store_nbr: selectedStore.store_nbr,
    city_name: selectedStore.city_name,
    state_prov_code: selectedStore.state_prov_code,
  };
  return {
    formValues,
    storeDetails,
    selectedStore,
    fetchedValues,
    refreshData,
  };
};

const reduxConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StoreDetails);

export default reduxConnected;
