import _ from 'lodash';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { fetchProjectComments } from '../../../services/comments';
import * as actions from '../commentActions';

export default function* fetchCommentWatcher() {
  yield takeLatest(actions.FETCH, fetchCommentWorker);
}

export function* fetchCommentWorker(action) {
  yield put(actions.loading(true));
  try {
    const response = yield call(fetchProjectComments, { closingId: action.closingId });
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.fetchCommentsByClosingId.Comments', []);
  yield put(actions.appendData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
