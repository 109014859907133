import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { default as MUIDialog } from '@material-ui/core/Dialog';

import { closeModal } from './modalActions';

const mapStateToProps = state => ({
  isOpen: state.modal.open,
  body: state.modal.body,
});

const mapDispatchToProps = {
  onCloseModal: closeModal,
};

const Modal = ({
  body,
  isOpen,
  outerClose,
  onCloseModal,
}) => (
  <div>
    <MUIDialog
      open={isOpen}
      onClose={outerClose ? onCloseModal : null}
    >
      <div>
        {body}
      </div>
    </MUIDialog>
  </div>
);

Modal.defaultProps = {
  body: null,
  isOpen: false,
  outerClose: false,
};

Modal.propTypes = {
  body: PropTypes.node,
  isOpen: PropTypes.bool,
  outerClose: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
