import {
    takeLatest,
    call,
    put,
    select,
} from 'redux-saga/effects';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import React from 'react';

import { createSysUser } from '../../../services/users';
import { openSnackbar } from '../../_shared/snack-bar/snackbarActions';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';
import * as actions from '../usersActions';
import _config from "../../../config"


const formValueSelector = state => _.get(state, 'form.createUser.values');

export default function* createUserWatcher() {
    yield takeLatest(actions.CREATE_USER, createUserWorker);
}

export function* createUserWorker(action) {
    yield put(actions.loading(true));
    // const secretKey = process.env.ENCRYPTION_KEY;
    const formData = yield select(formValueSelector);
    const formValues = JSON.parse(JSON.stringify(formData));
    formValues.extrn_company_id = formValues.extrn_company_name.value;
    formValues.extrn_company_name = formValues.extrn_company_name.label;
    formValues.password = formValues.password;
    // formValues.password = CryptoJS.AES.encrypt(formValues.password, secretKey).toString();

    try {
        const response = yield call(createSysUser, formValues);
        const data = _.get(response, 'data.createSysUser', {
            Success: 'false',
            message: 'Something went wrong',
        });
        console.log('saga create user res ', data);
        if (data.Success === 'true') {
            formValues.password = '';
            yield call(success, action.history, formValues);
        } else {
            yield call(failure, new Error(data.message));
        }
    } catch (error) {
        console.log(error);
        yield call(failure, error);
    }
}

export function* success(history, user) {
    yield put(actions.loading(false));
    history.push('/users/manage', { user });
    yield put(
        openSnackbar({
            message: 'User created successfully',
            showCloseIcon: true,
        }),
    );
}

export function* failure(error) {
    yield put(actions.loading(false));
    yield put(actions.error(error));
    const pwdPolicyLink = <a href={_config.URL_LINK} rel="noopener noreferrer" target="_blank" >here</a>;
    const pwdPolicy = <span>Password is not <b>adhering</b> to Walmart policy. Please follow Walmart password guidelines {pwdPolicyLink}.</span>;
    const errMsg = error.message.toLowerCase().includes('password') ? <span>{error.message} <br />{pwdPolicy}</span> : error.message;
    yield put(
        errorOpenModal({
            title: 'User could not be created',
            message: errMsg,
        }),
    );
}
