export const FETCH = 'STORE/FETCH';
export const FETCH_BY_COMPANY = 'STORE/FETCH_BY_COMPANY';
export const LOADING = 'STORE/LOADING';
export const DATA_FETCHED = 'STORE/DATA_FETCHED';
export const ERROR = 'STORE/ERROR';
export const SET_DATA = 'STORE/SET_DATA';
export const SET_SELECTED_STORE = 'STORE/SET_SELECTED_STORE';
export const REFRESH_DATA = 'STORE/REFRESH_DATA';


export const fetch = storeNumber => ({ type: FETCH, storeNumber });
export const fetchByCompany = companyNumber => ({ type: FETCH_BY_COMPANY, companyNumber });
export const loading = isLoading => ({ type: LOADING, loading: isLoading });
export const dataFetched = dataFetched => ({ type: DATA_FETCHED, dataFetched });
export const error = err => ({ type: ERROR, error: err });
export const setData = data => ({ type: SET_DATA, data });
export const setSelectedStore = (selectedStore) => ({
  type: SET_SELECTED_STORE, selectedStore,
});
export const refreshData = (refreshData) => ({
  type: REFRESH_DATA, refreshData,
});

