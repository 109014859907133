import React from 'react';
import PropTypes from 'prop-types';
import Header from '../header/Header';
import styles from './MainLayout.module.scss';

export const MainLayout = ({ children }) => (
  <div className={styles.layout}>
    <Header />
    { children }
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
