import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import * as actions from "../commentActions";
import { editCategoryComment } from "../../../services/comments";

const formValueSelector = state => _.get(state, "form.editCommentForm.values");
const commentSelector = state => _.get(state, "comments.data");
export default function* editCategoryCommentWatcher() {
  yield takeLatest(actions.EDIT_CATEGORY_COMMENT, editCategoryCommentWorker);
}

export function* editCategoryCommentWorker(action) {
  const formValues = yield select(formValueSelector);
  const variables = {
    doc_category_id: action.categoryId,
    comment_seq_nbr: formValues.commentId,
    comment_text: formValues.commentText,
    comment_name: formValues.commentTitle,
  };

  try {
    const response = yield call(editCategoryComment, variables);
    const data = _.get(response, "data.editCategoryComment");

    if (data.Success === "true") {
      yield call(success, { comment: variables });
    } else {
      yield call(failure, new Error("Something Went Wrong"));
      alert("Could not edit comment");
    }
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response) {
  const comments = yield select(commentSelector);
  //const updateIndex = _.findIndex(comments, ['comment_seq_nbr', response.comment.comment_seq_nbr]);
  const updateIndex = _.findIndex(comments, {
    doc_category_id: response.comment.doc_category_id,
    comment_seq_nbr: response.comment.comment_seq_nbr,
  });

  yield put(actions.updateComment(updateIndex, response.comment));
}

export function* failure(error) {
  yield put(actions.error(error));
}
