import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Field, reduxForm, change } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import renderDatePicker from '../../_shared/utils/renderDatePicker';
import renderSelectField from '../../_shared/utils/renderSelectField';
import renderTextField from '../../_shared/utils/renderTextField';
import InputField from '../../_shared/input-field/InputField';
import { upload } from '../../document/documentActions';
import { validateUploadDocFields } from './ValidateDocInfo';
import { selectProject } from '../projectSelectors';

const mapStateToProps = state => ({
  data: selectProject(state),
  isLoading: _.get(state, 'document.request.loading', false),
});

// eslint-disable-next-line react/prop-types
const customFileInput = ({ meta: { visited, touched, error }, input, ...custom }) => {
  delete input.value; // <-- just delete the value property
  return (
    <React.Fragment>
      <InputField {...input} {...custom} error />
      {(touched || visited) &&
        error && (
          <span
            style={{
              color: '#f44336',
              fontFamily: 'Arial',
              fontSize: '0.75rem',
              marginLeft: '10px',
            }}
          >
            {error}
          </span>
        )}
    </React.Fragment>
  );
};
const useStyles = makeStyles(theme => ({
  dialogPaper: {
    margin: theme.spacing(7),
    minWidth: '54vw',
    maxWidth: '54vw',
    padding: theme.spacing(1),
  },
  dialogContent: {
    overflowY: 'unset',
  },
  loadingSpinner: {
    position: 'absolute',
    left: '50%',
  },
}));
const UploadNewDocument = (props) => {
  const {
    open,
    handleClose,
    documentTypes,
    fetchDocumentSubTypes,
    documentSubTypes,
    reset,
    isLoading,
  } = props;
  const classes = useStyles();

  const getDocumentSubTypes = (value) => {
    fetchDocumentSubTypes(value);
    props.dispatch(change('uploadNewDocument', 'doc_subtype_desc', ''));
  };

  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const renderCheckbox = ({ label }) => (
    <FormControlLabel
      control={
      <Checkbox
        checked={checkBoxValue}
        onChange={handleChangeCheckBox("environmental")}
      />}
      label={label}
    />
  );

  const handleChangeCheckBox = name => event => {
    if (event){
      if (event.target){
        setCheckBoxValue(event.target.checked);
        props.dispatch(change(props.form, "ecm_attr_env_related", event.target.checked));
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
      scroll="body"
    >
      <form onSubmit={values => props.handleSubmit(values)}>
        <Container maxWidth="sm">
          {isLoading && <CircularProgress className={classes.loadingSpinner} />}
          <DialogTitle id="form-dialog-title"> Upload A Document</DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item md={12}>
                <Field
                  name="document_file"
                  component={customFileInput}
                  inputLabel="Document File"
                  inputType="file"
                  fullWidth
                  inputProps={{ accept: 'application/pdf' }}
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name="object_name"
                  component={renderTextField}
                  inputLabel="Document Name"
                  inputType="text"
                  fullWidth
                  value=""
                  accept="application/pdf"
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name="doc_type"
                  component={renderSelectField}
                  inputLabel="Document Type"
                  options={documentTypes}
                  onChange={(value) => {
                    getDocumentSubTypes(value.value);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name="doc_subtype_desc"
                  component={renderSelectField}
                  inputLabel="Document Sub Type"
                  options={documentSubTypes}
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name="ecm_attr_doc_date"
                  component={renderDatePicker}
                  inpuType="date"
                  inputLabel="Executed Date"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <Field 
                  name="ecm_attr_env_related" 
                  component={renderCheckbox} 
                  label="Environmental" 
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Container>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              handleClose();
            }}
            color="primary"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isLoading}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
UploadNewDocument.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  fetchDocumentSubTypes: PropTypes.func.isRequired,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type_name: PropTypes.string,
      label_text: PropTypes.string,
      use_type: PropTypes.string,
    }),
  ).isRequired,
  documentSubTypes: PropTypes.arrayOf(
    PropTypes.shape({
      rel_attr_value: PropTypes.string,
      rel_attr_desc: PropTypes.string,
      use_subtype: PropTypes.bool,
      inactive: PropTypes.bool,
    }),
  ).isRequired,
  reset: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const uploadForm = reduxForm({
  form: 'uploadNewDocument',
  enableReinitialize: true,
  validate: validateUploadDocFields,
  shouldError: () => true,
  onSubmit: (values, dispatch, props) => {
    const currentDatetime = new Date();
    const formattedDate = `${currentDatetime.getFullYear()}-${currentDatetime.getMonth() + 1}-${currentDatetime.getDate()}`;
    dispatch(upload({
      categoryId: props.categoryId,
      projectId: props.projectId,
      storeNumber: props.data.projectDetails.store_nbr,
      company: props.data.projectDetails.jde_company_nbr,
      lastModified: formattedDate,
      categoryIndex: props.match.params.documentsCategoryId,
      resetForm: props.reset,
    }));
  },
})(UploadNewDocument);

const reduxConnected = connect(mapStateToProps)(uploadForm);

export default withRouter(reduxConnected);
