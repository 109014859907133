import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { default as MUITable } from '@material-ui/core/Table';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';

import styles from './Table.css';

/**
 * @namespace Table
 * @memberof app.components
 * @param {*} props
 */



export const renderColumnHeader = (column) => (
  <TableCell key={column} >
    {column}
  </TableCell>
);

/*
columnHeaders => Header of Table.
children => Data collection to render table body rows.
sortingHandler => Method to execute 'onClick' of header column.
sortDirection => Sorting Direction. (asc/desc)
*/
export const Table = ({ columnHeaders, children,sortingHandler, sortDirection, sortColum}) => (
  <MUITable>
    <TableHead>
      <TableRow>
        {columnHeaders.map((column, i) => (
           <TableCell key={column} onClick={()=>sortingHandler(column)}
           sortDirection={sortColum === column ? sortDirection : false}
           >
              <TableSortLabel 
              active={column === sortColum}
              direction={sortColum === column ? sortDirection : 'asc'}
              >
                {column}       
              </TableSortLabel>
         </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody >
      { children }
    </TableBody>
  </MUITable>
);

Table.propTypes = {
  columnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sortingHandler: PropTypes.func,
  sortDirection: PropTypes.string,
  sortColumn: PropTypes.string,
};

// row will be an object of values per column (rows will be an array of these objects

export default withStyles(styles)(Table);
