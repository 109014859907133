import React, { Component } from "react";
import SSO from "../../sso";
import config from "../../sso/config";

class Login extends Component {
  constructor() {
    super();
  }
  componentWillMount() {
   const extUser = localStorage.getItem(config.fetch_details_param);
   SSO.signIn(extUser)
      .then(res => {})
      .catch(err => {});
  }

  render() {
    return <div></div>;
  }
}

export default Login;
