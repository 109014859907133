import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Field, reduxForm, change } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import renderDatePicker from "../../_shared/utils/renderDatePicker";
//import DatePicker from "../../_shared/date-picker/DatePicker";
import renderSelectField from "../../_shared/utils/renderSelectField";
import renderTextField from "../../_shared/utils/renderTextField";
import { validateMetdataInfo } from "./ValidateDocInfo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { editMetadata } from "../../document/documentActions";
import EditDocumentStatusModalContent from "./EditDocumentStatusModalContent";
import {
  relAdminWeight,
  getMaxWeight,
  adminWeight,
} from "../../../roleaccess/utils";
const useStyles = makeStyles(theme => ({
  dialogPaper: {
    margin: theme.spacing(6),
    minWidth: "60vw",
    maxWidth: "60vw",
    padding: theme.spacing(1),
  },
  dialogContent: {
    overflowY: "unset",
  },
  loadingSpinner: {
    position: "absolute",
    left: "50%",
  },
  title: {
    padding: '10px 24px',
  },
}));

const EditDocumentMetadata = props => {
  const {
    open,
    handleClose,
    documentSubTypes,
    reset,
    handleSubmit,
    openDocStatusChangedConfirmModal,
    isSubmitting,
    userRole,
    initialValues,
  } = props;
  const classes = useStyles();
  let docStatus = (docStatus = [
    { label: "In Progress", value: "In Progress" },
    { label: "Public Viewable", value: "Public Viewable" },
  ]);
  const canPromoteDoc =
    getMaxWeight(userRole) === adminWeight() ||
    getMaxWeight(userRole) === relAdminWeight() ; 
  const isRELAdmin = getMaxWeight(userRole) === relAdminWeight();
  const isPublicViewable = initialValues.doc_status === "Public Viewable";
  const canEditStatus = () => {
    if(isRELAdmin){
        return true;
    }else{
      return canPromoteDoc && !isPublicViewable;
    }
  }

  const [checkBoxValue, setCheckBoxValue] = useState(initialValues.ecm_attr_env_related);
  const renderCheckbox = ({ label }) => (
    <FormControlLabel
      control={
      <Checkbox
        checked={checkBoxValue}
        onChange={handleChangeCheckBox("environmental")}
      />}
      label={label}
    />
  );

  const handleChangeCheckBox = name => event => {
    if (event){
      if (event.target){
        setCheckBoxValue(event.target.checked);
        props.dispatch(change(props.form, "ecm_attr_env_related", event.target.checked));
      }
    }
  };

  useEffect(() => {
    setCheckBoxValue(initialValues.ecm_attr_env_related);
  }, [initialValues.ecm_attr_env_related]);

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
        classes={{ paper: classes.dialogPaper }}
        scroll='body'>
        <Container maxWidth='sm'>
          {isSubmitting && (
            <CircularProgress className={classes.loadingSpinner} />
          )}
          <DialogTitle id='form-dialog-title' className={classes.title}> 
            Edit Document Information
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid container spacing={1} alignItems='center' justify='center'>
              <Grid item md={12}>
                <Field
                  name='object_name'
                  component={renderTextField}
                  inputLabel='Document Name'
                  inputType='text'
                  fullWidth
                  disabled={isPublicViewable}
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name='doc_type_desc'
                  component={renderTextField}
                  fullWidth
                  disabled
                  inputLabel='Document Type'
                  
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name='doc_subtype_desc'
                  component={renderSelectField}
                  inputLabel='Document Sub Type'
                  options={documentSubTypes}
                  normalize={selection => selection.label}
                  format={value => ({ value, label: value })}
                  isClearable={false}
                  disabled={isPublicViewable}
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name='doc_status'
                  component={renderSelectField}
                  inputLabel='Document Status'
                  normalize={selection => selection.label}
                  format={value => ({ value, label: value })}
                  options={docStatus}
                  isClearable={false}
                  onChange={value => {
                    if (value.value === "In Progress") {
                      openDocStatusChangedConfirmModal(
                        <EditDocumentStatusModalContent></EditDocumentStatusModalContent>,
                      );
                    }
                  }}
                  fullWidth
                  disabled={!canEditStatus()}
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name='originating_dept'
                  component={renderTextField}
                  inputLabel='Originating Department'
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <Field
                  name='ecm_attr_doc_date'
                  component={renderDatePicker}
                  inputLabel='Executed Date'
                  fullWidth
                  type='Date'
                  inputStyleType='sizeFull'
                  invalidDateMessage=''
                  disabled={isPublicViewable}
                />
              </Grid>
              <Grid item md={12}>
                <Field 
                  name="ecm_attr_env_related" 
                  component={renderCheckbox} 
                  label="Environmental" 
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Container>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              handleClose();
              setCheckBoxValue(initialValues.ecm_attr_env_related);
            }}
            color='primary'
            disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={e => handleSubmit(e)}
            color='primary'
            variant='contained'
            disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
EditDocumentMetadata.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

const editDocumentMetadataForm = reduxForm({
  form: "editDocumentMetadata",
  enableReinitialize: true,
  validate: validateMetdataInfo,
  shouldError: () => true,
  onSubmitFail: (errors, dispatch, props) => {
    console.log(errors);
  },
  onSubmit: (values, dispatch, props) => {
    const { initialValues, isEditFormSubmitting } = props;
    const alteredValues = _.pickBy(
      values,
      (value, key) => value !== initialValues[key],
    );

    if (!_.isEmpty(alteredValues)) {
      if (alteredValues.ecm_attr_doc_date) {
        var formatedDate = alteredValues.ecm_attr_doc_date.format("YYYY-MM-DD");
        alteredValues.ecm_attr_doc_date = formatedDate;
        //initialValues.ecm_attr_doc_date = formatedDate;
        values.ecm_attr_doc_date = formatedDate;
      }
    }
    dispatch(
      editMetadata({
        history: props.history,
      }),
    );
  },
})(EditDocumentMetadata);

const reduxConnected = connect()(editDocumentMetadataForm);

export default withRouter(reduxConnected);
