import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import * as actions from "../commentActions";
import { deleteCategoryComment } from "../../../services/comments";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";

const commentSelector = state => _.get(state, "comments.data");
var values = {};

export default function* deleteCategoryCommentWatcher() {
  yield takeLatest(
    actions.DELETE_CATEGORY_COMMENT,
    deleteCategoryCommentWorker,
  );
}

export function* deleteCategoryCommentWorker(action) {
  const { categoryId, sequenceNumber } = action;
  values = {
    doc_category_id: categoryId,
    comment_seq_nbr: sequenceNumber,
  };

  try {
    const response = yield call(deleteCategoryComment, values);
    yield call(success, response, action.history);
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response, history) {
  var success = response.data.deleteCategoryComment.Success;
  if (success === "true") {
    var comments = yield select(commentSelector);
    //let removeIndex = _.findKey(comments, ['comment_seq_nbr', values.comment_seq_nbr]);
    let removeIndex = _.findKey(comments, {
      doc_category_id: values.doc_category_id,
      comment_seq_nbr: values.comment_seq_nbr,
    });
    yield put(actions.deleteItem(removeIndex));
  } else {
    yield call(failure, err);
  }
}

export function* failure(error) {
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: "Comment could not be deleted",
      message: "An error occured while deleting the comment",
    }),
  );
}
