export const ERROR = 'USERS/ERROR';
export const FETCH = 'USERS/FETCH';
export const SEARCH = 'USERS/SEARCH';
export const LOADING = 'USERS/LOADING';
export const SET_DATA = 'USERS/SET_DATA';
export const FETCH_PROJ_USERS = 'USERS/FETCH_PROJ_USERS';
export const UPDATE_PROJ_USERS = 'USERS/UPDATE_PROJ_USERS';
export const FETCH_USERS_WITH_ROLES = 'USERS/FETCH_USERS_WITH_ROLES';
export const SET_PAGE_DETAILS = 'USERS/SET_PAGE_DETAILS';
export const SET_TOTAL_USERS = 'USERS/SET_TOTAL_USERS';
export const UPDATE_PASSWORD = 'USERS/UPDATE_PASSWORD';
export const CREATE_TEMP_USER_PASSWORD = 'USERS/SET_TEMP_USER_PASSWORD';
export const DELETE_EXT_USER = 'USERS/DELETE_EXT_USER';
export const FETCH_COMPANIES = 'USERS/FETCH_COMPANIES';
export const SET_COMPANIES = 'USERS/SET_COMNANIES';
export const CREATE_USER = 'USERS/CREATE';

export const error = err => ({ type: ERROR, error: err });
export const fetch = pageDetails => ({ type: FETCH, pageDetails: pageDetails });
export const search = filter => ({ type: SEARCH, filter });
export const loading = isLoading => ({ type: LOADING, loading: isLoading });
export const setData = data => ({ type: SET_DATA, data });
export const setTotalUsers = totalUsers => ({
  type: SET_TOTAL_USERS,
  totalUsers,
});
export const setPageDetails = pageDetails => ({
  type: SET_PAGE_DETAILS,
  pageDetails: pageDetails,
});
export const fetchProjectUsers = closing_id => ({
  type: FETCH_PROJ_USERS,
  closing_id,
});
export const fetchUsersWithRoles = () => ({ type: FETCH_USERS_WITH_ROLES });
export const updateProjectUsers = (projectId, history) => ({ type: UPDATE_PROJ_USERS, projectId, history });
export const updatePassword = () => ({ type: UPDATE_PASSWORD });
export const createTempUserPassword = (userId) => ({ type: CREATE_TEMP_USER_PASSWORD, userId});
export const deleteExternalUser = userId => ({ type: DELETE_EXT_USER, userId });
export const fetchCompanies = () => ({ type: FETCH_COMPANIES });
export const setCompanies = data => ({ type: SET_COMPANIES, data });
export const createUser = history => ({ type: CREATE_USER, history });

