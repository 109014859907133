import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import usersReducer from '../components/users/usersReducer';
import projectsReducer from '../components/projects/projectsReducer';
import documentReducer from '../components/document/documentReducer';
import modalReducer from '../components/_shared/modal/modalReducer';
import errorModalReducer from '../components/_shared/modal/errorModalReducer';
import snackbarReducer from '../components/_shared/snack-bar/snackbarReducer';
import projectReducer from '../components/project/projectReducer';
import projectCategoryReducer from '../components/project/projectCategoryReducer';
import commentsReducer from '../components/comment/commentReducer';
import {sessionReducer } from 'redux-react-session';
import storeReducer from '../components/project/storeReducer';


export const rootReducer = combineReducers({
  users: usersReducer,
  projects: projectsReducer,
  projectDetails: projectReducer,
  projectCategories: projectCategoryReducer,
  document: documentReducer,
  modal: modalReducer,
  snackbar: snackbarReducer,
  ErrorModal: errorModalReducer,
  models: (state = {}) => state,
  form: formReducer,
  comments: commentsReducer,
  session: sessionReducer,
  store: storeReducer,
});
