import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchProjectCatogiriesByProjId } from "../../../services/projects";
import * as actions from "../projectCategoryActions";
import { convertData } from "../../../utilities";

export default function* fetchProjectCategoriesWatcher() {
  yield takeLatest(actions.FETCH, fetchProjectCategoriesWorker);
}

export function* fetchProjectCategoriesWorker(action) {
  yield put(actions.loading(true));

  try {
    const response = yield call(fetchProjectCatogiriesByProjId, {
      closing_id: action.closing_id,
    });
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, "data.propClosingCatgs", []);

  //   const convertedData = _.map(convertData(data), extraData => ({
  //    ...extraData,
  //    users: convertData(data.users),
  //    projectDocCategories: convertData(data.prop_Closing_Catgs),
  //  }));

  yield put(actions.setData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
