import React from 'react';

/**
 * Document Viewer Component
 *
 * @namespace Document
 * @memberof document.components
 */

const Viewer = () => (
  <div>
    Viewer
  </div>
);

export default Viewer;
