import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import CommentDropdown from '../forms/CommentDropdown';
import NoComment from '../../../assets/images/NoComment.svg';
import SubCategoryCommentForm from '../forms/SubCategoryCommentForm';
import './CommentsPanel.scss';
import { selectComments, selectCommentDropdownValue } from '../commentsSelectors';
import { selectProjectDocumentCategoriesList } from '../../project/projectSelectors';
import CommentCard from './CommentCard';
import * as commentActions from '../../comment/commentActions';
import { selectUserRoles } from '../../login/loginSelector';
import { REL_ADMIN } from "../../../roleaccess/adGroups";
import { viewOnlyWeight, getMaxWeight } from "../../../roleaccess/utils";

/**
 * Comments Panel
 *
 * @namespace Comment
 * @memberof Comment.components
 */

export class CommentsPanel extends React.Component {
  constructor(props) {
    super(props);

    let initialSelectedValueInDropdown = undefined;

    if (null != props.categoryId && undefined != props.categoryId
      && null != props.categoryId && undefined != props.categoryId) {
      initialSelectedValueInDropdown = {
        label: props.categoryName,
        value: props.categoryId,
      }
    }

    this.state = {
      comments: props.comments,
      resolved: true,
      unresolved: true,
      showHideFilter: false,
      categoryFilter: props.categoryName ? props.categoryName : "All",
      commentBoxShow: false,
      dropdownSelectedValue: props.isProjectLevel ? { label: "All", value: "All" } : initialSelectedValueInDropdown,
    };

    this.handleCommentBoxShow = this.handleCommentBoxShow.bind(this);
    this.stateFilterCancel = this.stateFilterCancel.bind(this);
    this.filterCommentByStatus = this.filterCommentByStatus.bind(this);
    this.showHideFilter = this.showHideFilter.bind(this);
    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
    this.filterComments = this.filterComments.bind(this);
  }

  // shouldComponentUpdate(nextProps) {
  //   if (this.props.categoryName !== nextProps.categoryName) {
  //     this.filterComments({ value: nextProps.categoryName });
  //   }
  //   return true;
  // }

  componentDidUpdate(prevProps) {
    if (this.props.comments !== prevProps.comments) {
      const { dropdownSelectedValue } = this.state;

      if (dropdownSelectedValue) {
        this.filterComments(dropdownSelectedValue);
      } else {
        this.setState({ comments: this.props.comments });
      }
    }
  }

  handleCommentBoxShow = () => {
    this.setState({ commentBoxShow: this.state.commentBoxShow ? false : true });
    this.forceUpdate();
  }

  stateFilterCancel = () => {
    this.setState({ resolved: true, unresolved: true });
  }

  handleChangeCheckBox = value => (event) => {
    this.setState({ [value]: event.target.checked });
  }

  showHideFilter = () => {
    this.setState({ showHideFilter: !this.state.showHideFilter });
  }

  isCommentProjectLevel = (comment) => {
    const { isProjectLevel } = this.props;
    let retVal = isProjectLevel;

    if (comment != null && comment != undefined) {
      if (comment.closing_id) {
        retVal = true;
      } else {
        retVal = false;
      }
    }
    return retVal;
  }

  isProjectLevelCommentPanel = (selected) => {
    const { isProjectLevel } = this.props;
    let retVal = isProjectLevel;

    if (selected != null && selected != undefined) {
      if (selected.value == "Project-Level") {
        retVal = true;
      } else {
        retVal = false;
      }
    }
    return retVal;
  }

  triggerSubmit = (isCommentPanelProjectLevel) => {
    const {
      createProjectLevelComment,
      createCategoryLevelComment,
    } = this.props;

    let submitValue = null;

    if (isCommentPanelProjectLevel) {
      submitValue = createProjectLevelComment;
    } else {
      submitValue = createCategoryLevelComment;
    }
    return submitValue;
  }

  filterComments = (value) => {
    const { dropdownSelectedValue } = this.state;
    const { comments, setCommentDropdownSelection } = this.props;

    let dropdownValueToUse = null;

    if (value && value.label && value.value) {
      dropdownValueToUse = value;
      this.setState({ dropdownSelectedValue: value });
    } else {
      dropdownValueToUse = dropdownSelectedValue;
    }

    let filteredComments = [];

    if (dropdownValueToUse.value === "All") {
      filteredComments = comments;
      filteredComments = this.filterCommentByStatus(filteredComments);
      this.setState({ comments: filteredComments })
    } else if (dropdownValueToUse.value === "Project-Level") {
      filteredComments = comments.filter(comment => (comment.closing_id));
      this.setState({ comments: filteredComments })
    } else {
      filteredComments = comments.filter(comment => (comment.doc_category_id && comment.doc_category_id == dropdownValueToUse.value));
      filteredComments = this.filterCommentByStatus(filteredComments);
      this.setState({ comments: filteredComments })
    }

    if (value && value.label && value.value) {
      setCommentDropdownSelection(value.label, value.value);
    }
    this.forceUpdate();
  }

  filterCommentByStatus = (comments) => {
    const { resolved, unresolved } = this.state;
    let retVal = null;

    if (resolved == true && unresolved == true) {
      retVal = comments;
    } else if (resolved == true) {
      retVal = comments.filter(comment => (comment.cmt_complete_ts))
    } else if (unresolved == true) {
      retVal = comments.filter(comment => (!comment.cmt_complete_ts))
    } else {
      retVal = [];
    }
    return retVal;
  }

  renderNoComments = () => (
    <div className="no-comment">
      <NoComment />
      <Typography className="no-comment-text" align="center">
        No comments yet
      </Typography>
    </div>
  );

  getCategoryId = (comment) => {
    const { categoryId } = this.props;
    let categoryIdToReturn = categoryId;

    if (comment.doc_category_id) {
      categoryIdToReturn = comment.doc_category_id;
    }
    return categoryIdToReturn;
  }

  getCategoryName = (comment) => {
    const { categoryName, categories } = this.props;
    let categoryNameToDisplay = categoryName;

    if (comment.doc_category_id) {
      let categoryToDisplay = categories.find(category => (category.doc_category_id == comment.doc_category_id));
      if (categoryToDisplay) {
        categoryNameToDisplay = categoryToDisplay.category_name;
      }
    }
    return categoryNameToDisplay;
  }

  render() {
    const {
      showHideFilter,
      commentBoxShow,
      resolved,
      unresolved,
      dropdownSelectedValue,
      comments,
    } = this.state;

    const {
      isProjectLevel,
      categoryId,
      categoryName,
      categories,
      userRole,
      loggedInUser,
      statusCode,
    } = this.props;

    let loginId = 'eUserId';
    if (loggedInUser) {
      loginId = loggedInUser.loginId;
    }

    let canCreateEditReplyComments = true;

    if (userRole && !userRole.includes(REL_ADMIN) && statusCode != '2'){
      canCreateEditReplyComments = !getMaxWeight(userRole) <= viewOnlyWeight();
    } else {
      canCreateEditReplyComments = false;
    }

    let commentCategoryId = categoryId;
    let nameToShowOnLeaveCommentBar = categoryName != undefined ? categoryName : 'Project-Level';
    let commentBarText = 'Click here to leave ' + nameToShowOnLeaveCommentBar + ' comment';
    let isCommentPanelProjectLevel = isProjectLevel;
    let showAddCommentBar = true;
    let showStatusFilterIcon = true;

    if (null != dropdownSelectedValue && undefined != dropdownSelectedValue) {
      isCommentPanelProjectLevel = this.isProjectLevelCommentPanel(dropdownSelectedValue);

      if (!isCommentPanelProjectLevel && dropdownSelectedValue.value != 'All') {
        commentCategoryId = parseInt(dropdownSelectedValue.value);
      }
      if (dropdownSelectedValue.label != null && dropdownSelectedValue.label != undefined) {
        commentBarText = 'Click here to leave ' + dropdownSelectedValue.label + ' comment';
      }
      if (dropdownSelectedValue.value == 'All') {
        commentBarText = 'You are currently viewing all comments';
        showAddCommentBar = false;
      }
      if (!canCreateEditReplyComments) {
        commentBarText = 'You cannot create comments';
      }
      if (dropdownSelectedValue.value == 'Project-Level') {
        showStatusFilterIcon = false;
      }
    }

    return (
      <div className="tab-comments">
        <Typography variant="h5" className="header">
          Comments
          <span style={{ paddingLeft: '50%' }}>
            {comments.length !== 0 && (
              <ReactToPrint
                trigger={() => <Button color="primary" variant="contained" className="apply-filters">Print</Button>}
                content={() => this
                }
              />
            )
            }
          </span>
        </Typography>

        <span className={'displayBlock'}>
          <CommentDropdown
            showStatusFilterIcon={showStatusFilterIcon}
            showFilter={showHideFilter}
            handleSubmit={this.filterComments}
            showHideFilter={this.showHideFilter}
            categories={categories}
            valueToShow={dropdownSelectedValue}
          />
        </span>

        {showStatusFilterIcon &&
          <div className="status-container" hidden={!showHideFilter}>
            <div className="form-container">
              <Typography>
                Comment Status
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={resolved}
                      onChange={this.handleChangeCheckBox('resolved')}
                      value="resolved"
                    />
                  )}
                  label="Resolved"
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={unresolved}
                      onChange={this.handleChangeCheckBox('unresolved')}
                      value="unresolved"
                    />
                  )}
                  label="Unresolved"
                />
              </FormGroup>
            </div>
            <div className="filter-button">
              <Button className="filter-cancel" onClick={this.stateFilterCancel}>Reset</Button>
              <Button variant="contained" color="primary" className="apply-filters"
                onClick={this.filterComments}>
                Apply
              </Button>
            </div>
          </div>
        }

        <div className="new-comment">
          <div className={commentBoxShow ? 'displayNone' : 'displayBlock'}>
            <Button
              testId="new-comment-button"
              className="new-comment-button"
              onClick={this.handleCommentBoxShow}
              disabled={!showAddCommentBar || !canCreateEditReplyComments}
            >
              {commentBarText}
            </Button>
          </div>
        </div>

        {showAddCommentBar && commentBoxShow && (
          <SubCategoryCommentForm
            isProjectLevel={isCommentPanelProjectLevel}
            reply={false}
            handleSubmit={this.triggerSubmit(isCommentPanelProjectLevel)}
            onCancel={this.handleCommentBoxShow}
            id={commentCategoryId}
          />
        )}
        
          {comments.length
            ? comments.map(comment => (
                <CommentCard
                  comment={comment}
                  isProjectLevel={this.isCommentProjectLevel(comment)}
                  categoryId={this.getCategoryId(comment)}
                  categoryName={this.getCategoryName(comment)}
                  userRole={userRole}
                  loginId={loginId}
                  statusCode={statusCode}
                />
            ))
            : this.renderNoComments()
          }
      </div>
    );
  }
}

CommentsPanel.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  categoryName: PropTypes.string,
  categoryId: PropTypes.number,
  isProjectLevel: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    doc_category_id: PropTypes.number,
    category_name: PropTypes.string,
  })),
  createProjectLevelComment: PropTypes.func,
  createCategoryLevelComment: PropTypes.func,
};

const mapStateToProps = state => ({
  comments: selectComments(state),
  categories: selectProjectDocumentCategoriesList(state),
  initialDropdownValue: selectCommentDropdownValue(state),
  //userRole: _.get(state, "session.user.data.userRole"),
  userRole: selectUserRoles(state),
  loggedInUser: _.get(state, 'session.user.data'),
});

const mapDispatchToProps = dispatch => ({
  createProjectLevelComment: () => {
    dispatch(commentActions.createProjectComment());
  },
  createCategoryLevelComment: (categoryId) => {
    dispatch(commentActions.createCategoryComment(categoryId));
  },
  setCommentDropdownSelection: (label, value) => {
    dispatch(commentActions.setDropdownSelection(label, value));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(CommentsPanel);
