import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as _ from 'lodash';
import { isRequestInprogress, selectCompanies } from '../usersSelectors';
import { createUser } from '../usersActions';
import ManageUser from '../ManageUser';
import { validate } from './ValidateCreateUser';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
  },
  innerContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  button: {
    float: 'right',
  },
  updateButton: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
  loadingSpinner: {
    position: 'relative',
    left: '44%',
  },
}));
let CreateUser = (props) => {
  const {
    history,
    reset,
    invalid,
    createSysUser,
    isLoading,
  } = props;
  const classes = useStyles();
  const onCancel = () => {
    reset();
    history.replace('/users');
  };
  return (
    <Grid
      container
      spacing={6}
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid
        container
        spacing={4}
        item
        xs={8}
      >
        <Grid item xs={12}>
          <IconButton
            className={classes.button}
          >
            <Close onClick={onCancel} />
          </IconButton>
          <Typography variant="h5">Create New External User </Typography>
          {isLoading && <CircularProgress className={classes.loadingSpinner} />}
        </Grid>
        <ManageUser {...props} />
        <Grid xs={12} item>
          <Button
            color="primary"
            variant="contained"
            className={classes.updateButton}
            disabled={invalid || isLoading}
            onClick={() => createSysUser()}
          >
            CREATE
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

CreateUser.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  }).isRequired,
  reset: PropTypes.func.isRequired,
  createSysUser: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

CreateUser = reduxForm({
  form: 'createUser',
  enableReinitialize: true,
  validate,
})(CreateUser);
const mapStateToProps = state => ({
  isLoading: isRequestInprogress(state),
  companies: selectCompanies(state),
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  createSysUser: () => dispatch(createUser(ownProps.history)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
