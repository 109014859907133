import { takeLatest, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import * as actions from '../commentActions';
import { deleteProjectComment } from '../../../services/comments';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';

const commentSelector = state => _.get(state, 'comments.data');

export default function* deleteProjectCommentWatcher() {
  yield takeLatest(actions.DELETE_PROJECT_COMMENT, deleteProjectCommentWorker);
}

export function* deleteProjectCommentWorker(action) {
  const { projectId, sequenceNumber } = action;
  const variables = {
    closing_id: projectId,
    seq_nbr: sequenceNumber,
  };

  try {
    const response = yield call(deleteProjectComment, variables);
    const data = _.get(response, 'data.deleteProjectComment');
    if (data.Success === 'true') {
      yield call(success, variables);
    } else {
      yield call(failure, new Error('Something Went Wrong'));
    }
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response) {
  const comments = yield select(commentSelector);
  const removeIndex = _.findKey(comments, ['seq_nbr', response.seq_nbr]);
  yield put(actions.deleteItem(removeIndex));
}

export function* failure(error) {
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: 'Comment could not be deleted',
      message: 'An error occured while deleting the comment'
    }),
  );
  return error;
}
