import _ from 'lodash';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { searchUsers } from '../../../services/users';
import * as actions from '../usersActions';

export default function* searchUsersWatcher() {
  yield takeLatest(actions.SEARCH, searchUsersWorker);
}

export function* searchUsersWorker(action) {
  yield put(actions.loading(true));
  try {
    const response = yield call(searchUsers, action.filter);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.usersSearch', {});
  yield put(actions.setData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
