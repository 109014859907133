import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import { sessionService } from 'redux-react-session';

const configureStore = (preloadedState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const allMiddleware = applyMiddleware(
    // logger,
    sagaMiddleware,
  );

  /* eslint-disable no-underscore-dangle */
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  /* eslint-enable */

  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancer(allMiddleware),
  );
 
  sessionService.initSessionService(store, {refreshOnCheckAuth: true, driver: 'LOCALSTORAGE' });
  store.runSaga = sagaMiddleware.run;

  return store;
};

export default configureStore;
