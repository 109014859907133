import { combineReducers } from 'redux';
import { createDataReducer } from '../../store/dataReducer';
import * as actions from './projectCategoryActions';

export const initialFilterState = null;
export const initialState = {}


export default combineReducers({
  data: createDataReducer({
    SET_DATA: actions.SET_DATA
  })
});
