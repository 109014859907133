import { combineReducers } from 'redux';
import { createDataReducer } from '../../store/dataReducer';
import { createRequestReducer } from '../../store/requestReducer';
import * as projectActions from '../project/projectActions';
import * as actions from './projectsActions';

export const initialFilterState = null;
export const initialState = {
  totalProjects: 0,
  pageDetails: {
    page: 0,
    rowsPerPage: 10,
    sortColumn: "Modified", 
    sortDirection: "desc",
    filterObject : {
      StoreNumber: "",
      SequenceNumber: "",
      City: "",
      State: "",
      ProjectName: "",
      checkbox : {
        active: true,
        locked: true,
        dropped: true,
        closed: true, 
      }
    },
    currentSortedColumnName: "Modified",
  }
}

export function pageDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_PAGE_DETAILS:
        return {
            ...state,
            pageDetails: action.pageDetails
        }
    default:
      return state;
  }
}

export function totalProjectsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_TOTAL_PROJECTS:
        return {
            ...state,
            totalProjects: action.data
        }
    default:
      return state;
  }
}

export default combineReducers({
  data: createDataReducer({
    SET_DATA: actions.SET_DATA,
    UPSERT_ITEM: projectActions.SET_DATA,
  }),
  totalProjects: totalProjectsReducer,
  globalPageDetails: pageDetailsReducer,
  request: createRequestReducer({
    ERROR: actions.ERROR,
    LOADING: actions.LOADING,
  }),
  createProject: combineReducers({
    request: createRequestReducer({
      ERROR: actions.ERROR,
      LOADING: actions.LOADING,
    }),
  }),
});
