import ProjectCreate from './components/project/create/ProjectCreate';
import ProjectEdit from './components/project/edit/ProjectEdit';
import ProjectEditUsers from './components/project/edit/ProjectEditUsers';
import Users from './components/users/Users';
import Project from './components/project/Project';
import Projects from './components/projects/Projects';
import { MainLayout } from './components/layouts/MainLayout';
import ChangePassword from './components/users/changePassword/ChangePassword';
import ManageUser from './components/users/externalUsers/SetTemPassword';
import CreateUser from './components/users/externalUsers/CreateUser';

const createProjectRoute = {
  component: ProjectCreate,
  url: '/projects/create',
};
const editProjectRoute = {
  component: ProjectEdit,
  url: '/projects/:projectId/details/edit',
};
const editProjectUserRoute = {
  component: ProjectEditUsers,
  url: '/projects/:projectId/users/edit',
};
const viewUsersRoute = {
  component: Users,
  url: '/users',
  layout: MainLayout,
};
const viewProjectsRoute = {
  component: Projects,
  url: '/projects',
  layout: MainLayout,
};
const viewProjectDetailRoute = {
  component: Project,
  url: '/projects/:projectId',
  layout: MainLayout,
};
const viewProjectCategoryRoute = {
  component: Project,
  url: '/projects/:projectId/documents/categories/:documentsCategoryId',
  layout: MainLayout,
};
const changePasswordRoute = {
  component: ChangePassword,
  url: '/changePassword',
  layout: MainLayout,
};
const manageUserRoute = {
  component: ManageUser,
  url: '/users/manage',
};
const createUserRoute = {
  component: CreateUser,
  url: '/users/create',
};
export {
  createProjectRoute,
  editProjectRoute,
  editProjectUserRoute,
  viewUsersRoute,
  viewProjectsRoute,
  viewProjectDetailRoute,
  viewProjectCategoryRoute,
  changePasswordRoute,
  manageUserRoute,
  createUserRoute,
};
