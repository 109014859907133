import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import ProjectMenu from './ProjectMenu';
import ProjectContent from './ProjectContent';
import ProjectNavbar from './ProjectNavbar';

import { fetch as fetchCategories } from './projectCategoryActions';
import { fetch as fetchProject } from './projectActions';
import {fetchProjectUsers} from '../users/usersActions';
import { fetch as fetchprojects} from '../projects/projectsActions';
import { selectProject } from './projectSelectors';

const mapStateToProps = (state, ownProps) => {
  const params = _.get(ownProps, 'match.params', {});
  return ({
    projectID: _.get(params, 'projectId', null),
  });
};

const mapDispatchToProps = dispatch => ({
  fetchProjects: pageDetails => dispatch(fetchprojects(pageDetails)),
  fetchProjectCategories: (closing_id) => dispatch(fetchCategories(closing_id)),
  fetchProjectUsers: (closing_id) => dispatch(fetchProjectUsers(closing_id)),
  fetchProjectDetails: (closing_id) => dispatch(fetchProject(closing_id)),
});

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
    padding: '35px 62px',
  },
  layout: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    zIndex: 1,
  },
}));

export const Project = ({
  fetchProjectCategories,
  fetchProjectUsers,
  fetchProjectDetails,
  fetchProjects,
  projectID,
}) => {
  const classes = useStyles();

  useEffect(() => {
    let pageDetails = {
      page: 0,
      rowsPerPage: 10,
      sortColumn: "Modified", 
      sortDirection: "desc",
      filterObject: {
        Location : "",
        checkbox : {
          active: true,
          locked: true,
          dropped: true,
          closed: true,   
      }
      }
    };

    fetchProjects(pageDetails);
    fetchProjectCategories(parseInt( projectID));
    fetchProjectUsers(parseInt(projectID));
    fetchProjectDetails(parseInt(projectID));
  
  },{});

  return (
    <Fragment>
      <ProjectNavbar />
      <section className={classes.layout}>
        <ProjectMenu />
        <section className={classes.content}>
          <ProjectContent />
        </section>
      </section>
    </Fragment>
  );
};

Project.defaultProps = {
  fetchProjectCategories: () => {},
  fetchProjectUsers: () => {},
  fetchProjectDetails: ()=>{},
  projectsData: {},
  projectID:  _.noop,
};

Project.propTypes = {
  fetchProjectCategories: PropTypes.func,
  fetchProjectUsers:  PropTypes.func,
  fetchProjectDetails: PropTypes.func,
  projectsData: PropTypes.object,
  projectID: PropTypes.string,
};

const reduxConnected = connect(mapStateToProps, mapDispatchToProps)(Project);

export default withRouter(reduxConnected);
