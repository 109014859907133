import React, { useState } from "react";
import SubjectIcon from "@material-ui/icons/Folder";
import Face from "@material-ui/icons/Face";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { Typography, Divider } from "@material-ui/core";
import _ from "lodash";
import * as commentActions from "../commentActions";
import EditCommentForm from "../forms/EditCommentForm";
import CommentPopup from "./CommentPopup";
import * as modalActions from "../../_shared/modal/modalActions";

import { REL_ADMIN } from "../../../roleaccess/adGroups";
import {
  viewOnlyWeight,
  adminWeight,
  getMaxWeight,
} from "../../../roleaccess/utils";

const Comment = props => {
  const {
    isProjectLevel,
    categoryId,
    categoryName,
    comment,
    isReply,
    resolved,
    triggerDeleteCategoryComment,
    editCategoryComment,
    editProjectComment,
    deleteProjectComment,
    openConfirmModal,
    deleteCategoryResponse,
    editCategoryResponse,
    userRole,
    isUserCommentOwner,
    statusCode,
  } = props;
  const [editable, setEditable] = useState(false);
  const formatDateTime = dateTime => {
    var localdate = new Date(dateTime);
    const m = moment(`${localdate.toString()}`);
    return m.isValid()
      ? m.calendar(null, {
          sameDay: "[Today at] LT",
          nextDay: "[Tomorrow at] LT",
          nextWeek: "dddd [at] LT",
          lastDay: "[Yesterday at] LT",
          lastWeek: "[Last] dddd [at] LT",
          sameElse: "MM/DD/YYYY [at] LT",
        })
      : "";
  };

  const editCommentReply = () => {
    if (isProjectLevel) {
      // edit project level comment
      editProjectComment(comment.projectOrCategoryId);
    } else {
      if (!isReply) {
        // edit category level comment
        editCategoryComment(categoryId);
      } else {
        // edit reply
        editCategoryResponse(
          categoryId,
          comment.commentId,
          comment.commentDate,
        );
      }
    }
    setEditable(false);
  };

  const showDeleteConfirmationPopup = () => {
    openConfirmModal(<CommentPopup onStay={deleteCommentAndReply} />);
  };

  const deleteCommentAndReply = () => {
    if (!isProjectLevel && categoryId) {
      if (isReply) {
        deleteCategoryResponse(
          categoryId,
          comment.commentId,
          comment.commentDate,
        );
      } else {
        triggerDeleteCategoryComment(categoryId, comment.commentId);
      }
    } else {
      deleteProjectComment(comment.projectOrCategoryId, comment.commentId);
    }
  };

  let canCreateEditReplyComments = true;
  let canDeleteComments = true;

  if (userRole && !userRole.includes(REL_ADMIN) && statusCode != '2') {
    canCreateEditReplyComments = !getMaxWeight(userRole) <= viewOnlyWeight();
  } else {
    canCreateEditReplyComments = false;
  }

  if (userRole && !userRole.includes(REL_ADMIN) && statusCode != '2') {
    canDeleteComments = getMaxWeight(userRole) == adminWeight();
  } else {
    canDeleteComments = false;
  }

  let userNameOrIdToShow = comment.commentUserId;
  let commentNameToShow = comment.commentName;

  if (isReply) {
    commentNameToShow = "Reply";
  }

  if (comment.userFullName) {
    userNameOrIdToShow = comment.userFullName;
  }

  return (
    <div
      key={`${comment.commentName}${comment.commentDate}`}
      className={isReply ? "reply" : "main-comment"}>
      <Typography className={isReply ? "displayNone" : "displayBlock"}>
        <SubjectIcon className='floatLeft' />
        <div>{categoryName}</div>
      </Typography>
      <Divider />
      <div className='headerComment'>
        <div className='infoContainer'>
          <Face className='avatar' />
          <div>
            <div className='comment-name'>
              {commentNameToShow}
              <span className='user-id'> by {userNameOrIdToShow} </span>
            </div>
            <div className='comment-date-time'>
              {formatDateTime(comment.commentDate)}
            </div>
          </div>
        </div>
        <div className='floatLeftEdit'>
          <div className={resolved ? "displayNone" : "displayBlock"}>
            {canCreateEditReplyComments && isUserCommentOwner && (
              <EditIcon
                className='editIcon'
                testId='editButton'
                onClick={() => setEditable(true)}
              />
            )}
            {canDeleteComments && (
              <DeleteIcon
                className='editIcon'
                testId='deleteButton'
                onClick={() => showDeleteConfirmationPopup()}
              />
            )}
          </div>
        </div>
      </div>
      <div className={editable ? "displayNone" : "displayBlock"}>
        <div className='comment-message'>{comment.commentText}</div>
      </div>
      {editable && (
        <EditCommentForm
          testId='editCommentForm'
          isProjectLevel={isProjectLevel}
          isReply={isReply}
          onSubmit={editCommentReply}
          onCancel={() => setEditable(false)}
          initialValues={{
            commentId: comment.commentId,
            commentText: comment.commentText,
            commentTitle: comment.commentName,
          }}
        />
      )}
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape({
    commentId: PropTypes.number,
    commentName: PropTypes.string,
    commentText: PropTypes.string,
    CommentDate: PropTypes.string,
    commentUserId: PropTypes.string,
  }).isRequired,
  isProjectLevel: PropTypes.bool.isRequired,
  categoryId: PropTypes.number,
  categoryName: PropTypes.string,
  resolved: PropTypes.bool.isRequired,
  isReply: PropTypes.bool.isRequired,
  triggerDeleteCategoryComment: PropTypes.func.isRequired,
  editCategoryComment: PropTypes.func.isRequired,
  editProjectComment: PropTypes.func.isRequired,
  deleteProjectComment: PropTypes.func.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  editCategoryResponse: PropTypes.func.isRequired,
  deleteCategoryResponse: PropTypes.func.isRequired,
};
Comment.defaultProps = {
  categoryName: "",
  categoryId: "",
};

const mapDispatchToProps = dispatch => ({
  triggerDeleteCategoryComment: (categoryId, sequenceNumber) => {
    dispatch(commentActions.deleteCategoryComment(categoryId, sequenceNumber));
  },
  editCategoryComment: categoryId => {
    dispatch(commentActions.editCategoryComment(categoryId));
  },
  editProjectComment: projectId => {
    dispatch(commentActions.editProjectComment(projectId));
  },
  deleteProjectComment: (projectId, sequenceNumber) => {
    dispatch(commentActions.deleteProjectComment(projectId, sequenceNumber));
  },
  openConfirmModal: body => dispatch(modalActions.openModal({ body })),
  deleteCategoryResponse: (categoryId, sequenceNumber, commentDate) => {
    dispatch(
      commentActions.deleteCategoryResponse(
        categoryId,
        sequenceNumber,
        commentDate,
      ),
    );
  },
  editCategoryResponse: (categoryId, sequenceNumber, commentDate) => {
    dispatch(
      commentActions.editCategoryResponse(
        categoryId,
        sequenceNumber,
        commentDate,
      ),
    );
  },
});

export default connect(null, mapDispatchToProps)(Comment);
