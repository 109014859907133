import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import CheckmarkIcon from '../../assets/images/Check.svg';
import InputField from '../_shared/input-field/InputField';
import WelcomeHeader from '../_shared/welcome-header/WelcomeHeader';
import './CreatePassword.scss';

const CreatePassword = () => {
  const passwordRequirementValidClass = 'password-requirement-label password-requirement-met';
  const passwordRequirementPendingClass = 'password-requirement-label password-requirement-pending';

  return (
    <div className="create-password">
      <WelcomeHeader
        title="Welcome to eClosing!"
        subtitle="Create a password."
      />

      <form
        className="form-container"
        noValidate
        autoComplete="off"
      >
        <InputField
          inputLabel="Password"
          inputType="password"
          inputDefaultValue=""
          showInputAdornment
        />
      </form>

      <div className="password-strength-container">
        <div className="password-strength-row">
          <Typography
            variant="subtitle1"
            className={passwordRequirementValidClass}
          >
            <CheckmarkIcon
              className="checkmark-icon"
            />
            8 characters minimum
          </Typography>
          <Typography
            variant="subtitle1"
            className={passwordRequirementPendingClass}
          >
            <div
              className="bullet-icon"
            >
              &#8226;
            </div>
            One uppercase letter
          </Typography>
        </div>

        <div className="password-strength-row">
          <Typography
            variant="subtitle1"
            className={passwordRequirementValidClass}
          >
            <CheckmarkIcon
              className="checkmark-icon"
            />
            One lowercase letter
          </Typography>
          <Typography
            variant="subtitle1"
            className={passwordRequirementPendingClass}
          >
            <div
              className="bullet-icon"
            >
              &#8226;
            </div>
            One number
          </Typography>
        </div>
      </div>

      <Link
        to="/"
        className="no-link-styling"
      >
        <Button
          color="primary"
          className="create-password-button"
          size="medium"
          variant="contained"
        >
          Create a password
        </Button>
      </Link>
    </div>
  );
};

export default CreatePassword;
