import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as modalActions from '../../_shared/modal/modalActions';
import { unlock } from '../projectActions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onConfirm: () => {
    dispatch(modalActions.closeModal());
    dispatch(unlock(ownProps.projectID, ownProps.history, ownProps.projectOpen));
  },
  onCancel: () => dispatch(modalActions.closeModal()),
});

export const ProjectUnLockModalContent = ({
  onCancel,
  onConfirm,
  projectName,
}) => (
  <Fragment>
    <DialogTitle id="responsive-dialog-title">
      Set {projectName} to Active?
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        You are about to set project {projectName} to Active status. <br />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        Cancel
      </Button>
      <Button variant="contained" onClick={onConfirm} color="primary">
        Proceed
      </Button>
    </DialogActions>
  </Fragment>
);

ProjectUnLockModalContent.defaultProps = {
  onCancel: _.noop,
  onConfirm: _.noop,
  projectName: '',
};

ProjectUnLockModalContent.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  projectName: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(ProjectUnLockModalContent);
