import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoLayout.module.scss';

export const NoLayout = ({ children }) => (
  <div className={styles.layout}>
    { children }
  </div>
);

NoLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
