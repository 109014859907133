import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles, IconButton } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import Person from '@material-ui/icons/Person';
import Search from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import {deleteExternalUser} from './usersActions';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Table from '../table/Table';
import UserModalContent from './UserModalContent';
import { formatDateAndTime } from '../../utilities';
import { openModal, closeModal } from '../_shared/modal/modalActions';
import { adminWeight, getMaxWeight } from '../../roleaccess/utils';
import { selectUserRoles } from '../login/loginSelector';
import DeleteUserPopUp from './externalUsers/DeleteUserPopup';

import './Users.scss';
import {
  selectUsersList,
  selectUsersTotal,
  selectUsersPageDetails,
} from './usersSelectors';

import { fetch, search, setPageDetails, fetchCompanies } from './usersActions';

const styles = {
  loadingSpinner: {
    position: 'absolute',
    left: '50%',
    top: '14%',
  },
  cell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

// Status Icon for the User table.
const Status = ({ status }) => (
  <div className='status-container'>
    <div className={`status-icon ${status.toLowerCase()}`} title={status} />
  </div>
);

Status.defaultProps = {
  status: 'Active',
};

Status.propTypes = {
  status: PropTypes.string,
};

const NoResults = () => (
  <div className='no-results-container'>
    <div className='no-results-icon-container'>
      <Person className='no-results-person' />
      <div className='no-results-search-container'>
        <Search className='no-results-search' />
      </div>
    </div>
    <div className='no-results-text1'>
      <Typography variant='h5'>No Users found</Typography>
    </div>
    <div className='no-results-text2'>Try adjusting your search</div>
  </div>
);

/**
 * Users Component
 *
 * @namespace Users
 * @memberof users.components
 */

const mapDispatchToProps = dispatch => ({
  openUserModal: body => dispatch(openModal({ body })),
  closeUserModal: closeModal,
  fetchUsers: pageDetails => dispatch(fetch(pageDetails)),
  setPageDetails: pageDetails => dispatch(setPageDetails(pageDetails)),
  searchUsers: search,
  getCompanies: () => dispatch(fetchCompanies()),
  deleteUser : (userId) => dispatch(deleteExternalUser(userId)),
});

const mapStateToProps = (state) => {
  return {
    data: selectUsersList(state),
    totalUsers: selectUsersTotal(state),
    usersPageDetails: selectUsersPageDetails(state),
    isLoading: _.get(state, 'users.request.loading', false),
    userRole: selectUserRoles(state),
  };
};

export const Users = ({
  data,
  classes,
  fetchUsers,
  deleteUser,
  setPageDetails,
  totalUsers,
  usersPageDetails,
  searchUsers,
  openUserModal,
  closeUserModal,
  isLoading,
  history,
  userRole,
  getCompanies,
}) => {
  useEffect(() => {
    let pageDetails = {
      page: 0,
      rowsPerPage: 10,
      sortColumn: 'LastLogin',
      sortDirection: 'desc',
      filterObject: { UserName: '' },
      currentSortedColumnName: 'Last Login',
    };
    setPageDetails(pageDetails);
    fetchUsers(pageDetails);
    getCompanies();
  }, {});
  const handleSearch = () => {
    let pageDetails = {
      page: 0,
      rowsPerPage: usersPageDetails.rowsPerPage,
      sortColumn: usersPageDetails.sortColumn,
      sortDirection: usersPageDetails.sortDirection,
      filterObject: usersPageDetails.filterObject,
    };
    setPageDetails(pageDetails);
    fetchUsers(pageDetails);
  };

  const handleChangeUserName = name => event => {
    let pageDetails = {
      page: usersPageDetails.page,
      rowsPerPage: usersPageDetails.rowsPerPage,
      sortColumn: usersPageDetails.sortColumn,
      sortDirection: usersPageDetails.sortDirection,
      filterObject: {
        UserName: event.target.value,
      },
    };
    setPageDetails(pageDetails);
  };

  const handleKeyPress = name => event => {
    var code = event.keyCode || event.which;
    if (code === 13) { //13 is the enter keycode
      handleSearch();
    }
  };

  const handleInviteRemoveButton = () => {
    openUserModal(<UserModalContent onClose={closeUserModal} />);
  };
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const handleMenuOpen = (event, user) => {
    setSelectedUser(user);
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => setMenuAnchorEl(null);
  const canManageUser = getMaxWeight(userRole) >= adminWeight();

  const showDeleteConfirmationPopup = () => {
    
    openUserModal(<DeleteUserPopUp onStay={deleteUserActionTriger} />);
  };


  const deleteUserActionTriger = () => {
    deleteUser(selectedUser.sys_userid)
  };

  const columnHeaders = [
    'Name',
    'Email',
    'Job Title',
    'Affiliation',
    'Last Login',
    'Status',
  ];
  if (canManageUser) {
    columnHeaders.push('Actions');
  }
  function handleChangePage(event, newPage) {
    let pageDetails = {
      page: newPage,
      rowsPerPage: usersPageDetails.rowsPerPage,
      sortColumn: usersPageDetails.sortColumn,
      sortDirection: usersPageDetails.sortDirection,
      filterObject: usersPageDetails.filterObject,
      currentSortedColumnName: usersPageDetails.currentSortedColumnName,
    };

    setPageDetails(pageDetails);
    fetchUsers(pageDetails);
  }
  function handleChangeRowsPerPage(event) {
    let pageDetails = {
      page: 0,
      rowsPerPage: +event.target.value,
      sortColumn: usersPageDetails.sortColumn,
      sortDirection: usersPageDetails.sortDirection,
      filterObject: usersPageDetails.filterObject,
      currentSortedColumnName: usersPageDetails.currentSortedColumnName,
    };

    setPageDetails(pageDetails);
    fetchUsers(pageDetails);
  }

  const handleSorting = column => {
    var currentSortedColumnName = column;

    if (column === 'Job Title') {
      column = 'JobTitle';
    }
    if (column === 'Name') {
      column = 'UserName';
    }
    if (column === 'Last Login') {
      column = 'LastLogin';
    }
    if (column === 'Email') {
      column = 'EmailAdress';
    }

    let pageDetails = {
      page: usersPageDetails.page,
      rowsPerPage: usersPageDetails.rowsPerPage,
      sortColumn: column,
      sortDirection: invertDirection[usersPageDetails.sortDirection],
      filterObject: usersPageDetails.filterObject,
      currentSortedColumnName: currentSortedColumnName,
    };

    //state update
    setPageDetails(pageDetails);

    //Action call
    fetchUsers(pageDetails);
  };
  const invertDirection = {
    asc: 'desc',
    desc: 'asc',
  };

  function getRoleName(roleCode) {
    if (roleCode !== null || typeof roleCode !== 'undefined') {
      switch (roleCode.trim()) {
        case 'ACCOUNTANT_ROLE':
          return 'Accountant';
        case 'OUTSIDE_COUNCIL_ROLE':
          return 'Outside Counsel';
        case 'REGIONAL_VICE_PRESIDENT_ROLE':
          return 'Regional Vice President';
        case 'AUDITOR_ROLE':
          return 'Auditor';
        case 'PARALEGAL_ROLE':
          return 'Paralegal';
        case 'REAL_ESTATE_MANAGER_ROLE':
          return 'Real Estate Manager';
        case 'GENERAL_COUNCIL_ROLE':
          return 'General Counsel';
        case 'DISTRICT_MANAGER_ROLE':
          return 'District Manager';
        case 'ADMIN_ROLE':
          return 'Admin';
        case 'POST_CLOSING_ROLE':
          return 'Post Closing';
        case 'MEMBER_ROLE':
          return 'Member';
        case 'PLADMIN_ROLE':
          return 'PL Admin';
        case 'AUADMIN_ROLE':
          return 'AU Admin';
        case 'SUPER_USER_ROLE':
          return 'Super User';
        case 'CIVIL_ENGINEER_ROLE':
          return 'Civil Engineer';
        case 'REL_ADMIN_ROLE':
          return 'REL Admin';
        default:
          return '';
      }
    }
    return '';
  }
  const renderTableRow = (rowData, showDeleteConfirmationPopup) => (
    <TableRow key={rowData.sys_userid + " " + rowData.role_name} className="user-row">
      <TableCell>{`${rowData.extrn_first_name} 
      ${rowData.extrn_last_name}`}</TableCell>
      <TableCell>{rowData.ext_email_addr_id}</TableCell>
      <TableCell>{getRoleName(rowData.role_name)}</TableCell>
      <TableCell>
        {rowData.external_user_ind === '1' ? 'External' : 'Internal'}
      </TableCell>
      <TableCell>{formatDateAndTime(rowData.last_login_ts)}</TableCell>
      <TableCell>
        <Status
          status={rowData.current_role_ind === 'Y' ? 'Active' : 'Non-Active'}
        />
      </TableCell>
      {canManageUser && (
        <TableCell className={classes.cell}>
          {rowData.external_user_ind === '1' && (<IconButton onClick={e => handleMenuOpen(e, rowData)}>
            <MoreIcon />
          </IconButton>
          )}
          <Menu
            id="lock-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            elevation={0}
            PaperProps={{
              style: {
                border: '1px solid #E0E0E0',
              },
            }}
          >
            <MenuItem
              onClick={() => {
                history.push('/users/manage', { user: selectedUser });
              }}
            >
              Change user password
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose();   
                showDeleteConfirmationPopup();
              }}
            >
              Delete user
            </MenuItem>
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );

  return (
    <div className='user'>
      <div className='user-container'>
        <div className='title-area'>
          <Typography className='title-text'>Users</Typography>
          {isLoading && <CircularProgress className={classes.loadingSpinner} />}
        </div>
        <div className='search-area'>
          <TextField
            className='search-input'
            variant='outlined'
            placeholder='Search..'
            inputProps={{ style: { padding: '9px' } }}
            onChange={handleChangeUserName()}
            onKeyPress={handleKeyPress()}
          />
          <Button onClick={handleSearch}>
            Search
          </Button>        </div>
        {canManageUser && (
          <div className='user-button-area'>
            <Button className='user-button' onClick={() => { history.push('/users/create'); }}>
              Create New User
            </Button>
          </div>
        )}
        <div className="body-area">
          {data.length ? (
            <div className="table-container">
              <Table
                columnHeaders={columnHeaders}
                sortingHandler={handleSorting}
                sortDirection={usersPageDetails.sortDirection}
                sortColum={usersPageDetails.currentSortedColumnName}>
                {_.map(data, rowData => renderTableRow(rowData,showDeleteConfirmationPopup))}
              </Table>
              <TablePagination
                rowsPerPageOptions={[2, 10, 20, 50, 100]}
                component='div'
                count={totalUsers}
                rowsPerPage={usersPageDetails.rowsPerPage}
                page={usersPageDetails.page}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          ) : (
              <NoResults />
            )}
        </div>
      </div>
    </div>
  );
};

Users.defaultProps = {
  data: [],
  history: { push: () => { } },
  classes: {},
};

Users.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  fetchUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  setPageDetails: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  openUserModal: PropTypes.func.isRequired,
  closeUserModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  userRole: PropTypes.object.isRequired,
};
const reduxConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Users));
export default withRouter(reduxConnected);
