
import React from 'react';
import {
  Button,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import './UserModalContent.scss';

export const UserModalContent = ({ onClose }) => (
  <div className="user-form">
    <div className="content">
      <Typography variant="h4" gutterBottom>
        Invite or remove a user
      </Typography>
      <Typography className="description">
        Create a ticket through the following links
      </Typography>
      <div className="link-group">
        <Typography className="link">
          + Internal User:
          {
            <a href="https://walmart.com/app/usermanagement" target="_blank" rel="noopener noreferrer">
              walmart.com/app/usermanagement
            </a>
          }
        </Typography>
        <Typography className="link">
          + External User:
          {
            <a href="https://walmart.com/app/usermanagement" target="_blank" rel="noopener noreferrer">
              walmart.com/app/usermanagement
            </a>}
        </Typography>
      </div>
      <div className="buttons">
        <Button
          className="close-button"
          type="button"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </div>
  </div>
);

UserModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default UserModalContent;
