import _ from 'lodash';

export const selectComments = state => _.get(state, 'comments.data', []);
export const selectCommentDropdownValue = state => _.get(state, 'comments.commentDropdownValue', []);
export const mapComment = comment => ({
  projectOrCategoryId: comment.closing_id,
  commentId: comment.seq_nbr,
  commentName: comment.comment_name,
  commentText: ((!comment.comment_txt || comment.comment_txt == '') ? comment.comment_name : comment.comment_txt) ,
  commentDate: comment.create_ts,
  commentUserId: comment.comment_userid,
  userFullName: comment.user_full_name,
});
export const mapCategoryComment = comment => ({
  projectOrCategoryId: comment.doc_category_id,
  commentId: comment.comment_seq_nbr,
  commentName: comment.comment_name,
  commentText: comment.comment_text,
  commentDate: comment.create_ts,
  commentUserId: comment.comment_userid,
  userFullName: comment.user_full_name,
});
export const mapCategoryCommentResponse = reply => ({
  projectOrCategoryId: reply.doc_category_id,
  commentId: reply.comment_seq_nbr,
  commentName: '',
  commentText: reply.response_txt,
  commentDate: reply.response_create_ts,
  commentUserId: reply.response_userid,
  userFullName: reply.user_full_name,
});
