import React from 'react';
import {
  Field,
  reduxForm,
} from 'redux-form';
import {
  Typography,
  Button,
  TextField,
  createMuiTheme,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';

import './CommentForm.scss';

export const CommentForm = (props) => {
  const { handleSubmit, reply, onClose } = props;

  const TextAreaField = () => {
    const theme = createMuiTheme({
      overrides: {
        MuiTextField: {
          root: {
            width: '100%',
          },
        },
      },
    });

    return (
      <ThemeProvider theme={theme}>
        <TextField
          rows="6"
          multiline
          variant="outlined"
        />
      </ThemeProvider>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="comment-form">
        <div className="comment-title">
          <Typography variant="h5" gutterBottom>
            {reply ? 'Add Reply' : 'New Comment'}
          </Typography>
        </div>
        <div className="comment-main">
          <Field
            className="comment-input"
            name="comment-input"
            component={TextAreaField}
          />
        </div>
        <div className="buttons">
          <Button
            className="cancel-button"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className="submit-button"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};

CommentForm.defaultProps = {
  reply: false,
};

CommentForm.propTypes = {
  reply: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'commentForm' })(CommentForm);
