import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import * as actions from "../commentActions";
import { createCategoryComment } from "../../../services/comments";

const formValueSelector = state =>
  _.get(state, "form.subCategoryCommentForm.values");
const loggedInUserSelector = state => _.get(state, "session.user.data");

export default function* createCategoryCommentWatcher() {
  yield takeLatest(actions.CATEGORY_CREATE, createCategoryCommentWorker);
}

export function* createCategoryCommentWorker(action) {
  const { categoryId } = action;
  const formValues = yield select(formValueSelector);
  const loggedInUserData = yield select(loggedInUserSelector);

  formValues.doc_category_id = categoryId;
  formValues.create_ts = moment().utc();
  if (loggedInUserData) {
    formValues.comment_userid = loggedInUserData.loginId;
  } else {
    formValues.comment_userid = "eUserId";
  }

  try {
    const response = yield call(createCategoryComment, formValues);
    const data = _.get(response, "data.createCategoryComment");
    if (data.Success === "true") {
      formValues.comment_seq_nbr = parseInt(data.SeqNbr);
      formValues.user_full_name = loggedInUserData.name.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
      formValues.responses = [];
      formValues.cmt_complete_ts = null;
      yield call(success, formValues, action.history);
    } else {
      yield call(failure, new Error("Something Went Wrong"));
      alert("Could not add comment");
    }
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response) {
  yield put(actions.SetNewComment(response));
}

export function* failure(error) {
  yield put(actions.error(error));
}
