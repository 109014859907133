import _ from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchDocumentByProject } from '../../../services/document';
import * as actions from '../documentActions';
import { convertData } from '../../../utilities';

export default function* fetchDocByProjectWatcher() {
  yield takeLatest(actions.FETCH_DOC_PROJ, fetchDocByProjectWorker);
}

export function* fetchDocByProjectWorker(action) {
  yield put(actions.loading(true));

  try {
    const response = yield call(fetchDocumentByProject, action.closing_id);

    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.documentsByProjectId', []);

  const convertedData = _.map(convertData(data), extraData => ({
    ...extraData
  }));

  yield put(actions.setData(convertedData));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
