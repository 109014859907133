import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isDirty, reset, isInvalid, reduxForm } from 'redux-form';
import * as _ from 'lodash';
import ProjectUsers from '../shared/ProjectUsers';
import * as modalActions from '../../_shared/modal/modalActions';
import ProjectEditDiscardModalContent from './ProjectEditDiscardModalContent';
import ProjectEditContainer from './ProjectEditContainer';
import { selectUserByRole, selectUsersList, selectUsersByRole } from '../../users/usersSelectors';
import { userRoles } from '../../users/userConstants';
import * as userActions from '../../users/usersActions';
import { validateProjectUsers } from '../shared/ValidateProjectUsers';

const formName = 'projectEditUsers';
let EditProjectUsers = ({
  isLoading,
  history,
  openConfirmModal,
  isFormDirty,
  projectId,
  resetForm,
  triggerSubmit,
  isFormInvalid,
  form,
  getUsersWithRoles,
}) => {
  useEffect(() => {
    getUsersWithRoles();
  }, {});

  const onCancel = () => {
    resetForm();
    history.replace(`/projects/${projectId}`);
  };
  const onClose = () => {
    if (isFormDirty) {
      openConfirmModal(<ProjectEditDiscardModalContent onDiscard={onCancel} />);
    } else {
      onCancel();
    }
  };
  return (
    <React.Fragment>
      <ProjectEditContainer
        isLoading={isLoading}
        isFormDirty={isFormDirty}
        isFormInvalid={isFormInvalid}
        onSave={triggerSubmit}
        onCancel={onClose}
      >
        <ProjectUsers form={form} />
      </ProjectEditContainer>
    </React.Fragment>
  );
};
EditProjectUsers.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  resetForm: PropTypes.func.isRequired,
  triggerSubmit: PropTypes.func.isRequired,
  isFormInvalid: PropTypes.bool.isRequired,
  form: PropTypes.string.isRequired,
  getUsersWithRoles: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  const projectUsers = selectUsersList(state);
  return ({
    initialValues: {
      attorney: selectUserByRole(projectUsers, userRoles.attorney),
      paraLegal: selectUserByRole(projectUsers, userRoles.paralegal),
      realEstateManager: selectUserByRole(projectUsers, userRoles.realEstateManager),
      designManager: selectUserByRole(projectUsers, userRoles.designManager),
      auditor: selectUserByRole(projectUsers, userRoles.auditor),
      regionalVicePresident: selectUserByRole(projectUsers, userRoles.regionalVicePresident),
      accountant: selectUserByRole(projectUsers, userRoles.accountant),
      outsideCouncil: selectUsersByRole(projectUsers, userRoles.outsideCouncel),
      cecFirms: selectUserByRole(projectUsers, userRoles.cecFirm),
    },
    isFormDirty: isDirty(formName)(state),
    isFormInvalid: isInvalid(formName)(state),
    projectId: _.get(ownProps, 'match.params.projectId', null),
    isLoading: _.get(state, 'users.request.loading', false),
  });
};
const mapDispatchToProps = (dispatch, ownProps) => ({
  openConfirmModal: body => dispatch(modalActions.openModal({ body })),
  resetForm: () => dispatch(reset(formName)),
  triggerSubmit: () => dispatch(userActions.updateProjectUsers(ownProps.projectId, ownProps.history)),
  getUsersWithRoles: () => dispatch(userActions.fetchUsersWithRoles()),
});

EditProjectUsers = connect(mapStateToProps, mapDispatchToProps)(EditProjectUsers);
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  validate: validateProjectUsers,
})(EditProjectUsers));
