import request from '../../graphql/request';
import {
  CREATE_COMMENT,
  CREATE_CATEGORY_COMMENT,
  FETCH_PROJECT_COMMENTS,
  FETCH_CATEGORY_COMMENTS,
  DELETE_CATEGORY_COMMENT,
  EDIT_CATEGORY_COMMENT,
  EDIT_PROJECT_COMMENT,
  DELETE_PROJECT_COMMENT,
  RESOLVE_COMMENT,
  UN_RESOLVE_COMMENT,
  REPLY_TO_CATEGORY_COMMENT,
  FETCH_ALL_CATEGORY_COMMENTS,
  DELETE_CATEGORY_COMMENT_RESPONSE,
  EDIT_CATEGORY_COMMENT_RESPONSE,
} from './queries';

export const createComment = variables => (
  request({
    query: CREATE_COMMENT,
    variables,
  })
);

export const createCategoryComment = variables => (
  request({
    query: CREATE_CATEGORY_COMMENT,
    variables,
  })
);

export const deleteCategoryComment = variables => (
  request({
    query: DELETE_CATEGORY_COMMENT,
    variables,
  })
);

export const deleteProjectComment = variables => (
  request({
    query: DELETE_PROJECT_COMMENT,
    variables,
  })
);

export const fetchProjectComments = variables => (
  request({
    query: FETCH_PROJECT_COMMENTS,
    variables,
  })
);

export const fetchCategoryComments = variables => (
  request({
    query: FETCH_CATEGORY_COMMENTS,
    variables,
  })
);

export const editCategoryComment = variables => (
  request({
    query: EDIT_CATEGORY_COMMENT,
    variables,
  })
);

export const editProjectComment = variables => (
  request({
    query: EDIT_PROJECT_COMMENT,
    variables,
  })
);

export const resolveComment = variables => (
  request({
    query: RESOLVE_COMMENT,
    variables,
  })
);

export const unResolveComment = variables => (
  request({
    query: UN_RESOLVE_COMMENT,
    variables,
  })
);

export const replyToCategoryComment = variables => (
  request({
    query: REPLY_TO_CATEGORY_COMMENT,
    variables,
  })
);

export const fetchAllCategoryComments = variables => (
  request({
    query: FETCH_ALL_CATEGORY_COMMENTS,
    variables,
  })
);
export const deleteCategoryCommentResponse = variables => (
  request({
    query: DELETE_CATEGORY_COMMENT_RESPONSE,
    variables,
  })
);
export const editCategoryCommentResponse = variables => (
  request({
    query: EDIT_CATEGORY_COMMENT_RESPONSE,
    variables,
  })
);
