import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import _ from "lodash";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

import * as modalActions from "../../_shared/modal/modalActions";

const mapDispatchToProps = (dispatch, ownProps) => ({
  onOK: () => {
    dispatch(modalActions.closeModal());
  }
});

const useStyles = makeStyles(theme => ({
  errorButton: {
    "background-color": theme.palette.error.main,
    color: "#fff",
    "&:hover": {
      "background-color": "#CA2213"
    }
  }
}));

export const EditDocumentStatusModalContent = ({ onOK }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <DialogTitle id='responsive-dialog-title'>WARNING:</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Changing the status of this file from ‘Public Viewable’ will remove it
          from REL. Please coordinate this with the ‘Library Services’ team.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onOK} color='primary'>
          OK
        </Button>
      </DialogActions>
    </Fragment>
  );
};

EditDocumentStatusModalContent.defaultProps = {
  onOK: _.noop
};

EditDocumentStatusModalContent.propTypes = {
  onOK: PropTypes.func
};

const reduxConnected = connect(
  null,
  mapDispatchToProps
)(EditDocumentStatusModalContent);

export default withRouter(reduxConnected);
