import { storeBusinnesUnitTypes } from '../../../configs/storeBusinessUnitTypes';
import { formatDateWithoutConversion } from '../../../utilities';

export const transformProjectDetails = data => [
  {
    label: 'Status',
    key: 'closingStatus',
    value: data.status_code_desc
  },
  {
    label: 'Modified Date',
    key: 'lastModified',
    //value: new Date(data.last_change_ts)
    value: data.last_change_ts
  },
  {
    label: 'Projected Closing Date',
    key: 'projectedClosingDate',
    //value: new Date(data.closing_end_ts),
    value: formatDateWithoutConversion(data.closing_start_ts),
  },
  {
    label: 'Project/Store Sequence #',
    key: 'projectSequenceNumber',
    value: data.project_seq_nbr
  },
  {
    label: 'Sellers',
    key: 'sellers',
    value: data.seller_count
  },
  {
    label: 'Parcels',
    key: 'parcels',
    value: data.parcel_count
  }
];

export const transformStoreDetails = data => [
  {
    label: 'Store #',
    key: 'storeNumber',
    value: data.store_nbr,
  },
  {
    label: 'Company #',
    key: 'status',
    value: data.company,
  },
  {
    label: 'Store Sequence #',
    key: 'storeSequenceNumber',
    value: data.store_seq_nbr,
  },
  {
    label: 'Address',
    key: 'address',
    value: data.address_line_1,
  },
  {
    label: 'City',
    key: 'city',
    value: data.city_name,
  },
  {
    label: 'State',
    key: 'state',
    value: data.state_prov_code,
  },
  {
    label: 'Store Type',
    key: 'storeType',
    value: data.store_type_code,
  },
  {
    label: 'Project Type',
    key: 'projectType',
    value: data.project_type_code,
  },
  {
    label: 'Business Unit Type',
    key: 'businessUnitType',
    value: storeBusinnesUnitTypes.get(data.bu_type_code) || data.bu_type_code,
  },
];

function getRoleValues(role, users) {
  try {
    let usersWithRole = users.filter(user => user.role_name.trim() === role.trim());
    let retVal = '';

    for (let i = 0; i < usersWithRole.length; i++) {
      if (i > 0){
        retVal = retVal + ", ";
      }
      retVal = retVal + (usersWithRole[i].extrn_first_name !== null ? usersWithRole[i].extrn_first_name : '') +
      ' ' +
      (usersWithRole[i].extrn_last_name !== null ? usersWithRole[i].extrn_last_name.trim() : '');
    }  
    return retVal;
  } catch {
    return '';
  }
}

function getRoleValue(role, users) {
  try {
    let user = users.find(user => user.role_name.trim() === role.trim());
    return (
      (user.extrn_first_name !== null ? user.extrn_first_name : '') +
      ' ' +
      (user.extrn_last_name !== null ? user.extrn_last_name : '')
    );
  } catch {
    return '';
  } 
}

function getRoleValueBasedOnID(roleIds, users) {
  try {
    let index = 0;
    let user = '';
    while ((!user || user == '') && index < roleIds.length ) { 
      user = users.find(user => user.role_id == roleIds[index]);
      index++; 
    }
    return (
      (user.extrn_first_name !== null ? user.extrn_first_name : '') +
      ' ' +
      (user.extrn_last_name !== null ? user.extrn_last_name : '')
    );
  } catch {
    return '';
  }
}

export const transformInternalUsersDetails = data => [
  {
    label: 'Attorney',
    key: 'GENERAL_COUNCIL_ROLE',
    value: getRoleValue('GENERAL_COUNCIL_ROLE', data)
  },
  {
    label: 'Paralegal',
    key: 'PLADMIN_ROLE',
    // pass these role Ids in the sequence of priority from higher to lower
    value: getRoleValueBasedOnID([12,5],data)
  },
  {
    label: 'Portfolio Manager',
    key: 'REAL_ESTATE_MANAGER_ROLE',
    value: getRoleValue('REAL_ESTATE_MANAGER_ROLE', data)
  },
  {
    label: 'Design Manager',
    key: 'DISTRICT_MANAGER_ROLE',
    value: getRoleValue('DISTRICT_MANAGER_ROLE', data)
  },
  {
    label: 'Auditor',
    key: 'AUDITOR_ROLE',
    // pass these role Ids in the sequence of priority from higher to lower
    value: getRoleValueBasedOnID([4,13],data)
  },
  {
    label: 'Vice President',
    key: 'REGIONAL_VICE_PRESIDENT_ROLE',
    value: getRoleValue('REGIONAL_VICE_PRESIDENT_ROLE', data)
  },
  {
    label: 'Accountant',
    key: 'ACCOUNTANT_ROLE',
    value: getRoleValue('ACCOUNTANT_ROLE', data)
  }
];

export const transformExternalUsersDetails = data => [
  {
    label: 'Outside Counsel',
    key: 'OUTSIDE_COUNCIL_ROLE',
    value: getRoleValues('OUTSIDE_COUNCIL_ROLE', data)
  },
  {
    label: 'CEC Firm',
    key: 'CIVIL_ENGINEER_ROLE',
    value: getRoleValue('CIVIL_ENGINEER_ROLE', data)
  }
];
