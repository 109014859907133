import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import _ from "lodash";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export const ProjectOverviewCard = ({
  children,
  onEdit,
  title,
  editMessage,
  locked,
  canEditProject,
}) => {
  const classes = useStyles();
  let editMessageText = "EDIT";
  let isLocked = false;
  if (editMessage != null && editMessage != undefined) {
    editMessageText = editMessage;
  }
  if (locked != null && locked != undefined) {
    isLocked = locked;
  }

  return (
    <Paper className={classes.paper}>
      <article>
        <header className={classes.header}>
          <Typography variant="h5">{title}</Typography>
          {canEditProject && onEdit !== _.noop && (
            <Button onClick={onEdit}>
              {!isLocked && <EditIcon className={classes.leftIcon} />}
              {editMessageText}
            </Button>
          )}
        </header>
        <Divider />
        {children}
      </article>
    </Paper>
  );
};

ProjectOverviewCard.defaultProps = {
  children: [],
  onEdit: _.noop,
  title: "",
};

ProjectOverviewCard.propTypes = {
  children: PropTypes.node,
  onEdit: PropTypes.func,
  title: PropTypes.string,
};
