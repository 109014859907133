import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { default as MUITabs } from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './Tabs.scss';

export const localStyles = theme => ({
  root: {
    height: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsIndicator: {
    backgroundColor: styles.primaryColor,
  },
  tabRoot: {
    textTransform: 'initial',
    minHeight: 62,
    minWidth: 72,
    marginRight: 'auto',
    marginLeft: 'auto',
    fontSize: 14,
    lineHeigth: 32,
    fontWeight: 'normal',
    '&:hover': {
      cursor: 'pointer',
    },
    '&$tabSelected': {
      color: styles.primaryColor,
    },
    '&:focus': {
      color: styles.primaryColor,
    },
  },
  tabSelected: {},
});

/**
 * Customized Tabs Component
 *
 * @namespace Shared
 * @memberof shared.components
 */

export class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
    };
  }

  handleChange = (e, tabValue) => {
    this.setState({ tabValue });
  };

  renderTabs = (children, classes) => (
    children.map(child => (
      <Tab
        key={child.props.label}
        label={child.props.label}
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
      />
    ))
  )

  render() {
    const {
      centered,
      classes,
      children,
    } = this.props;
    const { tabValue } = this.state;
    return (
      <div className={classes.root}>
        <MUITabs
          centered={centered}
          value={tabValue}
          onChange={this.handleChange}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          { this.renderTabs(children, classes) }
        </MUITabs>
        <div className="tab-content">
          { children[tabValue] }
        </div>
      </div>
    );
  }
}

Tabs.defaultProps = {
  centered: false,
};

Tabs.propTypes = {
  centered: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(localStyles)(Tabs);
