/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Button,
  makeStyles,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import './ProjectEdit.module.scss';

const useStyles = makeStyles(theme => ({
  formContainer: {
    padding: theme.spacing(2),
    'background-color': '#fff',
    'padding-bottom': '20px',
  },
  loadingSpinner: {
    position: 'absolute',
    left: '50%',
  },
  closeButton: {
    position: 'absolute',
    top: '6%',
    right: '20%',
  },
}));

const ProjectEditContainer = ({
  onCancel,
  onSave,
  isLoading,
  isFormDirty,
  isFormInvalid,
  children,
  projectTitle,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={10} className="grid-container">
      <Grid item xs={2} />
      <Grid item xs={8} className="app-view">
        <div className="project-create-container">
          <div>
            {isLoading && <CircularProgress className={classes.loadingSpinner} />}
            <Typography className="project-create-title" gutterBottom>
              Edit Project : {projectTitle}
            </Typography>
            <IconButton
              className={classes.closeButton}
              disabled={isLoading}
            >
              <Close onClick={onCancel} />
            </IconButton>
            <div className="form-container">
              <form className={classes.formContainer}>
                {children}
              </form>
            </div>
            <div className="project-create-button-container">
              <Button disabled={isLoading} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={onSave}
                disabled={isLoading || !isFormDirty || isFormInvalid}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};
ProjectEditContainer.propTypes = {
  isLoading: PropTypes.bool,
  isFormDirty: PropTypes.bool,
  isFormInvalid: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
  projectTitle: PropTypes.string.isRequired,
};
ProjectEditContainer.defaultProps = {
  children: null,
  isLoading: false,
  isFormDirty: false,
  isFormInvalid: false,
};
export default ProjectEditContainer;
