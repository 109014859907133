import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Typography } from '@material-ui/core';

import {
  dateValueTypes,
  formatDate,
} from '../../../utilities';

const formatValue = (key, value) => {
  if (_.includes(dateValueTypes, key)) {
    return formatDate(value);
  }

  return value;
};

const useStyles = makeStyles(() => ({
  detailsList: {
    listStyleType: 'none',
    margin: '12px 0 0',
    padding: 0,
  },
}));

export const ProjectDetailsList = ({ title, details }) => {
  const classes = useStyles();

  return (
    <ul className={classes.detailsList}>
      {title && (
      <li>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      </li>
      )}
      {_.map(details, detail => (
        <li class="detailLi" key={`detail_${detail.label}`}>
          <span class="detailLabel">
            {detail.label}:
          </span>
          &nbsp;{formatValue(detail.key,detail.value)}
        </li>
      ))}
    </ul>
  );
};

ProjectDetailsList.defaultProps = {
  details: [],
  title: '',
};

ProjectDetailsList.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.node,
  })),
  title: PropTypes.string,
};
