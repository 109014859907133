import { combineReducers } from 'redux';
import { createDataReducer } from '../../store/dataReducer';
import { createRequestReducer } from '../../store/requestReducer';
import * as actions from './commentActions';

export const initialState = {
  value: "All", 
  label: "All",
}

export function dropdownSelectedValueReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_DROPDOWN_SELECTION:
        return {
            value: action.value,
            label: action.label
        }
    default:
      return state;
  }
}

export default combineReducers({
  data: createDataReducer({
    SET_DATA: actions.SET_DATA,
    RESET_DATA: actions.RESET_DATA,
    INSERT_ITEM: actions.INSERT_ITEM,
    DELETE_ITEM: actions.DELETE_ITEM,
    UPSERT_ITEM: actions.UPDATE_ITEM,
    APPEND_DATA: actions.APPEND_DATA,
  }),
  request: createRequestReducer({
    ERROR: actions.ERROR,
    LOADING: actions.LOADING,
  }),
  commentDropdownValue: dropdownSelectedValueReducer,
});
