import { takeLatest, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { destroy } from 'redux-form';
import moment from 'moment';
import { updateProject } from '../../../services/projects';
import { openSnackbar } from '../../_shared/snack-bar/snackbarActions';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';
import * as actions from '../projectActions';

const formValueSelector = state => _.get(state, 'form.projectEdit.values');

export default function* editProjectWatcher() {
  yield takeLatest(actions.EDIT, editProjectWorker);
}

export function* editProjectWorker(action) {
  yield put(actions.loading(true));
  const formData = yield select(formValueSelector);
  const formValues = JSON.parse(JSON.stringify(formData));
  formValues.closing_end_ts = `${formValues.closing_end_ts}`;
  formValues.status_code = formValues.status_code_desc.value;
  formValues.status_code_desc = formValues.status_code_desc.label;
  formValues.store_nbr = parseInt(formValues.store_nbr, 10);
  formValues.closing_start_ts = `${moment(formValues.closing_start_ts).format(
    'YYYY-MM-DD',
  )}`;
  try {
    const response = yield call(updateProject, formValues);
    yield call(success, response, action.history);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, history) {
  const data = _.get(response, 'data.updateProject', {});
  if (data.Success == 'true') {
    yield put(
      openSnackbar({
        message: 'Project updated!',
        showCloseIcon: true,
      }),
    );
    yield put(actions.loading(false));
    const id = data.Args;
    yield call(history.push, `/projects/${id}`);
    yield put(destroy('projectEdit'));
  } else {
    yield call(failure, new Error(data.Message || 'Something went wrong while trying to update project'));
  }
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: 'Project could not be updated',
      message: error.message,
    }),
  );
  return error;
}
