export default [{
  id: 1,
  value: 'ACCOUNTANT_ROLE',
}, {
  id: 2,
  value: 'OUTSIDE_COUNCIL_ROLE',
}, {
  id: 3,
  value: 'REGIONAL_VICE_PRESIDENT_ROLE',
}, {
  id: 4,
  value: 'AUDITOR_ROLE',
}, {
  id: 5,
  value: 'PARALEGAL_ROLE',
}, {
  id: 6,
  value: 'REAL_ESTATE_MANAGER_ROLE',
}, {
  id: 7,
  value: 'GENERAL_COUNCIL_ROLE',
}, {
  id: 8,
  value: 'DISTRICT_MANAGER_ROLE',
}, {
  id: 9,
  value: 'ADMIN_ROLE',
}, {
  id: 12,
  value: 'PLADMIN_ROLE',
}, {
  id: 13,
  value: 'AUADMIN_ROLE',
}, {
  id: 14,
  value: 'SUPER_USER_ROLE',
}, {
  id: 15,
  value: 'CIVIL_ENGINEER_ROLE',
}];
