import _ from "lodash";
import SimpleCrypto from "simple-crypto-js";

export const selectUserRoles = state => {
  // var _secretKey = process.env.ENCRYPTION_KEY;
  // var simpleCrypto = new SimpleCrypto(_secretKey);
  var userRoles = _.get(state, "session.user.data.userRole", []);
  if (userRoles.length && !_.isUndefined(userRoles) && !_.isNull(userRoles)) {
    userRoles = userRoles;
    // userRoles = simpleCrypto.decryptObject(userRoles);
    return userRoles;
  }
  return [];
};

export const selectLoggedInUser = state => _.get(state, 'session.user.data', {});