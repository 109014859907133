import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { default as MUISnackbar } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import { closeSnackbar } from './snackbarActions';

const mapStateToProps = state => ({
  isOpen: state.snackbar.open,
  message: state.snackbar.message,
  showCloseIcon: state.snackbar.showCloseIcon,
  showUndoButton: state.snackbar.showUndoButton,
  undoFunction: state.snackbar.unodFunction,
});

const mapDispatchToProps = {
  onCloseSnackbar: closeSnackbar,
};

/**
 * Snackbar Component
 *
 * @namespace Shared
 * @memberof Shared.components
 *
 */

export const Snackbar = ({
  message,
  showCloseIcon,
  isOpen,
  onCloseSnackbar,
}) => {
  const handleOnClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onCloseSnackbar();
  };

  const showClose = () => {
    if (showCloseIcon) {
      return (
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onCloseSnackbar}
        >
          <Close />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <MUISnackbar
      open={isOpen}
      onClose={handleOnClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={6000}
    >
      <SnackbarContent
        message={message}
        action={[
          showClose(),
        ]}
      />
    </MUISnackbar>
  );
};

Snackbar.defaultProps = {
  message: null,
  showCloseIcon: false,
  isOpen: false,
};

Snackbar.propTypes = {
  message: PropTypes.node,
  showCloseIcon: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseSnackbar: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
