import { takeLatest, call, put, select } from "redux-saga/effects";
import { destroy } from "redux-form";
import _ from "lodash";
import * as actions from "../../projects/projectsActions";
import { createProject } from "../../../services/projects";
import moment from "moment";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";
import config from "../../../sso/config";

const formValueSelector = state => _.get(state, "form.projectCreate.values");

export default function* createProjectWatcher() {
  yield takeLatest(actions.CREATE, createProjectWorker);
}

export function* createProjectWorker(action) {
  try {
    const propTypes = [
      "BULK_STORAGE",
      "DOT_COM",
      "EXPANSION ",
      "EXPANSION_1",
      "EXPANSIONS",
      "FOOD",
      "MECHANIZED ",
      "MECHANIZED_PERISHABLE",
      "NEW STORE",
      "OTHER",
      "PERISHABLE ",
      "PROTO_TYPE",
      "RAWLAND",
      "REGIONAL",
      "RELOCATION",
      "REMODEL",
      "SPECIAL",
      "SPECIALITY",
      "TAKEOVER",
      "WPM",
      "ON SITE RELO",
	    "ALBERSONS TAKEOVER",
	    "ALCO TAKEOVER",
	    "AMES TAKEOVER",
	    "AMIGO TAKEOVER",
	    "AUTO NATION TAKEOVER",
	    "BLANK",
	    "BRADLEES TAKEOVER",
	    "BRADLESS TAKEOVER",
	    "BROADWAY TAKEOVER",
	    "BUILDERS SQUARE TAKEOVER",
	    "CALDOR TAKEOVER",
	    "CONSTRUCTION SPECIAL PROJECT",
	    "COSTCO TAKE OVER",
	    "DEPARTMENT",
	    "EXPANSION",
	    "GAS STATION",
	    "HOME QUARTERS TAKEOVER",
	    "HOMEBASE TAKEOVER",
	    "IMPROVED LAND",
	    "JCPENNEY TAKEOVER",
	    "KASH N KARRY TAKEOVER",
	    "K-MART TAKEOVER",
	    "LAND",
	    "LANECO TAKEOVER",
	    "LECHMERE TAKEOVER",
	    "LEEDMARK TAKEOVER",
	    "MACYS TAKEOVER",
	    "MINYARDS TAKEOVER",
	    "ON-SITE RELOCATION",
	    "OSCO TAKE OVER",
	    "PACE TAKEOVER",
	    "PRICE CLUB TAKEOVER",
	    "RICHS TAKEOVER",
	    "SAMS DISTRIBUTION CENTER",
	    "SAMS TAKE OVER",
	    "SAMS TAKEOVER",
	    "SAVE A CENTER TAKEOVER",
	    "SEARS TAKEOVER",
	    "SUPER SAVER TAKEOVER",
	    "TARGET TAKEOVER",
	    "VACANT LAND",
	    "W.T. GRANT TAKEOVER",
	    "WAL-MART TAKEOVER",
	    "WARDS TAKEOVER",
	    "WHOLESALE CLUB TAKEOVER",
	    "WINN DIXIE",
	    "WOOLCO TAKEOVER",
    ];
    
    const formData = yield select(formValueSelector);
    const formValues = JSON.parse(JSON.stringify(formData));
    let temp = [];

    for (let index = 0; index < formValues.outsideCouncil.length; index++) {
      temp[index] = formValues.outsideCouncil[index].value;
    }

    formValues.projectedClosingDate = `${formValues.projectedClosingDate}`;
    formValues.address_line_1 = formValues.addr_line1.label;
    formValues.outsideCouncil = temp;
    formValues.cecFirms = formValues.cecFirms.value;
    formValues.auditor = formValues.auditor.value;
    formValues.paraLegal = formValues.paraLegal.value;
    formValues.regionalVicePresident = formValues.regionalVicePresident.value;
    formValues.accountant = formValues.accountant.value;
    formValues.attorney = formValues.attorney.value;
    formValues.realEstateManager = formValues.realEstateManager.value;
    formValues.designManager = formValues.designManager.value;
    formValues.store_nbr = parseInt(formValues.store_nbr);
    formValues.status_code = 1;
    formValues.project_type_code =
      propTypes.indexOf(formValues.project_type_desc) + 1;
    formValues.closing_start_ts = formValues.closing_start_ts ? `${moment(formValues.closing_start_ts).format(
      "YYYY-MM-DD",
    )}` : null;

    formValues.closing_desc = "eClosing project";
    formValues.country_code = "US";
    formValues.closing_type_code = 1;
    formValues.rstrct_access_ind = "N";
    formValues.location_desc = null;
    formValues.postal_code = null;
    formValues.prop_usage_code = 0;
    formValues.region_nbr = 0;
    //formValues.closing_ts = formValues.closing_start_ts;
    //formValues.closing_end_ts = formValues.closing_start_ts;

    let userId = localStorage.getItem(config.SSO_user_loginId);
    formValues.last_change_userid = userId;

    yield put(actions.loading(true));
    const response = yield call(createProject, formValues);
    yield call(success, response, action.history);
  } catch (err) {
    yield call(
      failure,
      new Error("Some error occurred while trying to create a project"),
    );
  }
}

export function* success(response, history) {
  yield put(actions.loading(false));
  const success = _.get(response, "data.createProject.Success", null);
  if (success === "True") {
    const closingId = _.get(response, "data.createProject.ClosingId", null);

    if (closingId) {
      yield call(history.push, `/projects/${closingId}`);
      yield put(destroy("projectCreate"));
    }
  } else if (success === "Exists") {
    yield call(failure, new Error("Duplicate project number & sequence"));
  } else {
    yield call(
      failure,
      new Error("Some error occurred while trying to create a project"),
    );
  }
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: "Failed to create project",
      message: error.message,
    }),
  );
}
