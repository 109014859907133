export default [{
  value: 1,
  label: 'Active',
}, {
  value: 2,
  label: 'Locked',
}, {
  value: 5,
  label: 'Dropped',
}, {
  value: 6,
  label: 'Closed',
}];
