import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../input-field/InputField';
import { errorStyle } from './styles';

const renderTextField = ({ meta: { visited, touched, error }, input, ...custom }) => (
  <React.Fragment>
    <InputField {...input} {...custom} />
    {(touched || visited) && (error && (
      <span style={errorStyle}>
        {error}
      </span>
    ))}
  </React.Fragment>
);

renderTextField.defaultProps = {
  input: null,
  meta: {
    visited: false,
    error: '',
    touched: false,
  },
};

renderTextField.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    visited: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default renderTextField;
