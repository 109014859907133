import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import * as actions from "../commentActions";
import { editProjectComment } from "../../../services/comments";

const formValueSelector = state => _.get(state, "form.editCommentForm.values");
const commentSelector = state => _.get(state, "comments.data");

export default function* editProjectCommentWatcher() {
  yield takeLatest(actions.EDIT_PROJECT_COMMENT, editProjectCommentWorker);
}

export function* editProjectCommentWorker(action) {
  const formValues = yield select(formValueSelector);
  const variables = {
    closing_id: action.projectId,
    seq_nbr: formValues.commentId,
    comment_txt: formValues.commentText,
    comment_name: formValues.commentTitle,
  };

  try {
    const response = yield call(editProjectComment, variables);
    const data = _.get(response, "data.editProjectComment");

    if (data.Success === "true") {
      yield call(success, { comment: variables });
    } else {
      yield call(failure, new Error("Something Went Wrong"));
      alert("Could not edit comment");
    }
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response) {
  const comments = yield select(commentSelector);
  const updateIndex = _.findIndex(comments, [
    "seq_nbr",
    response.comment.seq_nbr,
  ]);
  yield put(actions.updateComment(updateIndex, response.comment));
}

export function* failure(error) {
  yield put(actions.error(error));
}
