import React from 'react';
import {
  Field,
  reduxForm,
} from 'redux-form';
import PropTypes from 'prop-types';
import Filter from '@material-ui/icons/FilterList';

import SelectField from '../../_shared/select-field/SelectField';

import './CommentForm.scss';
import '../../projects/Projects.scss';

const renderSelectField = ({
  input,
  ...custom
}) => (
    <SelectField
      {...custom}
      {...input}
    />
  );

renderSelectField.defaultProps = {
  input: null,
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};


export const CommentDropdown = (props) => {
  const { handleSubmit, showHideFilter, showFilter, categories, valueToShow, showStatusFilterIcon } = props;
  let i = 2;
  let dropdownOptions = [];

  dropdownOptions[0] = { value: 'All', label: 'All' };
  dropdownOptions[1] = { value: 'Project-Level', label: 'Project-Level' };

  for (let category of categories) {
    dropdownOptions[i] = {
      value: category.doc_category_id.toString(),
      label: category.category_name,
    }
    i++;
  }
  return (
    <form onChange={handleSubmit}>
      <div>
        <Field
          options={dropdownOptions}
          name="commentDropdown"
          component={renderSelectField}
          // normalize={selection => selection.value}
          // format={value => ({ value, label: value })}
          defaultValue={valueToShow}
          className="comment-input"
          onChange={value => handleSubmit(value != null && value != undefined ? value : dropdownOptions[0])}
        />
        <div
            className={showFilter ? 'filter-open filter-container' : 'filter-container'}
            onClick={showHideFilter}
          >
          <span className="filter-svg">
            <Filter disabled={showStatusFilterIcon}/>
          </span>
        </div>
      </div>
    </form>
  );
};

CommentDropdown.defaultProps = {
  reply: false,
};

CommentDropdown.propTypes = {
  reply: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  showHideFilter: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    doc_category_id: PropTypes.number,
    category_name: PropTypes.string,
  })),
};

export default reduxForm({
  form: 'commentDropdown',
})(CommentDropdown);
