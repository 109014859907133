import request from "../../graphql/request";
import {
  FETCH_USERS,
  SEARCH_USERS,
  FETCH_PROJECT_USERS,
  FETCH_USERS_WITH_ROLES,
  UPDATE_PROJECT_USERS,
  UPDATE_PASSWORD,
  CREAT_TEMP_USER_PASSWORD,
  DELETE_EXTERNAL_USER,
  FETCH_COMPANIES,
  CREATE_USER,
  FETCH_PASSWORD_EXPIRY,
} from "./queries";

export const fetchUsers = variables =>
  request({
    query: FETCH_USERS,
    variables: variables,
  });

export const fetchProjectUsers = variables =>
  request({
    query: FETCH_PROJECT_USERS,
    variables,
  });

export const searchUsers = filter =>
  request({
    query: SEARCH_USERS,
    variables: { filter },
  });
export const fetchUsersWithRoles = () =>
  request({
    query: FETCH_USERS_WITH_ROLES,
  });
export const updateProjectUsers = variables => request({
  query: UPDATE_PROJECT_USERS,
  variables,
});
export const updatePassword = variables => request({
  query: UPDATE_PASSWORD,
  variables,
});
export const createTempUserPassword = variables => request({
  query: CREAT_TEMP_USER_PASSWORD,
  variables,
});
export const deleteExternalUser = variables => request({
  query: DELETE_EXTERNAL_USER,
  variables,
});
export const fetchCompanies = () => request({
  query: FETCH_COMPANIES,
});
export const createSysUser = variables => request({
  query: CREATE_USER,
  variables,
});
export const fetchPasswordExpiryDays = variables => request({
  query: FETCH_PASSWORD_EXPIRY,
  variables,
});