import React, { useState } from 'react';
import {
  Field,
  reduxForm,
} from 'redux-form';
import {
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import './CommentForm.scss';
import * as _ from 'lodash';
import renderTextField from '../../_shared/utils/renderTextField';

let disableSubmit = true;

const validate = (values, props) => {
  const errors = {};

  const { isProjectLevel, isReply } = props;
  if (!isReply) {
    if (!values.commentTitle) {
      errors.commentTitle = 'Comment name Required';
    } else {
      const withoutLeadingSpaces = values.commentTitle.replace(/^\s+/g, '');
      const withoutTrailingSpaces = values.commentTitle.replace(/\s+$/g, '');
      if (withoutTrailingSpaces.length > 40) {
        errors.commentTitle = 'Comment name exceeds 40 characters';
      } else if (withoutLeadingSpaces == '') {
        errors.commentTitle = 'Comment name contains only whitespaces';
      }
    }
  }


  if (!values.commentText) {
    errors.commentText = 'Comment text Required';
  } else {
    const withoutLeadingSpacesText = values.commentText.replace(/^\s+/g, '');
    if (isProjectLevel && values.commentText.length > 250) {
      errors.commentText = 'Comment text exceeds 250 characters';
    } else if (!isProjectLevel && values.commentText.length > 1000) {
      errors.commentText = 'Comment text exceeds 1000 characters';
    } else if (withoutLeadingSpacesText == '') {
      errors.commentText = 'Comment text contains only whitespaces';
    }
  }

  if (_.isEmpty(errors)) {
    if (disableSubmit) {
      disableSubmit = false;
    }
  } else if (!disableSubmit) {
    disableSubmit = true;
  }
  return errors;
};

export const EditCommentForm = (props) => {

  const { handleSubmit, isReply, onCancel, commentText, title, commentId } = props;
  const [form, setValues] = useState({
    commentText,
    commentTitle: title,
  });
  const updateField = (e) => {
    setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={renderTextField}
        id="outlined-button-file"
        type="hidden"
        className="input"
        value={commentId}
        name="commentId"
      />
      {!isReply && (
        <Field
          id="outlined-button-file"
          type="text"
          className="input"
          value={form.commentTitle}
          name="commentTitle"
          component={renderTextField}
          onChange={updateField}
        />
      )}
      <div className="comment-main">
        <Field
          component={renderTextField}
          id="comment"
          type="text"
          className="comment-input"
          value={form.commentText}
          name="commentText"
          multiline
          rows="4"
          onChange={updateField}
        />
      </div>
      <div className="buttons">
        <Button
          testId="edit-comment-cancel-btn"
          className="cancel-button"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          testId="edit-comment-save-btn"
          disabled={disableSubmit}
          className="submit-button"
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

EditCommentForm.defaultProps = {
  isReply: false,
  commentText: '',
  title: '',
  commentId: '',
};

EditCommentForm.propTypes = {
  isReply: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  commentText: PropTypes.string,
  title: PropTypes.string,
  commentId: PropTypes.number,
  isProjectLevel: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'editCommentForm',
  enableReinitialize: true,
  validate,
})(EditCommentForm);
