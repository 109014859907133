export const ARCHIVE = 'CATEGORY/ARCHIVE';
export const EDIT = 'CATEGORY/EDIT';
export const CREATE = 'CATEGORYS/CREATE';
export const ERROR = 'CATEGORY/ERROR';
export const FETCH = 'CATEGORY/FETCH';
export const LOADING = 'CATEGORY/LOADING';
export const LOCK = 'CATEGORY/LOCK';
export const SET_DATA = 'CATEGORY/SET_DATA';

// TODO remove history argument once react connected router supports
// redux 7+
export const archive = (id, history) => ({ type: ARCHIVE, id, history });
export const edit = (id, updates) => ({ type: EDIT, id, updates });
export const create = history => ({ type: CREATE, history });
export const error = err => ({ type: ERROR, error: err });
export const fetch = closing_id => ({ type: FETCH, closing_id });
export const loading = isLoading => ({ type: LOADING, loading: isLoading });
export const lock = id => ({ type: LOCK, id });
export const setData = data => ({ type: SET_DATA, data });
