import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Checkbox, Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Face from "@material-ui/icons/Face";
import CommentForm from "./CommentForm";
import { openModal, closeModal } from "../_shared/modal/modalActions";

import NoComment from "../../assets/images/NoComment.svg";

import styles from "../../styles/utils/config.scss";
import "./TabComments.scss";

const data = [
  {
    id: 1,
    info: {
      icon: "",
      name: "Malcolm Reynolds",
      dateTime: moment("5-17-2019 12:00:00").valueOf(),
      comment:
        "I am Groot. We are Groot. We are Groot. We are Groot. We are Groot. We are Groot. I am Groot. We are Groot. I am Groot. I am Groot. We are Groot. We are Groot. I am Groot. We are Groot. I am Groot. I am Groot. I am Groot. I am Groot. I am Groot. I am Groot. We are Groot. We are Groot. I am Groot. We are Groot. We are Groot. ",
    },
    resolved: false,
    replies: [],
  },
  {
    id: 2,
    info: {
      icon: "",
      name: "Shepard Book",
      dateTime: moment("5-14-2019 12:00:00").valueOf(),
      comment:
        "I am Groot. I am Groot. We are Groot. I am Groot. I am Groot. We are Groot. We are Groot. We are Groot. We are Groot. We are Groot. I am Groot. I am Groot. I am Groot.",
    },
    resolved: false,
    replies: [
      {
        icon: "",
        name: "Kaylee",
        dateTime: moment("5-16-2019 1:00").valueOf(),
        comment: "I am Groot. I am Groot. We are Groot.",
      },
      {
        icon: "",
        name: "Simon",
        dateTime: moment("5-24-2019 2:00").valueOf(),
        comment:
          "I am Groot. We are Groot. We are Groot. We are Groot. We are Groot. We are Groot.",
      },
    ],
  },
];

const ResolvedCheckbox = withStyles({
  root: {
    margin: "0 0 0 -10px",
  },
  checked: {
    color: styles.primaryColor,
  },
})(props => <Checkbox color='default' {...props} />);

/**
 * Document Comments Tab
 *
 * @namespace Document
 * @memberof document.components
 */

export class TabComments extends React.Component {
  formatDateTime = dateTime =>
    moment(dateTime).calendar(null, {
      sameDay: "[Today at] LT",
      nextDay: "[Tomorrow at] LT",
      nextWeek: "dddd [at] LT",
      lastDay: "[Yesterday at] LT",
      lastWeek: "[Last] dddd [at] LT",
      sameElse: "MM/DD/YYYY [at] LT",
    });

  handleCommentButton = (id, reply) => {
    const { comments, openCommentModal, closeCommentModal } = this.props;

    const submit = values => {
      if (reply) {
        // add reply action
        const replyComment = {
          avatar: "",
          name: "Malcolm Reynolds",
          dateTime: moment().valueOf(),
          comment: values["comment-input"],
        };
        comments.map(comment => {
          if (comment.id === id) {
            comment.replies.push(replyComment);
          }
          return comment;
        });
      } else {
        // add new comment
        const newComment = {
          id: comments.length + 1,
          info: {
            avatar: "",
            name: "Malcolm Reynolds",
            dateTime: moment().valueOf(),
            comment: values["comment-input"],
          },
          replies: [],
          resolved: false,
        };
        comments.push(newComment);
      }
      this.forceUpdate();
      closeCommentModal();
    };

    openCommentModal({
      body: (
        <CommentForm
          reply={reply}
          onSubmit={submit}
          onClose={closeCommentModal}
        />
      ),
    });
  };

  updateResolvedFlag = (value, id) => {};

  renderNoComments = () => (
    <div className='no-comment'>
      <NoComment />
      <Typography className='no-comment-text' align='center'>
        No comments yet
      </Typography>
    </div>
  );

  renderComment = (commentInfo, reply = false) => (
    <div
      key={`${commentInfo.name}${commentInfo.dateTime}`}
      className={reply ? "reply" : "main-comment"}>
      <Face className='avatar' />
      <span>
        <div className='comment-name'>{commentInfo.name}</div>
        <div className='comment-date-time'>
          {this.formatDateTime(commentInfo.dateTime)}
        </div>
      </span>
      <div className='comment-message'>{commentInfo.comment}</div>
    </div>
  );

  renderCommentCard = comment => (
    <div key={comment.id} className='comment-card'>
      {this.renderComment(comment.info)}
      {comment.replies.map(commentInfo =>
        this.renderComment(commentInfo, true),
      )}
      <div className='replies'>
        <Button
          className='reply-button'
          onClick={() => this.handleCommentButton(comment.id, true)}>
          Reply
        </Button>
      </div>
      <ResolvedCheckbox
        defaultValue={comment.resolved}
        onChange={e => this.updateResolvedFlag(e.target.checked, comment.id)}
      />
      <span className='label'>Mark as resolved</span>
    </div>
  );

  render() {
    const { comments } = this.props;
    return (
      <div className='tab-comments'>
        <div className='new-comment'>
          <Button
            className='new-comment-button'
            onClick={() => this.handleCommentButton(undefined, false)}>
            Leave New Comment
          </Button>
        </div>
        {comments.length
          ? comments.map(comment => this.renderCommentCard(comment))
          : this.renderNoComments()}
      </div>
    );
  }
}

TabComments.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object).isRequired,
  openCommentModal: PropTypes.func.isRequired,
  closeCommentModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({
  comments: data,
});

const mapDispatchToProps = {
  openCommentModal: openModal,
  closeCommentModal: closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabComments);
