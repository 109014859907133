
import {
  REAL_ADMIN_R,
  REAL_PLADMIN_R,
  TEST_REAL_ADMIN_R,
  REAL_AU_R,
  REAL_DESIGN_M_R,
  REAL_GCA_R,
  REAL_RECON_R,
  REAL_REM_R,
  REAL_RVP_R,
  REAL_MEMBER_R,
  REL_ADMIN,
  EACH_OSC_FIRM,
  EACH_CEC_FIRM,
  REAL_ECLOSING_VIEW,
} from './adGroups';
import {
  createProjectRoute,
  editProjectRoute,
  editProjectUserRoute,
  viewUsersRoute,
  viewProjectsRoute,
  viewProjectDetailRoute,
  viewProjectCategoryRoute,
  changePasswordRoute,
  manageUserRoute,
  createUserRoute,
} from '../ProtectedRoutes';

export default {
  [REAL_ADMIN_R]: {
    routes: [
      createProjectRoute,
      editProjectRoute,
      editProjectUserRoute,
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
      manageUserRoute,
      createUserRoute,
    ],
  },
  [REAL_PLADMIN_R]: {
    routes: [
      createProjectRoute,
      editProjectRoute,
      editProjectUserRoute,
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
      manageUserRoute,
      createUserRoute,
    ],
  },
  // only for dev env
  [TEST_REAL_ADMIN_R]: {
    routes: [
      createProjectRoute,
      editProjectRoute,
      editProjectUserRoute,
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
      manageUserRoute,
    ],
  },
  [REAL_AU_R]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [REAL_DESIGN_M_R]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [REAL_GCA_R]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [REAL_RECON_R]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [REAL_REM_R]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [REAL_RVP_R]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [REAL_MEMBER_R]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [REL_ADMIN]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [EACH_OSC_FIRM]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [EACH_CEC_FIRM]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
  [REAL_ECLOSING_VIEW]: {
    routes: [
      viewUsersRoute,
      viewProjectsRoute,
      viewProjectDetailRoute,
      viewProjectCategoryRoute,
      changePasswordRoute,
    ],
  },
};
