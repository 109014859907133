import axios from "axios";
import request from "../../graphql/request";
import _config from "../../config";
import {
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_PROJECT_CAT,
  FETCH_DOCUMENT_PROJECT,
  FETCH_DOCUMENT_TYPES,
  FETCH_DOCUMENT_SUB_TYPES,
  FETCH_DOCUMENT_METADATA,
  DELETE_DOCUMENT,
  FETCH_DOCUMENT_VERSION_DETAILS,
  EDIT_DOCUMENT,
} from "./queries";
import SSO from "../../sso";

export const fetchDocument = id =>
  request({
    query: FETCH_DOCUMENT,
    variables: { id },
  });

export const fetchDocumentByProjectCat = (closing_id, project_category_id) =>
  request({
    query: FETCH_DOCUMENT_PROJECT_CAT,
    variables: { closingId: closing_id, categoryId: project_category_id },
  });
export const fetchDocumentByProject = closing_id =>
  request({
    query: FETCH_DOCUMENT_PROJECT,
    variables: { closingId: closing_id },
  });

export const fetchDocumentTypes = () =>
  request({
    query: FETCH_DOCUMENT_TYPES,
  });

export const fetchDocumentSubTypes = docType =>
  request({
    query: FETCH_DOCUMENT_SUB_TYPES,
    variables: { docType: docType },
  });

export const createDocument = (formValues, params, subTypeTagName) => {
  try {
    var executionDate = new Date(formValues.ecm_attr_doc_date);

    let data = new FormData();
    data.append("file", formValues.document_file[0]);
    data.append("categoryId", params.categoryId);
    data.append("projectId", params.projectId);
    data.append("lastModified", params.lastModified);
    data.append("storeNumber", params.storeNumber);
    data.append("company", params.company.trim());
    data.append("documentType", formValues.doc_type.value);
    data.append("documentTypeName", formValues.doc_type.label);
    data.append("documentSubType", formValues.doc_subtype_desc.value);
    data.append("documentName", formValues.object_name);
    data.append("environmental", formValues.ecm_attr_env_related ? formValues.ecm_attr_env_related : false);
    if (formValues.ecm_attr_doc_date) {
      data.append(
        "executedDate",
        executionDate.getFullYear() +
          "-" +
          (executionDate.getMonth() + 1) +
          "-" +
          executionDate.getDate() +
          "T13:00:00",
      );
    }
    data.append("subTypeTagName", subTypeTagName);

    return axios
      .post(
        _config.BASE_URL +  "uploadDocument",
        data,
        {
          // receive two parameter endpoint url ,form data
          withCredentials: true,
        },
      )
      .then(res => {
        if (res.status === 401) {
          SSO.redirectToSignInUrl(res.signInUrl);
        } else {
          return res.data;
        }
      });
  } catch (ex) {
    console.log(ex);
  }
};
export const uploadDocumentVersion = (formValues, params) => {
  try {
    let data = new FormData();
    data.append("file", formValues.document_file[0]);
    data.append("documentId", params.documentId);
    data.append("docStatus", params.docStatus);

    return axios
      .post(
        _config.BASE_URL + "uploadDocumentVersion",
        data,
        {
          // receive two parameter endpoint url ,form data
          withCredentials: true,
        },
      )
      .then(res => {
        if (res.status === 401) {
          SSO.redirectToSignInUrl(res.signInUrl);
        } else {
          return res.data;
        }
      });
  } catch (ex) {
    console.log(ex);
  }
};

export const fetchMetadataForDocument = documentId =>
  request({
    query: FETCH_DOCUMENT_METADATA,
    variables: { documentId: documentId },
  });

export const deleteDocumentFromSystem = (documentId, closing_id) =>
  request({
    query: DELETE_DOCUMENT,
    variables: { documentId: documentId, closingId: closing_id },
  });

export const fetchDocumentVersionDetails = documentId =>
  request({
    query: FETCH_DOCUMENT_VERSION_DETAILS,
    variables: { documentId },
  });

export const editDocumentMetadata = variables =>
  request({
    query: EDIT_DOCUMENT,
    variables,
  });
