import _ from "lodash";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { uploadDocumentVersion } from "../../../services/document";
import * as actions from "../documentActions";
import { openSnackbar } from "../../_shared/snack-bar/snackbarActions";
import { fetch as fetchProject } from "../../project/projectActions";

const documentSelector = state => _.get(state, "document.data");
const loggedInUserSelector = state => _.get(state, "session.user.data");

const formValueSelector = state =>
  _.get(state, "form.uploadNewDocVersion.values");

export default function* uploadDocumentVersionWatcher() {
  yield takeLatest(actions.UPLOAD_DOC_VERSION, uploadDocumentVersionWorker);
}

export function* uploadDocumentVersionWorker(action) {
  const formValues = yield select(formValueSelector);
  yield put(actions.loading(true));
  try {
    const response = yield call(uploadDocumentVersion, formValues, action.data);
    yield call(success, response, action.data, action.data.history);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, param) {
  yield put(actions.loading(false));

  if (response.status == 200) {
    const loggedInUserData = yield select(loggedInUserSelector);

    yield put(actions.closeUploadVersionModal());
    if (param.resetForm) param.resetForm();
    yield put(
      openSnackbar({
        message: response.message,
        showCloseIcon: true,
      }),
    );

    yield put(actions.fetchDocumentVersionsData(param.documentId));
    yield put(actions.loadMetadata(param.documentId));

    let documents = [];
    documents = yield select(documentSelector);
    let docIndex = _.findKey(documents, {documentum_doc_id: param.documentId});
   
    documents[docIndex].last_change_ts = new Date().toString();
    if (loggedInUserData) {
      documents[docIndex].last_change_userid = loggedInUserData.loginId;
    }

    let documentEdited = documents[docIndex];

    if (docIndex > -1) {
      documents.splice(docIndex, 1);
    }
    documents.unshift(documentEdited);

    yield put(fetchProject(parseInt(documentEdited.closing_id)));

  } else {
    yield put(
      openSnackbar({
        message: response.message,
        showCloseIcon: true,
      }),
    );
  }
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
