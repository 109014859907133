export const ADMIN_WEIGHT = 5;
export const REL_ADMIN_WEIGHT = 4;
export const MEMBERS_WEIGHT = 3;
export const EXTERNAL_USER_WEIGHT = 2;
export const AUDITOR_WEIGHT = 1;
export const VIEW_ONLY_WEIGHT = 0;

export const REAL_ADMIN_R = "cn=real_admin-r";
export const REAL_PLADMIN_R = "cn=real_pladmin-r";
export const TEST_REAL_ADMIN_R = "cn=test_real_admin-r";
export const REAL_AU_R = "cn=real_au-r";
export const REAL_DESIGN_M_R = "cn=real_design_m-r";
export const REAL_GCA_R = "cn=real_gca-r";
export const REAL_RECON_R = "cn=real_recon-r";
export const REAL_REM_R = "cn=real_rem-r";
export const REAL_RVP_R = "cn=real_rvp-r";
export const REAL_MEMBER_R = "cn=real_member-r";
export const REL_ADMIN = "cn=real_rel_admin";
export const EACH_OSC_FIRM = "osc firm";
export const EACH_CEC_FIRM = "cec firm";
export const REAL_ECLOSING_VIEW = "cn=real_eclosing_view";

export const GROUP_ADMIN = "ADMIN";
export const GROUP_REL_ADMIN = "REL_ADMIN";
export const GROUP_MEMBERS = "MEMBERS";
export const GROUP_EXTERNAL_USER = "EXTERNAL_USER";
export const GROUP_AUDITOR = "AUDITOR";
export const GROUP_VIEW_ONLY = "VIEW_ONLY";

export const ADRolesList = [
  {
    value: REAL_ADMIN_R,
    group: GROUP_ADMIN,
    weight: ADMIN_WEIGHT,
  },
  {
    value: REAL_PLADMIN_R,
    group: GROUP_ADMIN,
    weight: ADMIN_WEIGHT,
  },
  {
    value: TEST_REAL_ADMIN_R,
    group: GROUP_ADMIN,
    weight: ADMIN_WEIGHT,
  },
  {
    value: REL_ADMIN,
    group: GROUP_REL_ADMIN,
    weight: REL_ADMIN_WEIGHT,
  },
  {
    value: REAL_DESIGN_M_R,
    group: GROUP_MEMBERS,
    weight: MEMBERS_WEIGHT,
  },
  {
    value: REAL_GCA_R,
    group: GROUP_MEMBERS,
    weight: MEMBERS_WEIGHT,
  },
  {
    value: REAL_RECON_R,
    group: GROUP_MEMBERS,
    weight: MEMBERS_WEIGHT,
  },
  {
    value: REAL_REM_R,
    group: GROUP_MEMBERS,
    weight: MEMBERS_WEIGHT,
  },
  {
    value: REAL_RVP_R,
    group: GROUP_MEMBERS,
    weight: MEMBERS_WEIGHT,
  },
  {
    value: REAL_MEMBER_R,
    group: GROUP_MEMBERS,
    weight: MEMBERS_WEIGHT,
  },
  {
    value: EACH_OSC_FIRM,
    group: GROUP_EXTERNAL_USER,
    weight: EXTERNAL_USER_WEIGHT,
  },
  {
    value: EACH_CEC_FIRM,
    group: GROUP_EXTERNAL_USER,
    weight: EXTERNAL_USER_WEIGHT,
  },
  {
    value: REAL_AU_R,
    group: GROUP_AUDITOR,
    weight: AUDITOR_WEIGHT,
  },
  {
    value: REAL_ECLOSING_VIEW,
    group: GROUP_VIEW_ONLY,
    weight: VIEW_ONLY_WEIGHT,
  },
];
