import _ from "lodash";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { deleteDocumentFromSystem } from "../../../services/document";
import * as actions from "../documentActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";
import { openSnackbar } from "../../_shared/snack-bar/snackbarActions";
import * as projectCategoryActions from "../../project/projectCategoryActions";

const documentSelector = state => _.get(state, "document.data");
const categories = state => _.get(state, "projectCategories.data", []);
export default function* deleteDocumentMetadata() {
  yield takeLatest(actions.DELETE, deleteDocumentMetadataWorker);
}

export function* deleteDocumentMetadataWorker(action) {
  yield put(actions.loading(true));

  try {
    const response = yield call(
      deleteDocumentFromSystem,
      action.documentId,
      parseInt(action.closingId),
    );
    yield call(success, response, action.documentId);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, documentId) {
  yield put(actions.loading(false));

  const success = _.get(response, "data.deleteDocument.Success", {});
  if (success === "true") {
    let documents = yield select(documentSelector);
    let removeIndex = _.findKey(documents, { documentum_doc_id: documentId });
    let categoryId = documents[removeIndex].doc_category_id;

    documents = _.omit(documents, removeIndex);
    yield put(actions.setData(documents));

    const categoriesCollection = yield select(categories);

    let categoryIndexToBeChanged = _.findKey(categoriesCollection, { doc_category_id: categoryId });
    categoriesCollection[categoryIndexToBeChanged].doc_count--;

    yield put(projectCategoryActions.setData(categoriesCollection));
    yield put(
      openSnackbar({
        message: "Document deleted!",
        showCloseIcon: true,
      }),
    );
  } else {
    yield call(failure, err, message);
  }
}

export function* failure(error, message) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: "Failed to delete document",
      message: "Sorry, document could not be deleted",
    }),
  );
}
