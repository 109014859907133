export const OPEN = 'SNACKBAR/OPEN';
export const CLOSE = 'SNACKBAR/CLOSE';
export const LOADING = 'SNACKBAR/LOADING';

export const openSnackbar = ({
  message,
  showCloseIcon,
}) => ({
  type: OPEN,
  message,
  showCloseIcon,
});
export const closeSnackbar = () => ({ type: CLOSE });
