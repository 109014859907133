import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Toolbar from '@material-ui/core/Toolbar/index';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { selectProject } from './projectSelectors';

const mapStateToProps = (state, ownProps) => {
  const params = _.get(ownProps, 'match.params', {});
  const projectID = _.get(params, 'projectId', null);
  const projectData = selectProject(state);
  const projectName = _.get(projectData, 'projectDetails.closing_name', '');
  const storeNbr = _.get(projectData, 'projectDetails.store_nbr', '');
  const city = _.get(projectData, 'projectDetails.city_name', '');
  const projectSeqNbr = _.get(projectData, 'projectDetails.project_seq_nbr', '');
  return ({
    projectName,
    storeNbr,
    projectSeqNbr,
    city,
  });
};

const useStyles = makeStyles(theme => ({
  titleBar: {
    background: '#041e42',
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: '#fff',
  },
  titleBarButton: {
    fill: '#fff',
  }
}));

export const ProjectNavbar = ({
  history,
  projectName,
  storeNbr,
  projectSeqNbr,
  city,
}) => {
  const classes = useStyles();
  const goBack = () => history.push('/projects');

  return (
    <Toolbar className={classes.titleBar}>
      <Button onClick={goBack}>
        <ChevronLeft className={classes.titleBarButton} />
      </Button>
      <Typography variant="h5">
        {projectName}: {storeNbr} - {projectSeqNbr} {city}
      </Typography>
    </Toolbar>
  );
};

ProjectNavbar.defaultProps = {
  history: {
    push: _.noop,
  },
  projectName: '',
  projectSeqNbr: '',
  storeNbr: '',
  city: '',
};

ProjectNavbar.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  projectName: PropTypes.string,
  projectSeqNbr: PropTypes.string,
  storeNbr: PropTypes.string,
  city: PropTypes.string,
};

const reduxConnected = connect(mapStateToProps, null)(ProjectNavbar);

export default withRouter(reduxConnected);
