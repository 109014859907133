import _ from "lodash";
import { docStatuses } from "./utils";

export const selectDocumentList = state =>
  _.values(_.get(state, "document.data", {}));

export const selectDocumentType = (state) => {
  const docTypes = _.values(_.get(state, "document.documentTypes", {}));
  docTypes.sort((docType1, docType2) => {
    const docTypeA = docType1.label_text.toUpperCase();
    const docTypeB = docType2.label_text.toUpperCase();
    if (docTypeA < docTypeB) {
      return -1;
    }
    if (docTypeA > docTypeB) {
      return 1;
    }
    // equal
    return 0;
  });
  return docTypes.map(docType => ({
    value: docType.type_name,
    label: docType.label_text,
  }));
};

export const selectDocumentSubType = (state) => {
  let docSubTypes = _.values(_.get(state, 'document.documentSubTypes', {}));
  docSubTypes = docSubTypes.filter(subType => subType.use_subtype && !subType.inactive);
  docSubTypes.sort((docSubType1, docSubType2) => {
    const docSubTypeA = docSubType1.rel_attr_value.toUpperCase();
    const docSubTypeB = docSubType2.rel_attr_value.toUpperCase();
    if (docSubTypeA < docSubTypeB) {
      return -1;
    }
    if (docSubTypeA > docSubTypeB) {
      return 1;
    }
    // equal
    return 0;
  });
  return docSubTypes.map(docSubType => ({
    value: docSubType.rel_attr_value,
    label: docSubType.rel_attr_value,
  }));
};

export const getSelectedDocumentMetadata = state =>
  _.get(state, "document.selectedDocumentMetadata.data", {});

export const getDocumentVersionsData = state => {
  const versionsData = _.get(state, "document.documentVersions.data", []);
  versionsData.sort(
    (a, b) => new Date(b.r_modify_date) - new Date(a.r_modify_date),
  );
  const valuesToRemove = [...docStatuses, "CURRENT"];
  return versionsData.map(version => {
    const isCurrent = version.r_version_label.includes("CURRENT");
    const versionLabel = version.r_version_label.filter(
      value => !valuesToRemove.includes(value),
    )[0];
    return {
      isCurrent,
      versionLabel,
      modifier: version.r_modifier,
      ownerName: version.owner_name,
      modifiedDate: version.r_modify_date,
      documentVerionId: version.r_object_id,
    };
  });
};
