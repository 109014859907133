import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchAllCategoryComments } from "../../../services/comments";
import * as actions from "../commentActions";

export default function* fetchAllCategoryCommentsWatcher() {
  yield takeLatest(
    actions.FETCH_ALL_CATEGORY_COMMENTS,
    fetchAllCategoryCommentsWorker,
  );
}

export function* fetchAllCategoryCommentsWorker(action) {
  yield put(actions.loading(true));
  try {
    const response = yield call(fetchAllCategoryComments, {
      closingId: action.closingId,
    });
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(
    response,
    "data.fetchAllCategoryCommentsByClosingId.Comments",
    [],
  );
  yield put(actions.appendData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
