import {
  REAL_ADMIN_R,
  REAL_PLADMIN_R,
  TEST_REAL_ADMIN_R,
  REAL_AU_R,
  REAL_DESIGN_M_R,
  REAL_GCA_R,
  REAL_RECON_R,
  REAL_REM_R,
  REAL_RVP_R,
  REAL_MEMBER_R,
  REL_ADMIN,
  EACH_OSC_FIRM,
  EACH_CEC_FIRM,
  REAL_ECLOSING_VIEW,
} from './adGroups';

export const mapRolesToNames = new Map([
  [REAL_ADMIN_R, 'System Admin'],
  [REAL_PLADMIN_R, 'Paralegal Admin'],
  [TEST_REAL_ADMIN_R, 'Test System Admin'],
  [REAL_AU_R, 'Auditor'],
  [REAL_DESIGN_M_R, 'Design Manager'],
  [REAL_GCA_R, 'Accountant'],
  [REAL_RECON_R, 'Attorney'],
  [REAL_REM_R, 'Real Estate Manager'],
  [REAL_RVP_R, 'Vice President'],
  [REAL_MEMBER_R, 'Member'],
  [REL_ADMIN, 'REL Admin'],
  [EACH_OSC_FIRM, 'Outside Counsel'],
  [EACH_CEC_FIRM, 'Civil Engineer'],
  [REAL_ECLOSING_VIEW, 'View only User'],
]);
