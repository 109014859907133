import React, { useState, useEffect } from "react";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InputField, { SIZES } from "../_shared/input-field/InputField";
import "./PreLogin.scss";
import { Typography, Button } from "@material-ui/core";
import WelcomeHeader from "../_shared/welcome-header/WelcomeHeader";
import SSO from "../../sso";
import config from "../../sso/config";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import CryptoJS from 'crypto-js';
import CircularProgress from "@material-ui/core/CircularProgress";

const renderTextField = ({ input, ...custom }) => (
  <InputField {...input} {...custom} />
);

renderTextField.defaultProps = {
  input: null,
};

renderTextField.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

const PreLogin = () => {

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setError] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initateLoginForWalmartUsers = () => {
    localStorage.setItem(config.fetch_details_param, false);
    SSO.signIn(false)
      .then((res) => {})
      .catch((err) => {
        setError(true);
        setErrMsg(err);
      });
  };

  const initateLoginForExternalUsers = () => {

    if(_.isUndefined(username) || _.isEmpty(username) || _.isNull(username)) {
      setError(true);
      setErrMsg('User Id required');
    }
    else if(_.isUndefined(password) || _.isEmpty(password) || _.isNull(password)){
      setError(true);
      setErrMsg('Password required');
    } 
    else{
      setIsLoading(true);
      localStorage.setItem(config.fetch_details_param, true);
      SSO.signInExternalUsers(username, password).then((res) => {
        if (res && (res.status === 401 || res.status === 500)) {
          
          setError(true);
          setErrMsg(res.error);
        }
        setIsLoading(false);
      });
    }
    
  };

   // Similar to componentDidMount 

   useEffect(() => {
    // To print Hostname and cluster id 
    var pod = process.env.HOSTNAME == '' ? 'localhost':process.env.HOSTNAME;

    var clusterInfo = process.env.WCNP_CLUSTER_ID;
  
    console.log('Sucess! PodName:'+ pod + ',Cluster:'+clusterInfo);
  });

  return (
    <div className="pre-login">
      <WelcomeHeader
        title="Welcome to eClosing!"
        subtitle="Sign into eClosing with"
      />
      {isLoading && 
        <CircularProgress size={30} />
      }
      <Accordion
        expanded={true}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
          <Typography>Enterprise Login</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            color="primary"
            variant="contained"
            className="pre-login-buttons"
            onClick={initateLoginForWalmartUsers}
            disabled = {isLoading}
          >
            Walmart Inc
          </Button>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={true}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel2a-header">
          <Typography>External User</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form className="form-container" autoComplete="off">
            {isError && (
              <div className="error-msg">
                <Typography className="msg">
                  {errMsg}
                </Typography>
              </div>
            )} 
            <InputField
              name="username"
              inputLabel="Username"
              inputType="text"
              placeholder="Username"
              inputStyleType={SIZES.LARGE}
              showInputAdornment={false}
              onChange={(event) => {
                setErrMsg(null);
                setError(false);
                setUserName(event.target.value);
              }}
            />
            <InputField
              name="password"
              inputLabel="Password"
              inputType="password"
              placeholder="Password"
              showInputAdornment={false}
              inputStyleType={SIZES.LARGE}
              onChange={(event) => {
                setErrMsg(null);
                setError(false);
                setPassword(event.target.value);
              }}
            />

            <Button
              color="primary"
              variant="contained"
              className="pre-login-buttons"
              onClick={initateLoginForExternalUsers}
              disabled = {isLoading}
            >
              Sign In
            </Button>
          </form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default PreLogin;
