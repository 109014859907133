import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import * as actions from "../commentActions";
import { resolveComment, unResolveComment } from "../../../services/comments";

var values = {};

export default function* toggleResolveCommentWatcher() {
  yield takeLatest(actions.TOGGLE_COMMENT_RESOLVE, toggleResolveCommentWorker);
}

export function* toggleResolveCommentWorker(action) {
  const { categoryId, sequenceNumber, resolve } = action;
  values = {
    doc_category_id: categoryId,
    comment_seq_nbr: sequenceNumber,
  };
  try {
    var response = null;
    if (resolve == true) {
      response = yield call(resolveComment, values);
    } else {
      response = yield call(unResolveComment, values);
    }
    yield call(success, response, action.history);
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response, history) {
  var success = response.data.toggleResolveCategoryComment.Success;
  if (success === "true") {
  } else {
    alert("Could not resolve/unresolve comment");
  }
}

export function* failure(error) {
  yield put(actions.error(error));
}
