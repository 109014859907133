import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Grid from '@material-ui/core/Grid';
import * as modalActions from '../../_shared/modal/modalActions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDiscard: () => {
    dispatch(modalActions.closeModal());
  },
  onStay: () => {
    dispatch(modalActions.closeModal());
    ownProps.onStay();
  },
});

const useStyles = makeStyles(theme => ({
  errorButton: {
    'background-color': theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      'background-color': '#CA2213',
    },
  },
  button: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const PasswordShow = ({
  onStay,
  content,
  onDiscard,
}) =>{
  const classes = useStyles();

  const handleCopy=()=> {

    var copyText = document.getElementById("password")
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy")
    toast.success(`Hi! Now you can: ctrl+v: ${copyText.value} `)
  }

  return (
    <Fragment>
      <DialogTitle id="responsive-dialog-title">
      Reset Password
        
            <IconButton className={classes.button}>
                            <Close onClick={onDiscard} />
            </IconButton>
        
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
         
        </DialogContentText>
        <Grid container  alignItems="flex-end">
          <Grid item>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="New Password"
              fullWidth
              value={content}
            />
          </Grid>
          <Grid item>
            <FileCopyIcon onClick={handleCopy}/>
          </Grid>
        </Grid>
        
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onStay} color="primary">
          Yes
        </Button> */}
        {/* <Button variant="contained" onClick={onDiscard} >
          OK
        </Button> */}
      </DialogActions>
    </Fragment>
  );
};

PasswordShow.defaultProps = {
  onStay: _.noop,
  onDiscard: _.noop,
};

PasswordShow.propTypes = {
  onStay: PropTypes.func,
  onDiscard: PropTypes.func,
};

const reduxConnected = connect(null, mapDispatchToProps)(PasswordShow);

export default withRouter(reduxConnected);
