import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import { updateProjectUsers } from "../../../services/users";
import { openSnackbar } from "../../_shared/snack-bar/snackbarActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";
import * as actions from "../usersActions";

const formValueSelector = state => _.get(state, "form.projectEditUsers.values");
const getInitialFormValues = state =>
  _.get(state, "form.projectEditUsers.initial");
export default function* editProjectUsersWatcher() {
  yield takeLatest(actions.UPDATE_PROJ_USERS, editProjectUsersWorker);
}

export function* editProjectUsersWorker(action) {
  yield put(actions.loading(true));
  const formValues = yield select(formValueSelector);
  const initialFormValues = yield select(getInitialFormValues);
  const outsideCouncil = [];
  const outSideCouncilSet = new Set();
  formValues.outsideCouncil.forEach(element => {
    outSideCouncilSet.add(element.value);
  });
  initialFormValues.outsideCouncil.forEach(element => {
    if (outSideCouncilSet.has(element.value)) {
      outsideCouncil.push({
        old: element.value,
        new: element.value,
      });
      outSideCouncilSet.delete(element.value);
    } else {
      outsideCouncil.push({
        old: element.value,
        new: "",
      });
    }
  });
  outSideCouncilSet.forEach(value => {
    outsideCouncil.push({
      old: "",
      new: value,
    });
  });

  const projectUsersData = {
    closing_id: parseInt(action.projectId, 10),
    outsideCouncil,
    cecFirms: formValues.cecFirms.value,
    auditor: formValues.auditor.value,
    plAdmin: formValues.paraLegal.value,
    regionalVicePresident: formValues.regionalVicePresident.value,
    accountant: formValues.accountant.value,
    generalCouncil: formValues.attorney.value,
    portfolioManager: formValues.realEstateManager.value,
    districtManager: formValues.designManager.value,
  };
  try {
    const response = yield call(updateProjectUsers, { projectUsersData });
    const data = _.get(response, "data.updateProjectUsers", {});
    if (data.Success === "true") {
      yield call(success, data, action.history);
    } else {
      yield call(failure, new Error("Something went wrong"));
    }
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, history) {
  yield put(
    openSnackbar({
      message: "Project users updated!",
      showCloseIcon: true,
    }),
  );
  yield put(actions.loading(false));
  const id = response.Args;
  yield call(history.push, `/projects/${id}`);
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: "Project users could not be updated",
      message: "An error occured while updating the Project Users",
    }),
  );
  return error;
}
