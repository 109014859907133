import _ from "lodash";

export const selectUsersList = state =>
  _.values(_.get(state, "users.data", {}));

export const selectUsersTotal = state => state.users.totalUsers.totalUsers;

export const selectUsersPageDetails = state =>
  state.users.usersPageDetails.pageDetails;

export const selectUsersOptions = state =>
  _.map(_.values(_.get(state, "users.data", {})), userObject => ({
    value: userObject.id,
    label: `${userObject.firstName} ${userObject.lastName}`,
  }));

// export const selectUsersByRole = (state, role) => state.filter(user => user.role_name === role).map(user => ({ lable: `${user.extrn_first_name} ${user.extrn_last_name}`, value: user.sys_userid }))

const getUserFullName = userObj => (
  `${userObj.extrn_first_name ? userObj.extrn_first_name.trim() : ""} ${userObj.extrn_last_name ? userObj.extrn_last_name.trim() : ""}`
);
const getUserId = userObj => (userObj.sys_userid ? userObj.sys_userid.trim() : '');
export const selectUsersWithRoles = state => _.get(state, 'users.usersWithRoles.data', []);
export const selectUsersByRole = (users, role) => {
  return users
    .filter(user => user.role_name.trim() === role)
    .map(userObj => {
      const userName = `${
        userObj.extrn_first_name ? userObj.extrn_first_name.trim() : ""
        } ${userObj.extrn_last_name ? userObj.extrn_last_name.trim() : ""}`;
      const userId = userObj.sys_userid ? userObj.sys_userid.trim() : "";
      return { value: userId, label: userName };
    });
};

export const selectUserByRole = (users, role) => {
  const user = users.find((usr => usr.role_name.trim() === role));
  return {
    label: user ? getUserFullName(user) : '',
    value: user ? getUserId(user) : '',
  };
};

export const isRequestInprogress = state => _.get(state, 'users.request.loading', false);
export const selectCompanies = (state) => {
  const companies = _.get(state, 'users.companies', []);
  return companies.map(company => ({
    label: company.extrn_company_name,
    value: company.extrn_company_id,
  }));
};