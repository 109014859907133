export default [{
  id: 1,
  value: 'BULK_STORAGE',
}, {
  id: 2,
  value: 'DOT_COM',
}, {
  id: 3,
  value: 'EXPANSION',
}, {
  id: 4,
  value: 'EXPANSION_1',
}, {
  id: 5,
  value: 'EXPANSIONS',
}, {
  id: 6,
  value: 'FOOD',
}, {
  id: 7,
  value: 'MECHANIZED',
}, {
  id: 8,
  value: 'MECHANIZED_PERISHABLE',
}, {
  id: 9,
  value: 'NEW',
}, {
  id: 10,
  value: 'OTHER',
}, {
  id: 11,
  value: 'PERISHABLE',
}, {
  id: 12,
  value: 'PROTO_TYPE',
}, {
  id: 13,
  value: 'RAWLAND',
}, {
  id: 14,
  value: 'REGIONAL',
}, {
  id: 15,
  value: 'RELOCATION',
}, {
  id: 16,
  value: 'REMODEL',
}, {
  id: 17,
  value: 'SPECIAL',
}, {
  id: 18,
  value: 'SPECIALITY',
}, {
  id: 19,
  value: 'TAKEOVER',
}, {
  id: 20,
  value: 'WPM',
}];
