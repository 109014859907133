import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import * as actions from "../documentActions";
import { convertData } from "../../../utilities";

export default function* projectDocSortingtWatcher() {
  yield takeLatest(actions.SORT_DOC_LIST, projectDocSortingWorker);
}

export function* projectDocSortingWorker(action) {
  yield put(actions.loading(true));

  try {
    yield call(success, action.data);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(data) {
  yield put(actions.loading(false));

  const convertedData = _.map(convertData(data), extraData => ({
    ...extraData,
  }));

  yield put(actions.setData(convertedData));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
