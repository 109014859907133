import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import InputTextField from "./InputTextField";

import "./CommentForm.scss";

var disableSubmit = true;

const renderTextField = ({ meta: { touched, error }, input, ...custom }) => (
  <div>
    <InputTextField {...input} {...custom} />
    {touched && error && (
      <font
        color='#f44336'
        fontFamily='Arial'
        fontSize='0.75rem'
        margin-left='10px'>
        {error}
      </font>
    )}
  </div>
);

const validate = (values, props) => {
  const errors = {};

  if (!values.response_txt) {
    errors.response_txt = "Reply text required";
  } else {
    var withoutLeadingSpaces = values.response_txt.replace(/^\s+/g, "");
    if (values.response_txt.length > 1000) {
      errors.response_txt = "Reply text exceeds 1000 characters";
    } else if (withoutLeadingSpaces == "") {
      errors.response_txt = "Reply text contains only whitespaces";
    }
  }

  if (_.isEmpty(errors)) {
    if (disableSubmit) {
      disableSubmit = false;
    }
  } else {
    if (!disableSubmit) {
      disableSubmit = true;
    }
  }
  return errors;
};

export const ReplyCommentForm = props => {
  const { handleSubmit, onCancel, id } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        component={InputTextField}
        id='outlined-button-file'
        type='hidden'
        value={id}
        name='comment_seq_nbr'
      />
      <div className='comment-main'>
        <Field
          className='comment-input'
          name='response_txt'
          rows='6'
          multiline
          variant='outlined'
          component={renderTextField}
        />
      </div>
      <div className='buttons'>
        <Button className='cancel-button' type='button' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={disableSubmit}
          className='submit-button'
          type='submit'>
          Submit
        </Button>
      </div>
    </form>
  );
};

renderTextField.defaultProps = {
  input: "",
};

renderTextField.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

ReplyCommentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  id: PropTypes.number,
};

export default reduxForm({
  form: "replyCommentForm",
  validate,
})(ReplyCommentForm);
