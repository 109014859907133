
export const validateProjectUsers = (values) => {
  const errors = {};
  if (!values.attorney) {
    errors.attorney = 'User required';
  }
  if (!values.paraLegal) {
    errors.paraLegal = 'User required';
  }
  if (!values.realEstateManager) {
    errors.realEstateManager = 'User required';
  }
  if (!values.designManager) {
    errors.designManager = 'User required';
  }
  if (!values.auditor) {
    errors.auditor = 'User required';
  }
  if (!values.regionalVicePresident) {
    errors.regionalVicePresident = 'User required';
  }
  if (!values.accountant) {
    errors.accountant = 'User required';
  }
  if (!values.outsideCouncil || values.outsideCouncil.length < 1) {
    errors.outsideCouncil = 'User required';
  }
  if (!values.cecFirms) {
    errors.cecFirms = 'User required';
  }
  return errors;
};
