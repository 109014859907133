import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import _ from "lodash";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/MoreVert";
import SubjectIcon from "@material-ui/icons/Subject";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as modalActions from "../../_shared/modal/modalActions";
import { selectProject, selectProjectusers } from "../projectSelectors";
import { fetch as fetchProject } from '../projectActions';

import { ProjectDetailsList } from "./ProjectDetailsList";
import ProjectCloseModalContent from "./ProjectCloseModalContent";
import ProjectLockModalContent from "./ProjectLockModalContent";
import ProjectDropModalContent from "./ProjectDropModalContent";
import ProjectUnLockModalContent from "./ProjectUnLockModalContent";
import { ProjectOverviewCard } from "./ProjectOverviewCard";
import * as commentActions from "../../comment/commentActions";
import { adminWeight, getMaxWeight } from "../../../roleaccess/utils";
import { REL_ADMIN } from "../../../roleaccess/adGroups";
import { selectUserRoles } from '../../login/loginSelector';
import styles from "./ProjectOverview.module.scss";

import {
  transformProjectDetails,
  transformStoreDetails,
  transformInternalUsersDetails,
  transformExternalUsersDetails,
} from "./transformers";

import CommentsPanel from "../../comment/commentPanel/CommentsPanel";

const drawerWidth = "37%";

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    "background-color": "#e1edf9",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    //padding: theme.spacing(2),
  },
  floatLeft: {
    float: "left",
  },
  floatNone: {
    float: "none",
    marginBottom: "10px",
  },
  margin: {
    margin: "20px",
  },
  marginBottom: {
    marginBottom: "20px",
  },
  marginTop: {
    marginTop: "20px",
  },
  loadingSpinner: {
    position: "absolute",
    left: "50%",
    top: "6%",
  },
}));

const mapStateToProps = state => ({
  data: selectProject(state),
  users: selectProjectusers(state),
  isLoading:
    _.get(state, "projectDetails.request.loading", false) ||
    _.get(state, "users.request.loading", false),
  userRole: selectUserRoles(state),
});
const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(modalActions.closeModal),
  openConfirmModal: body => dispatch(modalActions.openModal({ body })),
  fetchProjectComments: closingId => dispatch(commentActions.fetch(closingId)),
  fetchAllCategoryCommentsForProject: closingId =>
    dispatch(commentActions.fetchAllCategoryCommentsForProject(closingId)),
  resetComments: () => dispatch({ type: commentActions.RESET_DATA }),
  fetchProject: closingId => dispatch(fetchProject(closingId)),
});

const canEdit = status_code_desc => {
  if (
    _.isUndefined(status_code_desc) ||
    _.isEmpty(status_code_desc) ||
    _.isNull(status_code_desc)
  ) {
    return false;
  } else {
    return !(status_code_desc && /locked/i.test(status_code_desc));
  }
};

export const ProjectOverview = ({
  data,
  users,
  history,
  openConfirmModal,
  fetchProjectComments,
  resetComments,
  fetchAllCategoryCommentsForProject,
  userRole,
  isLoading,
  fetchProject,
}) => {
  const projectDetails = _.get(data, 'projectDetails', {});
  const storeDetails = _.get(data, 'storeDetails', {});
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //console.log('modified date before transformation ', projectDetails.last_change_ts);
  //const canViewComments = userRole? !userRole.includes(REL_ADMIN) : true;

  function handleDrawerOpen(closingId) {
    fetchProjectComments(closingId);
    fetchAllCategoryCommentsForProject(closingId);
    setOpen(true);
  }

  const onEditDetails = canEdit(projectDetails.status_code_desc)
    ? () => history.push(`/projects/${projectDetails.closing_id}/details/edit`)
    : _.noop;

  const onEditUsers = canEdit(projectDetails.status_code_desc)
    ? () => history.push(`/projects/${projectDetails.closing_id}/users/edit`)
    : _.noop;

  function handleDrawerClose() {
    resetComments();
    setOpen(false);
    fetchProject(projectDetails.closing_id);
  }

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const handleMenuOpen = event => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  const triggerCloseConfirm = () => {
    handleMenuClose();
    openConfirmModal(
      <ProjectCloseModalContent
        projectID={projectDetails.closing_id}
        projectName={projectDetails.closing_name}
        history={history}
        projectOpen={true}
      />
    );
  };

  const triggerLockConfirm = () => {
    handleMenuClose();
    openConfirmModal(
      <ProjectLockModalContent
        projectID={projectDetails.closing_id}
        projectName={projectDetails.closing_name}
        history={history}
        projectOpen={true}
      />
    );
  };

  const triggerUnLockConfirm = () => {
    handleMenuClose();
    openConfirmModal(
      <ProjectUnLockModalContent
        projectID={projectDetails.closing_id}
        projectName={projectDetails.closing_name}
        history={history}
        projectOpen={true}
      />
    );
  };

  const triggerDropConfirm = () => {
    handleMenuClose();
    openConfirmModal(
      <ProjectDropModalContent
        projectID={projectDetails.closing_id}
        projectName={projectDetails.closing_name}
        history={history}
        projectOpen={true}
      />
    );
  };

  let editMessageOnProjectOverview = "EDIT";
  let functionOnButtonPress = onEditDetails;

  let showActiveOption = true;
  let showLockOption = true;
  let showCloseOption = true;
  let showDropOption = true;

  if (projectDetails.status_code == 1) {
    showActiveOption = false;
    showLockOption = true;
    showCloseOption = true;
    showDropOption = true;
  } else if (projectDetails.status_code == 2) {
    editMessageOnProjectOverview = "UNLOCK";
    functionOnButtonPress = triggerUnLockConfirm;
  } else if (projectDetails.status_code == 5) {
    showActiveOption = true;
    showLockOption = true;
    showCloseOption = true;
    showDropOption = false;
  } else if (projectDetails.status_code == 6) {
    showActiveOption = true;
    showLockOption = true;
    showCloseOption = false;
    showDropOption = true;
  }
  const canChangeStatus = getMaxWeight(userRole) >= adminWeight();
  const canEditProject = getMaxWeight(userRole) >= adminWeight();
  return (
    <Grid container justify="center" spacing={3}>
      {isLoading && <CircularProgress className={classes.loadingSpinner} />}
      <Grid item xs={12}>
        <header className={styles.header}>
          <Typography variant="h5">
            <SubjectIcon />
            Project Overview
          </Typography>
          {(!isLoading &&
            <Button
              variant='contained'
              color='primary'
              className='viewCommentsButton'
              onClick={() => handleDrawerOpen(projectDetails.closing_id)}
            >
              View Comments
          </Button>
          )}
          <Drawer
            className={classes.drawer}
            anchor="right"
            open={open}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <CommentsPanel
              isProjectLevel
              statusCode={projectDetails.status_code}
            />
          </Drawer>
          {projectDetails.status_code != 2 && canChangeStatus && (
            <section>
              <IconButton aria-label="Project Menu" onClick={handleMenuOpen}>
                <MoreIcon />
              </IconButton>
              <Menu
                id="project-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}>
                {/* <MenuItem onClick={handleMenuClose}>Share</MenuItem> */}
                {/* <MenuItem onClick={handleMenuClose}>Print</MenuItem> */}
                {showActiveOption && (
                  <MenuItem onClick={triggerUnLockConfirm}>Set Active</MenuItem>
                )}
                {showLockOption && (
                  <MenuItem onClick={triggerLockConfirm}>Lock</MenuItem>
                )}
                {showCloseOption && (
                  <MenuItem onClick={triggerCloseConfirm}>Close</MenuItem>
                )}
                {showDropOption && (
                  <MenuItem onClick={triggerDropConfirm}>Drop</MenuItem>
                )}
              </Menu>
            </section>
          )}
        </header>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectOverviewCard
          title="PROJECT DETAILS"
          onEdit={functionOnButtonPress}
          canEditProject={canEditProject}
          editMessage={editMessageOnProjectOverview}
          locked={projectDetails.status_code == 2}>
          <Fragment>
            <section>
              <ProjectDetailsList details={transformProjectDetails(projectDetails)} />
            </section>
            <section>
              <ProjectDetailsList
                title="Store Details"
                details={transformStoreDetails(storeDetails)}
              />
            </section>
          </Fragment>
        </ProjectOverviewCard>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectOverviewCard title="USERS" onEdit={onEditUsers} canEditProject={canEditProject}>
          <Fragment>
            <section>
              <ProjectDetailsList
                title="Internal Users"
                details={transformInternalUsersDetails(
                  users.filter(function (user) {
                    return user.external_user_ind === "0";
                  })
                )}
              />
            </section>
            <section>
              <ProjectDetailsList
                title="External Users"
                details={transformExternalUsersDetails(
                  users.filter(function (user) {
                    return user.external_user_ind === "1";
                  })
                )}
              />
            </section>
          </Fragment>
        </ProjectOverviewCard>
      </Grid>
    </Grid>
  );
};

ProjectOverview.defaultProps = {
  data: {},
  users: [],
  history: _.noop,
  openConfirmModal: _.noop,
};

ProjectOverview.propTypes = {
  data: PropTypes.object,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      role_id: PropTypes.number,
      role_name: PropTypes.string,
      extrn_first_name: PropTypes.string,
      extrn_last_name: PropTypes.string,
    })
  ),
  history: PropTypes.func,
  openConfirmModal: PropTypes.func,
  fetchProjectComments: PropTypes.func.isRequired,
  resetComments: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const reduxConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectOverview);

export default withRouter(reduxConnected);
