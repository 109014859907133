import {
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';
import _ from 'lodash';
import React from 'react';
import { reset } from 'redux-form';
import CryptoJS from 'crypto-js';
import { createTempUserPassword } from '../../../services/users';
import { openSnackbar } from '../../_shared/snack-bar/snackbarActions';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';
import { openModal } from '../../_shared/modal/modalActions';
import * as actions from '../usersActions';
import TempPasswordPopup from '../externalUsers/TempPasswordShowPopup';

import _config from "../../../config"

const formValueSelector = state => _.get(state, 'form.createTempUserPassword.values');

export default function* createTempPasswordWatcher() {
  yield takeLatest(actions.CREATE_TEMP_USER_PASSWORD, createTempPasswordWorker);
}

export function* createTempPasswordWorker(action) {
  yield put(actions.loading(true));
  const formData = yield select(formValueSelector);
  const formValues = JSON.parse(JSON.stringify(formData));
  formValues.userName = action.userId;
  formValues.password = formValues.password;
  // formValues.password = CryptoJS.AES.encrypt(formValues.password, process.env.ENCRYPTION_KEY).toString();

  try {
    const response = yield call(createTempUserPassword, formValues);

    const data = _.get(response, 'data.createTempAdPasswordOfUser',
      {
        Success: 'false',
        Message: 'Something went wrong',
        Password:''
      });
    if (data.Success === 'true') {
      yield call(success, data, action.body);
    } else {
      yield call(failure, new Error(data.Message));
    }
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, popUpbody) {

  yield put(actions.loading(false));
  yield put(reset('createTempUserPassword'));

  yield put(openModal({
    body:<TempPasswordPopup content={response.Password}/>
  }));
  // yield put(
  //   openSnackbar({
  //     message: response.Message+":  "+response.Password,
  //     showCloseIcon: true,
  //   }),
  // );
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  const pwdPolicyLink = <a href={_config.URL_LINK} rel="noopener noreferrer" target="_blank" >here</a>;
  const pwdPolicy = <span>Password is not <b>adhering</b> to Walmart policy. Please follow Walmart password guidelines {pwdPolicyLink}.</span>;
  const errMsg = error.message.toLowerCase().includes('password') ? <span>{error.message} <br />{pwdPolicy}</span> : error.message;
  yield put(
    errorOpenModal({
      title: 'Password could not be created',
      message: errMsg || 'An error occured while creating the temporary password',
    }),
  );
  return error;
}
