import React from "react";
import { Field, reduxForm } from "redux-form";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import "./CommentForm.scss";
import InputTextField from "./InputTextField";

import _ from "lodash";

var disableSubmit = true;

const validate = (values, props) => {
  const errors = {};

  const { isProjectLevel } = props;

  if (!values.comment_name) {
    errors.comment_name = "Comment name required";
  } else {
    var withoutLeadingSpaces = values.comment_name.replace(/^\s+/g, "");
    if (values.comment_name.length > 40) {
      errors.comment_name = "Comment name exceeds 40 characters";
    } else if (withoutLeadingSpaces == "") {
      errors.comment_name = "Comment name contains only whitespaces";
    }
  }

  if (!isProjectLevel) {
    if (!values.comment_text) {
      errors.comment_text = "Comment text required";
    } else {
      var withoutLeadingSpacesText = values.comment_text.replace(/^\s+/g, "");
      if (values.comment_text.length > 1000) {
        errors.comment_text = "Comment text exceeds 1000 characters";
      } else if (withoutLeadingSpacesText == "") {
        errors.comment_text = "Comment text contains only whitespaces";
      }
    }
  } else {
    if (!values.comment_txt) {
      errors.comment_txt = "Comment text required";
    } else {
      var withoutLeadingSpacesText = values.comment_txt.replace(/^\s+/g, "");
      if (values.comment_txt.length > 255) {
        errors.comment_txt = "Comment text exceeds 255 characters";
      } else if (withoutLeadingSpacesText == "") {
        errors.comment_txt = "Comment text contains only whitespaces";
      }
    }
  }

  if (_.isEmpty(errors)) {
    if (disableSubmit) {
      disableSubmit = false;
    }
  } else {
    if (!disableSubmit) {
      disableSubmit = true;
    }
  }
  return errors;
};

const renderTextField = ({ meta: { touched, error }, input, ...custom }) => (
  <div>
    <InputTextField {...input} {...custom} />
    {touched && error && (
      <font
        color='#f44336'
        fontFamily='Arial'
        fontSize='0.75rem'
        margin-left='10px'>
        {error}
      </font>
    )}
  </div>
);

export const SubCategoryCommentForm = props => {
  const { handleSubmit, onCancel, id, isProjectLevel } = props;

  function submit(e) {
    e.preventDefault();
    handleSubmit(id);
    onCancel(id);
    return false;
  }

  return (
    <form onSubmit={submit}>
      <div>
        <Field
          inputType='text'
          inputDefaultValue=''
          showInputAdornment={false}
          component={renderTextField}
          name='comment_name'
          placeholder='Comment Name'
        />
      </div>
      <div className='comment-main'>
        <Field
          className='comment-input'
          name={isProjectLevel ? "comment_txt" : "comment_text"}
          component={renderTextField}
          rows='6'
          multiline
          variant='outlined'
          placeholder='Comment Message'
        />
      </div>
      <div className='buttons'>
        <Button
          className='cancel-button'
          type='button'
          onClick={() => onCancel(id)}>
          Cancel
        </Button>
        <Button
          disabled={disableSubmit}
          className='submit-button'
          type='submit'>
          Submit
        </Button>
      </div>
    </form>
  );
};

renderTextField.defaultProps = {
  input: "",
};

renderTextField.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

SubCategoryCommentForm.defaultProps = {
  reply: false,
};

SubCategoryCommentForm.propTypes = {
  reply: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  id: PropTypes.number,
  isProjectLevel: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: "subCategoryCommentForm",
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  validate,
})(SubCategoryCommentForm);
