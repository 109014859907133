import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import * as _ from "lodash";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import renderTextField from "../../../_shared/utils/renderTextField";
import renderSelectField from "../../../_shared/utils/renderSelectField";
import renderDatePicker from "../../../_shared/utils/renderDatePicker";
import { selectProjectStatuses } from "../../projectSelectors";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    width: "100%",
  },
  closingStatus: {
    margin: 0,
  },
}));

const ProjectDetails = props => {
  const classes = useStyles();
  const { closingStatusOptions, form } = props;
  const handleChange = event => {
    let value = event.target.value;

    event.target.value = parseInt(value);
  };
  return (
    <div className={classes.root}>
      <Typography variant='h5' gutterBottom>
        Project Details
      </Typography>
      <Grid
        container
        item
        alignItems='center'
        spacing={2}
        xl={12}
        xs={12}
        lg={12}
        md={12}>
        {/* <Field name="status_code" component={renderTextField} type="hidden" /> */}
        <Grid item xl={12} xs={12} lg={12} md={12}>
          {form !== "projectEdit" && (
            <Field
              name='closing_name'
              component={renderTextField}
              inputLabel='Project Name'
              inputType='text'
              fullWidth
            />
          )}
        </Grid>
        <Grid item xl={6} xs={6} lg={6}>
          <Field
            inputLabel='Projected Closing Date'
            name='closing_start_ts'
            component={renderDatePicker}
            inpuType='date'
            placeholder='MM/DD/YYYY'
            dateFormat='MM/DD/YYYY'
            fullWidth
            // normalize={value => moment(value).valueOf()}
            // format={value => moment(value).format('MM/DD/YYYY')}
          />
        </Grid>
        <Grid item xl={6} xs={6} lg={6} md={6}>
          <Field
            disabled={form === "projectCreate"}
            inputLabel='Closing Status'
            type='text'
            defaultValue={closingStatusOptions[0]}
            showInputAdornment={false}
            component={renderSelectField}
            options={closingStatusOptions}
            name='status_code_desc'
            fullWidth
          />
        </Grid>
        <Grid item xl={4} xs={4} lg={4} md={4}>
          <Field
            inputLabel='Project/Store Sequence #'
            type='number'
            inputDefaultValue={0}
            showInputAdornment={false}
            component={renderTextField}
            name='project_seq_nbr'
            fullWidth
            onChange={handleChange}
            normalize={value => _.toNumber(value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Grid>
        <Grid item xl={4} xs={4} lg={4} md={4}>
          <Field
            inputLabel='Sellers'
            type='number'
            inputDefaultValue={0}
            showInputAdornment={false}
            component={renderTextField}
            name='seller_count'
            onChange={handleChange}
            fullWidth
            normalize={value => _.toNumber(value)}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Grid>
        <Grid item xl={4} xs={4} lg={4} md={4}>
          <Field
            inputLabel='Parcels'
            type='number'
            inputDefaultValue={0}
            showInputAdornment={false}
            component={renderTextField}
            onChange={handleChange}
            name='parcel_count'
            normalize={value => _.toNumber(value)}
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = state => {
  const closingStatusOptions = selectProjectStatuses(state);
  return {
    closingStatusOptions,
  };
};

ProjectDetails.propTypes = {
  closingStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  form: PropTypes.string.isRequired,
};

const ConnectedProjectDetails = connect(mapStateToProps)(ProjectDetails);

export default ConnectedProjectDetails;
