import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import {
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

import { selectUsersByRole, selectUsersWithRoles } from '../../users/usersSelectors';
import { userRoles } from '../../users/userConstants';
import renderSelectField from '../../_shared/utils/renderSelectField';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  closingStatus: {
    margin: 0,
  },
  divroot: {
    flexGrow: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

const mapStateToProps = (state) => {
  const users = selectUsersWithRoles(state);
  return ({
    usersOptions: {
      attorney: selectUsersByRole(users, userRoles.attorney),
      paralegal: selectUsersByRole(users, userRoles.paralegal),
      realEstateManager: selectUsersByRole(users, userRoles.realEstateManager),
      designManager: selectUsersByRole(users, userRoles.designManager),
      auditor: selectUsersByRole(users, userRoles.auditor),
      regionalVicePresident: selectUsersByRole(users, userRoles.regionalVicePresident),
      accountant: selectUsersByRole(users, userRoles.accountant),
      outsideCounsel: selectUsersByRole(users, userRoles.outsideCouncel),
      cecFirm: selectUsersByRole(users, userRoles.cecFirm),
    },
  });
};

const ProjectUsers = ({ usersOptions }) => {
  const classes = useStyles();
  return (
    <div className={classes.divroot}>
    <Grid container spacing={1}>
    <div className={classes.root}>
    
        <Grid container item alignItems="center" spacing={2} xl={12} xs={12} lg={12} md={12}>
        <Grid item xs={1} />
        <Grid item xs={10}>
        <Typography variant="h5" gutterBottom>
          Internal Users
        </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xl={5} xs={5} lg={5}>
            <Field
              inputLabel="Attorney"
              options={usersOptions.attorney}
              component={renderSelectField}
              name="attorney"
            />
        </Grid>
        <Grid item xl={5} xs={5} lg={5}>
            <Field
              inputLabel="Paralegal"
              options={usersOptions.paralegal}
              component={renderSelectField}
              name="paraLegal"
            />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xl={5} xs={5} lg={5}>
            <Field
              inputLabel="Portfolio Manager"
              options={usersOptions.realEstateManager}
              component={renderSelectField}
              name="realEstateManager"
            />
        </Grid>
        <Grid item xl={5} xs={5} lg={5}>         
            <Field
              inputLabel="Design Manager"
              options={usersOptions.designManager}
              component={renderSelectField}
              name="designManager"
            />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xl={5} xs={5} lg={5}>
            <Field
              inputLabel="Auditor"
              options={usersOptions.auditor}
              component={renderSelectField}
              name="auditor"
            />
        </Grid>
        <Grid item xl={5} xs={5} lg={5}>            
            <Field
              inputLabel="Vice President"
              options={usersOptions.regionalVicePresident}
              component={renderSelectField}
              name="regionalVicePresident"
            />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1} />
        <Grid item xl={5} xs={5} lg={5}>            
            <Field
              inputLabel="Accountant"
              options={usersOptions.accountant}
              component={renderSelectField}
              name="accountant"
            />
        </Grid>
        </Grid>

        <Grid container item alignItems="center" spacing={2} xl={12} xs={12} lg={12} md={12}>
        <Grid item xs={12} >
          <Typography variant="h5" gutterBottom className="add-user-title-external">
          External Users
        </Typography>
        </Grid>
        <Grid container item alignItems="center" spacing={2} xl={12} xs={12} lg={12} md={12}>
        <Grid item xs={1} />
        <Grid item xl={5} xs={5} lg={5}> 
            <Field
              inputLabel="Outside Counsel"
              options={usersOptions.outsideCounsel}
              component={renderSelectField}
              name="outsideCouncil"
              isMulti
            />
</Grid>
        <Grid item xl={5} xs={5} lg={5}> 
            <Field
              inputLabel="CEC Firm"
              options={usersOptions.cecFirm}
              component={renderSelectField}
              name="cecFirms"
            />
         </Grid>
    </Grid>
    </Grid>
    
    </div>
    </Grid>
    </div>
  );
};

ProjectUsers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  usersOptions: PropTypes.array,
};
ProjectUsers.defaultProps = {
  usersOptions: [],
};

export default connect(mapStateToProps)(ProjectUsers);
