import {
  ADMIN_WEIGHT,
  MEMBERS_WEIGHT,
  EXTERNAL_USER_WEIGHT,
  AUDITOR_WEIGHT,
  VIEW_ONLY_WEIGHT,
  REL_ADMIN_WEIGHT,
  ADRolesList,
} from "./adGroups";
import _ from "lodash";

export function adminWeight() {
  return ADMIN_WEIGHT;
}

export function memberWeight() {
  return MEMBERS_WEIGHT;
}

export function viewOnlyWeight() {
  return VIEW_ONLY_WEIGHT;
}

export function relAdminWeight() {
  return REL_ADMIN_WEIGHT;
}

export function auditorWeight() {
  return AUDITOR_WEIGHT;
}
export function externalUserWeight() {
  return EXTERNAL_USER_WEIGHT;
}
export function getMaxWeight(values) {
  let weight = -1;
  if (!_.isUndefined(values) && !_.isNull(values)) {
    let weightArray = ADRolesList.filter(r => values.includes(r.value)).map(
      a => a.weight
    );
    weightArray = weightArray.map(Number);
    if (weightArray.length > 0) {
      weight = Math.max(...weightArray);
    }
  }
  return weight;
}

export const getRoleWithMaxWeight = roles => ADRolesList.filter(role => roles.includes(role.value)).reduce((a, b) => (a.weight >= b.weight ? a : b));