import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import _ from 'lodash';
import * as actions from '../commentActions';
import { editCategoryCommentResponse } from '../../../services/comments';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';

const formValueSelector = state => _.get(state, 'form.editCommentForm.values');
const commentSelector = state => _.get(state, 'comments.data');

export default function* editCategoryResponseWatcher() {
  yield takeLatest(actions.EDIT_CATEGORY_COMMENT_RESPONSE, editCategoryResponseWorker);
}

export function* editCategoryResponseWorker(action) {
  const formValues = yield select(formValueSelector);
  const variables = {
    doc_category_id: action.categoryId,
    comment_seq_nbr: formValues.commentId,
    response_txt: formValues.commentText,
    comment_name: formValues.commentTitle,
    response_create_ts: action.commentDate,
  };

  try {
    const response = yield call(editCategoryCommentResponse, variables);
    const data = _.get(response, 'data.editCategoryResponse');
    if (data.Success === 'true') {
      yield call(success, { comment: variables });
    } else {
      yield call(failure, new Error('Something Went Wrong'));
    }
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response) {
  const comments = yield select(commentSelector);
  const updateIndex = _.findIndex(comments,
    {
      doc_category_id: response.comment.doc_category_id,
      comment_seq_nbr: response.comment.comment_seq_nbr,
    });
  const commentToEdit = comments[updateIndex];
  const replyIndex = _.findIndex(commentToEdit.responses,
    {
      response_create_ts: response.comment.response_create_ts,
    });
  commentToEdit.responses[replyIndex].response_txt = response.comment.response_txt;
  yield put(actions.updateComment(updateIndex, commentToEdit));
}

export function* failure(error) {
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: 'Response could not be edited',
      message: 'An error occured while editing the comment response',
    }),
  );
}
