import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import * as modalActions from '../../_shared/modal/modalActions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDiscard: () => {
    dispatch(modalActions.closeModal());
  },
  onStay: () => {
    dispatch(modalActions.closeModal());
    ownProps.onStay();
  },
});

const useStyles = makeStyles(theme => ({
  errorButton: {
    'background-color': theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      'background-color': '#CA2213',
    },
  },
}));

export const CommentPopup = ({
  onStay,
  onDiscard,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <DialogTitle id="responsive-dialog-title">
        Confirm delete
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
         Are you sure you want to delete this comment ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onStay} color="primary">
          Yes
        </Button>
        <Button variant="contained" onClick={onDiscard} className={classes.errorButton}>
          No
        </Button>
      </DialogActions>
    </Fragment>
  );
};

CommentPopup.defaultProps = {
  onStay: _.noop,
  onDiscard: _.noop,
};

CommentPopup.propTypes = {
  onStay: PropTypes.func,
  onDiscard: PropTypes.func,
};

const reduxConnected = connect(null, mapDispatchToProps)(CommentPopup);

export default withRouter(reduxConnected);
