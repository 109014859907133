export const CREATE = 'PROJECTS/CREATE';
export const ERROR = 'PROJECTS/ERROR';
export const FETCH = 'PROJECTS/FETCH';
export const FILTER_UPDATE = 'PROJECTS/FILTER_UPDATE';
export const LOADING = 'PROJECTS/LOADING';
export const SET_DATA = 'PROJECTS/SET_DATA';
export const SET_TOTAL_PROJECTS = 'PROJECTS/SET_TOTAL_PROJECTS';

export const SET_PAGE_DETAILS = 'PROJECTS/SET_PAGE_DETAILS';

export const create = history => ({ type: CREATE, history });
export const error = err => ({ type: ERROR, error: err });
export const fetch = ( pageDetails ) => ({ type: FETCH, pageDetails: pageDetails });
export const loading = isLoading => ({ type: LOADING, loading: isLoading });
export const setData = data => ({ type: SET_DATA, data });
export const setTotalProjects = data => ({ type: SET_TOTAL_PROJECTS, data });

export const setPageDetails = ( pageDetails ) => ({ type: SET_PAGE_DETAILS, pageDetails: pageDetails });

export const filterUpdate = filterObject => ({
  type: FILTER_UPDATE,
  filterObject,
});
