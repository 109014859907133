import _ from 'lodash';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { fetchUsersWithRoles } from '../../../services/users';
import * as actions from '../usersActions';
import { setData } from '../usersReducer';

export default function* fetchUsersWithRolesWatcher() {
  yield takeLatest(actions.FETCH_USERS_WITH_ROLES, fetchUsersWithRolesWorker);
}

export function* fetchUsersWithRolesWorker() {
  yield put(actions.loading(true));
  try {
    const response = yield call(fetchUsersWithRoles);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.fetchAllRoles.Users', []);
  yield put(setData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
