export default [{
  id: 1,
  description: 'Project Reconciliation with Attachments',
}, {
  id: 2,
  description: 'Wire Transfer Authorization Form',
}, {
  id: 3,
  description: 'Settlement Statement(s) (HUD-1)',
}, {
  id: 4,
  description: 'Closing Instruction Letter',
}, {
  id: 5,
  description: 'Title Work',
}, {
  id: 6,
  description: 'Deed(s) and REIT form',
}, {
  id: 7,
  description: 'Purchase Agreement/Lease',
}, {
  id: 8,
  description: 'Development Agreement',
}, {
  id: 9,
  description: 'ECR/Restrictive Covenants&Oper Restrictions Chklst',
}, {
  id: 10,
  description: 'Easement(s)',
}, {
  id: 11,
  description: 'ALTA Survey/Engineer Memo',
}, {
  id: 12,
  description: 'Final Site Plan',
}, {
  id: 13,
  description: 'REC/Re-approved Site Plan',
}, {
  id: 14,
  description: 'Other Document(s)',
}, {
  id: 15,
  description: 'Environmental',
}, {
  id: 16,
  description: 'Geotechnical Report(s)',
}, {
  id: 17,
  description: 'OPC/Building budget',
}, {
  id: 18,
  description: 'Appraisals',
}, {
  id: 19,
  description: 'Entitlements',
}, {
  id: 20,
  description: 'Auditors',
}];
