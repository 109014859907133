import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import _ from 'lodash';
import { updateProject } from '../../../services/projects';
import * as actions from '../projectActions';

export default function* archiveProjectWatcher() {
  yield takeLatest(actions.ARCHIVE, archiveProjectWorker);
}

export function* archiveProjectWorker(action) {
  const { id, history } = action;
  const variables = {
    id,
    closingStatus: 'Deleted',
  };

  try {
    const response = yield call(updateProject, variables);
    yield call(success, response, history);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, history) {
  const data = _.get(response, 'data.updateProject', {});
  const { id, closingStatus } = data;

  if (id && closingStatus) {
    yield put(actions.setData({ id, closingStatus }));
    yield call(history.replace('/projects'));
  }
}

export function* failure(error) {
  yield put(actions.error(error));
}
