/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isDirty, reset, isInvalid, reduxForm } from "redux-form";
import * as _ from "lodash";
import ProjectDetails from "../shared/projectDetails/ProjectDetailsContainer";
import { selectProject, mapProjectDataToUI } from "../projectSelectors";
import * as modalActions from "../../_shared/modal/modalActions";
import ProjectEditDiscardModalContent from "./ProjectEditDiscardModalContent";
import { edit, fetch as fetchProject } from "../projectActions";
import { validateProjectDetails } from "../shared/ValidateProjectDetails";
import ProjectEditContainer from "./ProjectEditContainer";

let EditProject = ({
  isLoading,
  history,
  openConfirmModal,
  isFormDirty,
  projectId,
  resetForm,
  triggerSubmit,
  isFormInvalid,
  form,
  fetchProjectDetails,
  projectData,
}) => {
  useEffect(() => {
    if (!projectData || projectData.length < 1) {
      // eslint-disable-next-line radix
      fetchProjectDetails(parseInt(projectId));
    }
  }, [projectData]);
  const onCancel = () => {
    resetForm();
    history.replace(`/projects/${projectId}`);
  };
  const onClose = () => {
    if (isFormDirty) {
      openConfirmModal(<ProjectEditDiscardModalContent onDiscard={onCancel} />);
    } else {
      onCancel();
    }
  };
  return (
    <React.Fragment>
      <ProjectEditContainer
        projectTitle={projectData && projectData.projectDetails ? projectData.projectDetails.closing_name : ''}
        isLoading={isLoading}
        isFormDirty={isFormDirty}
        isFormInvalid={isFormInvalid}
        onSave={triggerSubmit}
        onCancel={onClose}>
        <ProjectDetails form={form} />
      </ProjectEditContainer>
    </React.Fragment>
  );
};
EditProject.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  openConfirmModal: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  resetForm: PropTypes.func.isRequired,
  triggerSubmit: PropTypes.func.isRequired,
  isFormInvalid: PropTypes.bool.isRequired,
  form: PropTypes.string.isRequired,
  fetchProjectDetails: PropTypes.func.isRequired,
  projectData: PropTypes.array.isRequired,
};
const mapStateToProps = (state, ownProps) => ({
  projectData: selectProject(state),
  isFormDirty: isDirty("projectEdit")(state),
  isFormInvalid: isInvalid("projectEdit")(state),
  projectId: _.get(ownProps, "match.params.projectId", null),
  isLoading: _.get(state, "projectDetails.request.loading", false),
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  openConfirmModal: body => dispatch(modalActions.openModal({ body })),
  resetForm: () => dispatch(reset("projectEdit")),
  triggerSubmit: () => dispatch(edit(ownProps.history)),
  fetchProjectDetails: closingId => dispatch(fetchProject(closingId)),
});

EditProject = connect(mapStateToProps, mapDispatchToProps)(EditProject);
export default connect(
  state => ({
    initialValues: mapProjectDataToUI(selectProject(state)),
  }),
  null,
)(
  reduxForm({
    form: "projectEdit",
    validate: validateProjectDetails,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmitFail: () => {
      window.scrollTo(0, 0);
    },
  })(EditProject),
);
