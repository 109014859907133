import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchDocumentSubTypes } from "../../../services/document";
import * as actions from "../documentActions";

export default function* fetchDocSubTypesWatcher() {
  yield takeLatest(actions.FETCH_DOCUMENT_SUB_TYPES, fetchDocSubTypesWorker);
}

export function* fetchDocSubTypesWorker(action) {
  yield put(actions.resetDocumentSubTypes());
  yield put(actions.loading(true));
  try {
    const response = yield call(fetchDocumentSubTypes, action.docType);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));
  const data = _.get(response, "data.fetchSubTypes.DocSubTypesList", []);
  yield put(actions.setDocumentSubTypes(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
