import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import * as actions from "../commentActions";
import { replyToCategoryComment } from "../../../services/comments";

const formValueSelector = state => _.get(state, "form.replyCommentForm.values");
const commentsSelector = state => _.get(state, "comments.data", []);
const loggedInUserSelector = state => _.get(state, "session.user.data");

export default function* createCategoryCommentReplyWatcher() {
  yield takeLatest(
    actions.CREATE_CATEGORY_COMMENT_REPLY,
    createCategoryCommentReplyWorker,
  );
}

export function* createCategoryCommentReplyWorker(action) {
  const { categoryId, commentSeqNbr } = action;
  const formValues = yield select(formValueSelector);
  const loggedInUserData = yield select(loggedInUserSelector);

  let responseId = "eUserId";
  if (loggedInUserData) {
    responseId = loggedInUserData.loginId;
  }

  const variables = {
    doc_category_id: categoryId,
    comment_seq_nbr: commentSeqNbr,
    response_txt: formValues.response_txt,
    response_userid: responseId,
    response_create_ts: moment().utc(),
  };

  try {
    const response = yield call(replyToCategoryComment, variables);
    const data = _.get(response, 'data.createCategoryResponse');
    if (data.Success === 'true') {
      variables.user_full_name = loggedInUserData.name.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
      yield call(success, variables);
    } else {
      yield call(failure, new Error('Something Went Wrong'));
    }
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response) {
  const comments = yield select(commentsSelector);
  const updateIndex = _.findIndex(comments, {
    comment_seq_nbr: response.comment_seq_nbr,
    doc_category_id: response.doc_category_id,
  });
  const responses = [...comments[updateIndex].responses, response];
  yield put(actions.updateComment(updateIndex, { responses }));
}

export function* failure(error) {
  alert("Could not add reply");
  yield put(actions.error(error));
}
