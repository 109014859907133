import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography/index';
import './WelcomeHeader.scss';

export const renderSubtitle = (subtitle) => {
  if (subtitle.trim().length > 0) {
    return (
      <Typography
        variant="subtitle1"
        className="subtitle"
      >
        {subtitle}
      </Typography>
    );
  }
  return null;
};

const WelcomeHeader = (props) => {
  const { title, subtitle } = props;

  return (
    <div className="welcome-header">
      <Typography
        variant="h4"
        className="title"
      >
        {title}
      </Typography>
      {renderSubtitle(subtitle)}
    </div>
  );
};

WelcomeHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

WelcomeHeader.defaultProps = {
  subtitle: '',
};

export default WelcomeHeader;
