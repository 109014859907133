import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import _ from 'lodash';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as modalActions from '../../_shared/modal/modalActions';
import { lock } from '../projectActions';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onConfirm: () => {
    dispatch(modalActions.closeModal());
    dispatch(lock(ownProps.projectID, ownProps.history, ownProps.projectOpen));
  },
  onCancel: () => dispatch(modalActions.closeModal()),
});

export const ProjectLockModalContent = ({
  onCancel,
  onConfirm,
  projectName,
}) => (
  <Fragment>
    <DialogTitle id="responsive-dialog-title">
      Lock {projectName}?
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        You are about to lock project {projectName}. <br />
        Once the project is locked, users will be able to view the
        contents of the project but not make edits or upload new documents.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        Cancel
      </Button>
      <Button variant="contained" onClick={onConfirm} color="primary">
        Lock
      </Button>
    </DialogActions>
  </Fragment>
);

ProjectLockModalContent.defaultProps = {
  onCancel: _.noop,
  onConfirm: _.noop,
  projectName: '',
};

ProjectLockModalContent.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  projectName: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(ProjectLockModalContent);
