import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#eb148d' },
    secondary: { main: '#eb148d' },
    error: { main: '#e62716' },
    action: {
      // active: '#e0f6ff',
    },
  },
  typography: {
    fontFamily: [
      'Bogle',
      'Arial',
      'Sans-Serif',
    ].join(','),
  },
});

export default theme;
