import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MomentFnsUtils from '@date-io/moment';
import { createMuiTheme, Typography } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { ThemeProvider } from '@material-ui/styles';
import styles from '../../../styles/utils/config.scss';
import datePickerStyles from './DatePicker.module.scss';

const materialTheme = createMuiTheme({
  palette: {
    primary: { main: '#3f51bf' },
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiTextField: {
      root: {
        margin: '0'
      }
    },
    MuiInputBase: {
      formControl: {
        borderColor: styles.black38opacityColor,
        '&:hover': {
          borderColor: styles.black54opacityColor
        }
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: styles.primaryColor,
        '&:hover': {
          backgroundColor: styles.primaryColor
        }
      }
    }
  }
});

const DatePicker = ({
  dateFormat,
  onChange,
  inputLabel,
  placeholder,
  value,
  invalidDateMessage,
  disabled,
  inputProps,
}) => (
    <div className="form-item">
      <div className="label">
        <Typography variant="caption">
          {inputLabel}
        </Typography>
      </div>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={MomentFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            format={dateFormat}
            inputVariant="outlined"
            variant="inline"
            value={value || null}
            onChange={onChange}
            invalidDateMessage={invalidDateMessage}
            placeholder={placeholder}
            disabled={disabled}
            inputProps={inputProps}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );

DatePicker.defaultProps = {
  dateFormat: 'MM-DD-YYYY',
  onChange: null,
  inputLabel: '',
  placeholder: 'MM-DD-YYYY',
  invalidDateMessage: '',
  value: moment().valueOf(),
  disabled: false,
};

DatePicker.propTypes = {
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  inputLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  invalidDateMessage: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
};

export default DatePicker;
