import moment from "moment";
import { getValueWithoutLeadingSpaces } from "../../../utilities";
import _ from "lodash";

export const validateDocInfo = values => {
  const errors = {};
  if (!values.object_name) {
    errors.object_name = "Document name required";
  } else if (getValueWithoutLeadingSpaces(values.object_name) === "") {
    errors.object_name = "Document name only contains white spaces";
  }
  if (!values.doc_type) {
    errors.doc_type = "Document type required";
  }
  if (!values.doc_subtype_desc) {
    errors.doc_subtype_desc = "Document sub type required";
  }
  return errors;
};
export const validateUploadDocFields = values => {
  const errors = validateDocInfo(values);
  const allowedExtensions = /(\.pdf)$/i;
  if (!values.document_file || values.document_file.length < 1) {
    errors.document_file = "Please select a pdf file";
  } else if (!allowedExtensions.exec(values.document_file[0].name)) {
    errors.document_file = "Only pdf files are allowed";
  }
  if (values.ecm_attr_doc_date && !moment(values.ecm_attr_doc_date).isValid()) {
    errors.ecm_attr_doc_date = "Invalid Date";
  }
  return errors;
};

export const validateUploadDocVersionFields = values => {
  const errors = validateDocInfo(values);
  const allowedExtensions = /(\.pdf)$/i;
  if (!values.document_file || values.document_file.length < 1) {
    errors.document_file = "Please select a pdf file";
  } else if (!allowedExtensions.exec(values.document_file[0].name)) {
    errors.document_file = "Only pdf files are allowed";
  }
  return errors;
};

export const validateMetdataInfo = values => {
  const errors = validateDocInfo(values);

  if (
    values.doc_status != "In Progress" &&
    values.ecm_attr_doc_date &&
    !moment(values.ecm_attr_doc_date).isValid()
  ) {
    errors.ecm_attr_doc_date = "Invalid Date";
  }
  if (
    values.doc_status === "Public Viewable" &&
    (_.isNull(values.ecm_attr_doc_date) ||
      _.isEmpty(values.ecm_attr_doc_date) ||
      _.isUndefined(values.ecm_attr_doc_date))
  ) {
    errors.ecm_attr_doc_date = "Required";
  }

  return errors;
};
