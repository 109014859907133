import _ from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchProjectDetailsById } from '../../../services/projects';
import * as actions from '../projectActions';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';

export default function* fetchProjectDetailsWatcher() {
  yield takeLatest(actions.FETCH, fetchProjectDetailsWorker);
}

export function* fetchProjectDetailsWorker(action) {
  yield put(actions.loading(true));

  try {
    let response = yield call(fetchProjectDetailsById, {
      closing_id: action.closing_id,
    });
    response = _.get(response, 'data.fetchProjectDetails', {});
    if (response.Success) {
      yield call(success, response);
    } else {
      yield call(failure, new Error(response.Message));
    }
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));
  const data = { projectDetails: response.projectDetails, storeDetails: response.storeDetails };
  yield put(actions.setData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: 'Project details could not be fetched',
      message: error.message,
    }),
  );
}
