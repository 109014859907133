import "isomorphic-fetch";
import config from "./config";
import _ from "lodash";
import { sessionService } from "redux-react-session";
// import SimpleCrypto from "simple-crypto-js";
import { fetchPasswordExpiryDays } from "../services/users/index";
import axios from "axios";
import CryptoJS from 'crypto-js';
import _config from "../config"
import Cookies from 'js-cookie'

const fetchDetails = (extUser) => {
  // const _secretKey = process.env.ENCRYPTION_KEY;
  // var simpleCrypto = new SimpleCrypto(_secretKey);
  let promise = new Promise((resolve, reject) => {
    fetch(`${_config.BASE_URL}fetchDetails?extUser=${extUser}`, {
      credentials: `include`,
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("res", res);
        if (res.status === 200) {
          try {
            localStorage.setItem("alertCount", 0);
            // localStorage.setItem(config.SSO_extUser_Ind, 0); //setting default ext users flag to 0

            //setting default ext users flag to 0,  
            // moved to cookie from local storage
            Cookies.set(config.SSO_extUser_Ind,0);
            
            console.log("config.SSO_extUser_Ind i", Cookies.get(config.SSO_extUser_Ind));

            localStorage.setItem(
              config.SSO_Session_Idx,
              res.profile.sessionIndex,
              // simpleCrypto.encrypt(res.profile.sessionIndex)
            );
            localStorage.setItem(
              config.SSO_user_name,
              res.profile.sn ? res.profile.givenName + " " + res.profile.sn : res.profile.givenName
            );
            localStorage.setItem(config.SSO_user_loginId, res.profile.nameID);
            localStorage.setItem(config.SSO_signOutUrl, res.signOutUrl);

            const sesIdx = localStorage.getItem(config.SSO_Session_Idx);

            // var adRolesString = res.profile.memberOf;
            // if (_.isEmpty(adRolesString)) {
            //   adRolesString = [];
            // } else if (typeof adRolesString === "string") {
            //   adRolesString = JSON.parse(res.profile.memberOf);
            // }
            if (res.profile.extUserInfo && res.profile.extUserInfo.length > 0) {
              //setting up information for external users
              // fetch password expiry days
              // const passwordExpiry = {
              //   passwordExpiryDays: null,
              //   error: null,
              // };
              const authStatus = res.profile.authState;
              console.log("IAM Auth Status of user" + authStatus);
              // try {
              //   const pwdExpResponse = await fetchPasswordExpiryDays({
              //     userId: localStorage.getItem(config.SSO_user_loginId),
              //   });
              //   console.log("pwdExpResponse ", pwdExpResponse);
              //   const data = _.get(
              //     pwdExpResponse,
              //     "data.fetchPasswordExpiryDays"
              //   );
              //   if (data.Success === "true") {
              //     if (!Number.isNaN(parseInt(data.result, 10))) {
              //       passwordExpiry.passwordExpiryDays = parseInt(
              //         data.result,
              //         10
              //       );
              //     }
              //   } else {
              //     passwordExpiry.error = data.result;
              //   }
              // } catch (error) {
              //   console.log("fetchPasswordExpiryDays exception ", error);
              //   passwordExpiry.error = error;
              // }

              res.profile.extUserInfo[0].authStatus = authStatus;

              const extUser = res.profile.extUserInfo[0];
              // localStorage.setItem(config.SSO_extUser_Ind,extUser.external_user_ind);

              Cookies.set(config.SSO_extUser_Ind,extUser.external_user_ind);
              
              console.log("config.SSO_extUser_Ind n", Cookies.get(config.SSO_extUser_Ind));

              //indicates that the logged in user is external...will always be 1
              const roleID = extUser.role_id;
              const extRole = roleID === 2 ? ["osc firm"] : ["cec firm"]; //2 indicates OSC firm...15 would be CEC
              localStorage.setItem(config.SSO_user_adGroups,
                extRole
                // simpleCrypto.encryptObject(extRole),
              );
            } else {
              localStorage.setItem(config.SSO_user_adGroups,res.profile.memberOf);
            }

            const user = {
              sesIdx: sesIdx,
              data: {
                name: localStorage.getItem(config.SSO_user_name),
                loginId: localStorage.getItem(config.SSO_user_loginId),
                userRole: localStorage.getItem(config.SSO_user_adGroups),
                extUserInfo: res.profile.extUserInfo,
              },
            };

            sessionService.saveSession({ sesIdx }).then(() => {
              sessionService.saveUser(user);
            });
          } catch (error) {
            console.error("error when setting ls props", error);
          }
        } else if (
          res.status === 401 &&
          localStorage.getItem(config.fetch_details_param) === "false"
        ) {
          console.log("not an external user, going to redirect to pingfed URL");
          localStorage.setItem(config.SSO_signInUrl, res.signInUrl);
          window.location = res.signInUrl;
        }
        else if (
          res.status === 401 &&
          localStorage.getItem(config.fetch_details_param) === null
        ){
          window.location = '/preLogin';
        } else {
          return res;
        }
        resolve(res);
      })
      .catch((err) => {
        // incase error, removeItem the ls props
        try {
          localStorage.removeItem(config.SSO_Session_Idx);
          localStorage.removeItem(config.SSO_user_name);
          localStorage.removeItem(config.SSO_user_adGroups);
          localStorage.removeItem(config.SSO_user_loginId);
          localStorage.removeItem(config.SSO_signOutUrl);
          localStorage.removeItem(config.fetch_details_param) 
          localStorage.removeItem("alertCount");
        } catch (error) {
          console.error("error when removing ls props", error);
        }
        reject(err);
      });
  });
  return promise;
};

const signIn = (extUser) => {
  let promise = new Promise((resolve, reject) => {
    const sessionIndex = localStorage.getItem(config.SSO_Session_Idx);

    if (isTokenValid) {
      if (
        _.isUndefined(sessionIndex) ||
        _.isNull(sessionIndex) ||
        _.isEmpty(sessionIndex) ||
        sessionIndex == ""
      ) {
        fetchDetails(extUser)
          .then(() => {
            resolve({
              status: "success",
              message: "fetch Details returned result successfully",
            });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        resolve({
          status: "success",
          message: "user already authenticated",
        });
      }
    } else {
      signOut();
    }
  });
  return promise;
};

const signOut = () => {
  sessionService.deleteSession(); //delete user session
  sessionService.deleteUser();
  let signOutUrl;

  const extUserFlag = localStorage.getItem(config.fetch_details_param); 
  let promise = new Promise((resolve, reject) => {
    fetch(
      `${_config.BASE_URL}logout`,
      {
        credentials: `include`,
      }
    )
      .then((response) => response.json())
      .then((res) => {
        signOutUrl = localStorage.getItem("sso_signOut_url");
        // removing ls props
        try {
          localStorage.removeItem(config.SSO_Session_Idx);
          localStorage.removeItem(config.SSO_user_name);
          localStorage.removeItem(config.SSO_user_adGroups);
          localStorage.removeItem(config.SSO_user_loginId);
          localStorage.removeItem(config.SSO_signOutUrl);
          localStorage.removeItem(config.SSO_signInUrl);
          localStorage.removeItem(config.fetch_details_param);
          localStorage.removeItem("alertCount");
        } catch (error) {
          console.error("error when removing ls props", error);
          reject({
            status: "failure",
            message: "error when removing ls props",
          });
        }
        if(extUserFlag === "true"){
          window.location = '/preLogin';
        }else{
          window.location = signOutUrl;
        }
      });
  });
  return promise;
};

const isTokenValid = () => {
  const ls_ttl = parseInt(localStorage.getItem(config.SSO_token_ttl), 10);

  if (!ls_ttl) {
    return false;
  } else if (ls_ttl && new Date() > new Date(ls_ttl)) {
    return false;
  } else {
    return true;
  }
};

const redirectToSignInUrl = (signInUrl) => {
  const alertCount = localStorage.getItem("alertCount");
  if (alertCount === "0") {
    localStorage.setItem("alertCount", 1);
    alert("Your session timed out. All your unsaved progress would be lost.");
  }
  const localStor_singInUrl = localStorage.getItem(config.SSO_signInUrl); 
  const extUserFlag = localStorage.getItem(config.fetch_details_param); 
  try {
    sessionService.deleteSession(); //delete user session
    sessionService.deleteUser();

    localStorage.removeItem(config.SSO_Session_Idx);
    localStorage.removeItem(config.SSO_user_name);
    localStorage.removeItem(config.SSO_user_adGroups);
    localStorage.removeItem(config.SSO_user_loginId);
    localStorage.removeItem(config.SSO_signOutUrl);
    localStorage.removeItem(config.fetch_details_param); 
    localStorage.clear();
    
    var redirectUrl;
    if(signInUrl){
      redirectUrl = signInUrl;
    }else {
      redirectUrl = extUserFlag ? '/preLogin': localStor_singInUrl;
    }
    
    window.location = redirectUrl;

  } catch (error) {
    console.error("error when removing ls props", error);
  }
};

const signInExternalUsers = (username, pwExtuser) => {
  console.log("external users clicked");

  // var payload = {
  //   username: username,
  //   password: CryptoJS.AES.encrypt(pwExtuser, process.env.ENCRYPTION_KEY).toString(),
  // };

  var payload = {
    username: username,
    password: pwExtuser
  };

  return axios
    .post(`${config.express_base_url}loginExtUser`, payload, {
      withCredentials: true,
    })
    .then((res) => {
      console.log(res);
      const response = res;
      if (response.data.status === 200) {
       
        window.location = response.data.redirectURL;
      } else {
        return response.data;
      }
    })
    .catch((err) => {
      console.log(err);
      const error = "An error occured while trying to login!"
      var res ={
        status: 500,
        error: error
      }
      return res;
    });
};

export default {
  fetchDetails: fetchDetails,
  signIn: signIn,
  signOut: signOut,
  redirectToSignInUrl: redirectToSignInUrl,
  signInExternalUsers: signInExternalUsers,
};
