import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import _ from 'lodash';
import * as actions from '../commentActions';
import { deleteCategoryCommentResponse } from '../../../services/comments';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';

const commentSelector = state => _.get(state, 'comments.data');

export default function* deleteCategoryResponseWatcher() {
  yield takeLatest(actions.DELETE_CATEGORY_COMMENT_RESPONSE, deleteCategoryResponseWorker);
}

export function* deleteCategoryResponseWorker(action) {
  const { categoryId, sequenceNumber, commentDate } = action;
  const variables = {
    doc_category_id: categoryId,
    comment_seq_nbr: sequenceNumber,
    response_create_ts: commentDate,
  };

  try {
    const response = yield call(deleteCategoryCommentResponse, variables);
    const data = _.get(response, 'data.deleteCategoryResponse');
    if (data.Success === 'true') {
      yield call(success, variables);
    } else {
      yield call(failure, new Error('Something Went Wrong'));
    }
  } catch (err) {
    yield call(failure, err);
  }
}

export function* success(response) {
  const comments = yield select(commentSelector);
  const removeIndex = _.findIndex(comments,
    {
      doc_category_id: response.doc_category_id,
      comment_seq_nbr: response.comment_seq_nbr,
    });
  const commentToEdit = comments[removeIndex];
  commentToEdit.responses = _.filter(commentToEdit.responses,
    res => res.response_create_ts !== response.response_create_ts);
  yield put(actions.updateComment(removeIndex, commentToEdit));
}

export function* failure(error) {
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: 'Response could not be deleted',
      message: 'An error occured while deleting the response',
    }),
  );
  return error;
}
