import _ from "lodash";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { createDocument } from "../../../services/document";
import * as actions from "../documentActions";
import { openSnackbar } from "../../_shared/snack-bar/snackbarActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";
import * as projectCategoryActions from "../../project/projectCategoryActions";
import { fetch as fetchProject } from "../../project/projectActions";

const formValueSelector = state =>
  _.get(state, "form.uploadNewDocument.values");
const documentSubTypeSelector = state =>
  _.get(state, "document.documentSubTypes", {});
const documents = state => _.get(state, "document.data", []);
const categories = state => _.get(state, "projectCategories.data", []);

export default function* uploadDocumentWatcher() {
  yield takeLatest(actions.UPLOAD, uploadDocumentWorker);
}

export function* uploadDocumentWorker(action) {
  yield put(actions.loading(true));
  const documentSubTypes = yield select(documentSubTypeSelector);
  const formValues = yield select(formValueSelector);
  try {
    const response = yield call(
      createDocument,
      formValues,
      action.data,
      documentSubTypes[0].rel_attr_desc,
    );
    yield call(success, response, action.data, formValues);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, param, formValues) {
  yield put(actions.loading(false));
  if (response.status == 200) {
    yield put(
      openSnackbar({
        message: response.message,
        showCloseIcon: true,
      }),
    );

    if (param.resetForm) param.resetForm();
    yield put(actions.closeUploadDocumentModal());

    const documentsColletion = yield select(documents);
    let newDoc = {
      REL_doc_ind: "N",
      closing_doc_name: formValues.object_name.trim(),
      closing_id: param.projectId,
      doc_category_id: param.categoryId,
      doc_type_code: 1,
      documentum_doc_id: response.documentId,
      last_change_ts: new Date().toString(),
      last_change_userid: response.userName,
      slip_sheet_ind: "N",
      slip_sheet_userid: null,
    };
    documentsColletion.unshift(newDoc);
    yield put(actions.setData(documentsColletion));

    const categoriesCollection = yield select(categories);
    categoriesCollection.map(cat => {
      if (cat.doc_category_id == param.categoryId) {
        let doc_count = cat.doc_count + 1;
        cat.doc_count = doc_count;
      }
      return cat;
    });

    yield put(projectCategoryActions.setData(categoriesCollection));
    yield put(fetchProject(parseInt(param.projectId)));

  } else {
    yield put(
      errorOpenModal({
        title: "Document Could Not Be Uploaded!",
        message: response.message,
      }),
    );
  }
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
