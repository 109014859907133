import _ from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';
import { fetchDocumentByProjectCat } from '../../../services/document';
import * as actions from '../documentActions';
import { convertData } from '../../../utilities';

export default function* fetchDocByProjectCatWatcher() {
  yield takeLatest(actions.FETCH_DOC_PROJ_CAT, fetchDocByProjectCatWorker);
}

export function* fetchDocByProjectCatWorker(action) {
  yield put(actions.loading(true));

  try {
    const response = yield call(
      fetchDocumentByProjectCat,
      action.closing_id,
      action.doc_cat_id
    );

    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.documentsByProjectAndCategoryId', {});

  const convertedData = _.map(convertData(data), extraData => ({
    ...extraData
  }));

  yield put(actions.setData(convertedData));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
