import gql from "graphql-tag";

export const CREATE_COMMENT = gql`
  mutation CreateProjectComment(
    $closing_id: Int!
    $comment_userid: String
    $comment_txt: String
    $comment_name: String
  ) {
    createProjectComment(
      projectComment: {
        closing_id: $closing_id
        comment_userid: $comment_userid
        comment_txt: $comment_txt
        comment_name: $comment_name
      }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const CREATE_CATEGORY_COMMENT = gql`
  mutation createCategoryComment(
    $doc_category_id: Int!
    $comment_userid: String
    $comment_text: String
    $comment_name: String
  ) {
    createCategoryComment(
      categoryComment: {
        doc_category_id: $doc_category_id
        comment_userid: $comment_userid
        comment_text: $comment_text
        comment_name: $comment_name
      }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const DELETE_CATEGORY_COMMENT = gql`
  mutation deleteCategoryComment(
    $doc_category_id: Int!
    $comment_seq_nbr: Int!
  ) {
    deleteCategoryComment(
      categoryComment: {
        doc_category_id: $doc_category_id
        comment_seq_nbr: $comment_seq_nbr
      }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const FETCH_PROJECT_COMMENTS = gql`
  query FetchCommentsByClosingId($closingId: Int!) {
    fetchCommentsByClosingId(closingId: $closingId) {
      Comments {
        closing_id
        seq_nbr
        create_ts
        comment_userid
        comment_txt
        comment_name
        user_full_name
      }
    }
  }
`;

export const FETCH_CATEGORY_COMMENTS = gql`
  query FtchCommentsByCategoryId($categoryId: Int!) {
    fetchCommentsByCategoryId(categoryId: $categoryId) {
      Comments {
        doc_category_id
        comment_seq_nbr
        create_ts
        comment_userid
        comment_text
        comment_name
        cmt_complete_ts
        user_full_name
        responses {
          comment_seq_nbr
          doc_category_id
          response_create_ts
          response_userid
          response_txt
          user_full_name
        }
      }
    }
  }
`;

export const EDIT_CATEGORY_COMMENT = gql`
  mutation editCategoryComment(
    $doc_category_id: Int!
    $comment_seq_nbr: Int!
    $comment_text: String
    $comment_name: String
  ) {
    editCategoryComment(
      categoryComment: {
        doc_category_id: $doc_category_id
        comment_seq_nbr: $comment_seq_nbr
        comment_text: $comment_text
        comment_name: $comment_name
      }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const EDIT_PROJECT_COMMENT = gql`
  mutation editProjectComment(
    $closing_id: Int!
    $seq_nbr: Int!
    $comment_txt: String
    $comment_name: String
  ) {
    editProjectComment(
      projectComment: {
        closing_id: $closing_id
        seq_nbr: $seq_nbr
        comment_txt: $comment_txt
        comment_name: $comment_name
      }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const DELETE_PROJECT_COMMENT = gql`
  mutation deleteProjectComment($closing_id: Int!, $seq_nbr: Int!) {
    deleteProjectComment(
      projectComment: { closing_id: $closing_id, seq_nbr: $seq_nbr }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const RESOLVE_COMMENT = gql`
  mutation resolveCategoryComment(
    $doc_category_id: Int!
    $comment_seq_nbr: Int!
  ) {
    toggleResolveCategoryComment(
      categoryComment: {
        doc_category_id: $doc_category_id
        comment_seq_nbr: $comment_seq_nbr
        cmt_complete_ts: ""
      }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const UN_RESOLVE_COMMENT = gql`
  mutation unResolveCategoryComment(
    $doc_category_id: Int!
    $comment_seq_nbr: Int!
  ) {
    toggleResolveCategoryComment(
      categoryComment: {
        doc_category_id: $doc_category_id
        comment_seq_nbr: $comment_seq_nbr
        cmt_complete_ts: "PRESENT"
      }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const REPLY_TO_CATEGORY_COMMENT = gql`
  mutation createCategoryResponse(
    $doc_category_id: Int!
    $comment_seq_nbr: Int!
    $response_userid: String
    $response_txt: String
    $response_create_ts: String
  ) {
    createCategoryResponse(
      categoryResponse: {
        doc_category_id: $doc_category_id
        comment_seq_nbr: $comment_seq_nbr
        response_userid: $response_userid
        response_txt: $response_txt
        response_create_ts: $response_create_ts
      }
    ) {
      Success
      SeqNbr
    }
  }
`;

export const FETCH_ALL_CATEGORY_COMMENTS = gql`
  query fetchAllCategoryCommentsByClosingId($closingId: Int!) {
    fetchAllCategoryCommentsByClosingId(closingId: $closingId) {
      Comments {
        doc_category_id
        comment_seq_nbr
        create_ts
        comment_userid
        comment_text
        comment_name
        cmt_complete_ts
        user_full_name
        responses {
          comment_seq_nbr
          doc_category_id
          response_create_ts
          response_userid
          response_txt
          user_full_name
        }
      }
    }
  }
`;
export const DELETE_CATEGORY_COMMENT_RESPONSE = gql`
  mutation deleteCategoryCommentResponse(
    $doc_category_id: Int!
    $comment_seq_nbr: Int!
    $response_create_ts: String!
  ) {
    deleteCategoryResponse(
      categoryResponse: {
        doc_category_id: $doc_category_id
        comment_seq_nbr: $comment_seq_nbr
        response_create_ts: $response_create_ts
      }
    ) {
      Success
      SeqNbr
    }
  }
`;
export const EDIT_CATEGORY_COMMENT_RESPONSE = gql`
  mutation editCategoryCommentResponse(
    $doc_category_id: Int!
    $comment_seq_nbr: Int!
    $response_create_ts: String!
    $response_txt: String!
  ) {
    editCategoryResponse(
      categoryResponse: {
        doc_category_id: $doc_category_id
        comment_seq_nbr: $comment_seq_nbr
        response_create_ts: $response_create_ts
        response_txt: $response_txt
      }
    ) {
      Success
      SeqNbr
    }
  }
`;
