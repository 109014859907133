/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import _ from "lodash";

import Checkbox from "@material-ui/core/Checkbox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/MoreVert";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "../../../styles/utils/config.scss";
import { adminWeight, getMaxWeight } from "../../../roleaccess/utils";
import DeleteConfirmationPopup from "../../document/DeleteConfirmationPopup";
import { openModal } from "../../_shared/modal/modalActions";

import * as actions from "../projectActions";
import { deleteDocument } from "../../document/documentActions";
import { downloadDocument } from "../../document/utils";
import { formatDateAndTime } from "../../../utilities";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  hoverDocName: {
    "&:hover": {
      color: styles.primaryColor,
    },
  },
  loadingSpinner: {
    marginLeft: 15,
    marginTop: 1,
  },
  docRow: {
    '&:hover': {
      backgroundColor: styles.focusedSelectBackgroundRowColor,
      boxSizing: 'border- box',
      boxShadow: '0 2px 4px 0',
    },
  },
  cell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const columnHeaders = [
  "Document Name",
  "Last Modified",
  "By",
  "Actions",
];

const mapDispatchToProps = dispatch => ({
  isMetaDataView: (metaDataView, document) =>
    dispatch(actions.setMetaDataView(metaDataView, document)),
  openConfirmModal: body => dispatch(openModal({ body })),
  deleteDoc: (documentId, closingId) =>
    dispatch(deleteDocument(documentId, closingId)),
});

export const ProjectDocumentsTable = ({
  documents,
  handleSorting,
  sortDirection,
  openErrorModel,
  isMetaDataView,
  openConfirmModal,
  userRole,
  deleteDoc,
  statusCode,
}) => {
  const classes = useStyles();

  // const [sortedDocuments, setSortedDocuments] = useState(documents);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [
    selectedDocumentForDownload,
    setSelectedDocumentForDownload,
  ] = useState(null);

  const handleMenuOpen = (event, document) => {
    setSelectedDocument(document);
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const canDeleteProject = getMaxWeight(userRole) == adminWeight();

  const showDeleteConfirmationPopup = (
    documentId,
    closingId,
    closing_doc_name,
  ) => {
    openConfirmModal(
      <DeleteConfirmationPopup
        onStay={() => confirmDelete(documentId, closingId, closing_doc_name)}
        onDiscard={handleMenuClose}
        documentName={closing_doc_name}
      />,
    );
  };

  const confirmDelete = (documentId, closingId) => {
    deleteDoc(documentId, closingId);
    handleMenuClose();
  };

  function openMetaDataView(document) {
    isMetaDataView(true, document);
  }
  function renderCell(column, sortDirection) {
    if (column == "Actions") {
      return (
        <TableCell align='center' key={column}>
          <TableSortLabel>{column}</TableSortLabel>
        </TableCell>
      );
    } else {
      return (
        <TableCell key={column} onClick={() => handleSorting(column)}>
          <TableSortLabel direction={sortDirection}>{column}</TableSortLabel>
        </TableCell>
      );
    }
  }

  const requestDownloadDocument = (
    document,
    isLatestVersion,
    openErrorModel,
    setLoading,
  ) => {
    downloadDocument(
      document.documentum_doc_id,
      isLatestVersion,
      openErrorModel,
      setLoading,
    );
  };

  const renderDocNameCell = document => {
    let isLoading = false;
    if (selectedDocumentForDownload) {
      isLoading = true;
    }

    function setLoading(value, documentId) {
      isLoading = value;
      setSelectedDocumentForDownload(documentId);
    }

    return (
      <TableCell component='th' scope='row'>
        <Link
          target='_blank'
          component='button'
          underline='hover'
          color='inherit'
          className={classes.hoverDocName}
          onClick={() =>
            requestDownloadDocument(document, true, openErrorModel, setLoading)
          }>
          {document.closing_doc_name}
        </Link>
        {isLoading &&
          selectedDocumentForDownload &&
          selectedDocumentForDownload == document.documentum_doc_id && (
            <CircularProgress size={20} className={classes.loadingSpinner} />
          )}
      </TableCell>
    );
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {/* <TableCell padding="checkbox">
              <Checkbox inputProps={{ "aria-label": "Select all" }} />
            </TableCell> */}
            {columnHeaders.map((column, i) =>
              renderCell(column, sortDirection),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(documents, document => (
            <TableRow className={classes.docRow} key={document.documentum_doc_id}>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  checked={false}
                  inputProps={{ "aria-labelledby": "" }}
                />
              </TableCell> */}
              {renderDocNameCell(document)}
              { document.last_change_ts != 'Today' && (
              <TableCell className={classes.cell}>
                {formatDateAndTime(document.last_change_ts)}
              </TableCell>)}
              { document.last_change_ts == 'Today' && (<TableCell className={classes.cell}>
                {document.last_change_ts}
              </TableCell>)}
              <TableCell className={classes.cell}>{document.last_change_userid}</TableCell>
              <TableCell className={classes.cell} align='center'>
                <IconButton onClick={e => handleMenuOpen(e, document)}>
                  <MoreIcon />
                </IconButton>
                <Menu
                  elevation={0}
                  PaperProps={{
                    style: {
                      border: "1px solid #E0E0E0",
                    },
                  }}
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}>
                  <MenuItem onClick={() => openMetaDataView(selectedDocument)}>
                    View Info
                  </MenuItem>
                  {canDeleteProject && statusCode != '2' && (
                    <MenuItem
                      onClick={() =>
                        showDeleteConfirmationPopup(
                          selectedDocument.documentum_doc_id,
                          selectedDocument.closing_id,
                          selectedDocument.closing_doc_name,
                        )
                      }>
                      Delete
                    </MenuItem>
                  )}
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

ProjectDocumentsTable.defaultProps = {
  documents: [],
};

ProjectDocumentsTable.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      lastModified: PropTypes.string,
      modifiedBy: PropTypes.string,
    }),
  ),
  openErrorModel: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ProjectDocumentsTable);
