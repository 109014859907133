import * as actions from './modalActions';

export const initialState = { open: false };

export default function modalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.OPEN:
      return {
        ...state,
        open: true,
        body: action.body,
      };
    case actions.CLOSE:
      return {
        ...state,
        open: false,
        body: null,
      };
    default:
      return state;
  }
}
