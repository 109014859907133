import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { default as MUIDialog } from '@material-ui/core/Dialog';

import { errorCloseModal } from './errorModalActions';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const mapStateToProps = state => ({
  isOpen: state.ErrorModal.open,
  title: state.ErrorModal.title,
  message: state.ErrorModal.message
});

const mapDispatchToProps = {
  onCloseModal: errorCloseModal
};

const ErrorModal = ({ title, message, isOpen, outerClose, onCloseModal }) => (
  <div>
    <MUIDialog open={isOpen} onClose={outerClose ? onCloseModal : null}>
      <div>
        <Fragment>
          <DialogTitle id='responsive-dialog-title'>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseModal} color='primary'>
              OK
            </Button>
          </DialogActions>
        </Fragment>
      </div>
    </MUIDialog>
  </div>
);

ErrorModal.defaultProps = {
  title: null,
  message: null,
  isOpen: false,
  outerClose: false
};

ErrorModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  outerClose: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorModal);
