import _ from 'lodash';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { fetchProjectUsers } from '../../../services/users';
import * as actions from '../usersActions';

export default function* fetchUsersWatcher() {
  yield takeLatest(actions.FETCH_PROJ_USERS, fetchUsersWorker);
}

export function* fetchUsersWorker(action) {

  yield put(actions.loading(true));
  try {
    const response = yield call(fetchProjectUsers,{closing_id: action.closing_id});
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.projectUsers', []);
  yield put(actions.setData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
