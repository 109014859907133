import { combineReducers } from 'redux';
import { createDataReducer } from '../../store/dataReducer';
import * as actions from './storeActions';
import { createRequestReducer } from '../../store/requestReducer';

export const initialState = {
  bu_type_code: "",
  store_nbr: "",
  dark_store_nbr: "",
  company: "",
  store_seq_nbr: "",
  store_type_code: "",
  project_type_code: "",
  address_line_1: "",
  city_name: "",
  state_prov_code: "",
};

export const refreshData = false;

export function refreshDataForStoreDetails(state = refreshData, action = {}) {
  switch (action.type) {
    case actions.REFRESH_DATA:
      return {
        refreshData: action.refreshData
      }
    default:
      return state;
  }
}

export function selectedStoreDetails(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SET_SELECTED_STORE:
      return {
        bu_type_code: action.selectedStore.bu_type_code,
        store_nbr: action.selectedStore.store_nbr,
        dark_store_nbr: action.selectedStore.dark_store_nbr,
        company: action.selectedStore.company,
        store_seq_nbr: action.selectedStore.store_seq_nbr,
        store_type_code: action.selectedStore.store_type_code,
        project_type_code: action.selectedStore.project_type_code,
        address_line_1: action.selectedStore.address_line_1,
        city_name: action.selectedStore.city_name,
        state_prov_code: action.selectedStore.state_prov_code,
      }
    default:
      return state;
  }
}

export const dataFetched = false;

export function setDataFetchedFlag(state = dataFetched, action = {}) {
  switch (action.type) {
    case actions.DATA_FETCHED:
      return {
        dataFetched: action.dataFetched
      }
    default:
      return state;
  }
}

export default combineReducers({
  data: createDataReducer({
    SET_DATA: actions.SET_DATA,
  }),
  request: createRequestReducer({
    ERROR: actions.ERROR,
    LOADING: actions.LOADING,
  }),
  selectedStore: selectedStoreDetails,
  refreshData: refreshDataForStoreDetails,
  dataFetched: setDataFetchedFlag,
});
