import gql from "graphql-tag";

export const FILTER_PROJECTS = gql`
  query ProjectsFilter(
    $StoreNumber: String
    $SequenceNumber: String
    $City: String
    $State: String
    $Active: Boolean
    $Locked: Boolean
    $Archived: Boolean
  ) {
    projectsFilter(
      filterdropdown: {
        StoreNumber: $StoreNumber
        SequenceNumber: $SequenceNumber
        City: $City
        State: $State
        Active: $Active
        Locked: $Locked
        Archived: $Archived
      }
    ) {
      id
      projectName
      storeNumber
      projectedClosingDate
      closingStatus
      projectSequenceNumber
      sellers
      parcels
      address
      storeType
      projectType
      businessUnitType
      storeSequenceNumber
      attorney
      auditor
      paralegal
      regionalVP
      realEstateManager
      accountant
      designManager
      outsideCounsel
      cecFirm
      lastModified
      darkStoreNumber
      createdBy
      createdDate
      documents {
        id
        name
        categoryId
        file
        projectId
        deleted
        status
        executed
        executedDate
        type
        subType
        originatingDepartment
        url
        version
        lastModified
        modifiedBy
      }
    }
  }
`;

export const FETCH_PROJECTS = gql`
  query Projects(
    $field: SortFieldsEnum!
    $direction: SortDirectionEnum!
    $page: Int!
    $limit: Int!
    $StoreNumber: String
    $SequenceNumber: String
    $City: String
    $State: String
    $ProjectName: String
    $Active: Boolean
    $Locked: Boolean
    $Dropped: Boolean
    $Closed: Boolean
    $userId: String
  ) {
    fetchProjects(
      sort: { field: $field, direction: $direction }
      limit: $limit
      page: $page
      filterdropdown: {
        StoreNumber: $StoreNumber
        SequenceNumber: $SequenceNumber
        City: $City
        State: $State
        ProjectName: $ProjectName
        Active: $Active
        Locked: $Locked
        Dropped: $Dropped
        Closed: $Closed
      }
      externalUserId: $userId
    ) {
      ProjectsCount
      Projects {
        store_nbr
        project_seq_nbr
        closing_name
        parcel_count
        last_change_ts
        closing_start_ts
        closing_id
        remFirstName
        remLastName
        status_code_desc
        city_name
        state_prov_code
        closing_id
      }
    }
  }
`;

export const FETCH_PROJECT = gql`
  query Project($projectID: Int!) {
    project(id: $projectID) {
      id
      projectName
      storeNumber
      projectedClosingDate
      closingStatus
      projectSequenceNumber
      sellers
      parcels
      address
      storeType
      projectType
      businessUnitType
      storeSequenceNumber
      attorney
      auditor
      paralegal
      regionalVP
      realEstateManager
      accountant
      designManager
      outsideCounsel
      cecFirm
      lastModified
      darkStoreNumber
      createdBy
      createdDate
      documents {
        id
        name
        categoryId
        file
        projectId
        deleted
        status
        executed
        executedDate
        type
        subType
        originatingDepartment
        url
        version
        lastModified
        modifiedBy
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $closing_id: Int!
    $property_id: Int!
    $project_seq_nbr: Int
    $parcel_count: Int
    $closing_start_ts: Date
    $status_code: Int
    $last_change_userid: String
    $seller_count: Int
    $status_code_desc: String
    $store_nbr: Int
  ) {
    updateProject(
      projectData: {
        closing_id: $closing_id
        property_id: $property_id
        project_seq_nbr: $project_seq_nbr
        parcel_count: $parcel_count
        closing_start_ts: $closing_start_ts
        status_code: $status_code
        last_change_userid: $last_change_userid
        seller_count: $seller_count
        status_code_desc: $status_code_desc
        store_nbr: $store_nbr
      }
    ) {
      Success
      Message
      Args
    }
  }
`;

export const UNLOCK_PROJECT = gql`
  mutation UnlockProject($closing_id: Int!) {
    unlockProject(projectData: { closing_id: $closing_id }) {
      Success
      Message
    }
  }
`;

export const LOCK_PROJECT = gql`
  mutation LockProject($closing_id: Int!) {
    lockProject(projectData: { closing_id: $closing_id }) {
      Success
      Message
    }
  }
`;

export const CLOSE_PROJECT = gql`
  mutation CloseProject($closing_id: Int!) {
    closeProject(projectData: { closing_id: $closing_id }) {
      Success
      Message
    }
  }
`;

export const DROP_PROJECT = gql`
  mutation DropProject($closing_id: Int!) {
    dropProject(projectData: { closing_id: $closing_id }) {
      Success
      Message
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject(
    $address_line_1: String
    $city_name: String
    $state_prov_code: String
    $store_nbr: Int
    $project_seq_nbr: Int
    $parcel_count: Int
    $jde_company_nbr: String
    $status_code: Int
    $seller_count: Int
    $closing_name: String
    $outsideCouncil: [String]
    $cecFirms: String
    $auditor: String
    $paraLegal: String
    $regionalVicePresident: String
    $accountant: String
    $attorney: String
    $realEstateManager: String
    $designManager: String
    $country_code: String
    $project_type_code: Int
    $closing_type_code: Int
    $last_change_userid: String
    $rstrct_access_ind: String
    $location_desc: String
    $postal_code: String
    $prop_usage_code: Int
    $region_nbr: Int
    $closing_start_ts: String
    $closing_desc: String
  ) {
    createProject(
      project: {
        addr_line1: $address_line_1
        city_name: $city_name
        state_prov_code: $state_prov_code
        store_nbr: $store_nbr
        project_seq_nbr: $project_seq_nbr
        parcel_count: $parcel_count
        jde_company_nbr: $jde_company_nbr
        status_code: $status_code
        seller_count: $seller_count
        closing_name: $closing_name
        outsideCouncil: $outsideCouncil
        cecFirms: $cecFirms
        auditor: $auditor
        plAdmin: $paraLegal
        regionalVicePresident: $regionalVicePresident
        accountant: $accountant
        generalCouncil: $attorney
        portfolioManager: $realEstateManager
        districtManager: $designManager
        country_code: $country_code
        project_type_code: $project_type_code
        closing_type_code: $closing_type_code
        last_change_userid: $last_change_userid
        rstrct_access_ind: $rstrct_access_ind
        location_desc: $location_desc
        postal_code: $postal_code
        prop_usage_code: $prop_usage_code
        region_nbr: $region_nbr
        closing_start_ts: $closing_start_ts
        closing_desc: $closing_desc
      }
    ) {
      Success
      ClosingId
    }
  }
`;

export const CREATE_PROJECT_OLD = gql`
  mutation CreateProject(
    $id: Int
    $projectName: String
    $storeNumber: Int
    $projectedClosingDate: String
    $closingStatus: String
    $projectSequenceNumber: Int
    $sellers: Int
    $parcels: Int
    $address: String
    $storeType: String
    $projectType: String
    $businessUnitType: String
    $storeSequenceNumber: Int
    $attorney: String
    $auditor: String
    $paralegal: String
    $regionalVP: String
    $realEstateManager: String
    $accountant: String
    $designManager: String
    $outsideCounsel: String
    $cecFirm: String
    $lastModified: String
    $modifiedBy: String
    $darkStoreNumber: Int
    $createdBy: String
    $createdDate: String
  ) {
    createProject(
      project: {
        id: $id
        projectName: $projectName
        storeNumber: $storeNumber
        projectedClosingDate: $projectedClosingDate
        closingStatus: $closingStatus
        projectSequenceNumber: $projectSequenceNumber
        sellers: $sellers
        parcels: $parcels
        address: $address
        storeType: $storeType
        projectType: $projectType
        businessUnitType: $businessUnitType
        storeSequenceNumber: $storeSequenceNumber
        attorney: $attorney
        auditor: $auditor
        paralegal: $paralegal
        regionalVP: $regionalVP
        realEstateManager: $realEstateManager
        accountant: $accountant
        designManager: $designManager
        outsideCounsel: $outsideCounsel
        cecFirm: $cecFirm
        lastModified: $lastModified
        modifiedBy: $modifiedBy
        darkStoreNumber: $darkStoreNumber
        createdBy: $createdBy
        createdDate: $createdDate
      }
    ) {
      id
      projectName
      storeNumber
      projectedClosingDate
      closingStatus
      projectSequenceNumber
      sellers
      parcels
      storeSequenceNumber
      attorney
      auditor
      paralegal
      regionalVP
      realEstateManager
      accountant
      designManager
      outsideCounsel
      cecFirm
      createdDate
    }
  }
`;

export const FETCH_PROJECT_DETAILS = gql`
  query Project($closing_id: Int!) {
    fetchProjectDetails(closing_id: $closing_id) {
      Success
      Message
      projectDetails {
        property_id
        location_desc
        addr_line1
        city_name
        state_prov_code
        postal_code
        store_nbr
        country_code
        last_change_ts
        project_type_code
        prop_usage_code
        project_seq_nbr
        region_nbr
        parcel_count
        jde_company_nbr
        closing_name
        closing_desc
        last_change_ts_pct
        closing_id
        closing_ts
        closing_start_ts
        closing_end_ts
        closing_type_code
        status_code
        last_change_ts
        last_change_userid
        seller_count
        rstrct_access_ind
        status_code_desc
        project_type_desc
        closing_type_desc
        closing_type_name
      }
      storeDetails {
        r_object_id
        jde_bu_nbr
        bu_type_code
        div_nbr
        store_nbr
        dark_store_nbr
        company
        store_seq_nbr
        store_type_code
        project_type_code
        proj_seq_nbr
        address_book_nbr
        address_line_1
        city_name
        state_prov_code
      }
    }
  }
`;

export const PROP_CLOSING_CATGS = gql`
  query propClosingCatgs($closing_id: Int!) {
    propClosingCatgs(closing_id: $closing_id) {
      closing_id
      doc_category_id
      closing_type_code
      category_name
      doc_count
    }
  }
`;

export const FETCH_PROJECT_STATUS = gql`
  query {
    fetchAllClosingStatus {
      status_code
      status_code_desc
    }
  }
`;

export const FETCH_STORE_DETAILS = gql`
  query fetchStoreDetailsFromJDE($storeNumber: Int!) {
    fetchStoreDetailsFromJDE(storeNumber: $storeNumber) {
      Success
      Stores {
        r_object_id
        jde_bu_nbr
        bu_type_code
        div_nbr
        store_nbr
        dark_store_nbr
        company
        store_seq_nbr
        store_type_code
        project_type_code
        proj_seq_nbr
        address_book_nbr
        address_line_1
        city_name
        state_prov_code
      }
    }
  }
`;

export const FETCH_STORE_DETAILS_BY_COMPANY = gql`
  query fetchStoreDetailsByCompanyFromJDE($companyNumber: Int!) {
    fetchStoreDetailsByCompanyFromJDE(companyNumber: $companyNumber) {
      Success
      Stores {
        r_object_id
        jde_bu_nbr
        bu_type_code
        div_nbr
        store_nbr
        dark_store_nbr
        company
        store_seq_nbr
        store_type_code
        project_type_code
        proj_seq_nbr
        address_book_nbr
        address_line_1
        city_name
        state_prov_code
      }
    }
  }
`;
