import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as _ from "lodash";
import Document from "./components/document/Document";
import Projects from "./components/projects/Projects";
import Project from "./components/project/Project";
import NotFound from "./notFound";
import HealthCheck from "./healthCheck";
import { EmptyComponent } from "./components/EmptyComponent";
import { NoLayout } from "./components/layouts/NoLayout";
import Login from "./components/login/Login";
import PreLogin from "./components/preLogin/PreLogin";
import CreatePassword from "./components/create-password/CreatePassword";
import { MainLayout } from "./components/layouts/MainLayout";
import LayoutRoute from "./LayoutRoute";
import routesConfig from "./roleaccess/routesConfig";
import { selectUserRoles } from "./components/login/loginSelector";
import Unauthorized from "./Unauthorized";
import { getRoleWithMaxWeight } from './roleaccess/utils';
import ChangePassword from "./components/users/changePassword/ChangePassword";
import RestrictExtUser from './RestrictExtUser';

const DeverseRoute = ({
  component: ContentComponent,
  authenticated,
  ...rest
}) => (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Redirect
            to={{
              pathname: "/projects",
              state: { from: props.location },
            }}
          />
        ) : (
            <ContentComponent {...props} />
          )
      }
    />
  );

DeverseRoute.propTypes = {
  component: PropTypes.func,
  layout: PropTypes.func,
};

DeverseRoute.defaultProps = {
  component: EmptyComponent,
  layout: NoLayout,
};

/**
 * Routes Component
 *
 * @namespace Routes
 * @memberof routes.components
 */

const mapStateToProps = state => ({
  sessionInfo: state.session.authenticated,
  checked: state.session.checked,
  //userRoles: _.get(state, 'session.user.data.userRole', []),
  userRoles: selectUserRoles(state),
  extUserAuthState: _.get(state,'session.user.data.extUserInfo[0].authStatus')
});

const Routes = ({ sessionInfo, checked, userRoles, extUserAuthState }) => {
  console.log(extUserAuthState);
  const userRoleWithMaxWeight = userRoles && userRoles.length > 0 ? getRoleWithMaxWeight(userRoles)
    : { value: '' };
  // let allowedRoutes = userRoles.reduce((acc, role) => {
  //   const routes = routesConfig[role] ? routesConfig[role].routes : [];
  //   return [...acc, ...routes];
  // }, []);
  // allowedRoutes = _.uniqBy(allowedRoutes, "url");
  const allowedRoutes = routesConfig[userRoleWithMaxWeight.value] ?
    routesConfig[userRoleWithMaxWeight.value].routes : [];
    
  return (
    checked && (
      <div id='authenticationWrapper'>
        <Switch>
          {/* <Route exact path='/' render={() => <Redirect to='/login' />} /> */}
          <Route exact path='/' render={() => <Redirect to='/preLogin' />} />
          <DeverseRoute
            exact
            path='/preLogin'
            component={PreLogin}
            authenticated={sessionInfo}
          />
          <DeverseRoute
            exact
            path='/login'
            component={Login}
            authenticated={sessionInfo}
          />
          <DeverseRoute
            exact
            path='/register'
            component={CreatePassword}
            authenticated={sessionInfo}
          />
          <Route path='/theHealthCheck' component={HealthCheck} status={200} />
          {!sessionInfo && <Redirect to='/preLogin' />}
          {/* {passwordExpiryError && passwordExpiryDays === null && (
            <Route
              render={props => (
                <RestrictExtUser {...props} errorMsg={passwordExpiryError} />
              )}
            />
          )} */}
          {extUserAuthState && extUserAuthState === 'CHANGE_PASSWORD' && (
            <LayoutRoute
              exact
              path='/changePassword'
              component={ChangePassword}
              authenticated={sessionInfo}
              layout={MainLayout}
            />
          )}
          {extUserAuthState && extUserAuthState === 'CHANGE_PASSWORD' && <Redirect to='/changePassword' />}

          {allowedRoutes.map(({ component, url, layout }) => (
            <LayoutRoute
              exact
              path={url}
              component={component}
              authenticated={sessionInfo}
              layout={layout}
            />
          ))}
          {checked && sessionInfo && allowedRoutes.length < 1 && (
            <Route component={Unauthorized} />
          )}
          <Route component={NotFound} />
        </Switch>
      </div>
    )
  );
};

const { bool } = PropTypes;

Routes.propTypes = {
  sessionInfo: bool.isRequired,
  checked: bool.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string),
};

export default connect(mapStateToProps)(Routes);
