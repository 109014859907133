import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  TableRow,
  TableCell,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Button,
  Grid,
  TablePagination,
  withStyles,
  Menu,
  MenuItem,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import Filter from "@material-ui/icons/FilterList";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "../table/Table";
import SelectField from "../_shared/select-field/SelectField";
import InputField from "../_shared/input-field/InputField";
// eslint-disable-next-line import/no-unresolved
import "./Projects.scss";
import config from "../../styles/utils/config.scss";
import { formatDate, formatDateWithoutConversion } from "../../utilities";

import MoreIcon from "@material-ui/icons/MoreVert";

import ProjectCloseModalContent from "../project/ProjectOverview/ProjectCloseModalContent";
import ProjectLockModalContent from "../project/ProjectOverview/ProjectLockModalContent";
import ProjectDropModalContent from "../project/ProjectOverview/ProjectDropModalContent";
import ProjectUnLockModalContent from "../project/ProjectOverview/ProjectUnLockModalContent";
import { adminWeight, getMaxWeight } from "../../roleaccess/utils";
import * as modalActions from "../_shared/modal/modalActions";
import { selectUserRoles } from "../login/loginSelector";
import {
  selectProjectsList,
  fetchTotalProjects,
  fetchPageDetails,
} from "./projectsSelectors";
import { fetch, setPageDetails } from "./projectsActions";
import closedIcon from '../../assets/images/closed.png';
import lockIcon from '../../assets/images/lock-16.png';

const styles = {
  createNew: {
    "background-color": config.primaryColor,
    width: "128px",
    height: "36px",
    "&:hover": {
      "background-color": config.primaryButtonHoverColor,
    },
  },
  loadingSpinner: {
    position: "absolute",
    left: "50%",
    top: "14%",
    
  },
  overlay: {
    position: "fixed", /* Sit on top of the page content */
    display: "none", /* Hidden by default */
    width: "100%", /* Full width (cover the whole page) */
    height: "100%", /* Full height (cover the whole page) */
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    "background-color": "rgba(0,0,0,0.5)", /* Black background with opacity */
    "z-index": "2", /* Specify a stack order in case you're using a different order for other elements */
    cursor: "pointer", /* Add a pointer on hover */
  }
};

const statusStyles = makeStyles({
  statusIcon: {
    justifySelf: 'center',
    margin: '0 auto',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
  },
  active: {
    backgroundColor: config.walmartBlueColor,
  },
  locked: {
    backgroundImage: `url(${lockIcon})`,
  },
  closed: {
    backgroundColor: 'gray',
  },
  dropped: {
    backgroundImage: `url(${closedIcon})`,
  },
});
// eslint-disable-next-line react/prop-types
const Status = ({ status }) => {
  const classes = statusStyles();
  return (<div className={`${classes.statusIcon} ${classes[`${status.toLowerCase()}`]}`} title={status} />);
};
// this needs to change to an async call, for now Im just mocking this out
const mapStateToProps = state => {
  if (state.projectDetails) {
    state.projectDetails.data = {};
  }
  if (state.users) {
    state.users.data = [];
  }

  //const userRole = _.get(state, "session.user.data.userRole");
  const userRole = selectUserRoles(state);
  return {
    data: selectProjectsList(state),
    totalProjects: fetchTotalProjects(state),
    globalPageDetails: fetchPageDetails(state),
    userRole,
    isLoading: _.get(state, "projects.request.loading", false),
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProjects: pageDetails => dispatch(fetch(pageDetails)),
  setGlobalPageDetails: pageDetails => dispatch(setPageDetails(pageDetails)),
  openConfirmModal: body => dispatch(modalActions.openModal({ body })),
});

//Sorting direction swapper.
const invertDirection = {
  asc: "desc",
  desc: "asc",
};

export let columnHeaders = [
  "Store # - Seq #",
  "Name",
  "Parcels",
  "Modified",
  "Closing",
  "City",
  "State",
  "Portfolio Manager",
  "Status",
];

export const renderTableRow = (
  history,
  rowData,
  menuAnchorEl,
  handleMenuClose,
  handleMenuOpen,
  triggerCloseConfirm,
  triggerLockConfirm,
  triggerUnLockConfirm,
  triggerDropConfirm,
  selectedProject,
  userRole,
  canChangeProjectStatus,
) => {
  let showActiveOption = true;
  let showLockOption = true;
  let showCloseOption = true;
  let showDropOption = true;

  if (
    selectedProject != null &&
    selectedProject != undefined &&
    selectedProject.status_code_desc != null
  ) {
    if (selectedProject.status_code_desc.trim() == "Active") {
      showActiveOption = false;
      showLockOption = true;
      showCloseOption = true;
      showDropOption = true;
    } else if (selectedProject.status_code_desc.trim() == "Locked") {
      showActiveOption = true;
      showLockOption = false;
      showCloseOption = false;
      showDropOption = false;
    } else if (selectedProject.status_code_desc.trim() == "Dropped") {
      showActiveOption = true;
      showLockOption = true;
      showCloseOption = true;
      showDropOption = false;
    } else if (selectedProject.status_code_desc.trim() == "Closed") {
      showActiveOption = true;
      showLockOption = true;
      showCloseOption = false;
      showDropOption = true;
    }
  }

  return (
    <TableRow className="projects-row" key={rowData.closing_id}>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        {rowData.store_nbr} - {rowData.project_seq_nbr}
      </TableCell>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        {rowData.closing_name}
      </TableCell>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        {rowData.parcel_count}
      </TableCell>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        {formatDate(rowData.last_change_ts)}
      </TableCell>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        {formatDateWithoutConversion(rowData.closing_start_ts)}
      </TableCell>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        {rowData.city_name}
      </TableCell>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        {rowData.state_prov_code}
      </TableCell>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        {rowData.remFirstName} {rowData.remLastName}
      </TableCell>
      <TableCell
        onClick={() => {
          history.push(`/projects/${rowData.closing_id}`);
        }}>
        <Status
          status={rowData.status_code_desc ? rowData.status_code_desc.trim() : ''}
        />
      </TableCell>
      {canChangeProjectStatus && (
        <TableCell align="center">
          <section>
            <IconButton onClick={e => handleMenuOpen(e, rowData)}>
              <MoreIcon />
            </IconButton>
            <Menu
              id="project-menu"
              elevation={0}
              PaperProps={{
                style: {
                  border: "1px solid #E0E0E0",
                },
              }}
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}>
              {showActiveOption && (
                <MenuItem
                  onClick={() =>
                    triggerUnLockConfirm(
                      selectedProject.closing_id,
                      selectedProject.closing_name,
                      history,
                    )
                  }>
                  Set Active
                </MenuItem>
              )}
              {showLockOption && (
                <MenuItem
                  onClick={() =>
                    triggerLockConfirm(
                      selectedProject.closing_id,
                      selectedProject.closing_name,
                      history,
                    )
                  }>
                  Lock
                </MenuItem>
              )}
              {showCloseOption && (
                <MenuItem
                  onClick={() =>
                    triggerCloseConfirm(
                      selectedProject.closing_id,
                      selectedProject.closing_name,
                      history,
                    )
                  }>
                  Close
                </MenuItem>
              )}
              {showDropOption && (
                <MenuItem
                  onClick={() =>
                    triggerDropConfirm(
                      selectedProject.closing_id,
                      selectedProject.closing_name,
                      history,
                    )
                  }>
                  Drop
                </MenuItem>
              )}
            </Menu>
          </section>
        </TableCell>
      )}
    </TableRow>
  );
};

/**
 * Projects Component
 *
 * @namespace Projects
 * @memberof projects.components
 */

export const Projects = ({
  classes,
  data,
  fetchProjects,
  setGlobalPageDetails,
  history,
  totalProjects,
  globalPageDetails,
  openConfirmModal,
  isLoading,
  userRole,
}) => {
  const [showFilter, toggleFilter] = useState(false);
  const [selectedProjectsFilter, setProjectsFilter] = useState("allProjects");
  const [selectedProject, setSelectedProject] = useState(null);

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const handleMenuOpen = (event, project) => {
    setSelectedProject(project);
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => setMenuAnchorEl(null);

  const triggerCloseConfirm = (closing_id, closing_name, history) => {
    console.log("Close");
    handleMenuClose();
    openConfirmModal(
      <ProjectCloseModalContent
        projectID={closing_id}
        projectName={closing_name}
        history={history}
      />,
    );
  };

  const triggerLockConfirm = (closing_id, closing_name, history) => {
    console.log("Lock");
    handleMenuClose();
    openConfirmModal(
      <ProjectLockModalContent
        projectID={closing_id}
        projectName={closing_name}
        history={history}
      />,
    );
  };

  const triggerUnLockConfirm = (closing_id, closing_name, history) => {
    console.log("Unlock");
    handleMenuClose();
    openConfirmModal(
      <ProjectUnLockModalContent
        projectID={closing_id}
        projectName={closing_name}
        history={history}
      />,
    );
  };

  const triggerDropConfirm = (closing_id, closing_name, history) => {
    console.log("Drop");
    handleMenuClose();
    openConfirmModal(
      <ProjectDropModalContent
        projectID={closing_id}
        projectName={closing_name}
        history={history}
      />,
    );
  };

  const handleChangeCheckBox = name => event => {
    let pageDetailsReducer = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: {
        StoreNumber: globalPageDetails.filterObject.StoreNumber,
        SequenceNumber: globalPageDetails.filterObject.SequenceNumber,
        City: globalPageDetails.filterObject.City,
        State: globalPageDetails.filterObject.State,
        ProjectName: globalPageDetails.filterObject.ProjectName,
        checkbox: {
          ...globalPageDetails.filterObject.checkbox,
          [name]: event.target.checked,
        },
      },
      currentSortedColumnName: globalPageDetails.currentSortedColumnName,
    };
    setGlobalPageDetails(pageDetailsReducer);
  };

  const handleChangeStoreNumber = name => event => {
    let pageDetailsReducer = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: {
        StoreNumber: event.target.value,
        SequenceNumber: globalPageDetails.filterObject.SequenceNumber,
        City: globalPageDetails.filterObject.City,
        State: globalPageDetails.filterObject.State,
        ProjectName: globalPageDetails.filterObject.ProjectName,
        checkbox: globalPageDetails.filterObject.checkbox,
      },
      currentSortedColumnName: globalPageDetails.currentSortedColumnName,
    };
    setGlobalPageDetails(pageDetailsReducer);
  };

  const handleChangeSequenceNumber = name => event => {
    let pageDetailsReducer = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: {
        StoreNumber: globalPageDetails.filterObject.StoreNumber,
        SequenceNumber: event.target.value,
        City: globalPageDetails.filterObject.City,
        State: globalPageDetails.filterObject.State,
        ProjectName: globalPageDetails.filterObject.ProjectName,
        checkbox: globalPageDetails.filterObject.checkbox,
      },
      currentSortedColumnName: globalPageDetails.currentSortedColumnName,
    };
    setGlobalPageDetails(pageDetailsReducer);
  };

  const handleChangeCity = name => event => {
    let pageDetailsReducer = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: {
        StoreNumber: globalPageDetails.filterObject.StoreNumber,
        SequenceNumber: globalPageDetails.filterObject.SequenceNumber,
        City: event.target.value,
        State: globalPageDetails.filterObject.State,
        ProjectName: globalPageDetails.filterObject.ProjectName,
        checkbox: globalPageDetails.filterObject.checkbox,
      },
      currentSortedColumnName : globalPageDetails.currentSortedColumnName,
    };
    setGlobalPageDetails(pageDetailsReducer);
  };

  const handleChangeState = name => event => {
    let pageDetailsReducer = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: {
        StoreNumber: globalPageDetails.filterObject.StoreNumber,
        SequenceNumber: globalPageDetails.filterObject.SequenceNumber,
        City: globalPageDetails.filterObject.City,
        State: event.target.value,
        ProjectName: globalPageDetails.filterObject.ProjectName,
        checkbox: globalPageDetails.filterObject.checkbox,
      },
    };
    setGlobalPageDetails(pageDetailsReducer);
  };


  const handleChangeProjectName = name => event => {
    let pageDetailsReducer = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: {
        StoreNumber: globalPageDetails.filterObject.StoreNumber,
        SequenceNumber: globalPageDetails.filterObject.SequenceNumber,
        City: globalPageDetails.filterObject.City,
        State: globalPageDetails.filterObject.State,
        ProjectName: event.target.value,
        checkbox: globalPageDetails.filterObject.checkbox,
      },
    };
    setGlobalPageDetails(pageDetailsReducer);
  };
  //Method is used to manage sorting variables and action call.
  const handleSorting = column => {
    //# is removed from 'Store' because we can not create enum with special characters.
    var currentSortedColumnName = column;

    if (column === "Store # - Seq #") {
      column = "Store";
    }
    if (column === "Portfolio Manager") {
      column = "Portfolio_Manager";
    }

    if (column === "Actions") {
      return;
    }
    let pageDetails = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: column,
      sortDirection: invertDirection[globalPageDetails.sortDirection],
      filterObject: globalPageDetails.filterObject,
      currentSortedColumnName: currentSortedColumnName,
    };

    //state update
    setGlobalPageDetails(pageDetails);

    //Action call
    fetchProjects(pageDetails);
  };

  const handleApplyFilter = () => {
    let pageDetails = {
      page: 0,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: globalPageDetails.filterObject,
      currentSortedColumnName: globalPageDetails.currentSortedColumnName,
    };

    setGlobalPageDetails(pageDetails);

    fetchProjects(pageDetails);
  };

  const handleReset = () => {
    let pageDetails = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: {
        StoreNumber: "",
        SequenceNumber: "",
        City: "",
        State: "",
        ProjectName: "",
        checkbox: {
          active: true,
          locked: true,
          dropped: true,
          closed: true,
        },
      },
      currentSortedColumnName: globalPageDetails.currentSortedColumnName,
    };

    setGlobalPageDetails(pageDetails);
    fetchProjects(pageDetails);
  };

  function handleChangePage(event, newPage) {
    let pageDetails = {
      page: newPage,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: globalPageDetails.filterObject,
      currentSortedColumnName: globalPageDetails.currentSortedColumnName,
    };

    setGlobalPageDetails(pageDetails);
    fetchProjects(pageDetails);
  }

  function handleChangeRowsPerPage(event) {
    let pageDetails = {
      page: 0,
      rowsPerPage: +event.target.value,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: globalPageDetails.filterObject,
      currentSortedColumnName: globalPageDetails.currentSortedColumnName,
    };

    setGlobalPageDetails(pageDetails);
    fetchProjects(pageDetails);
  }

  useEffect(() => {
    let pageDetails = {
      page: globalPageDetails.page,
      rowsPerPage: globalPageDetails.rowsPerPage,
      sortColumn: globalPageDetails.sortColumn,
      sortDirection: globalPageDetails.sortDirection,
      filterObject: globalPageDetails.filterObject,
      currentSortedColumnName: globalPageDetails.currentSortedColumnName,
    };

    fetchProjects(pageDetails);
  }, {});

  const canCreateNewProject = getMaxWeight(userRole) >= adminWeight();
  const canChangeProjectStatus = getMaxWeight(userRole) >= adminWeight();
  if (canChangeProjectStatus) {
    if (!columnHeaders.includes("Actions")) {
      columnHeaders = [...columnHeaders, "Actions"];
    }
  }
  return (
    <div className="app-view">
      <Grid container spacing={10} className="grid-container">
        <Grid item xs={12} className="app-view">
          <div className="projects-header-container">
            <div className="projects-header">
              <div className="title-container">
                <Typography variant="h5" gutterBottom>
                  Projects
                </Typography>
                {isLoading && (
                  <CircularProgress className={classes.loadingSpinner} />
                )}
              </div>

              <div className={"filter-container"}>
                <span className="filter-title">
                  <Button
                    className="filter-buttons-top"
                    onClick={() => toggleFilter(!showFilter)}>
                    <Filter /> Filter / Search
                  </Button>
                </span>

                {canCreateNewProject && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.createNew}
                    onClick={() => {
                      history.push("/projects/create");
                    }}>
                    Create New
                  </Button>
                )}
              </div>

              <div className="search-container">
                {/* <FormControl>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Bootstrap
                  </InputLabel>
                  <BootstrapInput defaultValue="react-bootstrap" id="bootstrap-input" />
                </FormControl> */}
              </div>
              <div className="create-container">{/* Create Container */}</div>
            </div>
            <div className="projects-subheader">
              <div className="filter-subheader" hidden={!showFilter}>
                <Grid container spacing={10} className="sub-header-grid">
                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    <FormControl
                      component="fieldset"
                      className="sub-header-form">
                      <div className="sub-header-container">
                        {/* <div className='project-type-container '>
                          <Typography color='inherit'>Project Type</Typography>
                          <div className='project-type-inputs'>
                            <RadioGroup
                              aria-label='position'
                              name='position'
                              value={selectedProjectsFilter}
                              onChange={event =>
                                setProjectsFilter(event.target.value)
                              }
                            >
                              <FormControlLabel
                                value='allProjects'
                                control={<Radio color='primary' />}
                                label='All Projects'
                                labelPlacement='end'
                              />
                              <FormControlLabel
                                value='myProjects'
                                control={<Radio color='primary' />}
                                label='Only your projects'
                                labelPlacement='end'
                              />
                            </RadioGroup>
                          </div>
                        </div> */}
                        <div className="status-container">
                          <Typography color="inherit">Status</Typography>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    globalPageDetails.filterObject.checkbox
                                      .active
                                  }
                                  onChange={handleChangeCheckBox("active")}
                                  value="active"
                                />
                              }
                              label="Active"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    globalPageDetails.filterObject.checkbox
                                      .closed
                                  }
                                  onChange={handleChangeCheckBox("closed")}
                                  value="closed"
                                />
                              }
                              label="Closed"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    globalPageDetails.filterObject.checkbox
                                      .dropped
                                  }
                                  onChange={handleChangeCheckBox("dropped")}
                                  value="dropped"
                                />
                              }
                              label="Dropped"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    globalPageDetails.filterObject.checkbox
                                      .locked
                                  }
                                  onChange={handleChangeCheckBox("locked")}
                                  value="locked"
                                />
                              }
                              label="Locked"
                            />
                          </FormGroup>
                        </div>
                        <div className="store-container">
                          <Typography color="inherit">Store #</Typography>
                          <InputField
                            placeholder="store #"
                            value={globalPageDetails.filterObject.StoreNumber}
                            onChange={handleChangeStoreNumber()}
                          />
                        </div>
                        <div className="sequence-container">
                          <Typography color="inherit">Sequence #</Typography>
                          <InputField
                            placeholder="sequence #"
                            value={
                              globalPageDetails.filterObject.SequenceNumber
                            }
                            onChange={handleChangeSequenceNumber()}
                          />
                        </div>
                        <div className='project-name-container'>
                          <Typography color='inherit'>Project Name</Typography>
                          <InputField
                            placeholder='project name'
                            value={globalPageDetails.filterObject.ProjectName}
                            onChange={handleChangeProjectName()}
                          />
                        </div>
                        <div className='city-container'>
                          <Typography color='inherit'>City</Typography>
                          <InputField
                            placeholder="city"
                            value={globalPageDetails.filterObject.City}
                            onChange={handleChangeCity()}
                          />
                        </div>
                        <div className="state-container">
                          <Typography color="inherit">State</Typography>
                          <InputField
                            placeholder="state"
                            value={globalPageDetails.filterObject.State}
                            onChange={handleChangeState()}
                          />
                        </div>
                        <div className="button-container">
                          <Button
                            className="filter-buttons"
                            onClick={() => handleReset()}>
                            Reset Filters
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className="filter-buttons apply-filters"
                            onClick={() => handleApplyFilter()}>
                            Apply
                          </Button>
                        </div>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </div>
            </div>
          </div>
          <div className="projects-table">
            <Table
              columnHeaders={columnHeaders}
              sortingHandler={handleSorting}
              sortDirection={globalPageDetails.sortDirection}
              sortColum={globalPageDetails.currentSortedColumnName}>
              {_.map(data, rowData =>
                renderTableRow(
                  history,
                  rowData,
                  menuAnchorEl,
                  handleMenuClose,
                  handleMenuOpen,
                  triggerCloseConfirm,
                  triggerLockConfirm,
                  triggerUnLockConfirm,
                  triggerDropConfirm,
                  selectedProject,
                  userRole,
                  canChangeProjectStatus,
                ),
              )}
            </Table>

            <TablePagination
              rowsPerPageOptions={[2, 10, 20, 50, 100]}
              component="div"
              count={totalProjects}
              rowsPerPage={globalPageDetails.rowsPerPage}
              page={globalPageDetails.page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Projects.defaultProps = {
  data: [],
  fetchProjects: () => {},
  history: { push: () => {} },
  classes: {},
  fetchParams: { field: "", direction: "" },
  handleSorting: () => {},
};

Projects.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fetchProjects: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  fetchParams: PropTypes.object,
  handleSorting: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
};

const reduxConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Projects));

export default withRouter(reduxConnected);
