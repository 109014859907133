import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchProjects } from "../../../services/projects";
import * as actions from "../projectsActions";
import { convertData } from "../../../utilities";
import config from "../../../sso/config";
import Cookies from 'js-cookie'

export default function* fetchProjectsWatcher() {
  yield takeLatest(actions.FETCH, fetchProjectsWorker);
}

export function* fetchProjectsWorker(action) {
  yield put(actions.loading(true));

  const {
    sortColumn,
    sortDirection,
    rowsPerPage,
    page,
    filterObject,
  } = action.pageDetails;
  let userIdParameter = "";

  // let userRoleInd = localStorage.getItem(config.SSO_extUser_Ind); 

  let userRoleInd = Cookies.get(config.SSO_extUser_Ind)
  console.log("userRoleInd",userRoleInd);

  if (userRoleInd && userRoleInd === '1') { //indicates that user is external
      userIdParameter = localStorage.getItem(config.SSO_user_loginId);
  }
  
  try {
    const variables = {
      page: page + 1,
      limit: rowsPerPage,
      field: sortColumn,
      direction: sortDirection,
      StoreNumber: filterObject.StoreNumber,
      SequenceNumber: filterObject.SequenceNumber,
      City: filterObject.City,
      State: filterObject.State,
      ProjectName: filterObject.ProjectName,
      Active: filterObject.checkbox.active,
      Locked: filterObject.checkbox.locked,
      Closed: filterObject.checkbox.closed,
      Dropped: filterObject.checkbox.dropped,
      userId: userIdParameter,
    };

    const response = yield call(fetchProjects, variables);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, "data.fetchProjects.Projects", {});
  const totalProjects = _.get(response, "data.fetchProjects.ProjectsCount", {});

  const convertedData = _.map(convertData(data), extraData => ({
    ...extraData,
    documents: convertData(data.documents),
  }));

  yield put(actions.setData(convertedData));
  yield put(actions.setTotalProjects(totalProjects));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
