import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ProjectDetails from './ProjectDetails';
import StoreDetails from './StoreDetails';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

const AddDetails = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <ProjectDetails {...props} />
        <StoreDetails {...props} />
      </Grid>
    </div>
  );
};

export default AddDetails;
