import gql from "graphql-tag";

export const FETCH_USERS = gql`
  query FetchUsers(
    $field: UsersSortFieldsEnum!
    $direction: SortDirectionEnum!
    $page: Int
    $limit: Int
    $UserName: String
  ) {
    fetchUsers(
      sort: { field: $field, direction: $direction }
      limit: $limit
      page: $page
      filterdropdown: {
        UserName: $UserName
      }
    ) {
      UsersCount
      Users {
        role_id
        sys_userid
        company_user_ind
        role_name
        external_user_ind
        extrn_first_name
        extrn_last_name
        ext_email_addr_id
        last_login_ts
        current_role_ind
        extrn_addr_line1
        extrn_addr_line2
        extrn_city_name
        extrn_st_prov_cd
        extrn_postal_cd
        extrn_company_name
      }
    }
  }
`;

export const FETCH_PROJECT_USERS = gql`
  query projectUsers($closing_id: Int!) {
    projectUsers(closing_id: $closing_id) {
      closing_id
      role_id
      sys_userid
      last_change_ts
      user_approved_ind
      role_name
      role_desc
      external_user_ind
      extrn_first_name
      extrn_last_name
    }
  }
`;

export const FETCH_USER = gql`
  query Users($userID: Int!) {
    user(id: $userID) {
      id
      username
      firstName
      lastName
      email
      role
      jobTitle
      affiliation
      lastLogin
      status
      photo
    }
  }
`;

export const SEARCH_USERS = gql`
  query UsersSearch($filter: String!) {
    usersSearch(filter: $filter) {
      id
      username
      firstName
      lastName
      email
      role
      jobTitle
      affiliation
      lastLogin
      status
      photo
    }
  }
`;
export const FETCH_USERS_WITH_ROLES = gql`
  query FetchUsersWithRoles {
    fetchAllRoles {
      Users {
        extrn_first_name
        extrn_last_name
        sys_userid
        role_name
      }
    }
  }
`;

export const UPDATE_PROJECT_USERS = gql`
  mutation UpdateProjectUsers($projectUsersData: projectUsersUpdateInput){
    updateProjectUsers(
      projectUsersData: $projectUsersData
    ) {
      Success
      Message
      Args
    }
  }
`;

export const UPDATE_PASSWORD = gql`
mutation ResetAdUserPassword($userName: String, $password: String, $newPassword: String ){
  resetAdUserPassword(
    userInfo: { userName: $userName, password:  $password, newPassword: $newPassword }
    ) {
      Success
      Message
    }
  }
`;
export const CREAT_TEMP_USER_PASSWORD = gql`
mutation CreateTempUserPassword($userName: String, $password: String ){
  createTempAdPasswordOfUser(
    userInfo: { userName: $userName, password:  $password }
    ) {
      Success
      Message
      Password
    }
  }
`;
export const DELETE_EXTERNAL_USER = gql`
mutation DeleteExternalUser($userName: String ){
  deleteExternalUser(
    userInfo: { userName: $userName }
    ) {
      Success
      Message
    }
  }
`;
export const FETCH_COMPANIES = gql`
query FetchCompanies {
  fetchAllCompanies {
    Companies {
      extrn_company_id
      language_code
      extrn_company_name
    }
  }
}
`;
export const CREATE_USER = gql`
mutation CreateSysUser(
  $extrn_first_name: String,
  $extrn_last_name: String,
  $sys_userid: String,
  $password: String,
  $ext_email_addr_id : String,
  $extrn_company_name: String,
  $extrn_company_id: Int,
  $extrn_addr_line1: String,
  $extrn_city_name : String,
  $extrn_st_prov_cd : String, 
  $extrn_postal_cd : String,

    ){
  createSysUser(
    user: {
      extrn_first_name: $extrn_first_name,
      extrn_last_name: $extrn_last_name,
      sys_userid: $sys_userid,
      password: $password,
      ext_email_addr_id: $ext_email_addr_id,
      extrn_company_name: $extrn_company_name,
      extrn_company_id : $extrn_company_id,
      extrn_addr_line1 : $extrn_addr_line1,
      extrn_city_name : $extrn_city_name,
      extrn_st_prov_cd : $extrn_st_prov_cd,
      extrn_postal_cd : $extrn_postal_cd,
    }   
  ) {
    Success
    message
    sys_userid
  }
  }
`;
export const FETCH_PASSWORD_EXPIRY = gql`
query FetchPasswordExpiryDays($userId: String) {
  fetchPasswordExpiryDays(
    user: {
      username: $userId,
    }
  ){
    Success
    result
  }
}`;