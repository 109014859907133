import { takeLatest, call, put, select } from "redux-saga/effects";
import _ from "lodash";
import { lockProject } from "../../../services/projects";
import { openSnackbar } from "../../_shared/snack-bar/snackbarActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";
import * as actions from "../projectActions";
import { setData } from "../../projects/projectsActions";

const projectSelector = state => _.get(state, "projects.data");

export default function* lockProjectWatcher() {
  yield takeLatest(actions.LOCK, lockProjectWorker);
}

export function* lockProjectWorker(action) {
  let variables = {
    closing_id: action.closing_id,
  };
  try {
    const response = yield call(lockProject, variables);
    yield call(success, response, action);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, action) {
  const data = _.get(response, "data.lockProject", {});
  if (data.Success == "true") {
    yield put(
      openSnackbar({
        message: "Project Locked!",
        showCloseIcon: true,
      }),
    );

    if (action.projectOpen) {
      yield call(action.history.push, `/projects/`);
      yield call(action.history.push, `/projects/${action.closing_id}`);
    } else {
      let projects = yield select(projectSelector);
      let updateIndex = _.findKey(projects, { closing_id: action.closing_id });

      let projectEdited = projects[updateIndex];
      projectEdited.status_code_desc = "Locked";

      if (updateIndex > -1) {
        projects.splice(updateIndex, 1);
      }
      projects.unshift(projectEdited);

      yield put(setData(projects));
    }
  }
}

export function* failure(error) {
  yield put(actions.error(error));
  yield put(
    errorOpenModal({
      title: "Project could not be locked",
      message: "An error occured while locking the Project",
    }),
  );
  return error;
}
