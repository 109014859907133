import { normalizeData } from '../utilities';

import closingStatuses from './closingStatuses';
import closingTypes from './closingTypes';
import documentCategories from './documentCategories';
import projectTypes from './projectTypes';
import propertyUsage from './propertyUsage';
import userRoles from './userRoles';


const models = {
  closingStatuses: normalizeData(closingStatuses),
  closingTypes: normalizeData(closingTypes),
  documentCategories: normalizeData(documentCategories),
  projectTypes: normalizeData(projectTypes),
  propertyUsage: normalizeData(propertyUsage),
  userRoles: normalizeData(userRoles),
};

export default models;
