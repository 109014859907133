import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import _ from "lodash";

// import Divider from '@material-ui/core/Divider';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Badge from "@material-ui/core/Badge";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SubjectIcon from "@material-ui/icons/Subject";
import { makeStyles } from "@material-ui/core/styles";

import { leadWithZero } from "../../utilities";
import { selectProjectDocumentCategoriesList } from "./projectSelectors";

const mapStateToProps = (state, ownProps) => {
  const params = _.get(ownProps, "match.params", {});
  const projectID = _.get(params, "projectId", null);
  const documentsCategoryId = _.get(params, "documentsCategoryId", null);
  const isLoading = _.get(state, "projectDetails.request.loading", false);

  return {
    documentCategories: selectProjectDocumentCategoriesList(state),
    documentsCategoryID: documentsCategoryId && +documentsCategoryId,
    projectID,
    isLoading,
  };
};

const drawerWidth = 350;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    position: "absolute",
    width: drawerWidth,
  },
  listIcon: {
    minWidth: "32px",
  },
  selectedListItem: {
    borderLeft: `8px solid ${theme.palette.secondary.main}`,
    opacity: "1 !important",
  },
  customBadgeZero: {
    backgroundColor: "#9e9e9e",
    color: "white",
  },
  customBadge: {
    backgroundColor: "#eb148d",
    color: "white",
  },
}));

export const ProjectMenu = ({
  documentCategories,
  documentsCategoryID,
  history,
  projectID,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper,
      }}>
      {/*
        <section>Search</section>
        <Divider />
        */}
      <List>
        <ListItem
          classes={{ selected: classes.selectedListItem }}
          button
          key='ProjectOverview'
          divider
          onClick={() => history.replace(`/projects/${projectID}`)}
          selected={!documentsCategoryID}
          disabled={!documentsCategoryID}>
          <ListItemIcon className={classes.listIcon}>
            <SubjectIcon />
          </ListItemIcon>
          <ListItemText primary='Project Overview' />
        </ListItem>
        {_.map(documentCategories, category => (
          <ListItem
            classes={{ selected: classes.selectedListItem }}
            button
            key={`category_${category.doc_category_id}`}
            divider
            onClick={() =>
              history.replace(
                `/projects/${projectID}/documents/categories/${documentCategories.indexOf(
                  category,
                ) + 1}`,
              )
            }
            selected={
              documentsCategoryID === documentCategories.indexOf(category) + 1
            }
            disabled={
              documentsCategoryID === documentCategories.indexOf(category) + 1
            }>
            <ListItemIcon className={classes.listIcon}>
              <span>
                {leadWithZero(documentCategories.indexOf(category) + 1)}
              </span>
            </ListItemIcon>
            <ListItemText primary={category.category_name} />
            {!isLoading && (
              <Badge
                classes={
                  category.doc_count > 0
                    ? { badge: classes.customBadge }
                    : { badge: classes.customBadgeZero }
                }
                badgeContent={category.doc_count}
                showZero
                className={classes.margin}
              />
            )}
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

ProjectMenu.defaultProps = {
  documentCategories: [],
  documentsCategoryID: null,
  history: {
    replace: _.noop,
  },
  projectID: null,
};

ProjectMenu.propTypes = {
  documentCategories: PropTypes.arrayOf(
    PropTypes.shape({
      doc_category_id: PropTypes.number,
      category_name: PropTypes.string,
    }),
  ),
  documentsCategoryID: PropTypes.number,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
  projectID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const reduxConnected = connect(mapStateToProps, null)(ProjectMenu);

export default withRouter(reduxConnected);
