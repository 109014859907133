import _ from "lodash";
import fetch from "unfetch";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, execute, makePromise } from "apollo-link";
import SSO from "../sso/index";
import _config from "../config"
// import SimpleCrypto from "simple-crypto-js";



const API_ENDPOINT = _config.REACT_APP_API_URL;

function getAuthHeader() {
  // var _secretKey = process.env.ENCRYPTION_KEY;
  // var simpleCrypto = new SimpleCrypto(_secretKey);
  var authHeader = localStorage.getItem("sso_session_index");
  return authHeader;
  // return simpleCrypto.decrypt(authHeader);
}
export const getAuthContext = ({ headers = {} }) => ({
  headers: {
    ...headers,
    authorization: getAuthHeader(),
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add authorization to the headers
  operation.setContext(getAuthContext);
  console.log("Operation: ", operation );
  return forward(operation);
});

const handleGraphQLError = ({ message, locations, path, extensions }) =>
{  
  if(extensions.code === 401 || extensions.code === "UNAUTHENTICATED" || 
  extensions.code === "UNAUTHORIZED"){ //if unauthorized redirect to sign in url
    SSO.redirectToSignInUrl();
  }
  console.error(
    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Status: ${extensions.code}`,
  );
}
const handleNetworkError = (networkError) => {
  const errorMessage =
    networkError.statusCode === 401
      ? "[Network error]: UNAUTHORIZED"
      : `[Network error]: ${networkError}`;
  console.error(errorMessage);
  console.log("redirect from here");
  //SSO.redirectToSignInUrl();
};

const handleErrors = ({ graphQLErrors = [], networkError, error }) => {
  if(graphQLErrors.length > 0){
    console.log(graphQLErrors);
    _.forEach(graphQLErrors, helpers.handleGraphQLError)
  }else if(networkError){
    console.log(networkError, "Network error occured");  
    if(networkError.statusCode === 401 ){ //unathourized access, user has been logged out
      SSO.redirectToSignInUrl();
    }
  }
};

const errorHandlerLink = onError(handleErrors);

const composableLinks = [
  authMiddleware,
  errorHandlerLink,
  new HttpLink({
    uri: API_ENDPOINT,
    fetch,
    credentials: "include",
  }),
];

const link = ApolloLink.from(composableLinks);

const request = operation => makePromise(execute(link, operation));


export const helpers = {
  handleErrors,
  handleGraphQLError,
  handleNetworkError,
};

export default request;
