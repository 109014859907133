import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../../_shared/input-field/InputField';

const TextField = ({
  input,
  ...custom
}) => (
  <InputField
    {...input}
    {...custom}
  />
);


TextField.propTypes = {
  input: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};
TextField.defaultProps = {
  input: null,
};

export default TextField;
