import * as actions from './snackbarActions';

export const initialState = { open: false };

export default function snackbarReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.OPEN:
      return {
        ...state,
        open: true,
        message: action.message,
        showCloseIcon: action.showCloseIcon,
      };
    case actions.CLOSE:
      return {
        ...state,
        open: false,
        message: undefined,
        showCloseIcon: false,
      };
    default:
      return state;
  }
}
