import _config from "../config"

export default {
  express_base_url: _config.BASE_URL,
  SSO_signInUrl: "sso_signIn_url",
  SSO_signOutUrl: "sso_signOut_url",
  SSO_Session_Idx: "sso_session_index",
  SSO_isAuthenticated: "sso_is_authenticated",
  SSO_user_name: "sso_user_name",
  SSO_user_email: "sso_user_email",
  SSO_user_loginId: "sso_user_loginId",
  SSO_user_adGroups: "sso_user_adGroups",
  SSO_token_ttl: "sso_token_ttl",
  SSO_extUser_Ind: "sso_extUser_ind",
  fetch_details_param: "fetch_details_param",
};
