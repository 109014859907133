import request from '../../graphql/request';
import {
  FETCH_PROJECTS,
  UPDATE_PROJECT,
  CREATE_PROJECT,
  FILTER_PROJECTS,
  FETCH_PROJECT_DETAILS,
  PROP_CLOSING_CATGS,
  FETCH_PROJECT_STATUS,
  FETCH_STORE_DETAILS,
  FETCH_STORE_DETAILS_BY_COMPANY,
  UNLOCK_PROJECT,
  LOCK_PROJECT,
  CLOSE_PROJECT,
  DROP_PROJECT,
} from './queries';

export const fetchProjects = variables =>
  request({
    query: FETCH_PROJECTS,
    variables
  });

export const createProject = variables =>
  request({
    query: CREATE_PROJECT,
    variables
  });

export const updateProject = variables =>
  request({
    query: UPDATE_PROJECT,
    variables
  });

export const unlockProject = variables =>
  request({
    query: UNLOCK_PROJECT,
    variables
  });

export const lockProject = variables =>
  request({
    query: LOCK_PROJECT,
    variables
  });

export const closeProject = variables =>
  request({
    query: CLOSE_PROJECT,
    variables
  });

export const dropProject = variables =>
  request({
    query: DROP_PROJECT,
    variables
  });

export const filterProjects = variables =>
  request({
    query: FILTER_PROJECTS,
    variables
  });

export const fetchProjectDetailsById = variables =>
  request({
    query: FETCH_PROJECT_DETAILS,
    variables
  });

  export const fetchProjectCatogiriesByProjId = variables =>
  request({
    query: PROP_CLOSING_CATGS,
    variables
  });


export const fetchAllClosingStatus = variables =>
  request({
    query: FETCH_PROJECT_STATUS,
    variables
  });

  export const fetchStoreDetails = variables =>
  request({
    query: FETCH_STORE_DETAILS,
    variables
  });

  export const fetchStoreDetailsByCompany = variables =>
  request({
    query: FETCH_STORE_DETAILS_BY_COMPANY,
    variables
  });
