import { validateEmail } from '../../../utilities';

export const validatePasswordRules = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Password required';
  } 
  else if (values.password.trim() === '') {
    errors.password = 'Password only contains white spaces';
  } else if (values.password.length < 6) {
    errors.password = 'Password should contain at least 6 characters';
  } else if (values.password.match('(?=.*[A-Z])') === null) {
    errors.password = 'Password should contain at least one uppercase letter';
  } else if (values.password.match('(?=.*[a-z])') === null) {
    errors.password = 'Password should contain at least one lowercase letter';
  } else if (values.password.match('(?=.*[0-9])') === null) {
    errors.password = 'Password should contain at least one number';
  } 
  return errors;
};


export const validateUserId = (values) => {
  const errors={};
  
  if (!values.sys_userid) {
    errors.sys_userid = 'User Id required';
  } else if (values.sys_userid.length > 8) {
    errors.sys_userid = 'User Id should have 8 or less characters';
  }
  return errors;
}

export const validate = (values) => {
  const errors = validatePasswordRules(values); 
  if (!values.sys_userid) {
    errors.sys_userid = 'User Id required';
  } else if (values.sys_userid.length > 8) {
    errors.sys_userid = 'User Id should have 8 or less characters';
  }
  if (!values.extrn_first_name) {
    errors.extrn_first_name = 'First name required';
  } else if (values.extrn_first_name.length > 25) {
    errors.extrn_first_name = 'First name should have 25 or less characters';
  }
  if (!values.extrn_last_name) {
    errors.extrn_last_name = 'Last name required';
  } else if (values.extrn_last_name.length > 25) {
    errors.extrn_last_name = 'Last name should have 25 or less characters';
  }
  if (!values.extrn_addr_line1) {
    errors.extrn_addr_line1 = 'Address required';
  } else if (values.extrn_addr_line1.length > 35) {
    errors.extrn_addr_line1 = 'Address should have 35 or less characters';
  }
  if (!values.extrn_postal_cd) {
    errors.extrn_postal_cd = 'Zip required';
  } else if (values.extrn_postal_cd.length > 10) {
    errors.extrn_postal_cd = 'Zip should have 10 or less characters';
  }
  if (!values.extrn_st_prov_cd) {
    errors.extrn_st_prov_cd = 'State required';
  } else if (values.extrn_st_prov_cd.length > 2) {
    errors.extrn_st_prov_cd = 'State should have 2 or less characters';
  }
  if (!values.extrn_city_name) {
    errors.extrn_city_name = 'City required';
  } else if (values.extrn_city_name.length > 35 ) {
    errors.extrn_city_name = 'City should haev 35 or less characters';
  }
  if (!values.extrn_company_name) {
    errors.extrn_company_name = 'Firm required';
  }
  if (!values.ext_email_addr_id) {
    errors.ext_email_addr_id = 'Email required';
  } else if (!validateEmail(values.ext_email_addr_id)) {
    errors.ext_email_addr_id = 'Email is not valid';
  }
  return errors;
};
