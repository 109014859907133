import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import models from './models';
import configureStore from './store/configStore';
import { rootSaga } from './store/rootSaga';
import App from './App';
import './styles/styles.scss';
import theme from './theme';

const store = configureStore({ models });

store.runSaga(rootSaga);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
