import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchDocumentTypes } from "../../../services/document";
import * as actions from "../documentActions";

export default function* fetchDocTypesWatcher() {
  yield takeLatest(actions.FETCH_DOCUMENT_TYPES, fetchDocTypesWorker);
}

export function* fetchDocTypesWorker(action) {
  yield put(actions.loading(true));

  try {
    const response = yield call(fetchDocumentTypes, action);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));
  const data = _.get(response, "data.fetchAllDocListTypes.DocTypesList", []);
  yield put(actions.setDocumentTypes(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
