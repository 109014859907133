import * as actions from './errorModalActions';

export const initialState = { open: false };

export default function modalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.OPEN:
      return {
        ...state,
        open: true,
        title: action.title,
        message: action.message
      };
    case actions.CLOSE:
      return {
        ...state,
        open: false,
        title: null,
        message: null
      };
    default:
      return state;
  }
}
