import _ from 'lodash';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { filterProjects } from '../../../services/projects';
import * as actions from '../projectsActions';
import {
  convertData,
} from '../../../utilities';

export default function* filterProjectsWatcher() {
  yield takeLatest(actions.FILTER_UPDATE, filterProjectsWorker);
}

export function* filterProjectsWorker(action) {
  yield put(actions.loading(true));
  const filterObj = action.filterObject;

  try {
     let variables= {
       StoreNumber: filterObj[0].StoreNumber,
       SequenceNumber: filterObj[0].SequenceNumber,
       City: filterObj[0].City,
       State: filterObj[0].State,
       Active: filterObj[0].checkbox.active,
       Locked: filterObj[0].checkbox.locked,
       Closed: filterObj[0].checkbox.closed,
       Dropped: filterObj[0].checkbox.dropped,
     }
    const response = yield call(filterProjects, variables);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.projectsFilter', {});

  // var data = null;
  // if(!(filterObject.checkbox.active=== true && filterObject.checkbox.archived === true && filterObject.checkbox.locked === true))
  // {
  //   if(filterObject.checkbox.active===true && filterObject.checkbox.locked ===true)
  //   {
  //     data=_.filter(tempdata, function(temp) {
  //       return temp.closingStatus === 'Active' || temp.closingStatus === 'Closed';
  //   });
  //   }
  //   else if(filterObject.checkbox.locked===true && filterObject.checkbox.archived ===true)
  //   {
  //     data=_.filter(tempdata, function(temp) {
  //       return temp.closingStatus === 'Closed' || temp.closingStatus === 'Deleted';
  //   });
  //   }
  //   else if(filterObject.checkbox.active===true && filterObject.checkbox.archived ===true)
  //   {
  //     data=_.filter(tempdata, function(temp) {
  //       return temp.closingStatus === 'Active' || temp.closingStatus === 'Deleted';
  //   });
  //   }
  //   else if(filterObject.checkbox.active===true )
  //   {
  //     data=_.filter(tempdata, function(temp) {
  //       return temp.closingStatus === 'Active';
  //   });
  //   }
  //   else if(filterObject.checkbox.archived===true)
  //   {
  //     data=_.filter(tempdata, function(temp) {
  //       return temp.closingStatus === 'Deleted';
  //   });
  //   }
  //   else if(filterObject.checkbox.locked===true)
  //   {
      
  //     data=_.filter(tempdata, function(temp) {
  //       return temp.closingStatus === 'Closed';
  //   });
  //   }
  //   else
  //   {
  //     data = [];
  //   }


  // }
  // else
  // {
  //   data = tempdata;
  // }
  // //data = _.filter(tempdata, { closingStatus: 'Active' });
  

  const convertedData = _.map(convertData(data), extraData => ({
    ...extraData,
    documents: convertData(data.documents),
  }));

  yield put(actions.setData(convertedData));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
