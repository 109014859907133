import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DocInfo from './DocInfo';
import DocBar from './DocBar';
import DocViewer from './DocViewer';
import './Document.scss';

import { fetch } from './documentActions';

/**
 * Document Component
 *
 * @namespace Document
 * @memberof document.components
 */

const mapDispatchToProps = {
  fetchDocument: fetch,
};

export const Document = ({ match, fetchDocument }) => {
  useEffect(() => {
    fetchDocument(parseInt(match.params.documentId, 10));
  }, {});

  return (
    <div className="document">
      <div className="bar">
        <DocBar />
      </div>
      <div className="document">
        <DocViewer />
      </div>
      <div className="info">
        <DocInfo />
      </div>
    </div>
  );
};

Document.defaultProps = {
  match: { params: {} },
};

Document.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  fetchDocument: PropTypes.func.isRequired,
};

export default withRouter(connect(null, mapDispatchToProps)(Document));
