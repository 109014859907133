export const userRoles = {
  attorney: 'GENERAL_COUNCIL_ROLE',
  paralegal: 'PLADMIN_ROLE',
  realEstateManager: 'REAL_ESTATE_MANAGER_ROLE',
  designManager: 'DISTRICT_MANAGER_ROLE',
  auditor: 'AUDITOR_ROLE',
  regionalVicePresident: 'REGIONAL_VICE_PRESIDENT_ROLE',
  accountant: 'ACCOUNTANT_ROLE',
  outsideCouncel: 'OUTSIDE_COUNCIL_ROLE',
  cecFirm: 'CIVIL_ENGINEER_ROLE',
};
