import _ from 'lodash';

export const initialRequestState = {
  error: null,
  loading: false,
};

export const createRequestReducer = actionTypes => (state = initialRequestState, action = {}) => {
  switch (action.type) {
    case actionTypes.ERROR:
      return {
        ...state,
        error: _.get(action, 'error.message', null),
      };
    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};
