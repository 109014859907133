import {
  takeLatest,
  call,
  put,
  select,
} from 'redux-saga/effects';
import _ from 'lodash';
import React from 'react';
import { reset } from 'redux-form';
import CryptoJS from 'crypto-js';
import { deleteExternalUser } from '../../../services/users';
import { openSnackbar } from '../../_shared/snack-bar/snackbarActions';
import { errorOpenModal } from '../../_shared/modal/errorModalActions';
import * as actions from '../usersActions';

const usersSelector = state => _.get(state, "users.data");

export default function* deleteExternalUserWatcher() {
  yield takeLatest(actions.DELETE_EXT_USER, deleteExternalUserWorker);
}

export function* deleteExternalUserWorker(action) {
  yield put(actions.loading(true));

  var userName = action.userId;

  try {
    const response = yield call(deleteExternalUser, {userName:userName.trim()});
    const data = _.get(response, 'data.deleteExternalUser',
      {
        Success: 'false',
        Message: 'Something went wrong',
      });

    if (data.Success === 'true') {
      yield call(success, data, userName);
    } else {
      yield call(failure, new Error(data.Message));
    }
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response, userName) {

  let users = yield select(usersSelector);
  let removeIndex = _.findKey(users, { sys_userid: userName });
  
  users = _.omit(users, removeIndex);

  yield put(actions.setData(users));

  yield put(actions.loading(false));

  yield put(
    openSnackbar({
      message: response.Message,
      showCloseIcon: true,
    }),
  );
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));

  const errMsg = error.message;
  yield put(
    errorOpenModal({
      title: 'Delete User Failed',
      message: errMsg || 'An error occured while creating the temporary password',
    }),
  );
  return error;
}
