import _ from "lodash";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { editDocumentMetadata } from "../../../services/document";
import * as actions from "../documentActions";
import { setDocumentStatus, setDate } from "../utils";
import { openSnackbar } from "../../_shared/snack-bar/snackbarActions";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";
import { fetch as fetchProject } from "../../project/projectActions";

const documentSelector = state => _.get(state, "document.data");
const loggedInUserSelector = state => _.get(state, "session.user.data");

const formValueSelector = state =>
  _.get(state, "form.editDocumentMetadata.values");
const initialFormValuesSelector = state =>
  _.get(state, "form.editDocumentMetadata.initial");
const projectIdSelector = state =>
  _.get(state, "projectDetails.data.projectDetails.closing_id");
const documentTypeSelector = state =>
  _.get(state, "document.documentTypes");
let editMetadata;

export default function* editDocMetadataWatcher() {
  yield takeLatest(actions.EDIT_METADATA, editDocMetadataWorker);
}

export function* editDocMetadataWorker(action) {
  yield put(actions.loading(true));
  const formValues = yield select(formValueSelector);
  const initalformValues = yield select(initialFormValuesSelector);
  const projectId = yield select(projectIdSelector);
  const oldStatus = initalformValues.doc_status;
  const docStatuses = yield select(documentTypeSelector);
  //formValues.ecm_attr_doc_date = `${formValues.ecm_attr_doc_date}`;

  if (formValues.ecm_attr_doc_date && !formValues.ecm_attr_doc_date.includes("T")) {
    formValues.ecm_attr_doc_date = formValues.ecm_attr_doc_date + "T13:00:00";
  }

  //formValues.ecm_attr_doc_date = setDate(`${formValues.ecm_attr_doc_date}`);
  formValues.r_version_label = setDocumentStatus(
    formValues.r_version_label,
    formValues.doc_status,
  );

  let selectIndex = _.findKey(docStatuses, { type_name: formValues.doc_type });
  if (selectIndex > -1){
      formValues.doc_type_name = docStatuses[selectIndex].label_text;
  } else {
    formValues.doc_type_name = '';
  }

  editMetadata = formValues;
  formValues.project_id = projectId.toString();
  formValues.old_status = oldStatus;
  console.log("formValues", formValues);
  try {
    const response = yield call(editDocumentMetadata, formValues);
    yield call(success, response);
  } catch (error) {
    console.log("Error: ", error);
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));
  const data = _.get(response, "data.updateDocumentMetadata", []);
  const loggedInUserData = yield select(loggedInUserSelector);

  if (data.Success) {
    let documents = [];
    documents = yield select(documentSelector);
    let docIndex = _.findKey(documents, {
      documentum_doc_id: editMetadata.i_chronicle_id,
    });
   
    documents[docIndex].closing_doc_name = editMetadata.object_name;
    documents[docIndex].last_change_ts = new Date().toString();
    if (loggedInUserData) {
      documents[docIndex].last_change_userid = loggedInUserData.loginId;
    }
    
    let documentEdited = documents[docIndex];

    if (docIndex > -1) {
      documents.splice(docIndex, 1);
    }
    documents.unshift(documentEdited);

    yield put(actions.setData(documents));
    yield put(actions.closeEditDocumentMetadataModal());
    yield put(actions.setMetaDataForProject({ ...editMetadata }));
    yield put(
      openSnackbar({
        message: "Metadata updated!",
        showCloseIcon: true,
      }),
    );
    yield put(fetchProject(parseInt(documentEdited.closing_id)));

  } else {
    yield call(failure, data.responseMessage);
  }
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
  if (error.includes("E_IMMUTABLE_OBJECT")) {
    yield put(
      errorOpenModal({
        title: "Metadata Could Not Be Updated!",
        message: "The Document is Immutable",
      }),
    );
  }else if (error.includes("DOC_DUPLICATE")) {
    yield put(
      errorOpenModal({
        title: "Metadata Could Not Be Updated!",
        message: "Another document already has that name, type, and sub type for this store.  Please rename the document or change its type or sub type.",
      }),
    );
  }else {
    yield put(
      errorOpenModal({
        title: "Metadata Could Not Be Updated!",
        message: "An error occured while updating the document.",
      }),
    );
  }
  return error;
}
