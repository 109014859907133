/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from 'moment';

const mapStateToProps = (state) => {
  const { projectCreate } = state.form;
  return { projectCreate: projectCreate.values };
};

const Review = ({ projectCreate }) => (
  <div className="review-container">
    <div className="review-container-column">
      <Typography variant="h5" gutterBottom>
        Project Details
      </Typography>
      <div className="field-container">
        <Typography className="field-title">{projectCreate.closing_name}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Status:&nbsp;</Typography>
        <Typography>{'Active'}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">
          Projected Closing Date:&nbsp;
        </Typography>
        <Typography>{projectCreate.closing_start_ts ? moment(projectCreate.closing_start_ts).format('MM-DD-YYYY') : ''}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">
          Project/Store Sequence #:&nbsp;
        </Typography>
        <Typography>{projectCreate.project_seq_nbr}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Sellers:&nbsp;</Typography>
        <Typography>{projectCreate.seller_count}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Parcels:&nbsp;</Typography>
        <Typography>{projectCreate.parcel_count}</Typography>
      </div>
      <Typography variant="h5" gutterBottom>
        Store Details
      </Typography>
      <div className="field-container">
        <Typography className="field-title">Store #:&nbsp;</Typography>
        <Typography>{projectCreate.store_nbr}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Company #:&nbsp;</Typography>
        <Typography>{projectCreate.jde_company_nbr}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Store Sequence #:&nbsp;</Typography>
        <Typography>{projectCreate.store_seq_nbr}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Address:&nbsp;</Typography>
        <Typography>{projectCreate.addr_line1 ? projectCreate.addr_line1.label : ''}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">City:&nbsp;</Typography>
        <Typography>{projectCreate.addr_line1 ? projectCreate.city_name : ''}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">State:&nbsp;</Typography>
        <Typography>{projectCreate.addr_line1 ? projectCreate.state_prov_code : ''}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Store Type:&nbsp;</Typography>
        <Typography>{projectCreate.store_type_code}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Project Type:&nbsp;</Typography>
        <Typography>{projectCreate.project_type_desc}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">
          Business Unit Type:&nbsp;
        </Typography>
        <Typography>{projectCreate.bu_type_code}</Typography>
      </div>
    </div>
    <div className="review-container-divider" />
    <div className="review-container-column review-container-column-right">
      <Typography variant="h5" gutterBottom>
        Internal Users
      </Typography>
      <div className="field-container">
        <Typography className="field-title">Attorney:&nbsp;</Typography>
        <Typography>{projectCreate.attorney.label}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Paralegal:&nbsp;</Typography>
        <Typography>{projectCreate.paraLegal.label}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">
          Portfolio Manager:&nbsp;
        </Typography>
        <Typography>{projectCreate.realEstateManager.label}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Design Manager:&nbsp;</Typography>
        <Typography>{projectCreate.designManager.label}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Auditor:&nbsp;</Typography>
        <Typography>{projectCreate.auditor.label}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">
          Vice President:&nbsp;
        </Typography>
        <Typography>{projectCreate.regionalVicePresident.label}</Typography>
      </div>
      <div className="field-container">
        <Typography className="field-title">Accountant:&nbsp;</Typography>
        <Typography>{projectCreate.accountant.label}</Typography>
      </div>
      <Typography variant="h5" gutterBottom>
        External Users
      </Typography>
      <div className="field-container">
        <ul className="oustsideCounsel">
          <li>
            <span className="field-title">Outside Counsel:</span>
            &nbsp;{projectCreate.outsideCouncil.map((obj, i) => <span>{(i ? ', ' : '') + obj.label}</span>)}
          </li>
        </ul>
      </div>
      <div className="field-container">
        <Typography className="field-title">CEC Firm:&nbsp;</Typography>
        <Typography>{projectCreate.cecFirms.label}</Typography>
      </div>
    </div>
  </div>
);

Review.propTypes = {
  projectCreate: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Review);
