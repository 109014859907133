import _ from "lodash";
import { takeLatest, call, put } from "redux-saga/effects";
import { fetchUsers } from "../../../services/users";
import * as actions from "../usersActions";

export default function* fetchUsersWatcher() {
  yield takeLatest(actions.FETCH, fetchUsersWorker);
}

export function* fetchUsersWorker(action) {
  yield put(actions.loading(true));
  const { sortColumn, sortDirection, rowsPerPage, page, filterObject } = action.pageDetails;

  const variables = {
    page: page + 1,
    limit: rowsPerPage,
    field: sortColumn,
    direction: sortDirection,
    UserName: filterObject.UserName,
  };
  try {
    const response = yield call(fetchUsers, variables);
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, "data.fetchUsers.Users", {});
  const totalUsers = _.get(response, "data.fetchUsers.UsersCount", {});

  yield put(actions.setData(data));
  yield put(actions.setTotalUsers(totalUsers));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
