import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Button, TableRow, TableCell } from "@material-ui/core";
import Table from "../table/Table";

import "./TabVersion.scss";

// TODO: replace these constants with data from backend.
const sampleData = {
  versions: [
    {
      version: "3",
      modifiedBy: "Sevro",
      modifiedDate: moment("06-01-2019").valueOf(),
    },
    {
      version: "2",
      modifiedBy: "Sevro",
      modifiedDate: moment("09-01-2018").valueOf(),
    },
    {
      version: "1",
      modifiedBy: "Sevro",
      modifiedDate: moment("04-01-2018").valueOf(),
    },
  ],
};

export const columnHeaders = ["Version", "Modified by", "Modified date"];

/**
 * Document Version Metadata Tab Component
 *
 * @namespace Document
 * @memberof document.components
 */

export const TabVersion = props => {
  const getFile = file => async () => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => fileReader.result;

    fileReader.readAsArrayBuffer(file);
  };

  const openFileUploadDialog = async () => {
    const selectedFile = document.getElementById("input-upload-version")
      .files[0];
    if (selectedFile) {
      const file = await getFile(selectedFile);
      // TODO: Call saga to increment version and save file.
    }
  };

  const triggerFileInput = () => {
    document.getElementById("input-upload-version").click();
  };

  const { versions } = props;
  return (
    <div className='tab-version'>
      <input
        id='input-upload-version'
        type='file'
        onChange={openFileUploadDialog}
      />
      <Button className='new-version-button' onClick={triggerFileInput}>
        UPLOAD A NEW VERSION
      </Button>
      <Table columnHeaders={columnHeaders}>
        {versions.map(rowData => (
          <TableRow key={rowData.version} className='table-row'>
            <TableCell>{rowData.version}</TableCell>
            <TableCell>{rowData.modifiedBy}</TableCell>
            <TableCell>
              {moment(rowData.modifiedDate).format("MM/DD/YYYY")}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

TabVersion.defaultProps = {
  versions: [],
};

TabVersion.propTypes = {
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      version: PropTypes.string,
      modifiedBy: PropTypes.string,
      modifiedDate: PropTypes.number,
    }),
  ),
};

const mapStateToProps = () => ({
  versions: sampleData.versions,
});

export default connect(mapStateToProps)(TabVersion);
