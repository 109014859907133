import _ from "lodash";
import moment from "moment";

export const normalizeData = (data = []) => _.clone(data, true);

export const leadWithZero = number =>
  number < 10 ? `0${number}` : `${number}`;

export const dateValueTypes = [
  "lastModified",
  "createdDate",
  "executedDate",
  // "projectedClosingDate",
];

export const convertObjectValues = values => {
  const dateValues = _.pick(values, dateValueTypes);

  const convertedDateValues = _.mapValues(
    dateValues,
    value => value && _.toNumber(value),
  );

  return {
    ...values,
    ...convertedDateValues,
  };
};

export const convertData = data => _.map(data, convertObjectValues);

export const formatDate = dateString => {
  if (!_.isNull(dateString) && !_.isEmpty(dateString)) {
    var localdate = new Date(dateString);
    //console.log('dateTime after converting to local', moment(localdate).toString());
    return moment(localdate).format("MM-DD-YYYY");
  }
  return "";
};

export const formatDateAndTime = (dateString) => {
  if (!_.isNull(dateString) && !_.isEmpty(dateString)) {
    const localdate = new Date(dateString);
    return moment(localdate).format('MM-DD-YYYY LT');
  }
  return '';
};
export const formatDateWithoutConversion = (dateString) => {
  if (!_.isNull(dateString) && !_.isEmpty(dateString)) {
    const date = _.split(dateString, '-');
    return _.concat(date[1], '-', date[2], '-', date[0]);
  }
  return '';
};

export const getValueWithoutLeadingSpaces = value =>
  value ? value.replace(/^\s+/g, "") : value;
export const getValueWithoutTrailingSpaces = value =>
  value ? value.replace(/\s+$/g, "") : "";

export const validateEmail = (email) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}
