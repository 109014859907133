import _ from 'lodash';
import {
  takeLatest,
  call,
  put,
} from 'redux-saga/effects';
import { fetchDocument } from '../../../services/document';
import * as actions from '../documentActions';

export default function* fetchDocumentWatcher() {
  yield takeLatest(actions.FETCH, fetchDocumentWorker);
}

export function* fetchDocumentWorker(action) {
  yield put(actions.loading(true));

  try {
    // TODO: we can't connect to documentum yet.
    // Once we can, update this call with the service to get the PDF.
    const response = yield call(fetchDocument, action.documentId);
    // put the data in a format that normalizeData expects
    response.data.document = [response.data.document];
    yield call(success, response);
  } catch (error) {
    yield call(failure, error);
  }
}

export function* success(response) {
  yield put(actions.loading(false));

  const data = _.get(response, 'data.document', {});
  yield put(actions.setData(data));
}

export function* failure(error) {
  yield put(actions.loading(false));
  yield put(actions.error(error));
}
