import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  Typography,
  Button,
  IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import styles from "../../styles/utils/config.scss";
import WalmartSparkImage from "../../assets/images/walmartSpark.svg";
import "./Header.scss";
import SSO from "../../sso";
import * as _ from 'lodash';
import { selectUserRoles, selectLoggedInUser } from "../login/loginSelector";
import {
  adminWeight,
  getMaxWeight,
  viewOnlyWeight,
  externalUserWeight,
  getRoleWithMaxWeight,
} from "../../roleaccess/utils";
import { mapRolesToNames } from '../../roleaccess/mapRolesToNames';
const StyledTab = withStyles({
  selected: {
    color: styles.primaryColor,
  },
})(props => <Tab {...props} />);

const StyledTabs = withStyles({
  indicator: {
    "background-color": styles.primaryColor,
  },
})(props => <Tabs {...props} />);

const mapDispatchToProps = dispatch => ({});
const mapStateToProps = state => ({
  isLogoutDisabled: _.get(state, 'projects.createProject.request.loading', false)
    || _.get(state, 'document.request.loading', false)
    || _.get(state, 'projectDetails.request.loading', false),
  userRoles: selectUserRoles(state),
  LoggedInUser: selectLoggedInUser(state),
});

export const Header = ({ history, logoutUser, isLogoutDisabled, userRoles, LoggedInUser }) => {
  const initialNavValue = ["/projects", "/users"].findIndex(
    value => value === history.location.pathname
  );
  const [currentNavValue, setCurrentNavValue] = useState(
    initialNavValue === -1 ? 0 : initialNavValue
  );

  const canSeeUsers = getMaxWeight(userRoles) === adminWeight();
  const canChangePasword = getMaxWeight(userRoles) === externalUserWeight();
  // if user has more than 1 role get the role with highest power
  const userRoleWithMaxWeight = userRoles && userRoles.length > 0 ? getRoleWithMaxWeight(userRoles) : '';
  const userRole = userRoleWithMaxWeight ? mapRolesToNames.get(userRoleWithMaxWeight.value) : '';
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => setMenuAnchorEl(null);

  return (
    <div className="header">
      <AppBar position="static" color="default" className="app-bar">
        <Toolbar>
          <WalmartSparkImage height={24} width={24} />
          <Typography variant="h6" className="header-title">
            eClosing
          </Typography>
          <StyledTabs
            value={currentNavValue}
            onChange={(e, newVal) => setCurrentNavValue(newVal)}>
            <StyledTab
              className="nav-item"
              label="Projects"
              onClick={() => history.push("/projects")}
            />
            {canSeeUsers && (<StyledTab
              className="nav-item"
              label="Users"
              onClick={() => history.push("/users")}
            />)}
          </StyledTabs>
          <div style={{ marginLeft: 'auto' }}>
            <Typography variant="body1"> Welcome {LoggedInUser.name} </Typography>
            <Typography variant="caption"> Logged in as {userRole} </Typography>
          </div>
          <Button
            color="primary"
            className="logoutButton"
            size="medium"
            variant="contained"
            onClick={() => {
              SSO.signOut();
            }}
            disabled={isLogoutDisabled}
          >
            Logout
          </Button>
          {canChangePasword && (
            <React.Fragment>
              <IconButton color="primary" onClick={e => handleMenuOpen(e)}>
                <MoreIcon />
              </IconButton>
              <Menu
                id="lock-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                elevation={0}
                PaperProps={{
                  style: {
                    border: '1px solid #E0E0E0',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    history.push(`/changePassword`);
                  }}
                >
                  Change Password
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

Header.defaultProps = {
  history: { push: () => { } },
};

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
const reduxConnected = connect(mapStateToProps, mapDispatchToProps)(Header);

export default withRouter(reduxConnected);
