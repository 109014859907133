import React from "react";

const HealthCheck = () =>
{
  var pod = process.env.HOSTNAME == '' ? 'localhost':process.env.HOSTNAME;
  var clusterInfo = process.env.WCNP_CLUSTER_ID;
  console.log('Env values = ', process.env)
  return "ok" +  ',PodName:'+ pod + ',Cluster:'+clusterInfo ;
} ;


export default HealthCheck;
