import gql from "graphql-tag";

export const FETCH_DOCUMENT = gql`
  query Document($id: Int!) {
    document(id: $id) {
      id
      name
      file
      projectId
      deleted
      status
      executed
      executedDate
      type
      subType
      originatingDepartment
      url
      version
      lastModified
      modifiedBy
    }
  }
`;

export const FETCH_DOCUMENT_PROJECT_CAT = gql`
  query DocumentsByProjectAndCat($closingId: Int!, $categoryId: Int!) {
    documentsByProjectAndCategoryId(
      closingId: $closingId
      categoryId: $categoryId
    ) {
      closing_id
      documentum_doc_id
      doc_type_code
      slip_sheet_ind
      doc_category_id
      closing_doc_name
      slip_sheet_userid
      last_change_ts
      last_change_userid
      REL_doc_ind
      category_name
    }
  }
`;

export const FETCH_DOCUMENT_PROJECT = gql`
  query DocumentsByProjectAndCat($closingId: Int!) {
    documentsByProjectId(id: $closingId) {
      closing_id
      documentum_doc_id
      doc_type_code
      slip_sheet_ind
      doc_category_id
      closing_doc_name
      slip_sheet_userid
      last_change_ts
      last_change_userid
      REL_doc_ind
      category_name
    }
  }
`;

export const FETCH_DOCUMENT_TYPES = gql`
  query {
    fetchAllDocListTypes {
      Success
      DocTypesList {
        type_name
        label_text
        use_type
      }
    }
  }
`;

export const FETCH_DOCUMENT_SUB_TYPES = gql`
  query FetchSubTypes($docType: String!) {
    fetchSubTypes(docType: $docType) {
      Success
      DocSubTypesList {
        rel_attr_value
        rel_attr_desc
        use_subtype
        inactive
      }
    }
  }
`;

export const FETCH_DOCUMENT_METADATA = gql`
  query fetchDocumentMetadata($documentId: String!) {
    fetchDocumentMetadata(documentId: $documentId) {
      Success
      DocInfo {
        r_object_id
        object_name
        i_chronicle_id
        i_antecedent_id
        r_version_label
        r_creation_date
        r_modify_date
        owner_name
        acl_name
        doc_subtype_desc
        originating_dept
        doc_type
        ecm_attr_doc_date
        ecm_attr_env_related
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($documentId: String!, $closingId: Int!) {
    deleteDocument(
      document: { document_id: $documentId, projectId: $closingId }
    ) {
      Success
      Message
    }
  }
`;

export const FETCH_DOCUMENT_VERSION_DETAILS = gql`
  query fetchDocumentVersionDetails($documentId: String!) {
    fetchDocumentVersionDetails(documentId: $documentId) {
      Success
      Versions {
        r_version_label
        r_object_id
        r_modifier
        owner_name
        r_modify_date
      }
    }
  }
`;
export const EDIT_DOCUMENT = gql`
  query UpdateDocumentMetadata(
    $r_object_id: String!
    $object_name: String
    $doc_type: String!
    $doc_type_name: String
    $doc_subtype_desc: String
    $r_version_label: [String]
    $ecm_attr_doc_date: String
    $project_id: String!
    $ecm_attr_env_related: Boolean $old_status: String!  ) {
    updateDocumentMetadata(
      documentId: $r_object_id
      projectId: $project_id
      oldStatus: $old_status
      documentMetadata: {
        object_name: $object_name
        doc_type: $doc_type
        doc_type_name: $doc_type_name
        doc_subtype_desc: $doc_subtype_desc
        r_version_label: $r_version_label
        ecm_attr_doc_date: $ecm_attr_doc_date
        ecm_attr_env_related: $ecm_attr_env_related
      }
    ) {
      Success
      documentId
      responseMessage
    }
  }
`;
