import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import _ from "lodash";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { leadWithZero } from "../../../utilities";
import {
  selectProject,
  selectProjectDocumentCategory,
  selectProjectDocuments,
  metaDataViewOpen,
} from "../projectSelectors";

import {
  selectDocumentType,
  selectDocumentSubType,
} from "../../document/documentSelectors";
import { ProjectDocumentsNoResults } from "./ProjectDocumentsNoResults";
import ProjectDocumentsTable from "./ProjectDocumentsTable";
import ProjectDocumentMetaDataPage from "./ProjectDocumentMetaDataPage";

import styles from "./ProjectDocuments.module.scss";
import CommentsPanel from "../../comment/commentPanel/CommentsPanel";
import { viewOnlyWeight, getMaxWeight } from "../../../roleaccess/utils";
import * as commentActions from "../../comment/commentActions";
import {
  fetchDocProj,
  sortDocList,
  fetchDocTypes,
  fetchDocSubTypes,
  setMetaDataForProject,
  resetDocumentVersionsData,
  openUploadDocumentModal,
  closeUploadDocumentModal,
} from "../../document/documentActions";
import UploadNewDocument from "./UploadNewDocument";
import { errorOpenModal } from "../../_shared/modal/errorModalActions";
import { selectUserRoles } from "../../login/loginSelector";
import * as actions from "../projectActions";

import { REL_ADMIN } from "../../../roleaccess/adGroups";

const drawerWidth = "37%";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    'background-color': '#e1edf9',
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    // padding: theme.spacing(2),
  },
  floatLeft: {
    float: "left",
  },
  floatNone: {
    float: "none",
    marginBottom: "10px",
  },
  margin: {
    margin: "20px",
  },
  marginBottom: {
    marginBottom: "20px",
  },
  marginTop: {
    marginTop: "20px",
  },
}));

const canEdit = closingStatus =>
  !!closingStatus && /^active$/i.test(closingStatus);

const mapStateToProps = (state, ownProps) => {
  const params = _.get(ownProps, "match.params", {});
  const projectID = _.get(params, "projectId", null);
  const projectName = _.get(state, "projectDetails.data.projectDetails.closing_name", {});
  const statusCode = _.get(state, "projectDetails.data.projectDetails.status_code", {});
  const documentsCategoryID = _.get(params, "documentsCategoryId", null);
  const documentCategory = selectProjectDocumentCategory(
    state,
    documentsCategoryID,
  );
  const documents = selectProjectDocuments(state);
  const documentTypes = selectDocumentType(state);
  const documentSubTypes = selectDocumentSubType(state);
  const project = selectProject(state, projectID);
  const closingStatus = project.projectDetails ? project.projectDetails.closingStatus : '';
  const canAddNewDocuments = canEdit(closingStatus);
  const documentCategoryID = _.get(documentCategory, "doc_category_id", "");
  const isMetaDataViewOpen = metaDataViewOpen(state);
  const isUploadDialogOpen = _.get(
    state,
    "document.uploadNewDocModal.open",
    false,
  );
  //const userRole = _.get(state, "session.user.data.userRole");
  const userRole = selectUserRoles(state);
  const isLoading = _.get(state, "document.request.loading", false);
  return {
    canAddNewDocuments,
    documentCategoryIndex: documentsCategoryID,
    documentCategoryID: _.get(documentCategory, "doc_category_id", ""),
    documentCategoryDescription: _.get(documentCategory, "category_name", ""),
    documents,
    documentTypes,
    documentSubTypes,
    projectID,
    isMetaDataViewOpen,
    projectName,
    isUploadDialogOpen,
    statusCode,
    userRole,
    isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchProjectComments: closingId => dispatch(commentActions.fetch(closingId)),
  fetchCategoryComments: categoryId =>
    dispatch(commentActions.fetchCategoryComments(categoryId)),
  fetchAllCategoryCommentsForProject: closingId =>
    dispatch(commentActions.fetchAllCategoryCommentsForProject(closingId)),
  resetComments: () => dispatch({ type: commentActions.RESET_DATA }),
  fetchDocument: projectID => dispatch(fetchDocProj(projectID)),
  sortDocList: data => dispatch(sortDocList(data)),
  fetchDocumentTypes: () => {
    dispatch(fetchDocTypes());
  },
  fetchDocumentSubTypes: docType => dispatch(fetchDocSubTypes(docType)),
  openErrorModel: (title, message) => dispatch(errorOpenModal(title, message)),
  isMetaDataView: (metaDataView, document) =>
    dispatch(actions.setMetaDataView(metaDataView, document)),
  changeSelectedProjectMetadata: metadata =>
    dispatch(setMetaDataForProject(metadata)),
  resetDocumentVersionsData: () => dispatch(resetDocumentVersionsData()),
  openUploadDocModal: () => dispatch(openUploadDocumentModal()),
  closeUploadDocModal: () => dispatch(closeUploadDocumentModal()),
  fetchProject: closingId => dispatch(actions.fetch(closingId)),
});

export const ProjectDocuments = ({
  canAddNewDocuments,
  documentCategoryDescription,
  documentCategoryID,
  projectID,
  documentCategoryIndex,
  documents,
  documentTypes,
  documentSubTypes,
  fetchProjectComments,
  fetchCategoryComments,
  fetchAllCategoryCommentsForProject,
  resetComments,
  fetchDocument,
  sortDocList,
  fetchDocumentTypes,
  fetchDocumentSubTypes,
  openErrorModel,
  isMetaDataViewOpen,
  isMetaDataView,
  projectName,
  changeSelectedProjectMetadata,
  resetDocumentVersionsData,
  isUploadDialogOpen,
  openUploadDocModal,
  closeUploadDocModal,
  statusCode,
  userRole,
  isLoading,
  fetchProject,
}) => {
  let headingToShow =
    leadWithZero(documentCategoryIndex) + " - " + documentCategoryDescription;
  let subHeading = "";
  let headingGridWidth = 6;

  //const canViewComments = userRole ? !userRole.includes(REL_ADMIN) : true;
  const classes = useStyles();
  const [openUploadDialog, setOpenUploadDialog] = React.useState(false);

  var documentsOfCat = documents.filter(function (doc) {
    return doc.doc_category_id === documentCategoryID;
  });

  if (
    null != isMetaDataViewOpen &&
    undefined != isMetaDataViewOpen &&
    null != isMetaDataViewOpen.selectedDocument &&
    undefined != isMetaDataViewOpen.selectedDocument
  ) {
    if (
      isMetaDataViewOpen.selectedDocument.doc_category_id != documentCategoryID
    ) {
      clearSelectedDocumentForMetadataView();
    } else {
      headingToShow = isMetaDataViewOpen.selectedDocument.closing_doc_name;
      headingGridWidth = 11;
      subHeading = projectName + " > " + documentCategoryDescription;
    }
  }

  useEffect(() => {
    fetchDocument(parseInt(projectID));
    fetchDocumentTypes();
  }, {});

  const [open, setOpen] = React.useState(false);
  function handleDrawerOpen(categoryId) {
    fetchProjectComments(parseInt(projectID));
    fetchAllCategoryCommentsForProject(parseInt(projectID));
    setOpen(true);
  }
  function handleDrawerClose() {
    resetComments();
    setOpen(false);
    fetchProject(parseInt(projectID));
  }
  function clearSelectedDocumentForMetadataView() {
    isMetaDataView(false, null);
    changeSelectedProjectMetadata({
      r_object_id: "",
      object_name: "",
      i_chronicle_id: "",
      r_version_label: "",
      r_creation_date: "",
      r_modify_date: "",
      owner_name: "",
      acl_name: "",
      doc_subtype_desc: "",
      originating_dept: "",
      doc_type: "",
      ecm_attr_doc_date: "",
      doc_type_desc: "",
      doc_status: "",
    });
    resetDocumentVersionsData();
  }

  const invertDirection = {
    asc: "desc",
    desc: "asc",
  };
  const [sortDirection, setSortDirection] = React.useState("desc");
  const canNotUploadDoc = getMaxWeight(userRole) == viewOnlyWeight();

  const handleSorting = column => {
    setSortDirection(invertDirection[sortDirection]);

    let sortedColumn;

    if (column == "Document Name") {
      sortedColumn = "closing_doc_name";
    } else if (column == "Last Modified") {
      sortedColumn = "last_change_ts";
    } else if (column == "By") {
      sortedColumn = "last_change_userid";
    }
    let documentsSorted = _.orderBy(documents, [sortedColumn], [sortDirection]);
    sortDocList(documentsSorted);
  };
  return (
    <Grid container justify='center' spacing={3}>
      <Grid item xs={12}>
        <header className={styles.header}>
          <Grid container justify='flex-start' spacing={1}>
            {isMetaDataViewOpen.metaDataViewOpen ? (
              <Grid item xs={1} sm={1}>
                <Button
                  onClick={() => {
                    clearSelectedDocumentForMetadataView();
                  }}>
                  <ChevronLeft />
                </Button>
              </Grid>
            ) : (
                <span />
              )}
            <Grid item xs={headingGridWidth} sm={headingGridWidth}>
              <Typography variant="h5">{headingToShow}</Typography>
              {isMetaDataViewOpen.metaDataViewOpen ? (
                <Typography
                  variant="h6"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '1.2rem',
                  }
                  }
                >
                  {subHeading}
                </Typography>
              ) : (
                  <span />
                )}
            </Grid>
            {!isMetaDataViewOpen.metaDataViewOpen ? (
              <Grid item xs={6} sm={6}>
                {!isLoading && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='viewCommentsButtonDocuments'
                    onClick={() => {
                      handleDrawerOpen(documentCategoryID);
                    }}>
                    View Comments
                </Button>
                )}
                {(!canNotUploadDoc &&  !isLoading && statusCode != '2') && (
                  <Button
                    variant='contained'
                    color='primary'
                    className='viewCommentsButtonDocuments'
                    onClick={() => {
                      openUploadDocModal();
                    }}>
                    Add new document
                  </Button>
                )}
              </Grid>
            ) : (
                <span />
              )}
          </Grid>
        </header>
      </Grid>
      {isLoading && <CircularProgress />}
      <Grid item xs={12}>
        {documentsOfCat.length ? (
          isMetaDataViewOpen.metaDataViewOpen ? (
            <ProjectDocumentMetaDataPage
              document={isMetaDataViewOpen.selectedDocument}
              openErrorModel={openErrorModel}
              categoryId={documentCategoryID}
              projectId={projectID}
              statusCode={statusCode}
            />
          ) : (
              <ProjectDocumentsTable
                documents={documentsOfCat}
                handleSorting={handleSorting}
                sortDirection={sortDirection}
                openErrorModel={openErrorModel}
                userRole={userRole}
                statusCode={statusCode}
              />
            )
        ) : (
            !isLoading && <ProjectDocumentsNoResults />
          )}
        <Drawer
          className={classes.drawer}
          anchor='right'
          open={open}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}>
          <CommentsPanel
            categoryName={documentCategoryDescription}
            categoryId={documentCategoryID}
            isProjectLevel={false}
            statusCode={statusCode}
          />
        </Drawer>
        <UploadNewDocument
          documentTypes={documentTypes}
          documentSubTypes={documentSubTypes}
          fetchDocumentSubTypes={fetchDocumentSubTypes}
          open={isUploadDialogOpen}
          categoryId={documentCategoryID}
          projectId={projectID}
          handleClose={() => {
            closeUploadDocModal();
          }}
        />
      </Grid>
    </Grid>
  );
};

ProjectDocuments.defaultProps = {
  canAddNewDocuments: false,
  documentCategoryDescription: "",
  documentCategoryID: "",
  projectID: "",
  documents: [],
};

ProjectDocuments.propTypes = {
  canAddNewDocuments: PropTypes.bool,
  documentCategoryDescription: PropTypes.string,
  documentCategoryID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  projectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      closing_doc_name: PropTypes.string,
      last_change_userid: PropTypes.string,
    }),
  ),
  fetchCategoryComments: PropTypes.func.isRequired,
  resetComments: PropTypes.func.isRequired,
  fetchDocument: PropTypes.func,
  sortDocList: PropTypes.func,
  fetchDocumentTypes: PropTypes.func,
  fetchDocumentSubTypes: PropTypes.func,
  openErrorModel: PropTypes.func.isRequired,
  isUploadDialogOpen: PropTypes.bool.isRequired,
  openUploadDocModal: PropTypes.func.isRequired,
  closeUploadDocModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const reduxConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDocuments);

export default withRouter(reduxConnected);
