import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as _ from 'lodash';
import { isRequestInprogress } from '../usersSelectors';
import { createTempUserPassword } from '../usersActions';
import ManageUser from '../ManageUser';
import { validatePasswordRules } from './ValidateCreateUser';


const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
  },
  innerContainer: {
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  button: {
    float: 'right',
  },
  updateButton: {
    float: 'right',
    marginTop: theme.spacing(3),
  },
  loadingSpinner: {
    position: 'relative',
    left: '44%',
  },
}));
let SetTemPassword = (props) => {
  const {
    history,
    reset,
    invalid,
    setTempUserPassword,
    isLoading,
  } = props;
  const classes = useStyles();
  const { user } = history.location.state;
  const userName = `${user.extrn_first_name.trim()} ${user.extrn_last_name.trim()}`;

  const onCancel = () => {
    reset();
    history.replace('/users');
  };
  return (
        <Grid
            container
            spacing={6}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.container}
        >
            <Grid
                container
                spacing={4}
                item
                xs={8}
            >
                <Grid item xs={12}>
                    <IconButton
                        className={classes.button}
                    >
                        <Close onClick={onCancel} />
                    </IconButton>
                    <Typography variant="h5">Manage Outside Users: {userName}</Typography>
                    {isLoading && <CircularProgress className={classes.loadingSpinner} />}
                </Grid>
                <ManageUser {...props} />
                <Grid xs={12} item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.updateButton}
                        onClick={() => setTempUserPassword(user.sys_userid)}
                    >
                        RESET PASSWORD
          </Button>
                </Grid>
            </Grid>
        </Grid>
  );
};

SetTemPassword.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  }).isRequired,
  reset: PropTypes.func.isRequired,
  setTempUserPassword: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

SetTemPassword = reduxForm({
  form: 'createTempUserPassword',
  enableReinitialize: true,
  validate: validatePasswordRules,
})(SetTemPassword);
const mapStateToProps = (state, ownProps) => {
  const isLoading = isRequestInprogress(state);
  let initialValues = _.get(ownProps, 'history.location.state.user', {});
  initialValues = {
    ...initialValues,
    extrn_company_name: {
      label: initialValues.extrn_company_name,
      value: initialValues.extrn_company_name,
    }
  }
  return ({
    isLoading,
    initialValues,
  });

};
const mapDispatchToProps = dispatch => ({
  setTempUserPassword: (userId) => dispatch(createTempUserPassword(userId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SetTemPassword);
