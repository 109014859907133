import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import selectFieldStyles from './SelectField.scss';
import config from '../../../styles/utils/config.scss';

/**
 * Customized Select Field Component
 *
 * @namespace Shared
 * @memberof shared.components
 */

class SelectField extends Component {
  selectStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: config.whiteColor,
      'min-height': selectFieldStyles.inputHeight,
      borderColor: config.black38opacityColor,
    }),
    option: (styles, {
      data,
      isDisabled,
      isFocused,
      isSelected,
    }) => ({
      ...styles,
      backgroundColor: this.setBackgroundRowColor({ isDisabled, isFocused }),
      color: this.setTextColor({ isDisabled, isSelected }),
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (
          isSelected ? data.color : config.selectedSelectBackgroundRowColor),
      },
    }),
    input: styles => ({ ...styles }),
    indicatorSeparator: () => ({ display: 'none' }),
  };

  setBackgroundRowColor = (styles) => {
    const { isDisabled, isFocused } = styles;

    if (isDisabled) {
      return config.disabledSelectBackroundRowColor;
    }
    if (isFocused) {
      return config.focusedSelectBackgroundRowColor;
    }
    return config.whiteColor;
  };

  setTextColor = (styles) => {
    const { primaryColor, black87opacityColor, black54opacityColor } = config;
    const { isDisabled, isSelected } = styles;

    if (isDisabled) {
      return black87opacityColor;
    }
    if (isSelected) {
      return primaryColor;
    }
    return black54opacityColor;
  };

  render() {
    const {
      isClearable,
      isSearchable,
      placeholder,
      inputLabel,
      options,
      value,
      defaultValue,
      disabled,
      isMulti,
      onChange,
      onFocus,
    } = this.props;
    return (
      <div className="select-field">
        <div className="select-field-label">
          <Typography variant="caption">
            {inputLabel}
          </Typography>
        </div>

        <Select
          classNamePrefix="react-select"
          value={value || defaultValue}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          options={options}
          isClearable={isClearable}
          isSearchable={isSearchable}
          styles={this.selectStyles}
          isDisabled={disabled}
          isMulti={isMulti}
          onFocus={onFocus}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: config.focusedInputColor,
            },
          })}
        />
      </div>
    );
  }
}

SelectField.propTypes = {
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  inputLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  ]).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  onFocus: PropTypes.func,
};

SelectField.defaultProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: 'Select...',
  inputLabel: '',
  defaultValue: null,
  onChange: undefined,
  disabled: false,
  isMulti: false,
  onFocus: undefined,
};

export default SelectField;
