  /* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import _ from "lodash";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import styles from "../../../styles/utils/config.scss";
import { formatDateAndTime, formatDateWithoutConversion } from "../../../utilities";
import EditDocumentMetadata from "./EditDocumentMetadata";
import UploadNewDocVersion from "./UploadNewDocVersion";
import {
  loadMetadata,
  fetchDocumentVersionsData,
  openEditDocumentMetadataModal,
  closeEditDocumentMetadataModal,
  openUploadVersionModal,
  closeUploadVersionModal,
} from "../../document/documentActions";
import { downloadDocument } from "../../document/utils";
import * as modalActions from "../../_shared/modal/modalActions";
import { getDateToDisplay } from "../../document/utils";
import { selectUserRoles } from "../../login/loginSelector";
import {
  getSelectedDocumentMetadata,
  selectDocumentType,
  selectDocumentSubType,
  getDocumentVersionsData,
} from "../../document/documentSelectors";
import {
  adminWeight,
  relAdminWeight,
  getMaxWeight,
  viewOnlyWeight,
} from "../../../roleaccess/utils";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    width: `calc(100% + 1px) !important`,
  },
  hoverDocName: {
    "&:hover": {
      color: styles.primaryColor,
    },
  },
  loadingSpinner: {
    marginLeft: "48%",
  },
  versionLoadingSpinner: {
    marginLeft: 15,
    marginTop: 1,
  },
  headerTableCell: {
    color: styles.whiteColor,
  },
  btn: {
    padding: '4px 16px',
  },
}));
const RenderVersionNameCell = ({ version, openErrorModel }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  return (
    <TableCell>
      <Link
        target='_blank'
        component='button'
        underline='hover'
        color='inherit'
        className={classes.hoverDocName}
        onClick={() =>
          downloadDocument(
            version.documentVerionId,
            false,
            openErrorModel,
            setLoading,
          )
        }>
        <span
          style={
            version.isCurrent
              ? { fontWeight: "bold" }
              : { fontWeight: "normal" }
          }>
          {version.versionLabel}
        </span>
        {version.isCurrent && <span> (current)</span>}
      </Link>
      {isLoading && (
        <CircularProgress size={20} className={classes.versionLoadingSpinner} />
      )}
    </TableCell>
  );
};
RenderVersionNameCell.propTypes = {
  version: PropTypes.shape({
    isCurrent: PropTypes.string,
    versionLabel: PropTypes.string,
    documentVerionId: PropTypes.string,
  }).isRequired,
  openErrorModel: PropTypes.func.isRequired,
};
const mapDispatchToProps = dispatch => ({
  loadMetadataForDocument: documentId => dispatch(loadMetadata(documentId)),
  openDocStatusChangedConfirmModal: body =>
    dispatch(modalActions.openModal({ body })),
  fetchDocumentVersions: documentId =>
    dispatch(fetchDocumentVersionsData(documentId)),
  openEditDocMetadataModal: () => dispatch(openEditDocumentMetadataModal()),
  closeEditDocMetadataModal: () => dispatch(closeEditDocumentMetadataModal()),
  openUploadVersModal: () => dispatch(openUploadVersionModal()),
  closeUploadVersModal: () => dispatch(closeUploadVersionModal()),
});

const mapStateToProps = (state, ownProps) => {
  const documentMetadata = getSelectedDocumentMetadata(state);
  const documentSubTypes = selectDocumentSubType(state);
  const documentVersions = getDocumentVersionsData(state);
  const documentTypes = selectDocumentType(state);
  const isEditDialogOpen = _.get(
    state,
    "document.editMetadataDocModal.open",
    false,
  );
  const isUploadVersDialogOpen = _.get(
    state,
    "document.uploadDocVersionModal.open",
    false,
  );
  const isEditFormSubmitting = _.get(state, "document.request.loading", false);
  const isFecthingDocInfo = _.get(
    state,
    "document.selectedDocumentMetadata.request.loading",
    false,
  );
  const isFecthingDocVersions = _.get(
    state,
    "document.documentVersions.request.loading",
    false,
  );

  //const userRole = _.get(state, "session.user.data.userRole");
  const userRole = selectUserRoles(state);

  return {
    documentMetadata,
    documentSubTypes,
    documentTypes,
    documentVersions,
    isEditDialogOpen,
    isEditFormSubmitting,
    isUploadVersDialogOpen,
    isFecthingDocInfo,
    isFecthingDocVersions,
    userRole,
  };
};

export const ProjectDocumentMetaDataPage = ({
  document,
  loadMetadataForDocument,
  documentMetadata,
  documentSubTypes,
  openDocStatusChangedConfirmModal,
  fetchDocumentVersions,
  documentVersions,
  openErrorModel,
  openEditDocMetadataModal,
  closeEditDocMetadataModal,
  openUploadVersModal,
  closeUploadVersModal,
  isEditDialogOpen,
  isEditFormSubmitting,
  isUploadVersDialogOpen,
  isFecthingDocInfo,
  isFecthingDocVersions,
  userRole,
  statusCode,
}) => {
  const classes = useStyles();
  useEffect(() => {
    loadMetadataForDocument(document.documentum_doc_id);
    fetchDocumentVersions(document.documentum_doc_id);
  }, {});
  const canEditInformation = () => {
    const isStatus = documentMetadata.doc_status;
    if(getMaxWeight(userRole) === relAdminWeight() && ! _.isEmpty(isStatus)){
      return true;
    }else 
    if (_.isEmpty(isStatus) || isStatus === "Public Viewable") {
      return false;
    } else if (
      getMaxWeight(userRole) > viewOnlyWeight() 
    ) {
      return true;
    }else {
      return false;
    }  
  };

  const canUploadVersion = () => {
    const isStatus = documentMetadata.doc_status;
    const docVersions = documentVersions.length;
    return !(getMaxWeight(userRole) <= viewOnlyWeight() 
    || _.isEmpty(isStatus) || isStatus === "Public Viewable" || docVersions < 0);
  }
  const canEdit = canEditInformation();
  const  canUpload = canUploadVersion();

  let editFormInitialValues = documentMetadata;
  editFormInitialValues.ecm_attr_env_related = editFormInitialValues.ecm_attr_env_related == '1';

  return (
    <Grid container justify='flex-start' spacing={3} alignItems='stretch'>
      <Grid item xs={12} sm={12}>
        {(isFecthingDocInfo || isFecthingDocVersions) && (
          <CircularProgress className={classes.loadingSpinner} />
        )}
      </Grid>
      <Grid item xs={6} sm={6}>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left" className={classes.headerTableCell}>Document Information</TableCell>
                <TableCell align="right">
                  {canEdit && statusCode != 2 && (
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.btn}
                      onClick={() => {
                        openEditDocMetadataModal();
                      }}>
                      <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', display: 'block' }}>
                        Edit Information
                      </span>
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Document Name</b>
                </TableCell>
                <TableCell>{documentMetadata.object_name} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Document Type</b>
                </TableCell>
                <TableCell>{documentMetadata.doc_type_desc}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Document Sub-Type</b>
                </TableCell>
                <TableCell>{documentMetadata.doc_subtype_desc}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Document Status</b>
                </TableCell>
                <TableCell>{documentMetadata.doc_status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Originating Department</b>
                </TableCell>
                <TableCell>{documentMetadata.originating_dept}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Executed Date</b>
                </TableCell>
                <TableCell>
                  {formatDateWithoutConversion(getDateToDisplay(documentMetadata.ecm_attr_doc_date))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Environmental</b>
                  <Checkbox
                    checked={documentMetadata.ecm_attr_env_related == '1'}
                    disabled
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <EditDocumentMetadata
          open={isEditDialogOpen}
          isSubmitting={isEditFormSubmitting}
          document={document}
          documentSubTypes={documentSubTypes}
          initialValues={editFormInitialValues}
          openDocStatusChangedConfirmModal={openDocStatusChangedConfirmModal}
          userRole={userRole}
          handleClose={() => {
            closeEditDocMetadataModal(false);
          }}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              {canUpload && statusCode != 2 && (
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.btn}
                      onClick={() => {
                        openUploadVersModal();
                      }}>
                      Upload Version
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className={classes.headerTableCell}>Version</TableCell>
                <TableCell className={classes.headerTableCell}>Last Modified</TableCell>
                <TableCell className={classes.headerTableCell}>By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentVersions &&
                documentVersions.length > 0 &&
                documentVersions.map(version => (
                  <TableRow>
                    <RenderVersionNameCell
                      version={version}
                      openErrorModel={openErrorModel}
                    />
                    <TableCell>
                      {formatDateAndTime(version.modifiedDate)}
                    </TableCell>
                    <TableCell>{version.ownerName}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <UploadNewDocVersion
          documentId={document.documentum_doc_id}
          docStatus={documentMetadata.doc_status}
          open={isUploadVersDialogOpen}
          handleClose={() => {
            closeUploadVersModal();
          }}
        />
      </Grid>
    </Grid>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDocumentMetaDataPage);
