import _ from 'lodash';
import { normalizeData } from '../utilities';

export const initialDataState = [];

export const createDataReducer = actionTypes => (state = initialDataState, action = {}) => {
  if (!action.type) return state;
  switch (action.type) {
    case actionTypes.SET_DATA: {
      const normalizedData = normalizeData(action.data);
      return normalizedData;
    }
    case actionTypes.RESET_DATA: {
      return initialDataState;
    }
    case actionTypes.UPSERT_ITEM: {
      const { id, data } = action;
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          ...data,
        },
      };
      return _.values(newState);
    }
    case actionTypes.INSERT_ITEM: {
      const newState = state.slice();
      newState.unshift(action.data);
      return newState;
    }
    case actionTypes.DELETE_ITEM: {
      const newState = _.omit(state, action.id);
      return _.values(newState);
    }
    case actionTypes.APPEND_DATA: {
      return [
        ...state,
        ...action.data,
      ];
    }
    default:
      return state;
  }
};
